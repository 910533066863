import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AUTHOR_MUSIC_TABS, TRACK_COLUMNS_SETS } from 'constant';

import { LayoutStack, TabOptions, Tabs } from 'components/common';
import { MusicCollectionCard } from 'components/music-collection-card';
import { MusicTableHeader } from 'components/music-table-header';
import { PlaylistMusicItem } from 'components/particulars/playlist-music-item';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';
import { useCollectionsByWalletIdAndCategory } from 'services/collection-service';
import {
  useCreatedItemsByWalletIdAndCategory,
  useOwnedItemsByWalletId,
} from 'services/item-service';
import { useUser } from 'services/user-service';
import {
  generateAuthorSinglesPlaylistId,
  generateCollectionPlaylistId,
  generateUserOwnedItemsPlaylistId,
} from 'utils/music-playlist-names-generators';

import styles from './user-music-section.module.css';

interface Props {
  walletAddress: string;
}

export const UserMusicSection = ({ walletAddress }: Props) => {
  const { data: createdByUserItems } = useCreatedItemsByWalletIdAndCategory(walletAddress, 'music');
  const { data: userCollections } = useCollectionsByWalletIdAndCategory(walletAddress, 'music');
  const { data: userPodcasts } = useCollectionsByWalletIdAndCategory(walletAddress, 'podcasts');
  const { data: userOwnedItems } = useOwnedItemsByWalletId(walletAddress, 'music');
  const { data: author } = useUser(walletAddress);

  const { pause, play, isPaused, playPlaylist, addPlaylistFromItems, currentPlaylist } =
    useContext(AudioPlayerContext);

  const singlesPlaylistId = generateAuthorSinglesPlaylistId(walletAddress);
  const firstCollectionPlaylistId =
    userCollections && userCollections[0]
      ? generateCollectionPlaylistId(userCollections[0].id)
      : undefined;
  const firstPodcastPlaylistId =
    userPodcasts && userPodcasts[0] ? generateCollectionPlaylistId(userPodcasts[0].id) : undefined;
  const userOwnedItemsPlaylistId = generateUserOwnedItemsPlaylistId(walletAddress);

  const isSinglesACurrentPlaylist =
    currentPlaylist !== undefined && currentPlaylist.id === singlesPlaylistId;
  const isFirstCollectionACurrentPlaylist =
    currentPlaylist !== undefined && currentPlaylist.id === firstCollectionPlaylistId;
  const isFirstPodcastACurrentPlaylist =
    currentPlaylist !== undefined && currentPlaylist.id === firstPodcastPlaylistId;
  const isUserOwnedItemsACurrentPlaylist =
    currentPlaylist !== undefined && currentPlaylist.id === userOwnedItemsPlaylistId;

  const TABS: TabOptions = [
    {
      value: AUTHOR_MUSIC_TABS.SINGLES,
      label: 'Singles',
      badge: createdByUserItems
        ?.filter((i) => i.lockedMediaIpfsUrl || i.streamPreviewUrl)
        .length.toString(),
    },
    {
      value: AUTHOR_MUSIC_TABS.ALBUMS,
      label: 'Albums',
      badge: userCollections?.length.toString(),
    },
    {
      value: AUTHOR_MUSIC_TABS.PODCASTS,
      label: 'Podcasts',
      badge: userPodcasts?.length.toString(),
    },
    {
      value: AUTHOR_MUSIC_TABS.OWNED,
      label: 'Owned',
      badge: userOwnedItems
        ?.filter((i) => i.lockedMediaIpfsUrl || i.streamPreviewUrl)
        .filter((i) => i.hasLockedMedia)
        .length.toString(),
    },
  ];

  const [currentOption, setCurrentOption] = useState(TABS[0]);

  const getItemsBySelectedTab = useCallback(() => {
    const items =
      currentOption.value === AUTHOR_MUSIC_TABS.OWNED ? userOwnedItems : createdByUserItems;
    const cleanItems = (items || []).filter((i) => i.lockedMediaIpfsUrl || i.streamPreviewUrl);

    return cleanItems;
  }, [createdByUserItems, currentOption.value, userOwnedItems]);

  useEffect(() => {
    const items = getItemsBySelectedTab();
    if (items) {
      addPlaylistFromItems(items, `${walletAddress} singles`, singlesPlaylistId);
      addPlaylistFromItems(items, `${walletAddress} owned`, userOwnedItemsPlaylistId);
    }
  }, [
    addPlaylistFromItems,
    getItemsBySelectedTab,
    singlesPlaylistId,
    userOwnedItemsPlaylistId,
    walletAddress,
  ]);

  useEffect(() => {
    if (firstCollectionPlaylistId && userCollections && userCollections[0]) {
      addPlaylistFromItems(
        userCollections[0].items || [],
        userCollections[0].displayName,
        firstCollectionPlaylistId,
      );
    }
  }, [addPlaylistFromItems, firstCollectionPlaylistId, userCollections]);

  const isMainButtonPlaying = useMemo(() => {
    if (isPaused) {
      return false;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.SINGLES) {
      return isSinglesACurrentPlaylist && !isPaused;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.ALBUMS) {
      return isFirstCollectionACurrentPlaylist && !isPaused;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.PODCASTS) {
      return isFirstPodcastACurrentPlaylist && !isPaused;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.OWNED) {
      return isUserOwnedItemsACurrentPlaylist && !isPaused;
    }

    return false;
  }, [
    currentOption.value,
    isFirstCollectionACurrentPlaylist,
    isFirstPodcastACurrentPlaylist,
    isPaused,
    isSinglesACurrentPlaylist,
    isUserOwnedItemsACurrentPlaylist,
  ]);

  const onPlayPauseClicked = () => {
    if (
      (isSinglesACurrentPlaylist && currentOption.value === AUTHOR_MUSIC_TABS.SINGLES) ||
      (isFirstCollectionACurrentPlaylist && currentOption.value === AUTHOR_MUSIC_TABS.ALBUMS) ||
      (isFirstPodcastACurrentPlaylist && currentOption.value === AUTHOR_MUSIC_TABS.PODCASTS) ||
      (isUserOwnedItemsACurrentPlaylist && currentOption.value === AUTHOR_MUSIC_TABS.OWNED)
    ) {
      if (isPaused) {
        play();
        return;
      }
      pause();
      return;
    }

    if (
      currentOption.value === AUTHOR_MUSIC_TABS.ALBUMS &&
      firstCollectionPlaylistId &&
      userCollections &&
      userCollections[0]
    ) {
      playPlaylist(firstCollectionPlaylistId);
      return;
    }

    if (
      currentOption.value === AUTHOR_MUSIC_TABS.PODCASTS &&
      firstPodcastPlaylistId &&
      userPodcasts &&
      userPodcasts[0]
    ) {
      playPlaylist(firstPodcastPlaylistId);
      return;
    }

    const items = getItemsBySelectedTab();

    if (currentOption.value === AUTHOR_MUSIC_TABS.SINGLES && singlesPlaylistId && items) {
      playPlaylist(singlesPlaylistId);
      return;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.OWNED && userOwnedItemsPlaylistId && items) {
      playPlaylist(userOwnedItemsPlaylistId);
      return;
    }
  };

  const getPlaylistIdBasedOnTab = () => {
    if (currentOption.value === AUTHOR_MUSIC_TABS.SINGLES && singlesPlaylistId) {
      return singlesPlaylistId;
    }

    if (currentOption.value === AUTHOR_MUSIC_TABS.OWNED && userOwnedItemsPlaylistId) {
      return userOwnedItemsPlaylistId;
    }
  };

  return (
    <div className={styles.mainContainer}>
      <Tabs displayOptions={TABS} currentOption={currentOption} setValue={setCurrentOption} />

      <div className={styles.playlistContainer}>
        <PlayPauseButton
          isPlaying={isMainButtonPlaying}
          onPlayPauseClicked={onPlayPauseClicked}
          colorType="orange"
          padding={16}
          width={56}
          height={56}
        />
      </div>
      {currentOption.value !== AUTHOR_MUSIC_TABS.ALBUMS ? (
        <div>
          <MusicTableHeader setOfColumns={TRACK_COLUMNS_SETS.TAD} />
          <div className={styles.playlistList}>
            {getItemsBySelectedTab()?.map((item, i) => (
              <PlaylistMusicItem
                item={item}
                key={item.id}
                authorName={author?.displayName}
                imageURL={item.previewImageUrl}
                queuePosition={(i + 1).toString()}
                playlistId={getPlaylistIdBasedOnTab()}
              />
            ))}
          </div>
        </div>
      ) : (
        <LayoutStack rowElements={6} gapX={16} gapY={16}>
          {[
            ...(userCollections ? userCollections : []).map((collection) => (
              <MusicCollectionCard key={collection.id} collection={collection} />
            )),
          ]}
        </LayoutStack>
      )}
    </div>
  );
};
