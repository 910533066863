import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { AuctionsContext } from '../../context/auctions-context';
import { blockchainNumberRegex } from '../../utils/blockchain-number-regex';
import { addDays, getDaysBetweenTwoDates, getTomorrowDate } from '../../utils/date-helpers';
import { Button, ButtonStyle } from '../common/button/button';
import { DatePicker, RelativeDate } from '../common/date-picker/date-picker';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { UnderlinedInputControl } from '../common/underlined-input/underlined-input';
import styles from './put-item-on-auction.module.css';

const endsAtDropdownValues = [
  RelativeDate.OneHour,
  RelativeDate.TwelveHours,
  RelativeDate.OneDay,
  RelativeDate.TwoDays,
  RelativeDate.Custom,
];

const startsAtDropdownValues = [RelativeDate.Now, ...endsAtDropdownValues];

interface Props {
  itemId: string;
  quantity: number;
  onClick: () => void;
}

export const PutItemOnAuctionComponent = ({ itemId, quantity, onClick }: Props): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div>
      <Button
        type="button"
        buttonStyle={ButtonStyle.color}
        onClick={onClick ?? ((): void => setIsPopupOpen(true))}
        disabled={isPopupOpen}
        id={'put-on-auction-popup-open-button'}
      >
        Put on Auction
      </Button>

      {isPopupOpen && (
        <Popup quantity={quantity} closePopup={(): void => setIsPopupOpen(false)} itemId={itemId} />
      )}
    </div>
  );
};

interface FormInputs {
  purchaseLimit: string;
  bid: string;
  auctionQuantity: number;
}

const Popup = ({
  quantity,
  closePopup,
  itemId,
}: {
  quantity: number;
  closePopup: () => void;
  itemId: string;
}): JSX.Element => {
  const { createAuction } = useContext(AuctionsContext);
  const navigate = useNavigate();
  const [startsAt, setStartsAt] = useState<Date>(new Date());
  const [endsAt, setEndsAt] = useState<Date>(getTomorrowDate());
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormInputs>();

  const putOnAuction = useCallback(async () => {
    const bid = getValues('bid');
    const purchaseLimit = parseFloat(getValues('purchaseLimit'));
    const auctionQuantity = getValues('auctionQuantity');

    if (startsAt >= endsAt) {
      toast.warn('Starting date must be before expiration date for auction');
      return;
    }

    if (getDaysBetweenTwoDates(startsAt, endsAt) > 30) {
      toast.warn('Auction duration must not be longer than 30 days');
      return;
    }

    const auction = await createAuction(
      bid,
      itemId,
      auctionQuantity,
      'ORE', // must be ore for current smart contract
      startsAt.toString(),
      endsAt.toString(),
      purchaseLimit,
    );

    closePopup();
    navigate(`/auction/${auction.id}`);
  }, [closePopup, createAuction, endsAt, getValues, itemId, navigate, startsAt]);

  return (
    <GenericPopup
      onClose={closePopup}
      canBeClosed
      title="Put Item on Auction"
      id={'put-item-on-auction-popup'}
    >
      <div className={classNames(styles.container)}>
        <UnderlinedInputControl
          type="number"
          title="Minimum bid"
          placeholder="Enter minimum bid in ORE"
          id={'put-item-on-auction-popup-minimum-bid-input'}
          {...register('bid', {
            min: {
              value: 0,
              message: 'Minimum bid must be at least 0',
            },
            pattern: {
              value: blockchainNumberRegex,
              message: 'Minimum bid must be a number with maximum of 15 decimal places',
            },
            required: {
              value: true,
              message: 'Minimum bid is required',
            },
          })}
          errorMessage={errors.bid?.message}
        />
        <UnderlinedInputControl
          type="number"
          title="Quantity"
          placeholder="e.g. 1"
          {...register('auctionQuantity', {
            required: {
              value: true,
              message: 'You must specify quantity',
            },
            min: {
              value: 1,
              message: 'You must put at least 1 item',
            },
            max: {
              value: quantity,
              message: `You can't put more items than ${quantity}.`,
            },
          })}
          errorMessage={errors.auctionQuantity?.message}
          id={'place-a-bid-popup-auctionQuantity-input'}
        />
        <UnderlinedInputControl
          title="Purchase limit"
          type="number"
          placeholder="Enter how many items can user buy in one transaction"
          id={'put-item-on-auction-popup-purchase-limit-input'}
          {...register('purchaseLimit', {
            min: {
              value: 1,
              message: 'Purchase limit must be greater than 0!',
            },
          })}
          errorMessage={errors.purchaseLimit?.message}
        />
        <div className={styles.bidDateRow}>
          <DatePicker
            dropdownValues={startsAtDropdownValues}
            value={startsAt}
            setValue={setStartsAt}
            title={'Starting date'}
            id={'put-item-on-auction-popup-starting-date-dropdown'}
          />
          <DatePicker
            dropdownValues={endsAtDropdownValues}
            value={endsAt}
            setValue={setEndsAt}
            title={'Expiration date'}
            id={'put-item-on-auction-popup-ending-date-dropdown'}
            maxValue={addDays(startsAt, 30)}
          />
        </div>
        <Button
          type="button"
          buttonStyle={ButtonStyle.color}
          onClick={handleSubmit(putOnAuction, () => {
            toast.warn('Please verify form.');
          })}
          id={'put-on-auction-popup-create-button'}
        >
          Create Auction
        </Button>
      </div>
    </GenericPopup>
  );
};
