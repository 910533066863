import { Item } from 'services/item-service';

import { secondsToDuration } from './seconds-to-duration';

export const getItemsTotalDuration = (items?: Item[]) => {
  let totalSeconds = 0;
  items?.map((i) => {
    totalSeconds += Number(i.mediaLengthSeconds);
  });
  return secondsToDuration(totalSeconds.toString());
};

export const getDetailsFromItems = (pickedItems: Item[]) => {
  const totalDuration = getItemsTotalDuration(pickedItems);
  return pickedItems.length
    ? `${pickedItems.length === 1 ? '1 track' : `${pickedItems.length} tracks`} ${
        totalDuration ? `• ${totalDuration}` : ''
      }`
    : 'No tracks';
};
