import { useContext } from 'react';
import { toast } from 'react-toastify';

import { AuthContext } from '../context/auth-context';
import { CurrentUserProfileContext } from '../context/current-user-profile-context';
import { useItem } from '../services/item-service';

export const useLikeItem = (
  itemId?: string,
): {
  handleLikeItem: () => Promise<void>;
  isLiked: boolean;
} => {
  const { isAuthorized } = useContext(AuthContext);
  const { userProfile, updateUserLikedItems } = useContext(CurrentUserProfileContext);
  const { mutate, data: item } = useItem(itemId);

  const isLiked = !!(
    itemId &&
    isAuthorized &&
    userProfile &&
    userProfile.likedItemsIds &&
    userProfile.likedItemsIds.includes(itemId)
  );

  const handleLikeItem = async (): Promise<void> => {
    if (!isAuthorized) {
      toast.info('You have to log in to be able to like items');
      return;
    }

    if (itemId && item) {
      const updatedItem = {
        ...item,
        likesCount: item.likesCount + (isLiked ? -1 : 1),
      };

      mutate(() => updateUserLikedItems(itemId).then(() => updatedItem), {
        optimisticData: updatedItem,
      });
    }
  };

  return {
    handleLikeItem,
    isLiked,
  };
};
