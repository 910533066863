import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as WalletIcon } from 'assets/icons/wallet-icon.svg';
import MusicLogo from 'assets/ore-multi-color-logo.png';
import ForgeLogo from 'assets/ore-silver-logo.png';
import { Button, ButtonStyle } from 'components/common/button/button';
import { CircularImage } from 'components/common/circular-image';
// HACK: weird bug with importing common to common
import { Typography } from 'components/common/typography';
import { AuthContext, isLauncher } from 'context/auth-context';
import { CurrentUserProfileContext } from 'context/current-user-profile-context';
import { NavbarVisibilityContext } from 'context/navbar-visibility-context';

import { Dropdown, DropdownStyle } from '../dropdown';
import styles from './header.module.css';
import { WalletInfoPopup } from './wallet-info-popup/wallet-info-popup';

const HIDE_HEADER_THRESHOLD = 60;

export enum HeaderType {
  Music,
  Forge,
}

type Props = {
  type?: HeaderType;
};

export const Header = ({ type = HeaderType.Forge }: Props) => {
  const [walletInfoOpen, setWalletInfoOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const { isVisible, setIsVisible } = useContext(NavbarVisibilityContext);
  const { isAuthorized } = useContext(AuthContext);
  const { userProfile } = useContext(CurrentUserProfileContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setWalletInfoOpen(false);
  }, [location]);

  const showLinks = !['/connect', '/not-permitted'].includes(location.pathname);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < HIDE_HEADER_THRESHOLD);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, isVisible, setIsVisible]);

  return (
    <header
      className={classNames(
        styles.headerWrapper,
        { [styles.sticked]: window.scrollY > HIDE_HEADER_THRESHOLD },
        { [styles.hidden]: !isVisible },
      )}
    >
      <div className={styles.container}>
        <LogoComponent type={type} />
        {showLinks && (
          <ul className={`${styles.container} ${styles.nav}`}>
            {type === HeaderType.Music && (
              <li>
                <div className={styles.navLink}>
                  <Dropdown
                    dropdownStyle={DropdownStyle.WithArrow}
                    placeholderTitle="Create"
                    isWide
                    id={''}
                    options={[
                      <div
                        onClick={() => {
                          navigate('/music/create-playlist');
                        }}
                        key={'create-playlist'}
                      >
                        Playlist
                      </div>,
                    ]}
                  />
                </div>
              </li>
            )}
            {type === HeaderType.Forge && mapLinks(FORGE_LINKS)}
            {type === HeaderType.Music && mapLinks(MUSIC_LINKS)}
            {!isLauncher && (
              <li title="Download Ore Stealth">
                <Button
                  className={styles.downloadLauncherButton}
                  buttonStyle={ButtonStyle.color}
                  onClick={() =>
                    window.open('https://dl.patchkit.net/d/c5sqnxqvmh53k7hf4s3p/direct')
                  }
                  id={'get-stealth-button'}
                >
                  Get Stealth
                </Button>
              </li>
            )}
            {/* <li>
            <NavLink
              className={styles.nested}
              path='/resources'
            >
              Resources
              <ArrowIcon className={styles.arrowIcon} />
            </NavLink>
            <ul>
              <li><NavLink className={styles.nested} path='/resources'>Help center</NavLink></li>
              <li><NavLink className={styles.nested} path='/resources'>Community</NavLink></li>
            </ul>
          </li> */}

            {isAuthorized ? (
              <li>
                <div className={styles.accountButtons}>
                  <NavLink path="/current-user" id={'header-current-user-link'}>
                    <CircularImage
                      imageUrl={userProfile?.profileAvatarUrl || '/av-placeholder.jpg'}
                      withBorder
                      className={styles.avatar}
                    />
                  </NavLink>

                  <div
                    className={styles.walletIcon}
                    onClick={() => setWalletInfoOpen((val) => !val)}
                  >
                    <WalletIcon />
                  </div>
                  <WalletInfoPopup isOpen={walletInfoOpen} close={() => setWalletInfoOpen(false)} />
                </div>
              </li>
            ) : (
              <li>
                <ConnectWalletButton />
              </li>
            )}
          </ul>
        )}
      </div>
    </header>
  );
};

const LogoComponent = ({ type }: Props) => (
  <div className={styles.container}>
    <Link to={type === HeaderType.Forge ? '/' : '/music/home'} className={styles.logotypeWrapper}>
      {type === HeaderType.Forge && <img src={ForgeLogo} alt="Ore" />}
      {type === HeaderType.Music && <img src={MusicLogo} alt="Ore" />}
      <Typography.Heading fontSize={18} fontWeight={700}>
        FORGE
      </Typography.Heading>
      {type === HeaderType.Music && (
        <div className={styles.elementWrapper}>
          <Typography.Span fontWeight={400} fontSize={20}>
            |
          </Typography.Span>
        </div>
      )}
      {type === HeaderType.Music && (
        <Typography.Span fontSize={18} fontWeight={400}>
          Music
        </Typography.Span>
      )}
    </Link>
  </div>
);

interface NavLinkProps {
  path: string;
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const NavLink = ({ path, children, className, id }: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  const style = classNames(styles.navLink, className, { [styles.active]: isActive });

  return (
    <Link to={path} className={style} id={id}>
      {children}
    </Link>
  );
};

const mapLinks = (Links: { to: string; label: string }[]) => (
  <>
    {Links.map((link) => (
      <li key={link.label}>
        <NavLink path={link.to}>{link.label}</NavLink>
      </li>
    ))}
  </>
);

const ConnectWalletButton = () => {
  const location = useLocation();

  const previousPage =
    location.pathname === '/not-permitted'
      ? (location.state as { previousPage?: string }).previousPage
      : `${location.pathname}${location.search}`;

  return (
    <Link to="/connect" state={{ previousPage }} className={styles.loginButton}>
      Connect wallet
    </Link>
  );
};

const FORGE_LINKS = [
  { to: '/explore', label: 'Explore' },
  { to: '/create', label: 'Create' },
  { to: '/activity', label: 'Activity' },
  { to: '/music/home', label: 'ORE Music' },
];

const MUSIC_LINKS = [
  { to: '/music/explore', label: 'Explore audio' },
  { to: '/music/library', label: 'My library' },
  { to: '/', label: 'ORE Forge' },
];
