import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SalesContext } from '../../context/sales-context';
import { Sale, useSalesOfItem } from '../../services/sales-service';
import { Button, ButtonStyle } from '../common/button/button';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import styles from './end-sale-component.module.css';

interface Props {
  saleId: string;
  itemId: string;
  otherSales: Array<Sale>;
}

export const EndSaleComponent = ({ saleId, itemId, otherSales }: Props): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div>
      <Button
        buttonStyle={ButtonStyle.red}
        onClick={(): void => setIsPopupOpen(true)}
        id={'end-sale-popup-open-button'}
      >
        End Sale
      </Button>
      {isPopupOpen && (
        <Popup
          saleId={saleId}
          itemId={itemId}
          closePopup={(): void => setIsPopupOpen(false)}
          otherSales={otherSales}
        />
      )}
    </div>
  );
};

const Popup = ({
  saleId,
  itemId,
  closePopup,
  otherSales,
}: {
  saleId: string;
  itemId: string;
  closePopup: () => void;
  otherSales: Array<Sale>;
}): JSX.Element => {
  const { endSale } = useContext(SalesContext);
  const navigate = useNavigate();
  const { mutate } = useSalesOfItem(itemId);

  const submit = useCallback(async () => {
    await endSale(saleId);
    mutate(
      { sales: otherSales },
      {
        optimisticData: { sales: otherSales },
      },
    );
    navigate(`/item/${itemId}`);
  }, [endSale, itemId, mutate, navigate, otherSales, saleId]);

  return (
    <GenericPopup canBeClosed onClose={closePopup} title="End Sale" id={'end-sale-popup'}>
      <div className={styles.container}>
        <p>Are you sure you want to end this sale?</p>
        <Button onClick={submit} buttonStyle={ButtonStyle.red} id={'end-sale-popup-end-button'}>
          End
        </Button>
        <Button
          onClick={closePopup}
          buttonStyle={ButtonStyle.second}
          id={'end-sale-popup-cancel-button'}
        >
          Cancel
        </Button>
      </div>
    </GenericPopup>
  );
};
