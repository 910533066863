import { useContext } from 'react';

import { ProfileSummary } from 'components/profile-summary';
import { CurrentUserProfileContext } from 'context';

import styles from './profile-page.module.css';
import { UserAuctionsSection } from './user-auctions-section';
import { UserItemsSection } from './user-items-section';
import { UserMusicSection } from './user-music-section';

export const CurrentProfilePage = () => {
  const { userProfile, walletAddress } = useContext(CurrentUserProfileContext);

  if (userProfile === undefined || walletAddress === undefined) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <ProfileSummary userProfile={userProfile} walletAddress={walletAddress} editable />
      <section className={styles.profilePageSection}>
        <UserItemsSection walletAddress={walletAddress} title="Your items" />
      </section>
      <section className={styles.profilePageSection}>
        <UserAuctionsSection walletAddress={walletAddress} />
      </section>
    </div>
  );
};
