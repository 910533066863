import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { ReactComponent as MetamaskIcon } from '../../assets/icons/metamask.svg';
import { ReactComponent as WalletConnectIcon } from '../../assets/icons/wallet_connect.svg';
import { AuthContext } from '../../context/auth-context';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import styles from './connect-page.module.css';

export const ConnectPage = (): JSX.Element => {
  const { browserActions, isAuthorized } = useContext(AuthContext);
  const { state: connectMetamaskState, makeCall: makeMetamaskCall } = useCallWithState(
    browserActions?.connectMetamask ?? ((): Promise<void> => Promise.resolve()),
    true,
  );
  const { state: connectWalletConnectState, makeCall: makeWalletConnectCall } = useCallWithState(
    browserActions?.connectWalletConnect ?? ((): Promise<void> => Promise.resolve()),
    true,
  );

  const { previousPage } = useLocation().state as { previousPage?: string };

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate(previousPage || '/');
    }
  }, [isAuthorized, navigate, previousPage]);

  const renderMetamaskButtonChildren = (): JSX.Element => {
    switch (connectMetamaskState) {
      case CallState.Ready:
        return (
          <>
            <MetamaskIcon />
            Connect with Metamask
          </>
        );
      case CallState.Started:
        return (
          <>
            <ClipLoader />
            If nothing happens please click into plugin.
          </>
        );
      case CallState.Ended:
        return (
          <>
            <MetamaskIcon />
            Connected!
          </>
        );
    }
  };

  const renderWalletConnectButtonChildren = (): JSX.Element => {
    switch (connectWalletConnectState) {
      case CallState.Ready:
        return (
          <>
            <WalletConnectIcon />
            Connect using WalletConnect
          </>
        );
      case CallState.Started:
        return (
          <>
            <ClipLoader />
            Check your wallet app
          </>
        );
      case CallState.Ended:
        return (
          <>
            <WalletConnectIcon />
            Connected!
          </>
        );
    }
  };

  if (browserActions === undefined) {
    return <ConnectPage />;
  }

  return (
    <div className={styles.container}>
      <h1>Connect your wallet</h1>
      <p>Connect with one of available wallet providers or create a new wallet.</p>
      <button
        className={styles.loginButton}
        onClick={() => {
          makeMetamaskCall((window.ethereum as any)?.networkVersion);
        }}
        disabled={
          connectMetamaskState !== CallState.Ready || connectWalletConnectState !== CallState.Ready
        }
      >
        {renderMetamaskButtonChildren()}
        <div className={styles.buttonPlaceholder}></div>
      </button>
      <button
        className={styles.loginButton}
        onClick={makeWalletConnectCall}
        disabled={
          connectMetamaskState !== CallState.Ready || connectWalletConnectState !== CallState.Ready
        }
      >
        {renderWalletConnectButtonChildren()}
        <div className={styles.buttonPlaceholder}></div>
      </button>
      <p className={styles.legalText}>
        We do not own your private keys and cannot access your funds without your confirmation.
      </p>
    </div>
  );
};
