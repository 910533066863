import axios from 'axios';
import { snakeKeys } from 'js-convert-case';
import useSWR, { KeyedMutator, SWRResponse } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { AllowedCurrencies } from 'utils/blockchain-helpers';

import { env } from '../env/env';
import { amountToWei } from './blockchain-service';
import { dataFetcher } from './data-fetcher';
import { getAuthHeader, jsonHeaders } from './headers';

export interface Auction {
  id: string;
  itemId: string;
  quantity: number;
  minimumBid: string;
  settlementToken: 'ORE' | 'BNB';
  endsAt: string;
  ownerWalletId: string;
  startsAt: string;
  createdAt: string;
  purchaseLimit?: number;
  remainingQuantity: number;
}

interface AuctionsList {
  auctions: Array<Auction>;
}

export interface Bid {
  id: string;
  auctionId: string;
  ownerWalletId: string;
  quantity: number;
  amount: string;
  createdAt: string;
  status: 'accepted' | 'active';
}

interface BidsList {
  bids: Array<Bid>;
}

export const createNewAuction = async (
  jwt: string,
  minimumBid: string,
  itemId: string,
  quantity: number,
  settlementToken: AllowedCurrencies,
  startsAt?: string,
  endsAt?: string,
  purchaseLimit?: number,
): Promise<Auction> => {
  const url = `${env.apiUrl}/auctions`;
  const body = JSON.stringify(
    snakeKeys({
      minimumBid: amountToWei(minimumBid),
      endsAt,
      startsAt,
      settlementToken,
      quantity,
      itemId,
      purchaseLimit,
    }),
  );

  const response = await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
    data: body,
  });

  return response.data as Auction;
};

export const useAuctionsWithPagination = (
  limit: number,
  category?: string,
  showFinished = false,
): {
  auctions: Auction[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<AuctionsList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<AuctionsList[] | undefined>;
} => {
  const path = category ? `/categories/${category}/auctions` : '/auctions';
  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) =>
      `${env.apiUrl}${path}?offset=${
        page * limit
      }&limit=${limit}&show_finished=${showFinished.toString()}`,
    (url) => dataFetcher<AuctionsList>(url),
  );

  const extractedData = (data || []).map((p) => p.auctions);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const auctions = data ? new Array<Auction>().concat(...extractedData) : [];

  const isEmpty = auctions.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<AuctionsList[] | undefined> => setPage(page + 1);

  return {
    auctions,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};

export const useAuctionsOfItem = (
  itemId: string,
): {
  data: Auction[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<AuctionsList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/items/${itemId}/auctions?show_finished=false`;
  const result = useSWR(url, (url) => dataFetcher<AuctionsList>(url));
  return {
    ...result,
    data: result.data?.auctions,
  };
};

export const useAuction = (auctionId?: string): SWRResponse<Auction> => {
  const url = `${env.apiUrl}/auctions/${auctionId}`;
  const result = useSWR(auctionId ? url : null, (url) => dataFetcher<Auction>(url));
  return result;
};

export const useUserAuctions = (
  walletAddress: string,
  pullFinished?: boolean,
  pullActive?: boolean,
): SWRResponse<AuctionsList> => {
  const url = walletAddress
    ? `${env.apiUrl}/users/${walletAddress}/auctions?show_finished=${
        pullFinished?.toString() || 'false'
      }&show_ongoing=${pullActive?.toString() || 'false'}`
    : null;
  const result = useSWR(url, (url) => dataFetcher<AuctionsList>(url));
  return result;
};

export const placeNewBid = async (
  auctionId: string,
  jwt: string,
  quantity: number,
  amount: string,
): Promise<Bid> => {
  const url = `${env.apiUrl}/auctions/${auctionId}/bids`;
  const body = JSON.stringify(
    snakeKeys({
      amount: amountToWei(amount),
      quantity,
    }),
  );

  const response = await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
    data: body,
  });

  return response.data as Bid;
};

export const useBidsForAuction = (
  auctionId?: string,
): {
  data: Bid[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<BidsList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/auctions/${auctionId}/bids`;
  const result = useSWR(auctionId ? url : null, (url) => dataFetcher<BidsList>(url));

  return {
    ...result,
    data: result.data?.bids,
  };
};

export const markBidAccepted = async (
  auctionId: string,
  bidId: string,
  jwt: string,
): Promise<Bid> => {
  const url = `${env.apiUrl}/auctions/${auctionId}/bids/${bidId}/accept`;

  const response = await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
  });

  return response.data as Bid;
};
