import React from 'react';
import { ChangeHandler, FieldValues, UseFormRegister } from 'react-hook-form';
import classNames from 'classnames';

import styles from './toggle-input.module.css';

interface Props {
  title: string;
  description: string;
  errorMessage?: string;

  name: string; // FormControl registered name
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  id: string;
}

export const ToggleInputControl = React.forwardRef<
  HTMLInputElement,
  Props & ReturnType<UseFormRegister<FieldValues>>
>(({ title, description, name, errorMessage, onChange, onBlur, id }: Props, ref) => (
  <>
    <div className={styles.toggleInputContainer}>
      <div className={styles.inputInfo}>
        <p className={classNames(styles.inputTitle, 'gradientText')}>{title}</p>
        <p className={styles.inputDescription}>{description}</p>
      </div>
      <label className={styles.toggleInput}>
        <input type="checkbox" name={name} ref={ref} onChange={onChange} onBlur={onBlur} id={id} />
        <span className={styles.slider}></span>
      </label>
    </div>
    <span className="inputValidationError">{errorMessage}</span>
  </>
));
ToggleInputControl.displayName = 'ToggleInputControl';
