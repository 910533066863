import { TransactionType } from 'types/transaction-type';
import { getPriceInUSD } from 'utils/currency-helpers';

import styles from './transaction-popups.module.css';

interface Props {
  transactionType: TransactionType;
  currency: 'BNB' | 'ORE';
  balance: number;
  nftPrice: number;
  serviceFeePercent: number;
  orePriceInUSD?: number;
}

export const TransactionSummary = ({
  balance,
  nftPrice,
  currency,
  serviceFeePercent,
  transactionType,
  orePriceInUSD,
}: Props): JSX.Element => {
  return (
    <div className={styles.summary}>
      <div className={styles.line}>
        <div>Your balance: </div>
        <div>
          {balance} {currency}
        </div>
      </div>
      <div className={styles.line}>
        {transactionType === TransactionType.Bid ? (
          <div>Your bidding balance: </div>
        ) : (
          <div>Price: </div>
        )}
        <div>
          {nftPrice} {currency}
        </div>
      </div>
      <div className={styles.line}>
        <div>Service fee: </div>
        {/* TODO: Worth considering if we want to calculate and show the exact amount from % */}
        <div>{serviceFeePercent}%</div>
      </div>
      <div className={styles.line}>
        <div>Summary: </div>
        <div id="transaction-summary-total">
          {nftPrice * (1 + serviceFeePercent / 100)} {currency}
        </div>
      </div>
      {orePriceInUSD !== undefined && currency === 'ORE' && (
        <div className={styles.line}>
          <div>In USD: </div>
          <div id="transaction-summary-total">
            {getPriceInUSD(nftPrice * (1 + serviceFeePercent / 100), orePriceInUSD)}
          </div>
        </div>
      )}
    </div>
  );
};
