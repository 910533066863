import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuctionsContext } from '../../context/auctions-context';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { Auction, Bid } from '../../services/auctions-service';
import { amountFromWei } from '../../services/blockchain-service';
import { useUser } from '../../services/user-service';
import truncate from '../../utils/string-truncator';
import { Button, ButtonStyle } from '../common/button/button';
import styles from './bid-info.module.css';

interface Props {
  bid: Bid;
  auction: Auction;
  allowAcceptBid?: boolean;
}

export const BidInfo = ({ bid, auction, allowAcceptBid }: Props): JSX.Element => {
  const { data: bidder } = useUser(bid.ownerWalletId);
  const { walletAddress } = useContext(CurrentUserProfileContext);
  const { acceptBid } = useContext(AuctionsContext);

  const date = new Date(bid.createdAt);

  return (
    <div className={styles.bidContainer}>
      <div className={styles.bidMainRow}>
        <Link to={`/user/${bid.ownerWalletId}`}>
          <img
            className={styles.bidderAvatar}
            alt="bidder-avatar"
            src={bidder?.profileAvatarUrl || '/av-placeholder.jpg'}
          />
        </Link>
        <div className={styles.bidSummary}>
          <div className={styles.bid}>
            <p className={styles.amount}>
              {amountFromWei(bid.amount)} {auction.settlementToken}
            </p>
            <p>by</p>
            <Link to={`/user/${bid.ownerWalletId}`} className={styles.bidderLink}>
              {bidder?.displayName || truncate(bid.ownerWalletId)}
            </Link>
            <p>for {bid.quantity} item</p>
          </div>
          <div className={styles.bidDate}>
            {date.toLocaleDateString()}, {date.toLocaleTimeString()}
          </div>
        </div>
        <div></div>
      </div>
      {bid.status === 'active' &&
        auction.ownerWalletId === walletAddress &&
        isAuctionEnded(auction) &&
        allowAcceptBid && (
          <Button
            onClick={(): Promise<void> => acceptBid(auction, bid)}
            type="button"
            buttonStyle={ButtonStyle.color}
            className={styles.acceptBidButton}
            id={'accept-bid-button'}
          >
            Accept Bid
          </Button>
        )}
    </div>
  );
};

/* Helper functions */
const isAuctionEnded = (auction: Auction): boolean => {
  const now = new Date();

  return now >= new Date(auction.endsAt);
};
