import { useContext } from 'react';
import { env } from 'env/env';
import useSWR from 'swr';

import { AuthContext } from 'context/auth-context';

import { dataFetcher } from './data-fetcher';

export interface IFaqItem {
  question: string;
  answer: string;
  order: number;
}

export const useFaq = () => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/faqs/`;
  const result = useSWR(url, (url) => dataFetcher<IFaqItem[]>(url, jwt));
  return {
    ...result,
    data: result.data,
  };
};
