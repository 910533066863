import { useContext } from 'react';

import { LinkCard } from 'components/link-card';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';

interface IProps {
  redirectTo: string;
  imagesUrls: string[];
  title: string;
  playlistId: string;
}

export const PlaylistCard = ({ redirectTo, imagesUrls, title, playlistId }: IProps) => {
  const { playPlaylist, currentPlaylist, isPaused, play, pause } = useContext(AudioPlayerContext);

  const isPlayingThisPlaylist = playlistId === currentPlaylist?.id;

  const onClicked = () => {
    if (isPlayingThisPlaylist) {
      if (isPaused) {
        pause();
      } else {
        play();
      }
    } else {
      playPlaylist(playlistId);
    }
  };

  const renderPlayPauseButton = () => (
    <PlayPauseButton
      onPlayPauseClicked={onClicked}
      isPlaying={isPlayingThisPlaylist && !isPaused}
      colorType="orange"
      width={56}
      height={56}
      padding={18}
    />
  );

  return (
    <LinkCard
      redirectTo={redirectTo}
      imagesUrls={imagesUrls}
      title={title}
      hoverableElement={!isPlayingThisPlaylist && renderPlayPauseButton()}
      active={isPlayingThisPlaylist}
      additionalElement={isPlayingThisPlaylist && renderPlayPauseButton()}
    />
  );
};
