import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { env } from 'env/env';

import { Footer } from 'components/common/footer/footer';
import { LoadingPagePlaceholder } from 'components/common/loading-page-placeholder/loading-page-placeholder';
import { PrivacyPolicyPopup } from 'components/privacy-policy-popup/privacy-policy-popup';
import { RequireAccess } from 'components/require-access';
import { RequireAuth } from 'components/require-auth';
import { AuthContext } from 'context/auth-context';
import { ActivityPage } from 'pages/activity/activity-page';
import { BannedPage } from 'pages/banned-page';
import { CollectionPage } from 'pages/collection/collection-page';
import { ConnectPage } from 'pages/connect/connect-page';
import { CreatePage } from 'pages/create/create-page';
import { CreateCollectionPage } from 'pages/create-collection/create-collection-page';
import { CreateForgePage } from 'pages/create-forge/create-forge-page';
import { EditItemPage } from 'pages/edit-item/edit-item-page';
import { EditProfilePage } from 'pages/edit-profile/edit-profile-page';
import { ExplorePage } from 'pages/explore/explore-page';
import { FaqPage } from 'pages/faq/faq-page';
import { AuctionPage } from 'pages/item/auction-page';
import { ItemPage } from 'pages/item/item-page';
import { SalePage } from 'pages/item/sale-page';
import { MainPage } from 'pages/main/main-page';
import { MainPageLayoutWrapper } from 'pages/main/main-page-layout-wrapper';
import { MusicMainPage } from 'pages/music/main/music-main-page';
import { MusicLibrary } from 'pages/music/music-library';
import { MusicPageExplore } from 'pages/music/music-page-explore';
import { MusicPageLayoutWrapper } from 'pages/music/music-page-layout-wrapper/music-page-layout-wrapper';
import { MusicQueuePage } from 'pages/music/music-queue/music-queue-page';
import { ParticularAlbumPage } from 'pages/music/particulars/particular-album-page';
import { ParticularPlaylistPage } from 'pages/music/particulars/particular-playlist-page';
import { CreatePlaylistPage } from 'pages/music/particulars/playlist-page';
import { NoSubscriptionPage } from 'pages/no-subscription';
import { PrivacyPolicyPage } from 'pages/privacy-policy/privacy-policy-page';
import { CurrentProfilePage } from 'pages/profile/current-profile-page';
import { ProfilePage } from 'pages/profile/profile-page';
import { RefreshNftPage } from 'pages/refresh-nft/refresh-nft-page';
import { SendNftPage } from 'pages/send-nft/send-nft-page';
import { TakeDownRequestFormPage } from 'pages/take-down-request-form/take-down-request-form-page';
import { TransactionsHistoryPage } from 'pages/transactions-history/transactions-history-page';

import styles from './App.module.css';

import 'react-toastify/dist/ReactToastify.css';

const TOAST_AUTO_CLOSE_DELAY_MS = 5000;

export const App = () => {
  const { isInitialized } = useContext(AuthContext);

  return (
    <div className={styles.App}>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_AUTO_CLOSE_DELAY_MS}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      {isInitialized ? (
        <>
          {/* <button><Link to='activity'>activity</Link></button>
          <button><Link to='explore'>explore</Link></button>
          <button><Link to='current-user'>current user</Link></button>
          <button onClick={connectMetamask} disabled={metaState.isConnected}>
            connect metamask
          </button> */}
          <div className={styles.contentWrapper}>
            <Routes>
              <Route
                path={`${env.frontPrefix}/`}
                element={
                  <RequireAccess>
                    <MainPageLayoutWrapper />
                  </RequireAccess>
                }
              >
                <Route path="" element={<MainPage />} />
                <Route path="explore" element={<ExplorePage />} />
                <Route path="explore/:filter" element={<ExplorePage />} />
                <Route path="connect" element={<ConnectPage />} />
                <Route path="not-permitted" element={<ConnectPage />} />

                <Route path="user/:walletAddress" element={<ProfilePage />} />
                <Route
                  path="current-user/transactions-history"
                  element={
                    <RequireAuth>
                      <TransactionsHistoryPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="current-user/edit"
                  element={
                    <RequireAuth>
                      <EditProfilePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="current-user"
                  element={
                    <RequireAuth>
                      <CurrentProfilePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="current-user/:filter"
                  element={
                    <RequireAuth>
                      <CurrentProfilePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="create-forge"
                  element={
                    <RequireAuth>
                      <CreateForgePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="create-collection"
                  element={
                    <RequireAuth>
                      <CreateCollectionPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="create"
                  element={
                    <RequireAuth>
                      <CreatePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="send-nft"
                  element={
                    <RequireAuth>
                      <SendNftPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="refresh-nft"
                  element={
                    <RequireAuth>
                      <RefreshNftPage />
                    </RequireAuth>
                  }
                />
                <Route path="item/:id" element={<ItemPage />} />
                <Route path="item/:id/edit" element={<EditItemPage />} />
                <Route path="collection/:id" element={<CollectionPage />} />
                <Route path="sale/:id" element={<SalePage />} />
                <Route path="auction/:id" element={<AuctionPage />} />
                <Route path="activity" element={<ActivityPage />} />
                <Route path="take-down-request-form" element={<TakeDownRequestFormPage />} />
                <Route path="faq" element={<FaqPage />} />
                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="no-subscription" element={<NoSubscriptionPage />} />
                <Route path="*" element={<h3>Page not found</h3>} />
              </Route>
              <Route path={`${env.frontPrefix}/music`} element={<MusicPageLayoutWrapper />}>
                <Route path="home" element={<MusicMainPage />} />
                <Route path="explore" element={<MusicPageExplore />} />
                <Route path="explore/:selectedTab" element={<MusicPageExplore />} />
                <Route path="library" element={<MusicLibrary />} />
                <Route path="album/:id" element={<ParticularAlbumPage />} />
                <Route path="playlist/:id" element={<ParticularPlaylistPage />} />
                <Route path="queue" element={<MusicQueuePage />} />
                <Route path="create-playlist" element={<CreatePlaylistPage />} />
                <Route path="user/:walletAddress" element={<ProfilePage />} />
                {/* <Route path="owned" element={<></>} />
                <Route path="popular" element={<></>} />
                <Route path="podcasts/owned" element={<></>} />
                <Route path="podcasts/popular" element={<></>} /> */}
              </Route>
              <Route path="banned" element={<BannedPage />} />
            </Routes>
          </div>
          <PrivacyPolicyPopup />
          <Footer />
        </>
      ) : (
        <LoadingPagePlaceholder />
      )}
    </div>
  );
};
