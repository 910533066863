import { ClipLoader } from 'react-spinners';

import styles from './loading-page-placeholder.module.css';

export const LoadingPagePlaceholder = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <ClipLoader color={'#404048'} />
      <p>Loading, please wait</p>
    </div>
  );
};
