import { useCallback, useContext } from 'react';
import {
  CreateOrderActions,
  CreateOrderData,
  CreateOrderRequestBody,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { env } from 'env/env';

import { AuthContext } from 'context/auth-context';
import { getAuthHeader, jsonHeaders } from 'services/headers';
import { getOREPriceInUSD } from 'services/paypal-transactions-service';

interface IItemPurchase {
  sale_id: string;
  item_id: string;
  item_quantity: number;
}
interface Props {
  tokenQuantity: number;
  onApprove: () => void;
  onClick: () => Promise<void>;
  isValid?: boolean;
  directItemPurchase?: IItemPurchase;
}

export const PaypalCheckoutButtons = ({
  tokenQuantity,
  onApprove,
  onClick,
  isValid,
  directItemPurchase,
}: Props) => {
  const { jwt } = useContext(AuthContext);

  const totalPrice = useCallback(async () => {
    const OREPrice: number = await getOREPriceInUSD();
    const totalPrice = tokenQuantity * OREPrice;

    return totalPrice.toFixed(2).toString();
  }, [tokenQuantity]);

  const createOrder = useCallback(
    async (data: CreateOrderData, actions: CreateOrderActions) => {
      const createOrderPayload: CreateOrderRequestBody = {
        purchase_units: [
          {
            amount: {
              value: await totalPrice(),
              currency_code: 'USD',
            },
          },
        ],
      };
      return actions.order.create(createOrderPayload);
    },
    [totalPrice],
  );

  return (
    <PayPalButtons
      // FIXME: due to heavy rerender its required to implement different version of React PayPal buttons or implement refresh with lodash.debounce
      forceReRender={[createOrder]}
      disabled={isValid !== undefined && isValid !== true}
      onClick={onClick}
      style={{ tagline: false }}
      createOrder={async (data, actions) => {
        await onClick();
        return await createOrder(data, actions);
      }}
      onApprove={(data: OnApproveData, actions: OnApproveActions) => {
        try {
          const order = actions.order?.capture();

          return fetch(`${env.apiUrl}/token_purchase_orders`, {
            method: 'post',
            headers: {
              ...jsonHeaders,
              ...getAuthHeader(jwt),
            },
            body: JSON.stringify({
              token: 'ORE',
              token_quantity: tokenQuantity,
              price: totalPrice,
              currency: 'USD',
              paypal_order_id: data.orderID,
              ...(directItemPurchase && {
                sale_id: directItemPurchase.sale_id,
                item_id: directItemPurchase.item_id,
                item_quantity: directItemPurchase.item_quantity,
              }),
            }),
          }).then((res) => {
            if (res.ok) {
              onApprove();

              return res.json().then((data) => {
                console.log('success');
                console.log(data);
              });
            }
          });
        } catch (err) {
          console.error(err);
          return Promise.reject(err);
        }
      }}
    />
  );
};
