import { createContext, useEffect, useState } from 'react';
// import { IFrameEthereumProvider } from '@ledgerhq/iframe-provider';
// import { EIP1193 } from '@web3-react/eip1193';
// import { Eip1193Bridge } from '@ethersproject/experimental';
import { Web3Provider } from '@ethersproject/providers';
import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';

import { env } from '../env/env';
import { changeWeb3 } from '../services/blockchain-service';
import { AuthContextType } from './auth-context';

const desiredChainIdInt = parseInt(env.chainId);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const launcherEip1193Web3Provider = new Web3Provider(new IFrameEthereumProvider() as any);

// const launcherEip1193Bridge = new Eip1193Bridge(
//   launcherEip1193Web3Provider.getSigner(),
//   launcherEip1193Web3Provider,
// );

// const [launcherEip1193, launcherEip1193Hooks] = initializeConnector<EIP1193>(
//   (actions) => new EIP1193(actions, launcherEip1193Bridge),
//   [desiredChainIdInt],
// );

// HACK, FIXME: iframe ethereum does not work with smart contract somehow.
// So because of that we use window.ethereum and metamask only
const [launcherMetamask, launcherMetamaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask(actions),
  [desiredChainIdInt],
);

if (launcherMetamask.provider) {
  changeWeb3(new Web3Provider(launcherMetamask.provider));
}

export const LauncherAuthContext = createContext<AuthContextType>({
  isAuthorized: false,
  isBanned: false,
  isInitialized: false,
  jwt: '',
  providersSet: false,
});

export const LauncherAuthContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isBanned, _] = useState(false);
  const [jwt, setJwt] = useState<string>('');

  const isAuthorized = isInitialized;
  const walletAddress = launcherMetamaskHooks.useAccount()?.toLowerCase();
  const currentChainId = launcherMetamaskHooks.useChainId();

  const initialize = async (): Promise<void> => {
    await launcherMetamask.activate();
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    setJwt('');
    setIsInitialized(false);

    (async (): Promise<void> => {
      if (walletAddress === undefined) {
        return;
      }

      window.parent.postMessage('get_ore_stealth_jwt', '*');

      await new Promise<void>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const evListener = (ev: MessageEvent<any>): void => {
          if (ev.data.oreStealthMsg?.jwt !== undefined) {
            window.removeEventListener('message', evListener);
            setJwt(ev.data.oreStealthMsg.jwt);
            resolve();
          }
        };

        window.addEventListener('message', evListener);
      });

      setIsInitialized(true);
    })();
  }, [walletAddress]);

  const connectMetamask = async (currentChainId: number) => {
    await launcherMetamask.activate(currentChainId);
  };

  return (
    <LauncherAuthContext.Provider
      value={{
        isAuthorized,
        isBanned,
        isInitialized,
        jwt,
        browserActions: {
          connectMetamask,
        },
        walletAddress,
        providersSet: true,
        currentChainId,
      }}
    >
      {children}
    </LauncherAuthContext.Provider>
  );
};
