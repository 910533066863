import { COLORS } from 'constant';
import { TRACK_COLUMNS_SETS } from 'constant/music-page-constants';

import { PositioningWrapper, TableRow, Typography } from 'components/common';
import { SetOfColumns } from 'types/music-page';
import { getFontSizeInRem } from 'utils/style-builders';

import styles from './music-table-header.module.css';

interface IProps {
  setOfColumns: SetOfColumns;
}

export const MusicTableHeader = (props: IProps) => (
  <div className={styles.tableHeader}>
    <TableRow tableRowColumns={MUSIC_TABLE_HEADERS(props.setOfColumns)} />
  </div>
);

const getColumnContent = (text: string) => (
  <Typography.Span color={COLORS.TEXT_INFO_2} fontSize={14} fontWeight={500}>
    {text}
  </Typography.Span>
);

const MUSIC_TABLE_HEADERS = (setOfColumns: SetOfColumns) => [
  {
    columnContent: (
      <PositioningWrapper horizontalAlign="center" verticalAlign="center">
        {getColumnContent('#')}
      </PositioningWrapper>
    ),
    flexBasis: getFontSizeInRem(56),
    isFixed: true,
  },
  {
    columnContent: getColumnContent('Title'),
    flexBasis: '70%',
  },
  {
    columnContent:
      setOfColumns === TRACK_COLUMNS_SETS.TAD
        ? getColumnContent('Album')
        : getColumnContent('Price'),
    flexBasis: '20%',
  },
  {
    columnContent: getColumnContent('Duration'),
    flexBasis: '10%',
  },
];
