import { useTransactions } from 'services/paypal-transactions-service';

import appStyles from '../../App.module.css';
import styles from './transactions-history-page.module.css';

export const TransactionsHistoryPage = () => {
  const { transactions, isEmpty } = useTransactions();

  return (
    <div className={appStyles.container}>
      <h1>Transactions history</h1>

      {!isEmpty && (
        <table className={styles.transactionsTable}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Created at</th>
              <th>Quantity</th>
              <th>Token</th>
              <th>Price</th>
              <th>Currency</th>
              <th>PayPal order ID</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t) => (
              <tr key={t.id}>
                <td>{t.status}</td>
                <td>{t.createdAt}</td>
                <td>{t.tokenQuantity}</td>
                <td>{t.token}</td>
                <td>{t.price}</td>
                <td>{t.currency}</td>
                <td>{t.paypalOrderId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
