import { Link } from 'react-router-dom';

import { ReactComponent as AuctionIcon } from '../../assets/icons/hammer.svg';
import { ReactComponent as LikeIcon } from '../../assets/icons/like-icon.svg';
import { ReactComponent as ListingIcon } from '../../assets/icons/purchase.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/shopping-cart.svg';
import { Activity, ActivityKind } from '../../services/activity-service';
import { Auction, useAuction } from '../../services/auctions-service';
import { amountFromWei } from '../../services/blockchain-service';
import { Item, useItem } from '../../services/item-service';
import { Sale, useSale } from '../../services/sales-service';
import { UserProfile, useUser } from '../../services/user-service';
import truncate from '../../utils/string-truncator';
import styles from './activity-info.module.css';

interface Props {
  activity: Activity;
}

export const ActivityInfo = ({ activity }: Props): JSX.Element => {
  const { data: sale } = useSale(activity.saleId);
  const { data: auction } = useAuction(activity.auctionId);
  const { data: user1 } = useUser(activity.userId1);
  const { data: user2 } = useUser(activity.userId2);
  const { data: item } = useItem(activity.itemId);

  return (
    <div className={styles.container}>
      <div className={styles.type}>{getIconForActivityKind(activity.kind)}</div>
      <div className={styles.infoRow}>
        <div className={styles.image}>
          <Link to={getLink(activity)}>{getImage(item, user1)}</Link>
        </div>
        <div className={styles.description}>
          <Link className={styles.title} to={getLink(activity)}>
            {item !== undefined
              ? item.displayName
              : user1?.displayName || truncate(activity.userId1)}
          </Link>

          <div className={styles.action}>
            {getActionDescription(activity, user1, user2, auction, sale)}
          </div>
        </div>
      </div>
    </div>
  );
};

const getIconForActivityKind = (activityKind: ActivityKind): JSX.Element => {
  switch (activityKind) {
    case ActivityKind.AuctionCreated:
      return (
        <>
          <AuctionIcon /> Auction
        </>
      );
    case ActivityKind.ItemSold:
    case ActivityKind.SaleCreated:
      return (
        <>
          <SaleIcon /> Sale
        </>
      );
    case ActivityKind.ItemCreated:
      return (
        <>
          <ListingIcon /> Listing
        </>
      );
    case ActivityKind.ItemLiked:
    case ActivityKind.ItemUnliked:
      return (
        <>
          <LikeIcon /> Like
        </>
      );
  }
};

const getImage = (item?: Item, userProfile?: UserProfile): JSX.Element => {
  if (item !== undefined) {
    return <img alt="activity" src={item.previewImageUrl} className={styles.itemImage} />;
  }

  return (
    <img
      alt="activity"
      src={userProfile?.profileAvatarUrl || '/av-placeholder.jpg'}
      className={styles.profileImage}
    />
  );
};

const getActionDescription = (
  activity: Activity,
  userProfile1?: UserProfile,
  userProfile2?: UserProfile,
  auction?: Auction,
  sale?: Sale,
): JSX.Element => {
  switch (activity.kind) {
    case ActivityKind.AuctionCreated:
      return (
        <>
          <p>
            auction created by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
          {auction && (
            <p>
              with minimal bid: {amountFromWei(auction.minimumBid)} {auction.settlementToken}
            </p>
          )}
        </>
      );
    case ActivityKind.ItemSold:
      return (
        <>
          <p>
            item purchased by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
          {sale && (
            <p>
              for: {amountFromWei(sale.price)} {sale.settlementToken}
            </p>
          )}
          {activity.userId2 && (
            <p>
              from:
              <Link to={`/user/${activity.userId2}`}>
                {userProfile2?.displayName ? userProfile2.displayName : truncate(activity.userId2)}
              </Link>
            </p>
          )}
        </>
      );
    case ActivityKind.SaleCreated:
      return (
        <>
          <p>
            sale created by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
          {sale && (
            <p>
              with price: {amountFromWei(sale.price)} {sale.settlementToken} and with{' '}
              {sale.startQuantity} items available
            </p>
          )}
        </>
      );
    case ActivityKind.ItemCreated:
      return (
        <>
          <p>
            created by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
        </>
      );
    case ActivityKind.ItemLiked:
      return (
        <>
          <p>
            liked by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
        </>
      );
    case ActivityKind.ItemUnliked:
      return (
        <>
          <p>
            unliked by
            <Link to={`/user/${activity.userId1}`}>
              {userProfile1?.displayName ? userProfile1.displayName : truncate(activity.userId1)}
            </Link>
          </p>
        </>
      );
  }
};

const getLink = (activity: Activity): string => {
  switch (activity.kind) {
    case ActivityKind.AuctionCreated:
      return `/auction/${activity.auctionId}`;
    case ActivityKind.ItemSold:
    case ActivityKind.SaleCreated:
      return `/sale/${activity.saleId}`;
    case ActivityKind.ItemCreated:
    case ActivityKind.ItemLiked:
    case ActivityKind.ItemUnliked:
      return `/item/${activity.itemId}`;
  }
};
