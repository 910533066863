import React from 'react';

import { getFontSizeInRem } from 'utils/style-builders';

import styles from './layout-stack.module.css';

type Props = {
  children?: JSX.Element[] | JSX.Element;
  rowElements: number;
  gapY?: number;
  gapX?: number;
  marginBottom?: number;
  marginTop?: number;
};

/**
 * @returns simple grid layout with row and column spacing
 */

export const LayoutStack = (props: Props) => {
  const childComponents = React.Children.toArray(props.children);
  const columnGap = props.gapX ? getFontSizeInRem(props.gapX) : 0;
  const rowGap = props.gapY ? getFontSizeInRem(props.gapY) : 0;
  const marginBottom = props.marginBottom ? getFontSizeInRem(props.marginBottom) : 0;
  const marginTop = props.marginTop ? getFontSizeInRem(props.marginTop) : 0;

  return (
    <div
      style={{
        columnGap,
        gridColumnGap: columnGap,
        rowGap,
        gridRowGap: rowGap,
        marginBottom,
        gridTemplateColumns: `repeat(${props.rowElements}, 1fr)`,
        marginTop,
      }}
      className={styles.grid}
    >
      {childComponents?.map((element, index) => (
        <div key={index}>{element}</div>
      ))}
    </div>
  );
};
