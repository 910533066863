import classNames from 'classnames';

import { Category, useCategories } from '../../../services/categories-service';
import { getDropdownOptionDisplayName } from '../../../utils/dropdown-option-display-name';
import styles from './category-picker.module.css';

interface Props {
  pickedCategory?: Category;
  setPickedCategory: React.Dispatch<React.SetStateAction<Category | undefined>>;
}

export const CategoryPicker = ({ pickedCategory, setPickedCategory }: Props): JSX.Element => {
  const { data: categories } = useCategories();

  const toggleCategory = (category: Category): void => {
    setPickedCategory((currentCategory) => {
      if (currentCategory === category) {
        return undefined;
      }

      return category;
    });
  };

  return (
    <div className={styles.categoryContainer}>
      <p className="gradientText">Category</p>
      <div className={styles.categoryPickerContainer}>
        {(categories || []).map((category) => (
          <div
            className={classNames(styles.categoryTile, {
              [styles.pickedCategory]: category.id === pickedCategory?.id,
            })}
            onClick={(): void => toggleCategory(category)}
            key={category.id}
            id={`category-picker-${category.id}`}
          >
            {getDropdownOptionDisplayName(category.id)}
          </div>
        ))}
      </div>
    </div>
  );
};
