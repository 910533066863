import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SWRConfig } from 'swr';

import { AudioPlayerContextProvider } from 'context/audio-player-context';
import { CurrencyContextProvider } from 'context/currency-context';
import { LicenseContextProvider } from 'context/license-context';
import { overrideWindowEthereum } from 'utils/ethereum';

import { App } from './App';
import { ScrollToTop } from './components/scroll-to-top';
import { AuctionsContextProvider } from './context/auctions-context';
import { AuthContextProvider } from './context/auth-context';
import { CollectionsContextProvider } from './context/collections-context';
import { CurrentUserProfileContextProvider } from './context/current-user-profile-context';
import { ItemsContextProvider } from './context/items-context';
import { NavbarVisibilityContextProvider } from './context/navbar-visibility-context';
import { SalesContextProvider } from './context/sales-context';
import { env } from './env/env';
import reportWebVitals from './reportWebVitals';

import './index.css';

overrideWindowEthereum();

Sentry.init({
  dsn: 'https://98b6b49bf26240fbbf8848d1e6fffd76@o1195463.ingest.sentry.io/6324871',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: env.mode,
});

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnMount: true,
        errorRetryCount: 2,
        errorRetryInterval: 2000,
      }}
    >
      <PayPalScriptProvider
        options={{
          'client-id': env.paypalClientId,
          currency: 'USD',
        }}
      >
        <NavbarVisibilityContextProvider>
          <AuthContextProvider>
            <CurrentUserProfileContextProvider>
              <LicenseContextProvider>
                <CurrencyContextProvider>
                  <ItemsContextProvider>
                    <CollectionsContextProvider>
                      <SalesContextProvider>
                        <AuctionsContextProvider>
                          <AudioPlayerContextProvider>
                            <BrowserRouter>
                              <ScrollToTop />
                              <App />
                            </BrowserRouter>
                          </AudioPlayerContextProvider>
                        </AuctionsContextProvider>
                      </SalesContextProvider>
                    </CollectionsContextProvider>
                  </ItemsContextProvider>
                </CurrencyContextProvider>
              </LicenseContextProvider>
            </CurrentUserProfileContextProvider>
          </AuthContextProvider>
        </NavbarVisibilityContextProvider>
      </PayPalScriptProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
