import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import { useAuctionsOfItem } from '../../services/auctions-service';
import { sendNft } from '../../services/blockchain-service';
import { Item } from '../../services/item-service';
import { useSalesOfItem } from '../../services/sales-service';
import { refreshUserItemQuantity } from '../../services/user-service';
import { Button, ButtonStyle } from '../common/button/button';
import { CardActionButton } from '../common/card/card-action-button';
import { CardActionRow } from '../common/card/card-action-row';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { ItemCard } from '../common/item-card';
import { UnderlinedInputControl } from '../common/underlined-input/underlined-input';
import styles from './send-card.module.css';

interface Props {
  item: Item;
  collectionName?: string;
  ownerWalletAddress?: string;
  quantity: number;
  onItemSend: (quantity: number) => void;
}

export const SendCard = ({
  item,
  collectionName,
  ownerWalletAddress,
  quantity,
  onItemSend,
}: Props): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: auctions } = useAuctionsOfItem(item.id);
  const { data: sales } = useSalesOfItem(item.id);

  const userSalesOfItem = useMemo(
    () => (sales || []).filter((sale) => sale.ownerWalletId === ownerWalletAddress),
    [ownerWalletAddress, sales],
  );

  const userAuctionsOfItem = useMemo(
    () => (auctions || []).filter((auction) => auction.ownerWalletId === ownerWalletAddress),
    [auctions, ownerWalletAddress],
  );

  const handlePopupOpen = useCallback(() => {
    if (userAuctionsOfItem.length > 0) {
      toast.error('You cannot send an item with an active auction, please end it first.');
      return;
    }

    if (userSalesOfItem.length > 0) {
      toast.error('You cannot send an item with an active sale, please end it first.');
      return;
    }

    setIsPopupOpen(true);
  }, [userAuctionsOfItem.length, userSalesOfItem.length]);

  return (
    <ItemCard
      prefetchedItem={item}
      collectionName={collectionName}
      ownerWalletAddress={ownerWalletAddress}
    >
      {item && isPopupOpen && (
        <SendPopup
          item={item}
          closePopup={(): void => setIsPopupOpen(false)}
          ownedQuantity={quantity}
          onItemSend={onItemSend}
        />
      )}
      <CardActionRow
        button={<CardActionButton text="Send" onClick={handlePopupOpen} />}
        quantityInfo={`${quantity} items`}
      />
    </ItemCard>
  );
};

interface FormInputs {
  quantity: string;
  recipientAddress: string;
}

interface SendPopupProps {
  item: Item;
  ownedQuantity: number;
  closePopup: () => void;
  onItemSend?: (quantity: number) => void;
}

const SendPopup = ({
  item,
  ownedQuantity,
  closePopup,
  onItemSend,
}: SendPopupProps): JSX.Element => {
  const { walletAddress, refreshUserItem } = useContext(CurrentUserProfileContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormInputs>();

  const sendToken = useCallback(async () => {
    const recipientWalletAddress = getValues('recipientAddress');
    const quantity = parseInt(getValues('quantity'));

    try {
      await sendNft(walletAddress, recipientWalletAddress, item.id, quantity);
    } catch (e) {
      toast.error('We could not send your token, verify that recipient address is correct');
      throw e;
    }

    try {
      await refreshUserItem(item.id);
    } catch {
      toast.error(
        'Sorry, something went wrong with refreshing your items, please contact administrator',
      );
    }

    try {
      await refreshUserItemQuantity(recipientWalletAddress, item.id);
    } catch {
      toast.warn(
        'Sorry, something went wrong when refreshing items for receiver. ' +
          'They might not be registered on ORE Forge.',
      );
    }
  }, [getValues, item.id, refreshUserItem, walletAddress]);

  const { state, makeCall } = useCallWithState(sendToken, true);

  const submit = useCallback(async () => {
    const quantity = parseInt(getValues('quantity'));

    try {
      await makeCall();

      if (onItemSend !== undefined) {
        onItemSend(quantity);
      }

      closePopup();
    } catch {
      return;
    }
  }, [closePopup, getValues, makeCall, onItemSend]);

  const renderButtonChild = useCallback(() => {
    switch (state) {
      case CallState.Ready:
        return 'Send';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Sent!';
    }
  }, [state]);

  return (
    <GenericPopup
      canBeClosed={state !== CallState.Ready}
      onClose={closePopup}
      title="Send NFT"
      id={'send-nft-popup'}
    >
      <div className={styles.popupContainer}>
        <p>You are about to send {item.displayName}</p>
        <UnderlinedInputControl
          title="Quantity"
          placeholder="e.g. 2"
          type="number"
          id={'send-nft-popup-quantity-input'}
          {...register('quantity', {
            required: {
              value: true,
              message: 'You must specify quantity',
            },
            min: {
              value: 1,
              message: 'You must send at least 1 item',
            },
            max: {
              value: ownedQuantity,
              message: `You can't send more items than you have. You have: ${ownedQuantity} items.`,
            },
          })}
          errorMessage={errors.quantity?.message}
        />
        <UnderlinedInputControl
          title="Recipient address"
          placeholder="e.g. 0x124q23r..."
          type="text"
          id={'send-nft-popup-recipient-input'}
          {...register('recipientAddress', {
            required: {
              value: true,
              message: 'You must specify recipient address',
            },
          })}
          errorMessage={errors.recipientAddress?.message}
        />
        <Button
          buttonStyle={state === CallState.Ended ? ButtonStyle.green : ButtonStyle.white}
          onClick={handleSubmit(submit, () => {
            toast.warn('Please correct your form');
          })}
          disabled={state !== CallState.Ready}
          className={styles.popupSendButton}
          id={'send-nft-popup-send-button'}
        >
          {renderButtonChild()}
        </Button>
        <Button
          buttonStyle={ButtonStyle.second}
          onClick={closePopup}
          disabled={state !== CallState.Ready}
          id={'send-nft-popup-cancel-button'}
        >
          Cancel
        </Button>
      </div>
    </GenericPopup>
  );
};
