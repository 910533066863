import axios from 'axios';
import { snakeKeys } from 'js-convert-case';
import useSWR, { KeyedMutator, SWRResponse } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { env } from '../env/env';
import { amountToWei } from './blockchain-service';
import { dataFetcher } from './data-fetcher';
import { getAuthHeader, jsonHeaders } from './headers';

export interface Sale {
  id: string;
  createdAt: string;
  updatedAt: string;
  ownerWalletId: string;
  itemId: string;
  startQuantity: number;
  remainingQuantity: number;
  price: string;
  settlementToken: 'ORE' | 'BNB';
  status: 'active' | 'finished';
  purchaseLimit: number;
}

interface SalesList {
  sales: Array<Sale>;
}

export const useSalesWithPagination = (
  limit: number,
  category?: string,
  showFinished = 'false',
): {
  sales: Sale[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<SalesList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<SalesList[] | undefined>;
} => {
  const path = category ? `/categories/${category}/sales` : '/sales';

  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) =>
      `${env.apiUrl}${path}?offset=${page * limit}&limit=${limit}&show_finished=${showFinished}`,
    (url) => dataFetcher<SalesList>(url),
  );

  const extractedData = (data || []).map((p) => p.sales);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const sales = data ? new Array<Sale>().concat(...extractedData) : [];

  const isEmpty = sales.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<SalesList[] | undefined> => setPage(page + 1);

  return {
    sales,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};

export const useSalesOfItem = (
  itemId: string,
): {
  data: Sale[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<SalesList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/items/${itemId}/sales?show_finished=false`;
  const result = useSWR(url, (url) => dataFetcher<SalesList>(url));
  return {
    ...result,
    data: result.data?.sales,
  };
};

export const useSale = (saleId?: string): SWRResponse<Sale> => {
  const url = `${env.apiUrl}/sales/${saleId}`;

  const result = useSWR(saleId ? url : saleId, (url) => dataFetcher<Sale>(url));
  return result;
};

export const createNewSale = async (
  price: string,
  settlementToken: string,
  itemId: string,
  jwt: string,
  purchaseLimit?: number,
): Promise<Sale> => {
  const url = `${env.apiUrl}/sales`;
  const body = JSON.stringify(
    snakeKeys({
      price: amountToWei(price),
      settlementToken,
      itemId,
      purchaseLimit,
    }),
  );

  const response = await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
    data: body,
  });

  return response.data as Sale;
};

export const markPurchased = async (
  saleId: string,
  quantity: number,
  jwt: string,
): Promise<Sale> => {
  const url = `${env.apiUrl}/sales/${saleId}/purchase`;
  const body = JSON.stringify(
    snakeKeys({
      quantity,
    }),
  );

  const response = await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
    data: body,
  });

  return response.data as Sale;
};

export const deleteSale = async (saleId: string, jwt: string): Promise<void> => {
  const url = `${env.apiUrl}/sales/${saleId}`;

  await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'DELETE',
  });
};
