import { useNavigate, useParams } from 'react-router-dom';

import { useItem } from '../../services/item-service';
import { EditItemComponent } from './edit-item-component';

export const EditItemPage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: item, error } = useItem(id);

  if (error) {
    navigate('/not-found');
  }

  if (item === undefined) {
    return <></>;
  }

  return <EditItemComponent item={item} />;
};
