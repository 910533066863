import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useBalance } from '../services/blockchain-service';
import { likeItem, unlikeItem } from '../services/item-service';
import {
  acceptPrivacyPolicy,
  createUserProfile,
  editUserProfile,
  refreshUserItemQuantity,
  UserProfile,
  UserProfileRequest,
  useUser,
} from '../services/user-service';
import { AuthContext } from './auth-context';

interface CurrentUser {
  userProfile?: UserProfile;
  refreshUserProfile: () => Promise<void>;
  refreshUserProfileWithAcceptedPrivacyPolicy: (acceptedPrivacyPolicy: boolean) => Promise<void>;
  refreshUserItem: (itemId: string) => Promise<UserProfile>;
  walletAddress: string;
  updateCurrentUserProfile: (
    userProfileRequest: UserProfileRequest,
    profileAvatar?: File,
    profileBanner?: File,
  ) => Promise<void>;
  updateUserLikedItems: (itemId: string) => Promise<void>;
  oreBalance: number;
  bnbBalance: number;
  logOut?: () => void;
  askForPrivacyPolicy: boolean;
}

export const CurrentUserProfileContext = createContext<CurrentUser>({
  refreshUserProfile: () => Promise.reject(),
  refreshUserProfileWithAcceptedPrivacyPolicy: () => Promise.reject(),
  refreshUserItem: () => Promise.reject(),
  walletAddress: '',
  updateCurrentUserProfile: () => Promise.reject(),
  updateUserLikedItems: () => Promise.reject(),
  oreBalance: 0,
  bnbBalance: 0,
  logOut: () => ({}),
  askForPrivacyPolicy: false,
});

export const CurrentUserProfileContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { isAuthorized, jwt, browserActions, walletAddress } = useContext(AuthContext);
  const { data: userProfile, mutate, error: userNotFound } = useUser(walletAddress);
  // const [oreBalance, setOreBalance] = useState<number>(0);
  // const [bnbBalance, setBnbBalance] = useState<number>(0);
  const [askForPrivacyPolicy, setAskForPrivacyPolicy] = useState(false);

  const refreshUserProfile = useCallback(async () => {
    if (isAuthorized && walletAddress) {
      mutate();
    }
  }, [isAuthorized, mutate, walletAddress]);

  useEffect(() => {
    if (walletAddress && jwt) {
      if (userNotFound || (userProfile !== undefined && !userProfile.privacyPolicyAcceptedAt)) {
        setAskForPrivacyPolicy(true);
      }
    }
  }, [userProfile, userNotFound, walletAddress, jwt]);

  const refreshUserProfileWithAcceptedPrivacyPolicy = useCallback(
    async (acceptedPrivacyPolicy: boolean) => {
      if (!acceptedPrivacyPolicy) {
        toast.info('You must accept privacy policy to use ORE Forge');
        logOut?.();
        setAskForPrivacyPolicy(false);
        return;
      }

      if (isAuthorized && walletAddress) {
        if (userProfile !== undefined) {
          const newUserProfile = await acceptPrivacyPolicy(walletAddress, jwt);
          mutate(newUserProfile);
        } else {
          await createUserProfile({ acceptedPrivacyPolicy: 'true' }, jwt).then(mutate);
        }
      }
      setAskForPrivacyPolicy(false);
      // logout should not be dependency here
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isAuthorized, jwt, walletAddress, userProfile],
  );

  const updateCurrentUserProfile = async (
    userProfileRequest: UserProfileRequest,
    profileAvatar?: File,
    profileBanner?: File,
  ): Promise<void> => {
    const submit =
      userProfile !== undefined
        ? (userProfileRequest: UserProfileRequest): Promise<UserProfile> =>
            editUserProfile(userProfile.id, userProfileRequest, jwt, profileAvatar, profileBanner)
        : (userProfileRequest: UserProfileRequest): Promise<UserProfile> =>
            createUserProfile(userProfileRequest, jwt);
    const newProfile = await submit(userProfileRequest);
    mutate(newProfile);
  };

  const updateUserLikedItems = async (itemId: string): Promise<void> => {
    if (userProfile) {
      const isItemAlreadyLiked = userProfile.likedItemsIds?.includes(itemId);

      const updatedLikedItems = isItemAlreadyLiked
        ? (userProfile.likedItemsIds || []).filter((id) => id !== itemId)
        : [...(userProfile.likedItemsIds ? userProfile.likedItemsIds : []), itemId];

      const locallyUpdatedUserProfile: UserProfile = {
        ...userProfile,
        likedItemsIds: updatedLikedItems,
      };

      await mutate(
        async () => {
          if (isItemAlreadyLiked) {
            await unlikeItem(jwt, itemId);
          } else {
            await likeItem(jwt, itemId);
          }

          return locallyUpdatedUserProfile;
        },
        { optimisticData: locallyUpdatedUserProfile },
      );
    }
  };

  const refreshUserItem = useCallback(
    async (itemId: string) => {
      if (walletAddress === undefined) {
        throw new Error('tried to refresh user items without wallet address');
      }

      const updatedProfile = await refreshUserItemQuantity(walletAddress, itemId);

      mutate(updatedProfile);

      return updatedProfile;
    },
    [mutate, walletAddress],
  );

  const { oreBalance, bnbBalance } = useBalance(walletAddress);

  const logOut =
    browserActions?.disconnect === undefined
      ? undefined
      : (): void => {
          browserActions.disconnect?.();
          mutate(undefined);
        };

  return (
    <CurrentUserProfileContext.Provider
      value={{
        userProfile,
        refreshUserProfile,
        walletAddress: walletAddress || '',
        updateCurrentUserProfile,
        oreBalance,
        bnbBalance,
        updateUserLikedItems,
        refreshUserItem,
        logOut,
        askForPrivacyPolicy,
        refreshUserProfileWithAcceptedPrivacyPolicy,
      }}
    >
      {children}
    </CurrentUserProfileContext.Provider>
  );
};
