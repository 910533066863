import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export enum CallState {
  Ready,
  Started,
  Ended,
}

export const useCallWithState = <T>(
  call: (...args: any) => Promise<T>,
  isBlockchainCall?: boolean,
): {
  state: CallState;
  makeCall: (...args: any) => Promise<T>;
  resetState: () => void;
} => {
  const [state, setState] = useState(CallState.Ready);

  const makeCall = useCallback(
    async (...args: any) => {
      if (isBlockchainCall) {
        toast.info('Please check your wallet app');
      }
      setState(CallState.Started);

      try {
        const result = await call(...args);
        setState(CallState.Ended);
        return result;
      } catch (e) {
        setState(CallState.Ready);
        throw e;
      }
    },
    [call, isBlockchainCall],
  );

  const resetState = useCallback(() => setState(CallState.Ready), []);

  return {
    state,
    makeCall,
    resetState,
  };
};
