import styles from './social-links.module.css';

export interface ILinkWithIcon {
  icon: React.ReactElement;
  link: string;
  id?: string;
}

interface IProps {
  linksWithIcons: ILinkWithIcon[];
}

export const SocialLinks = ({ linksWithIcons }: IProps) => {
  return (
    <div className={styles.social}>
      {linksWithIcons.map((linkWithIcon, index) => (
        <a
          href={linkWithIcon.link}
          key={`social-link-${index}`}
          rel="noreferrer"
          target="_blank"
          className={styles.socialLink}
          id={linkWithIcon.id}
        >
          {linkWithIcon.icon}
        </a>
      ))}
    </div>
  );
};
