import classNames from 'classnames';

import { getFontSizeInRem } from 'utils/style-builders';

import styles from './icon-button.module.css';

interface Props {
  withBackground?: boolean;
  onClick?: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
  colorType?: 'orange' | 'white' | 'grey';
  width?: number;
  height?: number;
  padding?: number;
  iconType?: 'fill' | 'stroke' | 'both';
  margin?: string;
}

export const IconButton = ({
  withBackground,
  onClick,
  icon,
  disabled,
  colorType = 'white',
  width = 24,
  height = 24,
  padding,
  iconType = 'fill',
  margin,
}: Props) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled}
      style={{
        width: getFontSizeInRem(width),
        height: getFontSizeInRem(height),
        padding: padding ? getFontSizeInRem(padding) : undefined,
        margin,
      }}
      className={classNames(styles.button, {
        [styles.orange]: colorType === 'orange',
        [styles.white]: colorType === 'white',
        [styles.grey]: colorType === 'grey',
        [styles.withBackground]: withBackground,
        [styles.fill]: iconType === 'fill' || iconType === 'both',
        [styles.stroke]: iconType === 'stroke' || iconType === 'both',
      })}
    >
      {icon}
    </button>
  );
};
