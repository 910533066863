import { PrivacyPolicy } from '../../components/privacy-policy/privacy-policy';
import styles from './privacy-policy-page.module.css';

export const PrivacyPolicyPage = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <h1>Privacy Policy</h1>
      <PrivacyPolicy />
    </div>
  );
};
