import { COLORS } from 'constant';
import { TRACK_COLUMNS_SETS } from 'constant/music-page-constants';

import { Link, PositioningWrapper, TableRow, TableRowElement, Typography } from 'components/common';
import { getFontSizeInRem } from 'utils/style-builders';

import { ITrackCardProps } from '../track-card';
import { QueuePosition } from './queue-position';
import { SongDetails } from './song-details';

interface IProps extends ITrackCardProps {
  isHovered: boolean;
}

export const TrackCardContent = (props: IProps) => {
  const TABLE_ROW_COLUMNS: TableRowElement[] = [
    {
      columnContent: (
        <PositioningWrapper horizontalAlign="center" verticalAlign="center">
          <QueuePosition
            songId={props.songId}
            isHovered={props.isHovered}
            queuePosition={props.queuePosition}
            isPlaying={props.isPlaying}
            playlistId={props.playlistId}
          />
        </PositioningWrapper>
      ),
      flexBasis: getFontSizeInRem(56),
      isFixed: true,
    },
    {
      columnContent: (
        <SongDetails
          author={props.author}
          authorURL={props.authorURL}
          imageURL={props.albumImage ?? ''}
          isPlaying={props.isPlaying}
          songTitle={props.songTitle}
        />
      ),
      flexBasis: '70%',
    },
    {
      columnContent:
        props.setOfColumns === TRACK_COLUMNS_SETS.TAD ? (
          <div onClick={(e) => e.stopPropagation()}>
            <Link
              label={props.albumName}
              fontSize={14}
              weight={500}
              to={props.albumURL}
              theme="blue"
            />
          </div>
        ) : (
          <PositioningWrapper horizontalAlign="center" verticalAlign="center">
            <Typography.Span fontSize={14} fontWeight={500} color={COLORS.TEXT_INFO_2}>
              {props.price}
            </Typography.Span>
          </PositioningWrapper>
        ),
      flexBasis: '20%',
    },
    {
      columnContent: (
        <PositioningWrapper horizontalAlign="center" verticalAlign="center">
          <Typography.Span fontSize={14} fontWeight={500} color={COLORS.TEXT_INFO_2}>
            {props.duration}
          </Typography.Span>
        </PositioningWrapper>
      ),
      flexBasis: '10%',
    },
  ];

  if (props.lastColumn) {
    TABLE_ROW_COLUMNS.push({
      columnContent: props.lastColumn,
      flexBasis: getFontSizeInRem(56),
      isFixed: true,
    });
  }

  return <TableRow tableRowColumns={TABLE_ROW_COLUMNS} />;
};
