import { useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import useSWR, { SWRResponse } from 'swr';

import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { PdfViewer } from '../../components/pdf-viewer/pdf-viewer';
import { Item } from '../../services/item-service';
import styles from './item-page.module.css';

interface Props {
  item: Item;
  isCurrentUserTheOwner?: boolean;
}

export const ItemPreview = ({ item, isCurrentUserTheOwner }: Props): JSX.Element => {
  const { data: nftMediaType } = useMediaType(item.mediaIpfsUrl);
  const { data: unlockableContentMediaType } = useMediaType(item.lockedMediaIpfsUrl);
  const { data: streamPreviewMediaType } = useMediaType(item.streamPreviewUrl);

  const getNonOwnerNftMediaUrl = (item: Item): string => {
    if (nftMediaType?.split('/')[0] === 'image' && nftMediaType.split('/')[1] !== 'gif') {
      return item.watermarkedImageUrl;
    }

    return item.mediaIpfsUrl;
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.previewColumn} ref={containerRef}>
      {nftMediaType ? (
        <NftMedia
          defaultElement={<img src={item.previewImageUrl} alt="nft image" />}
          url={isCurrentUserTheOwner ? item.mediaIpfsUrl : getNonOwnerNftMediaUrl(item)}
          mediaType={nftMediaType}
          previewImageUrl={item.previewImageUrl}
          containerRef={containerRef}
        />
      ) : (
        <div className={styles.spinner}>
          <span>Loading media</span> <ClipLoader />
        </div>
      )}
      {!item.lockedMediaIpfsUrl && item.streamPreviewUrl && streamPreviewMediaType && (
        <>
          <p>Unlockable content preview</p>
          <NftMedia
            url={item.streamPreviewUrl}
            mediaType={streamPreviewMediaType}
            previewImageUrl={item.previewImageUrl}
            containerRef={containerRef}
            defaultElement={
              <a
                href={item.streamPreviewUrl}
                target="_blank"
                rel="noreferrer"
                className={styles.externalIpfsMedia}
              >
                <FileIcon /> File
              </a>
            }
          />
        </>
      )}
      {item.lockedMediaIpfsUrl && (
        <>
          <p>Unlockable content</p>
          {unlockableContentMediaType ? (
            <NftMedia
              defaultElement={
                <a
                  href={item.lockedMediaIpfsUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.externalIpfsMedia}
                >
                  <FileIcon /> File
                </a>
              }
              url={item.lockedMediaIpfsUrl}
              mediaType={unlockableContentMediaType}
              previewImageUrl={item.previewImageUrl}
              containerRef={containerRef}
            />
          ) : (
            <div className={styles.spinner}>
              <span>Loading media</span> <ClipLoader />
            </div>
          )}
        </>
      )}
    </div>
  );
};

interface NftMediaProps {
  url: string;
  previewImageUrl?: string;
  mediaType: string;
  defaultElement: JSX.Element;
  containerRef: React.RefObject<HTMLDivElement>;
}

const NftMedia = ({
  url,
  mediaType,
  defaultElement,
  previewImageUrl,
  containerRef,
}: NftMediaProps): JSX.Element => {
  if (mediaType === 'application/pdf') {
    return <PdfViewer url={url} containerRef={containerRef} />;
  }

  if (mediaType === 'application/mp4') {
    return (
      <video controls autoPlay muted loop>
        <source src={url} type={'video/mp4'} />
        Your browser does not support the video tag.
      </video>
    );
  }

  const generalType = mediaType.split('/')[0];

  switch (generalType) {
    case 'image':
      return <img src={url} alt="nft-image" />;
    case 'video':
      return (
        <video controls autoPlay muted loop>
          <source src={url} type={mediaType} />
          Your browser does not support the video tag.
        </video>
      );
    case 'audio':
      return <AudioMedia url={url} previewImageUrl={previewImageUrl} mediaType={mediaType} />;
    default:
      return defaultElement;
  }
};

interface AudioMediaProps {
  url: string;
  previewImageUrl?: string;
  mediaType: string;
}

const AudioMedia = ({ url, previewImageUrl, mediaType }: AudioMediaProps): JSX.Element => {
  return (
    <div className={styles.audioPreviewContainer}>
      {previewImageUrl && <img src={previewImageUrl} alt="nft-image" />}
      <audio controls autoPlay muted>
        <source src={url} type={mediaType} />
      </audio>
    </div>
  );
};

const fetchMediaType = async (url: string): Promise<string | undefined> => {
  const response = await fetch(url, { method: 'HEAD' });
  const headers = response.headers;
  return headers.get('Content-type')?.split('=').pop();
};

const useMediaType = (url?: string): SWRResponse<string | undefined> => {
  const result = useSWR(url, fetchMediaType);
  return result;
};
