import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingPagePlaceholder } from 'components/common/loading-page-placeholder/loading-page-placeholder';
import { ParticularAlbum } from 'components/particulars/particular-album';
import { useCollection } from 'services/collection-service';
import { useUser } from 'services/user-service';

export const ParticularAlbumPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: collection, error } = useCollection(id);
  const { data: creator } = useUser(collection?.creatorWalletId);

  useEffect(() => {
    if (error) {
      navigate('/not-found');
    }
  }, [error, navigate]);

  if (collection === undefined) {
    return <LoadingPagePlaceholder />;
  }

  return <ParticularAlbum collection={collection} creator={creator} />;
};
