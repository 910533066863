import { useContext } from 'react';

import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { ProfileForm } from './profile-form';

export const EditProfilePage = (): JSX.Element => {
  const { userProfile, walletAddress, updateCurrentUserProfile } =
    useContext(CurrentUserProfileContext);

  return (
    <ProfileForm
      submit={updateCurrentUserProfile}
      userProfile={userProfile}
      walletAddress={walletAddress}
    />
  );
};
