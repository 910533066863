/**
 * Color palette is based on index.css global css variables
 * use Figma's var names here !
 */

export const COLORS = {
  WHITE: '#f5f5f5',
  ORANGE: '#f3890c',
  BLUE1: '#2f80ed',
  GRAY_LIGHTER: '#404048',
  GRAY: '#302e38',
  GRAY_DARKER: '#16151a',
  GRAY_TEXT_1: '#85838e',
  GRAY_TEXT_2: '#c3c2c8',
  BALCK_1: '#09090b',

  // Figma naming
  TEXT_INFO_2: '#7a7882',
} as const;
