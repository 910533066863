import { useEffect } from 'react';

import { AllowedCurrencies, getCurrentCurrency } from 'utils/blockchain-helpers';

import styles from './currency-dropdown.module.css';

interface Props {
  setValue: (value: AllowedCurrencies) => void;
  value: AllowedCurrencies;
  id: string;
}

export const CurrencyDropdown = ({ setValue, value, id }: Props): JSX.Element => {
  const currentCurrency = getCurrentCurrency();
  const options =
    !currentCurrency || currentCurrency === 'bnb'
      ? ['ORE', 'BNB']
      : [currentCurrency?.toLocaleUpperCase()];

  const handleOnChange = (value: AllowedCurrencies): void => {
    setValue(value);
  };
  // TODO: style
  return (
    <select
      onChange={(event): void => handleOnChange(event.target.value as AllowedCurrencies)}
      className={styles.currencyDropdown}
      value={value}
      id={id}
    >
      {options.map((opt) => {
        return (
          <option value={opt} key={opt} className={styles.currencyDropdownMenu} id={`${id}-${opt}`}>
            {opt}
          </option>
        );
      })}
    </select>
  );
};
