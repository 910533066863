export const BannedPage = () => {
  return (
    <div style={{ margin: '6rem' }}>
      <h2>Your Account has been blocked and flagged due to a violation.</h2>
      <p>
        Please contact support at{' '}
        <a
          style={{ color: '#f3890c', textDecoration: 'none' }}
          href="mailto: support@ore-system.com"
        >
          support@ore-system.com
        </a>
      </p>
    </div>
  );
};
