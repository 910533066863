import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from '../context/auth-context';

export const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isAuthorized } = useContext(AuthContext);
  const location = useLocation();

  return isAuthorized ? (
    children
  ) : (
    <Navigate
      to="/not-permitted"
      replace
      state={{ previousPage: `${location.pathname}${location.search}` }}
    />
  );
  // TODO: navigate to connect metamask when implemented
};
