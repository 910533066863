import { useState } from 'react';
import classNames from 'classnames';
import { marked } from 'marked';

import { useFaq } from 'services/faq-service';

import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import styles from './faq-page.module.css';

export const FaqPage = (): JSX.Element => {
  const { data } = useFaq();
  return (
    <div className={styles.container}>
      <h1>FAQ</h1>
      {data?.map((faqItem) => (
        <FaqSection key={faqItem.order} title={faqItem.question}>
          <div
            style={{ display: 'flex' }}
            dangerouslySetInnerHTML={{ __html: marked.parse(faqItem.answer) }}
          />
        </FaqSection>
      ))}
    </div>
  );
};

interface FaqSectionProps {
  title: string;
  children: JSX.Element; // description
}

const FaqSection = ({ title, children }: FaqSectionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.faqSectionContainer}>
      <div
        onClick={(): void => setIsOpen((v) => !v)}
        className={classNames(styles.faqTitleRow, { [styles.faqTitleRowActive]: isOpen })}
      >
        <h3>{title}</h3>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <div className={isOpen ? styles.answerOpen : styles.answer}>{children}</div>
    </div>
  );
};
