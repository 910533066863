import { env } from '../../env/env';
import { useItems } from '../../services/item-service';
import { GenericCarousel } from '../common/carousel/generic-carousel';
import { LoadingItemsPlaceholder } from '../common/carousel-placeholders/loading-items-placeholder';
import { NoItemsPlaceholder } from '../common/carousel-placeholders/no-items-placeholder';
import { ItemCard } from '../common/item-card/item-card';

export const StealthGamesCarousel = (): JSX.Element => {
  const { data: games } = useItems('games');

  const renderChildren = (): JSX.Element[] => {
    if (games === undefined) {
      return [<LoadingItemsPlaceholder key={'game-carousel-loading-items'} />];
    }

    if (games.length === 0) {
      return [
        <NoItemsPlaceholder text={'There are no games yet'} key={'game-carousel-no-items'} />,
      ];
    }

    return games
      .slice(0, env.maxCarouselItems)
      .map((game) => <ItemCard key={game.id} prefetchedItem={game} />);
  };

  return <GenericCarousel title={'ORE Stealth Games'}>{renderChildren()}</GenericCarousel>;
};
