import { Weight } from 'types/themes';

export const typographyStylesBuilder = ({
  fontWeight,
  fontSize,
  color,
  paddingBottom,
  textAlign,
  marginBottom,
  marginTop,
}: {
  fontWeight?: Weight;
  fontSize?: string;
  color?: string;
  paddingBottom?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  marginBottom?: string;
  marginTop?: string;
}) => ({
  ...(fontWeight && { fontWeight }),
  ...(fontSize && { fontSize }),
  ...(color && { color }),
  ...(paddingBottom && { paddingBottom }),
  ...(textAlign && { textAlign }),
  ...(marginBottom && { marginBottom }),
  ...(marginTop && { marginTop }),
});

export const combineRemString = (value: number) => `${value}rem`;

export const getFontSizeInPx = () =>
  Number(getComputedStyle(document.documentElement).fontSize.replace('px', ''));

export const getFontSizeInRem = (requestedFontSize: number) =>
  `${requestedFontSize / getFontSizeInPx()}rem`;
