import axios from 'axios';

import { baseHeaders, getAuthHeader } from './headers';

export const dataFetcher = async <T>(url: string, jwt?: string): Promise<T> => {
  const response = await axios({
    url,
    headers: {
      ...baseHeaders,
      ...(jwt && getAuthHeader(jwt)),
    },
  });

  const data: T = response.data;

  return data;
};
