import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth-context';

export const RequireAccess = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isBanned } = useContext(AuthContext);

  return isBanned ? <Navigate to="/banned" replace /> : children;
};
