import axios, { AxiosError } from 'axios';

import { env } from '../env/env';
import { objectToFormData } from '../utils/object-to-form-data';
import { baseHeaders, getAuthHeader } from './headers';

interface PresignedUrlResponse {
  presignedUrl: string;
  id: string;
}

interface UploadRecord {
  id: string;
  finished: boolean;
  updatedAt: string;
  createdAt: string;
  pinataResponse?: string;
}

class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'timeoutError';
  }
}

export const uploadFileToIpfs = async (authToken: string, file: Blob): Promise<string> => {
  const { id: uploadId } = await createUploadRecord(authToken);

  const { presignedUrl, id } = await getPresignedUrl(authToken);
  await putFileWithPresignedUrl(file, presignedUrl);

  try {
    const ipfsHash = await uploadWithFromId(authToken, id, uploadId);

    return ipfsHash;
  } catch (err) {
    return await waitForUploadFinish(authToken, uploadId, new Date());
  }
};

const waitForUploadFinish = async (
  authToken: string,
  uploadId: string,
  startDate: Date,
): Promise<string> => {
  const { finished, pinataResponse } = await getUploadStatus(authToken, uploadId);

  const isTakingTooLong = new Date().getTime() - startDate.getTime() > env.maximumUploadDurationMs;

  if (!finished && isTakingTooLong) {
    throw Error('upload is taking too long');
  }

  if (!finished) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), env.uploadStatusWaitTime));

    return await waitForUploadFinish(authToken, uploadId, startDate);
  }

  if (pinataResponse) {
    const data = JSON.parse(pinataResponse);

    return data.IpfsHash;
  }

  throw Error('Upload finished but no ipfs hash received');
};

const createUploadRecord = async (authToken: string): Promise<UploadRecord> => {
  const url = `${env.apiUrl}/ipfs/create_media_requests`;

  const response = await axios({
    url,
    method: 'POST',
    headers: { ...getAuthHeader(authToken), ...baseHeaders },
  });

  return response.data as UploadRecord;
};

const getUploadStatus = async (authToken: string, uploadId: string): Promise<UploadRecord> => {
  const url = `${env.apiUrl}/ipfs/create_media_requests/${uploadId}`;

  const response = await axios({
    url,
    headers: { ...getAuthHeader(authToken), ...baseHeaders },
  });

  return response.data as UploadRecord;
};

const uploadWithFromId = async (
  authToken: string,
  fromId: string,
  uploadId: string,
): Promise<string> => {
  const url = `${env.apiUrl}/ipfs/media`;
  const body = objectToFormData({ fromId, createMediaRequestId: uploadId });

  try {
    const response = await axios({
      url,
      method: 'POST',
      data: body,
      headers: { ...getAuthHeader(authToken), ...baseHeaders },
    });

    return response.data.ipfsHash;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosErr: AxiosError = err;

      if (axiosErr.response !== undefined && axiosErr.response.status) {
        throw new TimeoutError('ipfs upload timeout, check in a while');
      }
    }

    throw err;
  }
};

export const getPresignedUrl = async (authToken: string): Promise<PresignedUrlResponse> => {
  const url = `${env.apiUrl}/ipfs/presigned_url`;

  const response = await axios({
    url,
    method: 'POST',
    headers: { ...getAuthHeader(authToken), ...baseHeaders },
  });

  return response.data as PresignedUrlResponse;
};

export const putFileWithPresignedUrl = async (file: Blob, url: string): Promise<void> => {
  await axios({
    url,
    method: 'PUT',
    data: file,
  });
};
