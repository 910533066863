import { useContext } from 'react';
import { COLORS } from 'constant';

import { Typography } from 'components/common';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';

interface IProps {
  queuePosition: string;
  isPlaying: boolean;
  isHovered: boolean;
  songId: string;
  playlistId?: string;
}

export const QueuePosition = (props: IProps) => {
  const { play, pause, playPlaylist, isPaused } = useContext(AudioPlayerContext);

  const onPlayPauseClicked = () => {
    if (props.isPlaying) {
      isPaused ? play() : pause();
    } else if (props.playlistId && props.songId) {
      playPlaylist(props.playlistId, props.songId);
    }
  };

  return (
    <>
      {props.isHovered ? (
        <PlayPauseButton
          colorType={props.isPlaying ? 'orange' : 'white'}
          isPlaying={props.isPlaying && !isPaused}
          onPlayPauseClicked={onPlayPauseClicked}
          withBackground={false}
        />
      ) : props.isPlaying ? (
        <PlayPauseButton
          colorType={'orange'}
          isPlaying={props.isPlaying && !isPaused}
          onPlayPauseClicked={onPlayPauseClicked}
          withBackground={false}
        />
      ) : (
        <Typography.Span fontSize={14} fontWeight={500} color={COLORS.TEXT_INFO_2}>
          {props.queuePosition}
        </Typography.Span>
      )}
    </>
  );
};
