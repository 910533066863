import { useMemo } from 'react';

import { ReactComponent as FireIcon } from '../../assets/icons/fire.svg';
import { env } from '../../env/env';
import { Auction, useAuctionsWithPagination } from '../../services/auctions-service';
import { amountFromWei } from '../../services/blockchain-service';
import { useItem } from '../../services/item-service';
import { TransactionType } from '../../types/transaction-type';
import { AuctionCard } from '../auction-card';
import { GenericCarousel } from '../common/carousel/generic-carousel';
import { LoadingItemsPlaceholder } from '../common/carousel-placeholders/loading-items-placeholder';
import { NoItemsPlaceholder } from '../common/carousel-placeholders/no-items-placeholder';

export const AuctionsCarousel = (): JSX.Element => {
  const { auctions } = useAuctionsWithPagination(25);

  const activeAuctions = useMemo(() => {
    return auctions
      .filter((auction) => new Date(auction.endsAt) > new Date())
      .slice(0, env.maxCarouselItems);
  }, [auctions]);

  const renderChildren = (): JSX.Element[] => {
    if (activeAuctions === undefined) {
      return [<LoadingItemsPlaceholder key={'auctions-carousel-loading-items'} />];
    }

    if (activeAuctions.length === 0) {
      return [
        <NoItemsPlaceholder
          text={'There are no auctions yet'}
          key={'auctions-carousel-no-items'}
        />,
      ];
    }

    return activeAuctions.map((auction) => (
      <CarouselItem auction={auction} key={`auction-${auction.id}`} />
    ));
  };

  return (
    <GenericCarousel
      title={
        <>
          Live Auctions <FireIcon />
        </>
      }
      link={`/explore?transactionType=${TransactionType.Bid}`}
      linkDisplayName={'Go to all auctions'}
    >
      {renderChildren()}
    </GenericCarousel>
  );
};

const CarouselItem = ({ auction }: { auction: Auction }): JSX.Element => {
  const { data: item } = useItem(auction.itemId);

  return (
    <AuctionCard
      item={item}
      minimalBid={amountFromWei(auction.minimumBid)}
      currency={auction.settlementToken}
      quantity={auction.quantity}
      auctionId={auction.id}
      ownerWalletAddress={auction.ownerWalletId}
      endOfAuction={auction.endsAt}
    />
  );
};
