import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ClipLoader } from 'react-spinners';

import { useContainerDimensions } from '../../hooks/container-dimensions';
import { Button, ButtonStyle } from '../common/button/button';
import styles from './pdf-viewer.module.css';

interface Props {
  url: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const PdfViewer = ({ url, containerRef }: Props): JSX.Element => {
  const [numberOfPages, setNumberOfPages] = useState<number>();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [goToPage, setGoToPage] = useState(1);

  const { width: containerWidth } = useContainerDimensions(containerRef);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumberOfPages(numPages);
  };

  const isPreviousPageAvailable = currentPageNumber > 1;
  const isNextPageAvailable = numberOfPages !== undefined && currentPageNumber < numberOfPages;

  const goToNextPage = (): void => setCurrentPageNumber((page) => page + 1);
  const goToPreviousPage = (): void => setCurrentPageNumber((page) => page - 1);

  const goToPickedPage = (): void => {
    if (goToPage < 1) {
      setCurrentPageNumber(1);
      setGoToPage(1);
      return;
    }

    if (numberOfPages && goToPage > numberOfPages) {
      setCurrentPageNumber(numberOfPages);
      setGoToPage(numberOfPages);
      return;
    }

    setCurrentPageNumber(goToPage);
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      goToPickedPage();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.optionsContainer}>
        <div className={styles.goToPageContainer}>
          <p>Go to page:</p>
          <input
            className={styles.goToPageInput}
            type="number"
            value={goToPage}
            onChange={(e): void => setGoToPage(parseInt(e.target.value))}
            onBlur={goToPickedPage}
            onKeyDown={onInputKeyDown}
            id={'pdf-viewer-go-to-page-input'}
          ></input>
        </div>
        <a href={url} target="_blank" rel="noreferrer" className={styles.newTabLink}>
          Open in browser tab
        </a>
      </div>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div className={styles.pageLoader}>
            <ClipLoader />
          </div>
        }
        className={styles.pdfContent}
      >
        <Page pageNumber={currentPageNumber} renderAnnotationLayer={false} width={containerWidth} />
      </Document>
      <div className={styles.controlsContainer}>
        <Button
          buttonStyle={ButtonStyle.second}
          onClick={goToPreviousPage}
          disabled={!isPreviousPageAvailable}
          id={'pdf-viewer-previous-page-button'}
        >
          Previous Page
        </Button>
        <p className={styles.pageInfo}>
          page {currentPageNumber} of {numberOfPages}
        </p>
        <Button
          buttonStyle={ButtonStyle.second}
          onClick={goToNextPage}
          disabled={!isNextPageAvailable}
          id={'pdf-viewer-next-page-button'}
        >
          Next Page
        </Button>
      </div>
    </div>
  );
};
