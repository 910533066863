/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-len */
export const env = {
  chainId: process.env.REACT_APP_CHAIN_ID!,
  binanceUrl: process.env.REACT_APP_BINANCE_URL!,
  etheruemUrl: process.env.REACT_APP_ETHEREUM_URL!,
  polygonUrl: process.env.REACT_APP_POLYGON_URL!,
  apiUrl: process.env.REACT_APP_API_URL!,
  oreContractAddress: process.env.REACT_APP_ORE_CONTRACT_ADDRESS!,
  oreTokenContractAddress: process.env.REACT_APP_ORE_TOKEN_CONTRACT_ADDRESS!,
  polygonContractAddress: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS!,
  ethereumContractAddress: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS!,
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
  ipfsUrl: process.env.REACT_APP_IPFS_URL!,
  allowedChainIds: [
    parseInt(process.env.REACT_APP_ETHEREUM_CHAIN_ID!),
    parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID!),
    parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID!),
  ],
  ethereumChainId: parseInt(process.env.REACT_APP_ETHEREUM_CHAIN_ID!),
  polygonChainId: parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID!),
  binanceChainId: parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID!),
  shortenedWalletIdLength: 12,
  frontPrefix: '',
  jwtExpirationInHours: parseInt(process.env.REACT_APP_JWT_EXPIRATION_IN_HOURS!),
  tokenStorageKey: process.env.REACT_APP_TOKEN_STORAGE_KEY!,
  notForSaleTokenPrice: 100000000,
  maxCarouselItems: 8,
  lastConnectedProviderKey: 'last-connected-provider',
  takeDownRequestFormUrl: process.env.REACT_APP_TAKE_DOWN_REQUEST_URL,
  mode: process.env.NODE_ENV,
  maximumUploadFileSize: 1024 * 1024 * 1024,
  maximumSimpleUploadFileSize: 1024 * 1024 * 4,
  uploadStatusWaitTime: 5000,
  maximumUploadDurationMs: 120 * 1000,
  oreMusicSubscriptionUrl: 'https://ore-spark.com/user/register',
};
