import { useContext, useEffect, useMemo } from 'react';

import { Card } from 'components/common/card';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';
import { Collection } from 'services/collection-service';
import { useUser } from 'services/user-service';
import { generateCollectionPlaylistId } from 'utils/music-playlist-names-generators';

import styles from './music-collection-card.module.css';

interface Props {
  collection?: Collection;
  previewMode?: boolean;
}

export const MusicCollectionCard = ({ collection, previewMode }: Props) => {
  const { data: creator } = useUser(collection?.creatorWalletId);

  const { playPlaylist, addPlaylistFromItems, isPaused, play, pause, currentPlaylist } =
    useContext(AudioPlayerContext);
  const playlistId = useMemo(
    () => (collection ? generateCollectionPlaylistId(collection.id) : undefined),
    [collection],
  );
  const isCollectionACurrentPlaylist =
    currentPlaylist !== undefined && currentPlaylist?.id === playlistId;

  const isPlaying = !isPaused && isCollectionACurrentPlaylist;

  const canBePlayed = useMemo(() => {
    if (collection === undefined || collection.items === undefined) {
      return false;
    }

    const itemsWithPlayableMedia = collection.items.filter(
      (item) => item.lockedMediaIpfsUrl || item.streamPreviewUrl,
    );

    if (itemsWithPlayableMedia.length > 0) {
      return true;
    }

    return false;
  }, [collection]);

  useEffect(() => {
    if (collection && playlistId) {
      addPlaylistFromItems(collection?.items || [], collection?.displayName, playlistId);
    }
  }, [addPlaylistFromItems, collection, playlistId]);

  const onClicked = () => {
    if (isCollectionACurrentPlaylist) {
      if (isPlaying) {
        pause();
      } else {
        play();
      }
    } else if (playlistId) {
      playPlaylist(playlistId);
    }
  };

  const renderButton = () => {
    if (!canBePlayed) return null;

    return (
      <div className={styles.playPauseButton}>
        <PlayPauseButton
          isPlaying={isPlaying}
          onPlayPauseClicked={onClicked}
          colorType="orange"
          width={56}
          height={56}
          padding={18}
        />
      </div>
    );
  };

  return (
    <Card
      imageUrl={collection?.previewImageUrl}
      creatorImgUrl={creator?.profileAvatarUrl}
      creatorWalletAddress={collection?.creatorWalletId}
      type="wide"
      title={collection?.displayName || ''}
      userLinkPrefix="/music"
      bottomElement={
        <>
          {isCollectionACurrentPlaylist && renderButton()}
          <p className={styles.description}>{collection?.description}</p>
        </>
      }
      hoverableElement={!isCollectionACurrentPlaylist && renderButton()}
      hoverable
      active={isCollectionACurrentPlaylist}
      redirectLink={`/music/album/${collection?.id}`}
      disableLinks={previewMode}
    />
  );
};
