import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import { Weight } from 'types/themes';
import { getFontSizeInRem } from 'utils/style-builders';

import styles from './link.module.css';

export type LinkColors = 'blue' | 'grey';

type Props = {
  label: string;
  to: string;
  theme?: LinkColors;
  fontSize?: number;
  weight?: Weight;
};

/**
 *
 * @param fontSize indicates fontsize in `px` unit
 * @returns styled `react-router-dom` `<Link />` component
 */

export const Link = ({ label, to, theme = 'grey', fontSize = 14, weight = 400 }: Props) => {
  const remFontSize = getFontSizeInRem(fontSize);

  return (
    <RouterLink
      className={classNames(styles.link, styles[weight], {
        [styles.greyLink]: theme === 'grey',
        [styles.blueLink]: theme === 'blue',
      })}
      to={to}
      style={{ fontSize: `${remFontSize}rem`, fontWeight: weight }}
    >
      {label}
    </RouterLink>
  );
};
