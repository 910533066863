import classNames from 'classnames';

import styles from './badge.module.css';

interface Props {
  content: string;
  selected?: boolean;
}

export const Badge = ({ content, selected }: Props) => (
  <p className={classNames(styles.container, { [styles.selected]: selected })}>{content}</p>
);
