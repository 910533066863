import { env } from '../env/env';

const truncate = (string: string, maxLen: number = env.shortenedWalletIdLength): string => {
  if (string.length <= maxLen) {
    return string;
  }

  return `${string.slice(0, maxLen - 5)}...${string.slice(-5)}`;
};

export default truncate;
