import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { OWNERSHIP_LICENSES } from 'constant';
import { env } from 'env/env';

import { ReactComponent as LikeIcon } from 'assets/icons/like-icon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { BurnNftComponent } from 'components/burn-nft/burn-nft-component';
import { BidTimer } from 'components/common/bid-timer';
import { Button, ButtonStyle } from 'components/common/button/button';
import { CircularImage } from 'components/common/circular-image';
import { MarkdownDisplay } from 'components/common/markdown-display/markdown-display';
import { EndSaleComponent } from 'components/end-sale/end-sale-component';
import WithChainChange from 'components/hoc/with-chain-change/with-chain-change';
import { PutItemOnAuctionComponent } from 'components/put-item-on-auction/put-item-on-auction-component';
import { PutItemOnSaleComponent } from 'components/put-item-on-sale/put-item-on-sale-component';
import { GenericSalesAuctionsList } from 'components/sales-auctions-list/generic-sales-auctions-list';
import { CheckoutPopup } from 'components/transaction-popups/checkout-popup';
import { PlaceABidPopup } from 'components/transaction-popups/place-a-bid-popup';
import { AuthContext } from 'context/auth-context';
import { getLastConnectedProvider, ProviderType } from 'context/browser-auth-context';
import { CurrentUserProfileContext } from 'context/current-user-profile-context';
import { LicenseContext } from 'context/license-context';
import { useLikeItem } from 'hooks/like-item';
import { Auction, useAuctionsOfItem, useBidsForAuction } from 'services/auctions-service';
import { amountFromWei, getServiceFee } from 'services/blockchain-service';
import { Item } from 'services/item-service';
import { Sale, useSalesOfItem } from 'services/sales-service';
import { useUser } from 'services/user-service';
import { getChainIdFromSymbol, getCurrentChain } from 'utils/blockchain-helpers';
import { getPriceInUSD } from 'utils/currency-helpers';
import truncate from 'utils/string-truncator';

import { ItemBidsInfo } from './item-bids-info';
import { ItemOwner } from './item-owner';
import styles from './item-page.module.css';
import { ItemPreview } from './item-preview';

interface Props {
  item: Item;
  sale?: Sale;
  auction?: Auction;
  orePriceInUSD?: number;
}

export const ItemComponent = ({ item, sale, auction, orePriceInUSD }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthorized, browserActions } = useContext(AuthContext);
  const { walletAddress, userProfile } = useContext(CurrentUserProfileContext);
  const { licenseTexts } = useContext(LicenseContext);
  const { data: creator } = useUser(item.firstOwnerWalletId);
  const { data: owner } = useUser(sale?.ownerWalletId || auction?.ownerWalletId);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [serviceFee, setServiceFee] = useState(0);
  const currentUserOwnedEntry = userProfile?.ownedItems.find(
    (entry) => entry.itemId.toString() === item.id,
  );
  const { isLiked, handleLikeItem } = useLikeItem(item.id);

  const { data: sales } = useSalesOfItem(item.id);
  const { data: auctions } = useAuctionsOfItem(item.id);
  const { data: bids } = useBidsForAuction(auction?.id);
  const provider = getLastConnectedProvider();
  const makeWalletCall = async (chainId?: number) => {
    chainId &&
      (provider === ProviderType.Metamask
        ? await browserActions?.connectMetamask(chainId)
        : await browserActions?.connectWalletConnect?.());
    window.location.reload();
  };

  const displayedSales = useMemo(() => {
    if (sales) {
      return sales.filter((s) => sale === undefined || s.id !== sale.id);
    }

    return [];
  }, [sale, sales]);

  const displayedAuctions = useMemo(() => {
    if (auctions) {
      return auctions.filter((a) => auction === undefined || a.id !== auction.id);
    }

    return [];
  }, [auction, auctions]);

  const canBeManagedByOwner = useMemo(() => {
    const hasSale = !!(sales || []).find((sale) => sale.ownerWalletId === walletAddress);
    const hasAuction = !!(auctions || []).find(
      (auction) => auction.ownerWalletId === walletAddress,
    );

    return (
      !(hasSale || hasAuction) &&
      sale === undefined &&
      auction === undefined &&
      currentUserOwnedEntry !== undefined
    );
  }, [auction, auctions, currentUserOwnedEntry, sale, sales, walletAddress]);

  const canBeManagedByCreator = useMemo(() => {
    return sale === undefined && auction === undefined && walletAddress === item.firstOwnerWalletId;
  }, [auction, item.firstOwnerWalletId, sale, walletAddress]);

  useEffect(() => {
    getServiceFee().then(setServiceFee);
  }, [auction, sale, item]);

  const auctionStarted = auction !== undefined && new Date(auction.startsAt) <= new Date();
  const auctionEnded = auction !== undefined && new Date(auction.endsAt) < new Date();
  const canBid =
    auction !== undefined &&
    !auctionEnded &&
    auctionStarted &&
    auction.ownerWalletId !== walletAddress;

  const openPopupIfAuthorized = () => {
    if (isAuthorized) {
      const currentChainId = (window.ethereum as any).networkVersion;
      if (currentChainId != getChainIdFromSymbol(item.chain)) {
        makeWalletCall(getChainIdFromSymbol(item.chain));
      } else {
        setIsPopupOpen(true);
      }
    } else {
      toast.warn('Please log in.');
    }
  };

  const PutItemOnSaleWithChainChange = useMemo(() => WithChainChange(PutItemOnSaleComponent), []);
  const PutItemOnAuctionWithChainChange = WithChainChange(PutItemOnAuctionComponent);

  return (
    <div className={styles.container}>
      {sale && sale.status === 'active' && isPopupOpen && (
        <CheckoutPopup
          itemOwner={owner}
          sale={sale}
          item={item}
          close={(): void => setIsPopupOpen(false)}
          serviceFee={serviceFee}
        />
      )}

      {auction && isPopupOpen && (
        <PlaceABidPopup
          itemOwner={owner}
          item={item}
          close={(): void => setIsPopupOpen(false)}
          serviceFee={serviceFee}
          auction={auction}
        />
      )}

      <div className={styles.columns}>
        <ItemPreview item={item} isCurrentUserTheOwner={currentUserOwnedEntry !== undefined} />
        <div className={styles.informationColumn}>
          <div className={styles.header}>
            <h1>{item.displayName}</h1>
            <div className={styles.headerButtons}>
              <button
                className={classNames(styles.likeButton, { [styles.liked]: isLiked })}
                onClick={handleLikeItem}
                id={'item-page-like-button'}
              >
                {item.likesCount}
                <LikeIcon />
              </button>
              <div className={styles.optionsButton}></div>
            </div>
          </div>
          {sale !== undefined && sale.status === 'active' && (
            <div className={styles.buyActionContainer}>
              <div className={styles.infoRow}>
                <div className={styles.priceInfo}>
                  <p>current price</p>
                  <h3>
                    {amountFromWei(sale.price)} {sale.settlementToken}
                  </h3>
                  {sale.settlementToken === 'ORE' && !!orePriceInUSD && (
                    <p>{getPriceInUSD(parseInt(amountFromWei(sale.price)), orePriceInUSD)}</p>
                  )}
                </div>
              </div>
              <div className={styles.buttonsRow}>
                <Button
                  type="button"
                  buttonStyle={ButtonStyle.color}
                  onClick={openPopupIfAuthorized}
                  disabled={sale === undefined || sale.ownerWalletId === walletAddress}
                  id={'item-page-buy-button'}
                >
                  Buy
                </Button>
              </div>
            </div>
          )}
          {auction !== undefined && (
            <div className={styles.buyActionContainer}>
              <div className={styles.infoRow}>
                <div className={styles.priceInfo}>
                  <p>Minimum bid</p>
                  <h3>
                    {amountFromWei(auction.minimumBid)} {auction.settlementToken}
                  </h3>
                  {auction.settlementToken === 'ORE' && !!orePriceInUSD && (
                    <p>
                      {getPriceInUSD(parseInt(amountFromWei(auction.minimumBid)), orePriceInUSD)}
                    </p>
                  )}
                </div>
                <BidTimer endOfAuction={auction.endsAt} displayMode={'itemPage'} />
              </div>
              <div className={styles.buttonsRow}>
                <Button
                  type="button"
                  buttonStyle={ButtonStyle.color}
                  onClick={openPopupIfAuthorized}
                  disabled={!canBid}
                  id={'item-page-place-a-bid-button'}
                  className={styles.bidButton}
                >
                  {auctionEnded
                    ? 'Auction ended'
                    : auctionStarted
                    ? 'Place a bid'
                    : 'Auction has not started yet'}
                </Button>
              </div>
            </div>
          )}
          <div className={styles.description}>
            <MarkdownDisplay text={item.description} />
            {/* <p>some auction info</p> */}
          </div>
          <details className={styles.license}>
            <summary>Item&apos;s license</summary>
            <span className={styles.licenseContent}>
              {item.legal === 'full_ownership' ? (
                <p>{licenseTexts?.fullOwnership}</p>
              ) : (
                item.legal === 'usage_within_ore' && <p>{licenseTexts?.usageWithinOre}</p>
              )}
              {item.legal === 'usage_within_ore' &&
                (item.usage === 'private' ? (
                  <p>{licenseTexts?.privateUse}</p>
                ) : item.usage === 'commercial_single' ? (
                  <>
                    <p>{licenseTexts?.commercialUse}</p>
                    <p>{licenseTexts?.commercialUseSingle}</p>
                  </>
                ) : (
                  <>
                    <p>{licenseTexts?.commercialUse}</p>
                    <p>{licenseTexts?.commercialUseMulti}</p>
                  </>
                ))}
            </span>
          </details>

          <div className={styles.details}>
            <DetailWidget title={item.id} subtitle="Item ID" id="item-page-item-id" />
            {item.hasLockedMedia && (
              <DetailWidget
                icon={<LockIcon />}
                subtitle="Unlockable content"
                id="item-page-has-unlockable-content"
              />
            )}
            {sale !== undefined && (
              <DetailWidget
                title={`${sale.remainingQuantity}/${sale.startQuantity}`}
                subtitle="Amount"
                id="item-page-sale-quantity"
              />
            )}
            {sale === undefined && auction === undefined && currentUserOwnedEntry && (
              <DetailWidget
                title={`${currentUserOwnedEntry.quantity}`}
                subtitle="Owned Amount"
                id="item-page-owned-quantity"
              />
            )}
            {sale !== undefined && (
              <DetailWidget
                title={sale.settlementToken}
                subtitle="Blockchain"
                id="item-page-blockchain-token"
              />
            )}
            {sale !== undefined && sale.purchaseLimit && (
              <DetailWidget
                title={`${sale.purchaseLimit}`}
                subtitle="Purchase Limit"
                id="item-page-purchase-limit"
              />
            )}
            {auction !== undefined && auction.purchaseLimit && (
              <DetailWidget
                title={`${auction.purchaseLimit}`}
                subtitle="Purchase Limit"
                id="item-page-purchase-limit"
              />
            )}
            {auction !== undefined && (
              <DetailWidget
                title={`${auction.remainingQuantity}/${auction.quantity}`}
                subtitle="Amount"
                id="item-page-remaining-amount"
              />
            )}
            {auction !== undefined && (
              <DetailWidget
                title={auction.settlementToken}
                subtitle="Blockchain"
                id="item-page-blockchain-token"
              />
            )}
            {/* <DetailWidget
          title='some contract address'
          subtitle='Contract address'
        /> */}
          </div>
          <div className={styles.walletsContainer}>
            <div className={styles.userInfoContainer}>
              <p className={styles.creatorTitle}>
                Creator {item.royalties && <span>{item.royalties} royalties</span>}
              </p>
              <div
                className={styles.userInfo}
                onClick={(): void => navigate(`/user/${item.firstOwnerWalletId}`)}
              >
                {
                  <CircularImage
                    imageUrl={creator?.profileAvatarUrl || '/av-placeholder.jpg'}
                    className={styles.avatarCircle}
                    width={36}
                    height={36}
                  />
                }
                <p>
                  {creator?.displayName ||
                    truncate(item.firstOwnerWalletId, env.shortenedWalletIdLength)}
                </p>
              </div>
            </div>
            {<ItemOwner item={item} sale={sale} auction={auction} />}
          </div>
          {<ItemBidsInfo auction={auction} walletAddress={walletAddress} />}
          <div className={styles.ownerActions}>
            {canBeManagedByCreator && (
              <Link to={`/item/${item.id}/edit`}>
                <Button
                  buttonStyle={ButtonStyle.color}
                  onClick={(): unknown => ({})}
                  id={'item-page-edit-button'}
                >
                  Edit Item
                </Button>
              </Link>
            )}
            {canBeManagedByOwner && (
              <>
                {item.visibility === 'public' && (
                  <>
                    <PutItemOnSaleWithChainChange
                      chainId={getChainIdFromSymbol(item.chain) ?? env.binanceChainId}
                      walletAddress={walletAddress}
                      itemId={item.id}
                      sales={sales || []}
                      childOnClickDisabled={item.chain !== getCurrentChain()}
                    />
                    {/* canBeManaged checks isCurrentUserTheOwner, so no worries here */}
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {item.chain === 'bsc' && (
                      <PutItemOnAuctionWithChainChange
                        chainId={env.binanceChainId}
                        itemId={item.id}
                        quantity={currentUserOwnedEntry!.quantity}
                        childOnClickDisabled={getCurrentChain() !== 'bsc'}
                      />
                    )}
                  </>
                )}
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                <BurnNftComponent
                  itemId={item.id}
                  ownedQuantity={currentUserOwnedEntry!.quantity}
                />
              </>
            )}
            {sale !== undefined && sale.ownerWalletId === walletAddress && (
              <EndSaleComponent
                itemId={item.id}
                saleId={sale.id}
                otherSales={(sales || []).filter((sale) => sale.ownerWalletId !== walletAddress)}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.relatedListings}>
        <h3>Related Auctions and Sales</h3>
        <GenericSalesAuctionsList
          sales={displayedSales}
          auctions={displayedAuctions}
          canFilterByCategory={false}
        />
      </div>
    </div>
  );
};

interface DetailWidgetProps {
  subtitle: string;
  title?: string;
  icon?: JSX.Element;
  id: string;
}

const DetailWidget = ({ subtitle, title, icon, id }: DetailWidgetProps): JSX.Element => (
  <div className={styles.detailContainer}>
    {title && (
      <p className={styles.detailTitle} id={id}>
        {title}
      </p>
    )}
    {icon}
    <p className={styles.detailSubtitle}>{subtitle}</p>
  </div>
);
