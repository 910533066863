import { useContext } from 'react';
import useSWR from 'swr';

import { AuthContext } from 'context/auth-context';

import { env } from '../env/env';
import { dataFetcher } from './data-fetcher';
import { jsonHeaders } from './headers';

export interface PayPalTransaction {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  ownerWalletId: string;
  token: 'ORE' | 'BNB';
  tokenQuantity: number;
  price: number;
  currency: 'USD';
  paypalOrderId: string;
  status: 'pending' | 'canceled' | 'completed';
}

export const useTransactions = (): {
  transactions: PayPalTransaction[];
  isLoading: boolean;
  isEmpty: boolean;
} => {
  const { jwt } = useContext(AuthContext);
  const { data, error } = useSWR(jwt ? `${env.apiUrl}/token_purchase_orders` : null, (url) =>
    dataFetcher<Record<'tokenPurchaseOrders', PayPalTransaction[]>>(url, jwt),
  );

  const isLoading = !data && !error;
  const transactions: PayPalTransaction[] = data?.tokenPurchaseOrders || [];
  const isEmpty = transactions.length === 0;

  return {
    transactions,
    isLoading,
    isEmpty,
  };
};

// TODO: Check if this this one returns proper value!
export const getOREPriceInUSD = (): Promise<number> => {
  return fetch(`${env.apiUrl}/quotes/ore`, {
    method: 'get',
    headers: {
      ...jsonHeaders,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json().then((data) => {
        console.log('data of price: ', data);
        if (!data) {
          throw new Error('Data is undefined');
        }
        return data?.quote?.usd?.price;
      });
    }
  });
};

export const useOrePriceInUSD = () => {
  const { data, error } = useSWR(
    `${env.apiUrl}/quotes/ore`,
    (url) => dataFetcher<Record<'quote', { usd: { price: number } }>>(url),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  );
  const isLoading = !data && !error;
  const price = data?.quote?.usd?.price;
  return {
    isLoading,
    price,
  };
};
