import { useState } from 'react';

import { Tabs, Typography } from 'components/common';
import { TabOption, TabOptions } from 'components/common/tabs/tabs';
import { UserAuctionsList } from 'components/user-auctions-list';

import styles from './profile-page.module.css';

const tabs: TabOptions = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'finished', label: 'Finished' },
];

interface Props {
  walletAddress: string;
}

export const UserAuctionsSection = ({ walletAddress }: Props) => {
  const [currentTab, setCurrentTab] = useState<TabOption>(tabs[0]);

  return (
    <>
      <Typography.Heading fontSize={32} fontWeight={700} marginBottom={24}>
        Your auctions
      </Typography.Heading>
      <div className={styles.filters}>
        <Tabs currentOption={currentTab} setValue={setCurrentTab} displayOptions={tabs} />
      </div>
      <UserAuctionsList
        walletAddress={walletAddress}
        showFinished={currentTab.value === 'finished' || currentTab.value === 'all'}
        showActive={currentTab.value === 'active' || currentTab.value === 'all'}
      />
    </>
  );
};
