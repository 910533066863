import { ReactComponent as FacebookLogo } from 'assets/icons/facebook-page.svg';
import { ReactComponent as InstagramLogo } from 'assets/icons/instagram-page.svg';
import { ReactComponent as TwitterLogo } from 'assets/icons/twitter-page.svg';
import { ReactComponent as PageLogo } from 'assets/icons/web-page.svg';
import { ReactComponent as YoutubeLogo } from 'assets/icons/youtube-page.svg';
import { SocialLinks } from 'components/common';
import { UserProfile } from 'services/user-service';

interface IProps {
  userProfile: UserProfile;
}

export const UserSocialLinks = ({ userProfile }: IProps) => {
  const linksWithIcons = [
    {
      icon: <PageLogo />,
      link: userProfile?.websiteUrl,
      id: 'profile-page-website-link',
    },
    {
      icon: <FacebookLogo />,
      link: userProfile?.facebook,
      id: 'profile-page-facebook-link',
    },
    {
      icon: <InstagramLogo />,
      link: userProfile?.instagram,
      id: 'profile-page-instagram-link',
    },
    {
      icon: <TwitterLogo />,
      link: userProfile?.twitter,
      id: 'profile-page-twitter-link',
    },
    {
      icon: <YoutubeLogo />,
      link: userProfile?.youtubeChannel,
      id: 'profile-page-youtube-link',
    },
  ].flatMap((linkWithIcon) =>
    linkWithIcon.link
      ? {
          ...linkWithIcon,
          link: linkWithIcon.link,
        }
      : [],
  );

  return <>{linksWithIcons.length > 0 && <SocialLinks linksWithIcons={linksWithIcons} />}</>;
};
