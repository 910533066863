import { useContext } from 'react';
import { BeatLoader } from 'react-spinners';

import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import { Button, ButtonStyle } from '../common/button/button';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { PrivacyPolicy } from '../privacy-policy/privacy-policy';
import styles from './privacy-policy-popup.module.css';

export const PrivacyPolicyPopup = (): JSX.Element => {
  const { askForPrivacyPolicy } = useContext(CurrentUserProfileContext);
  return askForPrivacyPolicy ? (
    <GenericPopup title="Privacy Policy" canBeClosed={false} id={'privacy-policy-popup'}>
      <PopupContent />
    </GenericPopup>
  ) : (
    <></>
  );
};

const PopupContent = (): JSX.Element => {
  const { refreshUserProfileWithAcceptedPrivacyPolicy } = useContext(CurrentUserProfileContext);
  const { makeCall: accept, state: acceptState } = useCallWithState(() =>
    refreshUserProfileWithAcceptedPrivacyPolicy(true),
  );

  const { makeCall: reject, state: rejectState } = useCallWithState(() =>
    refreshUserProfileWithAcceptedPrivacyPolicy(false),
  );

  return (
    <div className={styles.container}>
      <div className={styles.privacyPolicyContainer}>
        <PrivacyPolicy />
      </div>
      <div className={styles.buttonsRow}>
        <Button buttonStyle={ButtonStyle.red} onClick={reject} id={'privacy-policy-reject-button'}>
          {rejectState === CallState.Ready ? 'Reject' : <BeatLoader />}
        </Button>
        <Button
          buttonStyle={ButtonStyle.green}
          onClick={accept}
          id={'privacy-policy-accept-button'}
        >
          {acceptState === CallState.Ready ? 'Accept' : <BeatLoader />}
        </Button>
      </div>
    </div>
  );
};
