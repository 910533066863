import { useMemo } from 'react';

import { AuthorCard } from 'components/author-card';
import { CollectionCard } from 'components/collection-card';
import { LayoutStack, Typography } from 'components/common';
import { MusicCard } from 'components/music-card';
import { MusicLinkCards } from 'components/music-link-cards';
import { MusicMainPageRow } from 'components/music-main-page-row';
import { useCurrentUserTopAuthors, usePopularNewAlbums } from 'services/music-service';
import { useCurrentUser } from 'services/user-service';
import { getGreeting } from 'utils/get-greeting';

import PlaylistsTable from '../music-library/components';
import styles from './music-main-page.module.css';

export const MusicMainPage = () => {
  const greetingText = getGreeting();
  const { data: user } = useCurrentUser();
  const { data: topAuthorsData } = useCurrentUserTopAuthors(6);
  const { data: popularNewAlbumsData } = usePopularNewAlbums(5);

  const recentlyPlayedPlaylists = useMemo(() => {
    if (!user) {
      return [];
    }

    return user.recentlyPlayedPlaylists.slice(0, 6);
  }, [user]);

  const recentlyPlayedSongs = useMemo(() => {
    if (!user) {
      return [];
    }

    return user.recentlyPlayedItems.slice(0, 6);
  }, [user]);

  return (
    <div className={styles.musicPageWrapper}>
      <Typography.Heading fontSize={36} fontWeight={700} marginBottom={24}>
        {greetingText}
      </Typography.Heading>
      <MusicLinkCards />
      <MusicMainPageRow heading="Recently played">
        {recentlyPlayedSongs.map((song) => (
          <MusicCard key={song.id} itemId={song.id} />
        ))}
      </MusicMainPageRow>
      {!!recentlyPlayedPlaylists?.length && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <h2>Recently played playlists</h2>
          <PlaylistsTable playlists={recentlyPlayedPlaylists} />
        </div>
      )}
      {/* <MusicMainPageRow heading="Recommended for today">
        {/* TODO: add mapping of components wrapped in stack
        NOTE: no endpoint for that
      </MusicMainPageRow> */}
      {topAuthorsData?.users && (
        <MusicMainPageRow heading="Your top authors">
          {topAuthorsData?.users.map((author) => (
            <AuthorCard key={author.id} prefetchedUser={author} />
          ))}
        </MusicMainPageRow>
      )}
      {/* <MusicMainPageRow heading="Your top podcasts">
        TODO: add mapping of components wrapped in stack
        NOTE: waiting for backend
      </MusicMainPageRow> */}
      {popularNewAlbumsData?.collections && (
        <>
          <Typography.Heading fontSize={24} fontWeight={700} marginBottom={24}>
            Popular new albums
          </Typography.Heading>
          <LayoutStack rowElements={4}>
            {/* TODO: add mapping of components wrapped in stack */}
            {popularNewAlbumsData.collections.map((collection) => (
              <CollectionCard collection={collection} key={collection.id} />
            ))}
          </LayoutStack>
        </>
      )}
    </div>
  );
};
