import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ToggleInputControl } from 'components/common/toggle-input';

import { Button, ButtonStyle } from '../../components/common/button/button';
import { CategoryPicker } from '../../components/common/category-picker/category-picker';
import { FileInput } from '../../components/common/file-input/file-input';
import { ItemCard } from '../../components/common/item-card/item-card';
import { MarkdownDisplay } from '../../components/common/markdown-display/markdown-display';
import { UnderlinedTextAreaControl } from '../../components/common/underlined-input/underlined-text-area';
import { ItemsContext } from '../../context/items-context';
import { useCategories } from '../../services/categories-service';
import { Item } from '../../services/item-service';
import styles from './edit-item-page.module.css';

interface FormInputs {
  description: string;
  isPrivate: boolean;
}

interface Props {
  item: Item;
}

export const EditItemComponent = ({ item }: Props): JSX.Element => {
  const { data: categories } = useCategories();
  const { updateItem } = useContext(ItemsContext);
  const [pickedCategory, setPickedCategory] = useState(
    (categories || []).find((c) => c.id === item.categoryId),
  );
  const [previewImageFile, setPreviewImageFile] = useState<File>();
  const [previewImageFileUrl, setPreviewImageFileUrl] = useState<string>(item.previewImageUrl);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm<FormInputs>({
    defaultValues: {
      description: item.description,
      isPrivate: item.visibility === 'private' ? true : false,
    },
  });

  const description = watch('description');

  const onPreviewImageFileDrop = useCallback((acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) {
      console.error('no files specified');
    }
    const file = acceptedFiles[0];

    if (file.size > 4 * 1024 * 1024) {
      toast.error('Your preview image file is too big!');
      toast.info('Maximum accepted preview image size is: 4 MiB');
      return;
    }

    setPreviewImageFile(file);
  }, []);

  useEffect(() => {
    if (previewImageFile !== undefined) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event.target && event.target.result) {
          setPreviewImageFileUrl(event.target.result as string);
        }
      };

      reader.readAsDataURL(previewImageFile);
    }
  }, [previewImageFile]);

  const previewItem: Item = useMemo(() => {
    return { ...item, description, previewImageUrl: previewImageFileUrl };
  }, [description, item, previewImageFileUrl]);

  const submit = useCallback(async () => {
    await updateItem(
      {
        description,
        previewImage: previewImageFile,
        categoryId: pickedCategory?.id,
        visibility: getValues('isPrivate') ? 'private' : 'public',
      },
      item.id,
    );

    navigate(`/item/${item.id}`);
  }, [description, item.id, navigate, pickedCategory, previewImageFile, updateItem]);

  return (
    <div className={styles.container}>
      <h2>Edit item</h2>
      <div className={styles.columns}>
        <div className={styles.previewColumn}>
          <ItemCard prefetchedItem={previewItem} previewMode />
          <p>Description preview:</p>
          <MarkdownDisplay text={watch('description')} />
        </div>
        <div className={styles.formColumn}>
          <div className={styles.fileInputContainer}>
            <p>
              <span className="gradientText">Preview image</span>

              <span className={styles.previewImageInfo}>
                It will be displayed in the card. Maximum accepted size is <strong>4 MiB</strong>.
                Consider cropping image to fit 244x244px container.
              </span>
            </p>
            <FileInput
              tooltipText="PNG, JPEG"
              onDrop={onPreviewImageFileDrop}
              id={'edit-item-preview-image-input'}
            />
            <p className={styles.fileInputTooltip}>
              The image that is shown in the list of all items.
            </p>
          </div>
          <CategoryPicker setPickedCategory={setPickedCategory} pickedCategory={pickedCategory} />

          <ToggleInputControl
            title="Private"
            description="Visible only to you"
            {...register('isPrivate')}
            id={'edit-item-private-checkbox'}
          />

          <UnderlinedTextAreaControl
            title="Description"
            placeholder='e.g. "When owning, you can use this item in game"'
            id={'edit-item-description-input'}
            {...register('description')}
            errorMessage={errors.description?.message}
            bottomNote={'You can use Markdown syntax.'}
          />
          <Button
            buttonStyle={ButtonStyle.color}
            onClick={handleSubmit(submit, () => toast.warn('Please verify form.'))}
            id={'edit-item-submit-button'}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
