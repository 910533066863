import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { AllowedCurrencies, getCurrentCurrency } from 'utils/blockchain-helpers';

import { SalesContext } from '../../context/sales-context';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import { amountToWei, putItemOnSale } from '../../services/blockchain-service';
import { Sale, useSalesOfItem } from '../../services/sales-service';
import { blockchainNumberRegex } from '../../utils/blockchain-number-regex';
import { Button, ButtonStyle } from '../common/button/button';
import { CurrencyDropdown } from '../common/currency-dropdown/currency-dropdown';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { UnderlinedInputControl } from '../common/underlined-input/underlined-input';
import styles from './put-item-on-sale.module.css';

interface Props {
  itemId: string;
  walletAddress: string;
  sales: Array<Sale>;
  onClick: () => void;
}

export const PutItemOnSaleComponent = ({
  itemId,
  walletAddress,
  sales,
  onClick,
}: Props): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div>
      <Button
        type="button"
        buttonStyle={ButtonStyle.color}
        onClick={onClick ?? ((): void => setIsPopupOpen(true))}
        disabled={isPopupOpen}
        id={'put-on-sale-popup-open-button'}
      >
        Put on Sale
      </Button>
      {isPopupOpen && (
        <Popup
          itemId={itemId}
          walletAddress={walletAddress}
          closePopup={(): void => setIsPopupOpen(false)}
          sales={sales}
        />
      )}
    </div>
  );
};

interface FormInputs {
  price: string;
  purchaseLimit: string;
}

const Popup = ({
  walletAddress,
  closePopup,
  itemId,
  sales,
}: {
  walletAddress: string;
  closePopup: () => void;
  itemId: string;
  sales: Array<Sale>;
}): JSX.Element => {
  const { createSale } = useContext(SalesContext);
  const currentCurrency = getCurrentCurrency()?.toLocaleUpperCase();
  const [settlementToken, setSettlementToken] = useState<AllowedCurrencies>(
    currentCurrency as AllowedCurrencies,
  );
  const navigate = useNavigate();
  const { mutate } = useSalesOfItem(itemId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormInputs>();

  const putOnSale = useCallback(async () => {
    const price = getValues('price');
    await putItemOnSale(walletAddress, itemId, amountToWei(price).toString());
  }, [getValues, walletAddress, itemId]);

  const { state, makeCall } = useCallWithState(putOnSale, true);

  const signSellOrder = useCallback(async () => {
    const price = getValues('price');
    const purchaseLimit = parseInt(getValues('purchaseLimit'));

    await makeCall();
    console.log(settlementToken);
    const sale = await createSale(price, settlementToken, itemId, purchaseLimit);
    mutate({ sales: [...sales, sale] }, { optimisticData: { sales: [...sales, sale] } });

    closePopup();
    navigate(`/sale/${sale.id}`);
  }, [
    closePopup,
    createSale,
    getValues,
    itemId,
    makeCall,
    mutate,
    navigate,
    sales,
    settlementToken,
  ]);

  const getButtonChild = (): JSX.Element | string => {
    switch (state) {
      case CallState.Ready:
        return 'Sign sell order';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Signed!';
    }
  };

  return (
    <GenericPopup
      onClose={closePopup}
      canBeClosed={state === CallState.Ready}
      title="Put Item on Sale"
      id={'put-item-on-sale-popup'}
    >
      <div className={styles.container}>
        <UnderlinedInputControl
          title="Price"
          type="number"
          placeholder="Enter price for one piece"
          unit={
            <CurrencyDropdown
              setValue={setSettlementToken}
              value={settlementToken}
              id={'put-item-on-sale-popup-currency-select'}
            />
          }
          id={'put-item-on-sale-popup-price-input'}
          {...register('price', {
            required: {
              value: true,
              message: 'Price is required.',
            },
            pattern: {
              value: blockchainNumberRegex,
              message: 'Price must be a number with maximum of 15 decimal places',
            },
            min: {
              value: 0,
              message: 'Price must be greater than 0!',
            },
          })}
          errorMessage={errors.price?.message}
        />
        <UnderlinedInputControl
          title="Purchase limit"
          type="number"
          placeholder="Enter how many items can user buy in one transaction"
          id={'put-item-on-sale-popup-purchase-limit-input'}
          {...register('purchaseLimit', {
            min: {
              value: 1,
              message: 'Purchase limit must be greater than 0!',
            },
          })}
          errorMessage={errors.purchaseLimit?.message}
        />
        <Button
          type="button"
          buttonStyle={state === CallState.Ended ? ButtonStyle.green : ButtonStyle.white}
          onClick={handleSubmit(signSellOrder, () => {
            toast.warn('Please verify form.');
          })}
          disabled={state !== CallState.Ready}
          id={'put-item-on-sale-popup-sign-button'}
        >
          {getButtonChild()}
        </Button>
      </div>
    </GenericPopup>
  );
};
