import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AudioPlayerWidget } from 'components/audio-player-widget';
import { Header, HeaderType } from 'components/common/header/header';
import { AuthContext } from 'context/auth-context';
import { useCurrentUserSubscriptions } from 'services/user-service';

import styles from './music-page-layout-wrapper.module.css';

export const MusicPageLayoutWrapper = () => {
  const { jwt } = useContext(AuthContext);
  const { data, error } = useCurrentUserSubscriptions();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!jwt || (data === undefined && error !== undefined)) {
      navigate('/not-permitted', {
        replace: true,
        state: { previousPage: `${location.pathname}${location.search}` },
      });
    }

    if (data !== undefined) {
      const { subscriptions } = data;

      const musicSubscription = subscriptions.find((s) => s.name === 'music');

      if (!musicSubscription?.active) {
        navigate('/no-subscription');
      }
    }
  }, [data, error, jwt, navigate]);

  return (
    <>
      <Header type={HeaderType.Music} />
      <div className={styles.outletWrapper}>
        <Outlet />
      </div>
      <AudioPlayerWidget />
    </>
  );
};
