import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { CollectionCard } from '../../components/collection-card';
import { Button, ButtonStyle } from '../../components/common/button/button';
import { CategoryPicker } from '../../components/common/category-picker/category-picker';
import { FileInput } from '../../components/common/file-input/file-input';
import { ToggleInputControl } from '../../components/common/toggle-input/toggle-input';
import { UnderlinedInputControl } from '../../components/common/underlined-input/underlined-input';
import { ItemsPickerPopup } from '../../components/items-picker-popup/items-picker-popup';
import { CollectionsContext } from '../../context/collections-context';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { Category } from '../../services/categories-service';
import { Item } from '../../services/item-service';
import styles from './create-collection-page.module.css';

interface FormInputs {
  privateCollection: boolean;
  name: string;
  description: string;
}

export const CreateCollectionPage = (): JSX.Element => {
  const [previewImageFile, setPreviewImageFile] = useState<File>();
  const [previewImageFileUrl, setPreviewImageFileUrl] = useState<string>();
  const [pickedCategory, setPickedCategory] = useState<Category>();
  const [isItemsPopupOpen, setIsItemsPopupOpen] = useState(false);
  const [pickedItems, setPickedItems] = useState<Array<Item>>([]);
  const { createCollection, addItem } = useContext(CollectionsContext);
  const { walletAddress } = useContext(CurrentUserProfileContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      privateCollection: false,
    },
  });

  const name = watch('name');
  const description = watch('description');
  const privateCollection = watch('privateCollection');

  const onPreviewImageFileDrop = useCallback((acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) {
      console.error('no files specified');
    }
    const file = acceptedFiles[0];

    setPreviewImageFile(file);
  }, []);

  const submit = async (): Promise<void> => {
    if (previewImageFile === undefined) {
      toast('Please add preview image');
      return;
    }

    const collection = await createCollection({
      previewImage: previewImageFile,
      displayName: name,
      description,
      visibility: privateCollection ? 'private' : 'public',
      categoryId: pickedCategory?.id,
    });

    await Promise.all(pickedItems.map((item) => addItem(item.id, collection.id)));
    navigate(`/collection/${collection.id}`);
  };

  useEffect(() => {
    if (previewImageFile !== undefined) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event.target && event.target.result) {
          setPreviewImageFileUrl(event.target.result as string);
        }
      };

      reader.readAsDataURL(previewImageFile);
    }
  }, [previewImageFile]);

  return (
    <div className={styles.container}>
      {isItemsPopupOpen && (
        <ItemsPickerPopup
          close={(): void => setIsItemsPopupOpen(false)}
          pickedItems={pickedItems}
          setPickedItems={setPickedItems}
        />
      )}
      <h1>Create collection</h1>
      <div className={styles.contentContainer}>
        <div className={styles.previewColumn}>
          <CollectionCard
            previewMode
            collection={{
              displayName: name,
              id: '',
              creatorWalletId: walletAddress,
              createdAt: '',
              updatedAt: '',
              visibility: 'private',
              description: description,
              previewImageUrl: previewImageFileUrl || '',
              itemsIds: [],
            }}
          />
        </div>
        <div className={styles.formColumn}>
          <div className={styles.fileInputContainer}>
            <p>Upload file</p>
            <FileInput
              tooltipText="PNG, JPEG"
              onDrop={onPreviewImageFileDrop}
              id={'create-collection-image-input'}
            />
          </div>
          <ToggleInputControl
            title="Private"
            description="Visible only to you"
            id={'create-collection-private-checkbox'}
            {...register('privateCollection')}
          />
          <CategoryPicker pickedCategory={pickedCategory} setPickedCategory={setPickedCategory} />
          <div className={styles.textInputsContainer}>
            <UnderlinedInputControl
              type="text"
              title="Name"
              placeholder="e.g. “Game item”"
              id={'create-collection-name-input'}
              {...register('name', {
                required: {
                  value: true,
                  message: 'Name is required',
                },
              })}
              errorMessage={errors.name?.message}
            />
            <UnderlinedInputControl
              type="text"
              title="Description"
              placeholder="e.g. “Collection of my work”"
              id={'create-collection-description-input'}
              {...register('description', {
                required: {
                  value: true,
                  message: 'Description is required',
                },
              })}
              errorMessage={errors.description?.message}
            />
          </div>
          <div className={styles.addItemsContainer}>
            <div className={styles.addItemsInfo}>
              <p className={classNames(styles.addItemsTitle, 'gradientText')}>Add your items</p>
              <p className={styles.addItemsDescription}>Items that were from this collection </p>
            </div>

            <Button
              type="button"
              buttonStyle={ButtonStyle.second}
              onClick={(): void => setIsItemsPopupOpen(true)}
              id={'create-collection-add-items-button'}
            >
              Add <PlusIcon className={styles.plusIcon} />
            </Button>
          </div>
          {pickedItems.length > 0 && (
            <div className={styles.pickedItemsList}>
              <p>Picked items</p>
              {pickedItems.map((item) => {
                return (
                  <div key={item.id} className={styles.itemRow}>
                    <img src={item.previewImageUrl} alt={'preview'} />
                    <p>{item.displayName}</p>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.submitContainer}>
            <Button
              type="submit"
              buttonStyle={ButtonStyle.color}
              onClick={handleSubmit(submit, () => toast.warn('Please verify form.'))}
              id={'create-collection-submit-button'}
            >
              Create collection
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
