import { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import classNames from 'classnames';

import { ReactComponent as BidsIcon } from '../../assets/filter-icons/bids.svg';
import { ReactComponent as FollowingsIcon } from '../../assets/filter-icons/followings.svg';
import { ReactComponent as LikesIcon } from '../../assets/filter-icons/likes.svg';
import { ReactComponent as ListingsIcon } from '../../assets/filter-icons/listings.svg';
import { ReactComponent as SalesIcon } from '../../assets/filter-icons/sales.svg';
import { ActivityInfo } from '../../components/activity-info/activity-info';
import { LoadMoreButton } from '../../components/common/load-more-button/load-more-button';
import { ActivityKindFilter, useActivitiesWithPagination } from '../../services/activity-service';
import styles from './activity-page.module.css';

const PAGINATION_LIMIT = 10;

export const ActivityPage = (): JSX.Element => {
  const [filterChosen, setFilterChosen] = useState<ActivityKindFilter | undefined>(undefined);

  const {
    activities,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  } = useActivitiesWithPagination(PAGINATION_LIMIT, filterChosen);

  return (
    <div className={styles.container}>
      <h1>Activity</h1>
      <div className={styles.flexWrapper}>
        <div className={styles.activitiesList}>
          {!isEmpty ? (
            <>
              {(activities || []).map((activity) => (
                <ActivityInfo key={activity.id} activity={activity} />
              ))}

              {!isReachingEnd && <LoadMoreButton isLoading={!!isLoadingMore} onClick={loadMore} />}
            </>
          ) : isLoadingInitialData ? (
            <span className={styles.loadingActivities}>
              Loading activities <BeatLoader color="#85838E" size={9} />
            </span>
          ) : (
            <span className={styles.noActivities}>There are no activities of this type yet.</span>
          )}
        </div>
        <Filters
          filterChosen={filterChosen}
          setFilter={(filter): void => {
            setFilterChosen(filter);
            setPage(0);
          }}
        />
      </div>
    </div>
  );
};

interface FiltersProps {
  filterChosen: ActivityKindFilter | undefined;
  setFilter: (type: ActivityKindFilter | undefined) => void;
}

const Filters = ({ filterChosen, setFilter }: FiltersProps): JSX.Element => {
  const toggleFilter = (filter: ActivityKindFilter): void => {
    if (filter === filterChosen) {
      setFilter(undefined);
    } else {
      setFilter(filter);
    }
  };

  return (
    <div className={styles.filters}>
      <h3 className={styles.filtersTitle}>Filters</h3>
      <div className={styles.filterButtons}>
        <button
          className={classNames(styles.filterButton, {
            [styles.active]: filterChosen === ActivityKindFilter.listings,
          })}
          onClick={(): void => toggleFilter(ActivityKindFilter.listings)}
        >
          <ListingsIcon />
          Listings
        </button>
        <button
          className={classNames(styles.filterButton, {
            [styles.active]: filterChosen === ActivityKindFilter.sales,
          })}
          onClick={(): void => toggleFilter(ActivityKindFilter.sales)}
        >
          <SalesIcon />
          Sales
        </button>
        <button
          className={classNames(styles.filterButton, {
            [styles.active]: filterChosen === ActivityKindFilter.bids,
          })}
          onClick={(): void => toggleFilter(ActivityKindFilter.bids)}
        >
          <BidsIcon />
          Bids
        </button>
        <button
          className={classNames(styles.filterButton, {
            [styles.active]: filterChosen === ActivityKindFilter.likes,
          })}
          onClick={(): void => toggleFilter(ActivityKindFilter.likes)}
        >
          <LikesIcon />
          Likes
        </button>
        <button
          className={classNames(styles.filterButton, {
            [styles.active]: filterChosen === ActivityKindFilter.followings,
          })}
          onClick={(): void => toggleFilter(ActivityKindFilter.followings)}
        >
          <FollowingsIcon />
          Followings
        </button>
      </div>
    </div>
  );
};
