import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DisplayType } from 'components/common/display-type-picker/display-type-picker';
import { useAuctionsWithPagination } from 'services/auctions-service';
import { useCollectionsWithPagination } from 'services/collection-service';
import { useSalesWithPagination } from 'services/sales-service';
import { TransactionType } from 'types/transaction-type';

import { GenericSalesAuctionsList } from './generic-sales-auctions-list';

interface Props {
  transactionTypeFilter?: TransactionType;
  categoryFilter?: string;
  displayType?: DisplayType;
}

const ITEMS_LIMIT = 10;

export const SalesAuctionsList = ({
  transactionTypeFilter,
  categoryFilter,
  displayType,
}: Props): JSX.Element => {
  const [pickedCategory, setPickedCategory] = useState<string | undefined>(categoryFilter);
  const { filter } = useParams();

  const {
    sales,
    loadMore: loadMoreSales,
    isLoadingInitialData: isLoadingInitialSales,
    isLoadingMore: isLoadingMoreSales,
    isReachingEnd: areSalessReachingEnd,
  } = useSalesWithPagination(ITEMS_LIMIT, pickedCategory);

  const {
    auctions,
    loadMore: loadMoreAuctions,
    isLoadingInitialData: isLoadingInitialAuctions,
    isLoadingMore: isLoadingMoreAuctions,
    isReachingEnd: areAuctionsReachingEnd,
  } = useAuctionsWithPagination(ITEMS_LIMIT, pickedCategory);

  const {
    collections,
    loadMore: loadMoreCollections,
    isLoadingInitialData: isLoadingInitialCollections,
    isLoadingMore: isLoadingMoreCollections,
    isReachingEnd: areCollectionsReachingEnd,
  } = useCollectionsWithPagination(ITEMS_LIMIT, pickedCategory);

  useEffect(() => {
    if (filter && ['music'].includes(filter)) {
      setPickedCategory(filter);
    }
  }, [filter]);

  return (
    <GenericSalesAuctionsList
      auctions={auctions}
      sales={sales}
      loadMoreAuctions={
        loadMoreAuctions ? (): Promise<void> => loadMoreAuctions().then() : undefined
      }
      loadMoreSales={loadMoreSales ? (): Promise<void> => loadMoreSales().then() : undefined}
      transactionTypeFilter={transactionTypeFilter}
      canDisplayCollections
      displayType={displayType}
      collections={collections}
      loadMoreCollections={
        loadMoreCollections ? (): Promise<void> => loadMoreCollections().then() : undefined
      }
      isLoadingInitialAuctions={isLoadingInitialAuctions}
      isLoadingInitialCollections={isLoadingInitialCollections}
      isLoadingInitialSales={isLoadingInitialSales}
      canLoadMoreAuctions={!areAuctionsReachingEnd}
      canLoadMoreCollections={!areCollectionsReachingEnd}
      canLoadMoreSales={!areSalessReachingEnd}
      isLoadingMoreAuctions={isLoadingMoreAuctions}
      isLoadingMoreSales={isLoadingMoreSales}
      isLoadingMoreCollections={isLoadingMoreCollections}
      pickedCategory={pickedCategory}
      setPickedCategory={setPickedCategory}
    />
  );
};
