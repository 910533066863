import React from 'react';
import classNames from 'classnames';

import './button.css';
// import { ReactComponent as Icon } from '../../../assets/CTA-button-icon.svg';

// TODO: I think it could be solved better by replacing enum
export enum ButtonStyle {
  color = 'btn CTA-color',
  second = 'btn CTA-second',
  white = 'btn white',
  wideWhite = 'btn white fullWidth',
  wideSecond = 'btn CTA-second fullWidth',
  red = 'btn red',
  green = 'btn green',
  wideGreen = 'btn green fullWidth',
  wideColor = 'btn CTA-color fullWidth',
}

interface Props {
  onClick: (e?: any) => void;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonStyle: ButtonStyle;
  disabled?: boolean;
  className?: string;
  isActive?: boolean;
  id: string;
}

export const Button = ({
  onClick,
  children,
  type,
  buttonStyle,
  disabled,
  className,
  isActive,
  id,
}: Props): JSX.Element => {
  return (
    <button
      className={classNames(buttonStyle, className, isActive && 'active')}
      type={type ? type : 'button'}
      onClick={
        disabled
          ? undefined
          : (e) => {
              onClick(e);
            }
      }
      disabled={disabled}
      id={id}
    >
      {children}
      {/* {buttonStyle === ButtonStyle.color
                ? <Icon className={`${buttonStyle}--icon`}/>
                : null
            } */}
    </button>
  );
};
