import { toSnakeCase } from 'js-convert-case';

export const objectToFormData = (object: Record<string, string | Blob | undefined>): FormData =>
  Object.entries(object).reduce((form, entry) => {
    if (entry[1] !== undefined) {
      form.append(toSnakeCase(entry[0]), entry[1]);
    }

    return form;
  }, new FormData());
