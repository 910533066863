import { useContext } from 'react';
import axios from 'axios';
import { snakeKeys } from 'js-convert-case';
import useSWR, { KeyedMutator, SWRResponse } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { AuthContext } from 'context/auth-context';

import { env } from '../env/env';
import { objectToFormData } from '../utils/object-to-form-data';
import { dataFetcher } from './data-fetcher';
import { baseHeaders, getAuthHeader } from './headers';
import { Item } from './item-service';

export interface Collection {
  id: string;
  createdAt: string;
  updatedAt: string;
  creatorWalletId: string;
  displayName: string;
  description: string;
  categoryId?: string;
  visibility: 'public' | 'private';
  previewImageUrl: string;
  itemsIds: Array<string>;
  items?: Array<Item>;
}

interface CollectionsList {
  collections: Array<Collection>;
  offset: number;
  totalCount: number;
}

export interface CollectionRequest {
  displayName: string;
  description: string;
  categoryId?: string;
  visibility: 'public' | 'private';
  previewImage: Blob;
}

export const useCollectionsWithPagination = (
  limit: number,
  category?: string,
): {
  collections: Collection[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<CollectionsList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<CollectionsList[] | undefined>;
} => {
  const { jwt } = useContext(AuthContext);
  const path = category ? `/categories/${category}/collections` : '/collections';

  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) => `${env.apiUrl}${path}?offset=${page * limit}&limit=${limit}`,
    (url) => dataFetcher<CollectionsList>(url, jwt),
  );

  const extractedData = (data || []).map((p) => p.collections);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const collections = data ? new Array<Collection>().concat(...extractedData) : [];

  const isEmpty = collections.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<CollectionsList[] | undefined> => setPage(page + 1);

  return {
    collections,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};

export const useHotCollections = (): {
  data: Collection[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<CollectionsList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/collections/hot`;
  const result = useSWR(url, (url) => dataFetcher<CollectionsList>(url));

  return {
    ...result,
    data: result.data?.collections,
  };
};

export const useCollection = (collectionId?: string): SWRResponse<Collection> => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/collections/${collectionId}`;
  const result = useSWR(collectionId ? url : null, (url) => dataFetcher<Collection>(url, jwt));
  return result;
};

export const useCollectionsByWalletIdWithPagination = (
  limit: number,
  walletAddress?: string,
  category?: string,
): {
  collections: Collection[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<CollectionsList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<CollectionsList[] | undefined>;
} => {
  const { jwt } = useContext(AuthContext);
  const categoryParam = category ? `&category_id=${category}` : '';
  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) =>
      walletAddress
        ? `${env.apiUrl}/users/${walletAddress}/collections?offset=${
            page * limit
          }&limit=${limit}${categoryParam}`
        : null,
    (url) => dataFetcher<CollectionsList>(url, jwt),
  );

  const extractedData = (data || []).map((p) => p.collections);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const collections = data ? new Array<Collection>().concat(...extractedData) : [];

  const isEmpty = collections.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<CollectionsList[] | undefined> => setPage(page + 1);

  return {
    collections,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};

export const useCollectionsByWalletIdAndCategory = (
  walletAddress?: string,
  category?: string,
): {
  data: Collection[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<CollectionsList>;
  isValidating: boolean;
} => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletAddress}/collections?category_id=${category}`;
  const result = useSWR(url, (url) => dataFetcher<CollectionsList>(url, jwt));

  return {
    ...result,
    data: result.data?.collections,
  };
};

export const createNewCollection = async (
  collectionRequest: CollectionRequest,
  jwt: string,
): Promise<Collection> => {
  const url = `${env.apiUrl}/collections`;

  const body = objectToFormData({ ...collectionRequest });

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as Collection;
};

export const addItemToCollection = async (
  itemId: string,
  collectionId: string,
  jwt: string,
): Promise<Collection> => {
  const url = `${env.apiUrl}/collections/${collectionId}/add_item`;

  const body = JSON.stringify(snakeKeys({ itemId }));

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as Collection;
};

export const removeItemFromCollection = async (
  itemId: string,
  collectionId: string,
  jwt: string,
): Promise<Collection> => {
  const url = `${env.apiUrl}/collections/${collectionId}/delete_item`;

  const body = JSON.stringify(snakeKeys({ itemId }));

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as Collection;
};

export const deleteCollectionById = async (collectionId: string, jwt: string): Promise<void> => {
  const url = `${env.apiUrl}/collections/${collectionId}`;

  await axios({
    url,
    method: 'DELETE',
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });
};
