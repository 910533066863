import { useLocation } from 'react-router-dom';

import { DisplayType } from '../../components/common/display-type-picker/display-type-picker';
import { SalesAuctionsList } from '../../components/sales-auctions-list/sales-auctions-list';
import { TransactionType } from '../../types/transaction-type';
import styles from './explore-page.module.css';

export const ExplorePage = (): JSX.Element => {
  const search = useLocation().search;

  const transactionType = new URLSearchParams(search).get('transactionType');
  const categoryId = new URLSearchParams(search).get('categoryId');
  const displayType = new URLSearchParams(search).get('displayType');

  return (
    <div className={styles.container}>
      <h1>Explore FORGE</h1>
      <p>
        Buy, Sell, and Discover thousands exclusive of NFT items: Music, Art, Photos, Games etc.
      </p>
      <SalesAuctionsList
        transactionTypeFilter={
          transactionType ? (parseInt(transactionType) as TransactionType) : undefined
        }
        categoryFilter={categoryId || undefined}
        displayType={displayType ? (parseInt(displayType) as DisplayType) : undefined}
      />
    </div>
  );
};
