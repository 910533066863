import classNames from 'classnames';

import { ReactComponent as ImageIcon } from '../../../assets/icons/image.svg';
import styles from './image-placeholder.module.css';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export const ImagePlaceholder = ({ width, height, className }: Props): JSX.Element => (
  <div
    className={classNames(styles.imagePlaceholderContainer, className)}
    style={{
      minWidth: width,
      minHeight: height,
    }}
  >
    <ImageIcon />
  </div>
);
