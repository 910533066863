import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CurrencyContext } from 'context/currency-context';

import { LoadingPagePlaceholder } from '../../components/common/loading-page-placeholder/loading-page-placeholder';
import { useItem } from '../../services/item-service';
import { useSale } from '../../services/sales-service';
import { ItemComponent } from './item-component';

export const SalePage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: sale, error: saleError } = useSale(id);
  const { data: item, error: itemError } = useItem(sale?.itemId);
  const { orePriceInUSD } = useContext(CurrencyContext);

  useEffect(() => {
    if (saleError || itemError) {
      navigate('/not-found');
    }
  }, [saleError, itemError, navigate]);

  if (item === undefined) {
    return <LoadingPagePlaceholder />;
  }

  return <ItemComponent item={item} sale={sale} orePriceInUSD={orePriceInUSD} />;
};
