import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { env } from 'env/env';

import { Typography } from 'components/common';
import { useCurrentUserSubscriptions } from 'services/user-service';

export const NoSubscriptionPage = () => {
  const { data } = useCurrentUserSubscriptions();
  const navigate = useNavigate();

  useEffect(() => {
    if (data !== undefined) {
      const { subscriptions } = data;

      const musicSubscription = subscriptions.find((s) => s.name === 'music');

      if (musicSubscription?.active) {
        navigate('/music/home');
      }
    }
  }, [data, navigate]);

  return (
    <div>
      <Typography.Heading fontSize={32} fontWeight={600} marginBottom={32}>
        You need to acquire music subscription.
      </Typography.Heading>
      <Typography.Paragraph fontSize={16} marginBottom={128}>
        <>
          Please subscribe to ORE Music to access audio player and other features. You can do that
          by entering this{' '}
          <a href={env.oreMusicSubscriptionUrl} target="_blank" rel="noopener noreferrer">
            page
          </a>
          .
        </>
      </Typography.Paragraph>
    </div>
  );
};
