import axios from 'axios';

import { env } from '../env/env';
import { jsonHeaders } from './headers';

export const getNonce = async (walletAddress: string): Promise<string> => {
  const url = `${env.apiUrl}/auth/${walletAddress}`;
  const response = await axios({ url, headers: jsonHeaders });

  return response.data['token'];
};

export const exchangeSignatureForToken = async (
  walletAddress: string,
  signature: string,
): Promise<string> => {
  const url = `${env.apiUrl}/auth/${walletAddress}`;
  const response = await axios({
    url,
    method: 'POST',
    headers: jsonHeaders,
    data: JSON.stringify({ signature }),
  });

  return response.data['jwt'];
};
