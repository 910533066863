import { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import { createOreToken, OreTokenInfo } from 'services/blockchain-service';
import { uploadFileToIpfs } from 'services/ipfs-service';
import { createNewItem, Item, ItemRequestBody, patchItem } from 'services/item-service';
import { MP3_MIME_TYPE, WAV_MIME_TYPE } from 'utils/accepted-audio-file-types';

import { AuthContext } from './auth-context';

interface ItemsStore {
  createItem: (oreTokenInfo: OreTokenInfo) => Promise<Item>;
  updateItem: (item: ItemRequestBody, itemId: string) => Promise<Item>;
  uploadUnlockableContent: (itemId: string, file: Blob) => Promise<Item>;
}

export const ItemsContext = createContext<ItemsStore>({
  createItem: () => Promise.reject(),
  updateItem: () => Promise.reject(),
  uploadUnlockableContent: () => Promise.reject(),
});

export const ItemsContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { jwt } = useContext(AuthContext);

  const createItem = async (oreTokenInfo: OreTokenInfo): Promise<Item> => {
    try {
      const { oreTokenId, ipfsImageId } = await createOreToken(oreTokenInfo, jwt);
      const newItem = await createNewItem(
        jwt,
        oreTokenId.toString(),
        ipfsImageId,
        oreTokenInfo.legal,
        oreTokenInfo.chain,
        oreTokenInfo.previewImage,
        oreTokenInfo.usage,
        oreTokenInfo.oreChamberStreamingApproved,
      );

      return newItem;
    } catch {
      toast.error('Sorry, we could not create your token. Please contact administrator.');
      return Promise.reject();
    }
  };

  const updateItem = async (item: ItemRequestBody, itemId: string): Promise<Item> => {
    const updatedItem = await patchItem(jwt, item, itemId);

    return updatedItem;
  };

  const uploadUnlockableContent = async (itemId: string, file: Blob): Promise<Item> => {
    const unlockableFileHash = await uploadFileToIpfs(jwt, file);

    const item = await updateItem({ lockedMediaIpfsId: unlockableFileHash }, itemId);

    if (file.type === MP3_MIME_TYPE || file.type === WAV_MIME_TYPE) {
      const audio = new Audio(item.lockedMediaIpfsUrl);
      const itemWithDuration = await new Promise<Item>((resolve) => {
        audio.onloadedmetadata = async () => {
          const updatedItem = await updateItem(
            { mediaLengthSeconds: audio.duration.toString() },
            itemId,
          );
          resolve(updatedItem);
        };
      });

      return itemWithDuration;
    }

    return item;
  };

  return (
    <ItemsContext.Provider
      value={{
        createItem,
        updateItem,
        uploadUnlockableContent,
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};
