import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CategoryDropdown from 'components/category-picker';
import { Tabs } from 'components/common';
import { TabOption, TabOptions } from 'components/common/tabs/tabs';
import { Typography } from 'components/common/typography';

import styles from './profile-page.module.css';
import { UserCollections } from './user-collections';
import { UserItems } from './user-items';

const availableTabs: TabOptions = [
  { value: 'items', label: 'Items' },
  { value: 'collections', label: 'Collections' },
];

interface Props {
  title: 'Your items' | 'User items';
  walletAddress: string;
}

export const UserItemsSection = ({ title, walletAddress }: Props) => {
  const [currentTab, setCurrentTab] = useState<TabOption>(availableTabs[0]);
  const [pickedCategory, setPickedCategory] = useState<string>();
  const { hash } = useLocation();
  const { filter } = useParams();

  useEffect(() => {
    if (hash === '#collections') {
      setCurrentTab(availableTabs[1]);
    }
    if (hash === '#items') {
      setCurrentTab(availableTabs[0]);
    }
  }, [hash]);

  useEffect(() => {
    if (filter && ['music'].includes(filter)) {
      setPickedCategory(filter);
    }
  }, [filter]);

  const renderTab = (walletAddress: string) => {
    switch (currentTab.value) {
      case 'items':
        return <UserItems walletAddress={walletAddress} pickedCategory={pickedCategory} />;
      case 'collections':
        return <UserCollections walletAddress={walletAddress} />;
    }
  };

  return (
    <>
      <div id="items" />
      <div id="collections" />
      <Typography.Heading fontSize={32} fontWeight={700} marginBottom={24}>
        {title}
      </Typography.Heading>
      <div className={styles.filters}>
        <Tabs currentOption={currentTab} setValue={setCurrentTab} displayOptions={availableTabs} />
        {currentTab.value === 'items' && (
          <CategoryDropdown setPickedCategory={setPickedCategory} pickedCategory={pickedCategory} />
        )}
      </div>
      {renderTab(walletAddress)}
    </>
  );
};
