import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import styles from './generic-popup.module.css';

interface GenericPopupProps {
  canBeClosed: boolean;
  onClose?: (e?: any) => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  zIndex?: number;
  id: string;
}

export const GenericPopup = ({
  canBeClosed,
  onClose,
  title,
  children,
  zIndex,
  id,
}: GenericPopupProps): JSX.Element => {
  return (
    <div className={styles.dimmedBackground}>
      <div className={styles.container} style={{ zIndex }} id={id}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{title}</span>
          </div>
          {canBeClosed && (
            <div
              className={styles.closeBtn}
              onClick={(e) => onClose && onClose(e)}
              id={'close-popup-button'}
            >
              <CloseIcon />
            </div>
          )}
        </div>
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};
