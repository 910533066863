import classNames from 'classnames';

import { ColorsValueType } from 'types/themes';
import { getFontSizeInRem } from 'utils/style-builders';

import styles from './circular-image.module.css';

interface IProps {
  imageUrl: string;
  width?: number;
  height?: number;
  className?: string;
  withBorder?: boolean;
  borderColor?: ColorsValueType;
  borderWidth?: number;
  onClick?: () => void;
}

export const CircularImage = ({
  imageUrl,
  width,
  height,
  className,
  withBorder,
  borderColor,
  borderWidth,
  onClick,
}: IProps) => {
  return (
    <img
      src={imageUrl}
      alt="circular image"
      className={classNames(styles.image, className, {
        [styles.withBorder]: withBorder,
      })}
      onClick={onClick}
      style={{
        width: width !== undefined ? getFontSizeInRem(width) : undefined,
        height: height !== undefined ? getFontSizeInRem(height) : undefined,
        borderColor,
        borderWidth: borderWidth !== undefined ? getFontSizeInRem(borderWidth) : undefined,
      }}
    />
  );
};
