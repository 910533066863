import useSWR, { KeyedMutator } from 'swr';

import { env } from '../env/env';
import { dataFetcher } from './data-fetcher';
import { UserProfile } from './user-service';

export interface SellerProfile extends UserProfile {
  itemsSellCount: number;
}

interface SellersList {
  users: Array<SellerProfile>;
}

export const useTopSellers = (
  offset: number,
  limit: number,
): {
  data: SellerProfile[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<SellersList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/sellers/top?offset=${offset}&limit=${limit}`;
  const result = useSWR(url, (url) => dataFetcher<SellersList>(url));
  return {
    ...result,
    data: result.data?.users,
  };
};
