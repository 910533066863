import { Link } from 'react-router-dom';

import { ReactComponent as CollectionLogo } from '../../assets/icons/illustration_collection.svg';
import { ReactComponent as SingleForgeLogo } from '../../assets/icons/illustration_single.svg';
import styles from './create-page.module.css';

export const CreatePage = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <h1>Create NFT</h1>
      <p>We do not own your private keys and cannot access your funds without your confirmation.</p>
      <div className={styles.optionRow}>
        <Link className={styles.optionContainer} to="/create-forge">
          <SingleForgeLogo />
          <p>Forge NFT</p>
        </Link>
        <Link className={styles.optionContainer} to="/create-collection">
          <CollectionLogo />
          <p>Collection</p>
        </Link>
      </div>
    </div>
  );
};
