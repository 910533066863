import { useContext, useMemo } from 'react';
import { TRACK_COLUMNS_SETS } from 'constant';

import { TrackCard } from 'components/track-card';
import { AudioPlayerContext } from 'context/audio-player-context';
import { Item } from 'services/item-service';
import { useUser } from 'services/user-service';
import { secondsToDuration } from 'utils/seconds-to-duration';

interface Props {
  item: Item;
  authorName?: string;
  authorId?: string;
  albumName?: string;
  queuePosition?: string;
  imageURL?: string;
  price?: string;
  playlistId?: string;
}

export const PlaylistMusicItem = ({
  item,
  authorName,
  authorId,
  imageURL,
  queuePosition,
  albumName,
  price,
  playlistId,
}: Props) => {
  const { currentSong, isPaused } = useContext(AudioPlayerContext);

  const nameFromId = useUser(authorId).data?.displayName;

  const isItemACurrentSong = useMemo(
    () => currentSong !== undefined && currentSong?.id === item?.id,
    [currentSong, item?.id],
  );
  const isPlaying = !isPaused && isItemACurrentSong;

  return item ? (
    <TrackCard
      songId={item.id}
      setOfColumns={TRACK_COLUMNS_SETS.TAD}
      queuePosition={queuePosition ?? ''}
      author={nameFromId ?? authorName ?? ''}
      authorURL={''}
      songTitle={item.displayName}
      albumURL={''}
      isPlaying={isPlaying}
      duration={secondsToDuration(item.mediaLengthSeconds, true)}
      albumName={albumName ?? ''}
      albumImage={imageURL ?? ''}
      price={price ?? ''}
      playlistId={playlistId}
    />
  ) : null;
};
