import React from 'react';
import { ChangeHandler, FieldValues, UseFormRegister } from 'react-hook-form';

import styles from './underlined-input.module.css';

interface Props {
  title: string;
  placeholder: string;
  unit?: JSX.Element;
  errorMessage?: string;

  name: string; // FormControl registered name
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  id: string;
  bottomNote?: string;
}

export const UnderlinedTextAreaControl = React.forwardRef<
  HTMLTextAreaElement,
  Props & ReturnType<UseFormRegister<FieldValues>>
>(
  (
    { title, placeholder, unit, name, errorMessage, onChange, onBlur, id, bottomNote }: Props,
    ref,
  ) => (
    <div className={styles.inputContainer}>
      <p>{title}</p>
      <div className={styles.inputRow}>
        <textarea
          name={name}
          ref={ref}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onWheel={(e): void => e.currentTarget.blur()}
          id={id}
          rows={6}
        ></textarea>
        {unit !== undefined && unit}
      </div>
      <span className="inputValidationError">{errorMessage}</span>
      <span className={styles.bottomNote}>{bottomNote}</span>
    </div>
  ),
);

UnderlinedTextAreaControl.displayName = 'UnderlinedTextAreaControl';
