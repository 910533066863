import { Dispatch, SetStateAction, useRef, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/nav-arrow-icon.svg';
import { Typography } from 'components/common/typography';
import { useOnClickOutside } from 'hooks/on-click-outside';
import { getFontSizeInRem } from 'utils/style-builders';

import styles from './dropdown.module.css';

export enum DropdownStyle {
  Underlined,
  FullBorder,
  WithArrow,
  Basic,
}

export enum DropdownButtonStyle {
  Primary,
  Link,
}

interface Props {
  options: Array<string | JSX.Element>;
  icon?: JSX.Element;
  placeholderTitle?: string;
  selected?: string;
  setSelected?: Dispatch<SetStateAction<string>>;
  dropdownStyle?: DropdownStyle;
  isWide?: boolean;
  id?: string; // required for accessibility in automatic tests
  alignRight?: boolean;
  fixedWidth?: number;
  buttonStyle?: DropdownButtonStyle;
}

/**
 * @param setSelected REQUIRED if `options` is a string array
 * @returns Dropdown menu with optional icon on left side
 */

export const Dropdown = ({
  selected,
  setSelected,
  options,
  icon,
  dropdownStyle = DropdownStyle.Underlined,
  placeholderTitle = 'All',
  isWide = false,
  id,
  fixedWidth,
  alignRight = false,
  buttonStyle = DropdownButtonStyle.Primary,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const width = fixedWidth ? getFontSizeInRem(fixedWidth) : '100%';

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div
      className={classNames(styles.dropdownContainer, {
        [styles.dropdownContainerFocus]: isOpen,
        [styles.dropdownContainerFullBorder]: dropdownStyle === DropdownStyle.FullBorder,
        [styles.withArrow]: dropdownStyle === DropdownStyle.WithArrow,
        [styles.basic]: dropdownStyle === DropdownStyle.Basic,
      })}
      ref={ref}
      style={{ width }}
    >
      <div className={styles.pickedValue} onClick={() => setIsOpen((e) => !e)} id={id}>
        <div className={styles.iconWrapper}>{icon}</div>
        <Typography.Span fontSize={14} fontWeight={500} classNames={styles.value}>
          {selected ? selected : placeholderTitle}
        </Typography.Span>
        {dropdownStyle === DropdownStyle.WithArrow && <ArrowIcon className={styles.arrowIcon} />}
      </div>
      <div
        className={classNames(styles.dropMenu, {
          [styles.dropMenuHidden]: !isOpen,
          [styles.wideMenu]: isWide,
          [styles.alignRight]: alignRight,
        })}
      >
        {options.map((option) => {
          const key = typeof option === 'string' ? option : option.key;
          const onClick = () => {
            if (setSelected && typeof option === 'string') {
              setSelected(option);
            }
            setIsOpen(false);
          };

          return (
            <div
              key={key}
              className={classNames(styles.dropValue, {
                [styles.dropValueLink]: buttonStyle === DropdownButtonStyle.Link,
              })}
              onClick={onClick}
            >
              {option}
              {buttonStyle === DropdownButtonStyle.Link && <LinkIcon className={styles.linkIcon} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
