import classNames from 'classnames';

import styles from './table-row.module.css';

export type TableRowElement = {
  columnContent: string | JSX.Element;
  flexBasis: string;
  isFixed?: boolean;
};

interface Props {
  tableRowColumns: TableRowElement[];
}

export const TableRow = (props: Props) => {
  const getElemWidth = (elem: TableRowElement) =>
    elem.isFixed ? { width: elem.flexBasis } : { flexBasis: elem.flexBasis };

  return (
    <div className={styles.tableRow}>
      {props.tableRowColumns.map((col, index) => (
        <div
          key={index}
          className={classNames(styles.column, { [styles.fixedWidth]: col.isFixed })}
          style={getElemWidth(col)}
        >
          {col.columnContent}
        </div>
      ))}
    </div>
  );
};
