import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './link-card.module.css';

interface Props {
  redirectTo: string;
  title: string;
  imagesUrls: string[];
  active?: boolean;
  hoverableElement?: React.ReactNode;
  additionalElement?: React.ReactNode;
}

export const LinkCard = ({
  redirectTo,
  title,
  imagesUrls,
  active,
  hoverableElement,
  additionalElement,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(styles.container, { [styles.active]: active })}
      onClick={() => navigate(redirectTo)}
    >
      <div className={styles.leftContainer}>
        <div className={styles.imagesContainer}>
          {imagesUrls.map((url, index) => (
            <img key={`link-card-img-${index}`} src={url} className={styles.image} alt={title} />
          ))}
        </div>
        <p className={styles.title}>{title}</p>
      </div>
      {hoverableElement !== undefined && (
        <div className={styles.hoverableElement}>{hoverableElement}</div>
      )}
      {additionalElement}
    </div>
  );
};
