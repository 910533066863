import { useContext, useMemo } from 'react';
import { COLORS } from 'constant';

import { Typography } from 'components/common';
import { Card } from 'components/common/card';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext, OWNED_MUSIC_ID } from 'context/audio-player-context';
import { Item, useItem } from 'services/item-service';
import { useUser } from 'services/user-service';
import truncate from 'utils/string-truncator';

import styles from './music-card.module.css';

interface IProps {
  prefetchedItem?: Item;
  itemId?: string;
  price?: string;
  redirectLink?: string;
  playAsSingle?: boolean;
}

export const MusicCard = ({
  prefetchedItem,
  itemId,
  price,
  redirectLink,
  playAsSingle = false,
}: IProps) => {
  const { data: fetchedItem } = useItem(itemId);
  const item = prefetchedItem || fetchedItem;

  const canItemBePlayed = useMemo(() => item?.streamPreviewUrl || item?.lockedMediaIpfsUrl, [item]);

  const { playPlaylist, currentSong, isPaused, play, pause, playSingleItem } =
    useContext(AudioPlayerContext);

  const isItemACurrentSong = useMemo(
    () => currentSong !== undefined && currentSong?.id === item?.id,
    [currentSong, item?.id],
  );
  const isPlaying = !isPaused && isItemACurrentSong;

  const onClicked = () => {
    if (isItemACurrentSong) {
      if (isPlaying) {
        pause();
      } else {
        play();
      }
    } else if (playAsSingle && item) {
      playSingleItem(item);
    } else {
      playPlaylist(OWNED_MUSIC_ID, item?.id);
    }
  };

  const { data: creator } = useUser(item?.firstOwnerWalletId);

  const creatorImgUrl = creator?.profileAvatarUrl;

  const renderButton = () => {
    if (!canItemBePlayed) return null;

    return (
      <div className={styles.playPauseButton}>
        <PlayPauseButton
          isPlaying={isPlaying}
          onPlayPauseClicked={onClicked}
          colorType="orange"
          width={56}
          height={56}
          padding={18}
        />
      </div>
    );
  };

  return (
    <Card
      imageUrl={item?.previewImageUrl}
      creatorImgUrl={creatorImgUrl}
      creatorWalletAddress={item?.firstOwnerWalletId}
      redirectLink={redirectLink || `/item/${item?.id}`}
      title={item?.displayName || ''}
      type="small"
      hoverable
      active={isItemACurrentSong}
      userLinkPrefix="/music"
      bottomElement={
        <>
          <div className={styles.textContainer}>
            <Typography.Paragraph
              fontSize={12}
              fontWeight={700}
              color={COLORS.GRAY_TEXT_1}
              classNames={styles.authorName}
            >
              {creator?.displayName || truncate(item?.firstOwnerWalletId || '', 6)}
            </Typography.Paragraph>
            {price && (
              <Typography.Paragraph color={COLORS.WHITE} fontSize={14}>
                {truncate(price, 6)}
              </Typography.Paragraph>
            )}
          </div>
          {isItemACurrentSong && renderButton()}
        </>
      }
      hoverableElement={!isItemACurrentSong && renderButton()}
    />
  );
};
