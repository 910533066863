import { createContext, useCallback, useContext } from 'react';

import { buyTokenWithBNB, buyTokenWithORE } from '../services/blockchain-service';
import { createNewSale, deleteSale, markPurchased, Sale } from '../services/sales-service';
import { AuthContext } from './auth-context';

interface SalesContextType {
  createSale: (
    price: string,
    settlementToken: string,
    itemId: string,
    purchaseLimit?: number,
  ) => Promise<Sale>;
  buyToken: (
    price: number,
    quantity: number,
    ownerWalletAddress: string,
    tokenId: string,
    currency: 'ORE' | 'BNB',
    saleId: string,
  ) => Promise<void>;
  endSale: (saleId: string) => Promise<void>;
}

export const SalesContext = createContext<SalesContextType>({
  endSale: () => Promise.reject(),
  createSale: () => Promise.reject(),
  buyToken: () => Promise.reject(),
});

export const SalesContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { jwt } = useContext(AuthContext);

  const createSale = async (
    price: string,
    settlementToken: string,
    itemId: string,
    purchaseLimit?: number,
  ): Promise<Sale> => {
    const newSale = await createNewSale(price, settlementToken, itemId, jwt, purchaseLimit);

    return newSale;
  };

  const endSale = useCallback(
    async (saleId: string) => {
      await deleteSale(saleId, jwt);
    },
    [jwt],
  );

  const buyToken = async (
    price: number,
    quantity: number,
    ownerWalletAddress: string,
    tokenId: string,
    currency: 'ORE' | 'BNB',
    saleId: string,
  ): Promise<void> => {
    if (currency === 'ORE') {
      await buyTokenWithORE(price, quantity, ownerWalletAddress, tokenId);
    } else {
      await buyTokenWithBNB(price, quantity, ownerWalletAddress, tokenId);
    }

    await markPurchased(saleId, quantity, jwt);
  };

  return (
    <SalesContext.Provider
      value={{
        createSale,
        buyToken,
        endSale,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};
