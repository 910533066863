import { useMemo } from 'react';
import { BeatLoader } from 'react-spinners';

import { BidInfo } from 'components/bid-info/bid-info';
import { Auction, useBidsForAuction } from 'services/auctions-service';

import styles from './item-page.module.css';

interface Props {
  auction?: Auction;
  walletAddress?: string;
}

export const ItemBidsInfo = ({ auction, walletAddress }: Props) => {
  const { data: bids } = useBidsForAuction(auction?.id);

  const highestBid = useMemo(() => {
    const sorted = [...(bids || [])].sort((a, b) => +b.amount - +a.amount);
    return sorted[0];
  }, [bids]);

  return (
    <>
      {auction && (
        <>
          <p className={styles.bidsTitle}>Bids</p>
          <div className={styles.bidsHistory}>
            {bids === undefined ? (
              <small className={styles.noBids}>
                Loading <BeatLoader />
              </small>
            ) : bids.length > 0 ? (
              bids.map((bid) => (
                <BidInfo
                  key={bid.id}
                  bid={bid}
                  auction={auction}
                  allowAcceptBid={
                    auction.ownerWalletId === walletAddress && highestBid.id === bid.id
                  }
                />
              ))
            ) : (
              <small className={styles.noBids}>There are no bids yet</small>
            )}
          </div>
        </>
      )}
    </>
  );
};
