import { useState } from 'react';

import { HighVolumeIcon, MutedVolumeIcon, VolumeIcon } from 'assets/audio-player-icons';
import { IconButton } from 'components/common/icon-button';
import { RangeInput } from 'components/common/range-input';

import styles from './volume-controller.module.css';

interface Props {
  volume: number;
  setVolume: (v: number) => void;
}

export const VolumeController = ({ volume, setVolume }: Props) => {
  const [isMuted, setIsMuted] = useState(false);
  const [prevVolumeState, setPrevVolumeState] = useState(volume);

  const toggleMute = () => {
    if (isMuted) {
      setVolume(prevVolumeState);
      setIsMuted(false);
    } else {
      setPrevVolumeState(volume);
      setVolume(0);
      setIsMuted(true);
    }
  };

  const renderVolumeIcon = (volume: number) => {
    if (volume === 0) {
      return <MutedVolumeIcon />;
    } else if (volume > 75) {
      return <HighVolumeIcon />;
    } else {
      return <VolumeIcon />;
    }
  };

  return (
    <div className={styles.volumeController}>
      <IconButton
        icon={renderVolumeIcon(volume)}
        onClick={toggleMute}
        colorType="grey"
        iconType="stroke"
      />
      <RangeInput
        min={0}
        max={100}
        value={volume === undefined ? 50 : volume}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setVolume(parseFloat(e.target.value));
        }}
        className={styles.volumeRange}
      />
    </div>
  );
};
