import { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { UserSocialLinks } from 'components/user-social-links';

import { Button, ButtonStyle } from '../../components/common/button/button';
import { GenericPopup } from '../../components/common/generic-popup/generic-popup';
import { ItemCard } from '../../components/common/item-card/item-card';
import { ItemsPickerPopup } from '../../components/items-picker-popup/items-picker-popup';
import { CollectionsContext } from '../../context/collections-context';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { Collection } from '../../services/collection-service';
import { Item, useCurrentUserItems } from '../../services/item-service';
import { useUser } from '../../services/user-service';
import truncate from '../../utils/string-truncator';
import styles from './collection-page.module.css';

interface Props {
  collection: Collection;
}

export const CollectionComponent = ({ collection }: Props): JSX.Element => {
  const { data: creator } = useUser(collection.creatorWalletId);
  const { data: currentUserItems } = useCurrentUserItems();
  const { addItem, removeItem, removeCollection } = useContext(CollectionsContext);
  const { walletAddress } = useContext(CurrentUserProfileContext);
  const isOwner = useMemo(
    () => collection.creatorWalletId === walletAddress,
    [collection.creatorWalletId, walletAddress],
  );

  const [pickedItems, setPickedItems] = useState<Array<Item>>(
    (currentUserItems || []).filter(
      (item) => collection.itemsIds && collection.itemsIds.includes(item.id),
    ),
  );
  const [isItemsPopupOpen, setIsItemsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const navigate = useNavigate();

  const manageItems = useCallback(
    async (newItems: Array<Item>, currentItems: Array<Item>) => {
      const toAdd = newItems.filter((item) => !currentItems.includes(item));
      const toRemove = currentItems.filter((item) => !newItems.includes(item));

      await Promise.all(toAdd.map((item) => addItem(item.id, collection.id)));
      await Promise.all(toRemove.map((item) => removeItem(item.id, collection.id)));
      setPickedItems(newItems);
    },
    [addItem, collection.id, removeItem],
  );

  const deleteCollection = useCallback(async () => {
    await removeCollection(collection.id);
    navigate('/current-user');
  }, [collection.id, navigate, removeCollection]);

  return (
    <div className={styles.container}>
      <img className={styles.banner} alt={'collection-banner'} src={collection.previewImageUrl} />
      <div className={styles.creatorRow}>
        <Link className={styles.creatorInfo} to={`/user/${collection.creatorWalletId}`}>
          <img alt={'creator'} src={creator?.profileAvatarUrl || '/av-placeholder.jpg'} />
          <p>{creator?.displayName || truncate(collection.creatorWalletId)}</p>
        </Link>
        {creator && <UserSocialLinks userProfile={creator} />}
      </div>
      <h1 className={styles.title}>{collection.displayName}</h1>
      {/* TODO: info tiles */}
      <p className={styles.description}>{collection.description}</p>
      {isOwner && (
        <div className={styles.ownerActions}>
          <Button
            onClick={(): void => setIsItemsPopupOpen(true)}
            buttonStyle={ButtonStyle.second}
            id={'collection-page-manage-items-button'}
          >
            Manage items
          </Button>
          <Button
            onClick={(): void => setIsDeletePopupOpen(true)}
            buttonStyle={ButtonStyle.red}
            id={'collection-page-delete-button'}
          >
            Delete collection
          </Button>
          {isItemsPopupOpen && (
            <ItemsPickerPopup
              pickedItems={pickedItems}
              setPickedItems={(picked): void => {
                setPickedItems((current) => {
                  manageItems(picked, current);
                  return picked;
                });
              }}
              close={(): void => setIsItemsPopupOpen(false)}
              buttonText="Update"
            />
          )}
          {isDeletePopupOpen && (
            <GenericPopup
              onClose={(): void => setIsDeletePopupOpen(false)}
              canBeClosed
              title={'Are you sure you want to delete this collection?'}
              id={'collection-page-delete-popup'}
            >
              <div className={styles.deleteCollectionContainer}>
                <Button
                  buttonStyle={ButtonStyle.red}
                  onClick={deleteCollection}
                  id={'delete-collection-submit-button'}
                >
                  Yes
                </Button>
                <Button
                  buttonStyle={ButtonStyle.second}
                  onClick={(): void => setIsDeletePopupOpen(false)}
                  id={'delete-collection-cancel-button'}
                >
                  No
                </Button>
              </div>
            </GenericPopup>
          )}
        </div>
      )}
      <div className={styles.itemsContainer}>
        {(collection.itemsIds || []).map((id) => (
          <ItemCard itemId={id} key={id} />
        ))}
      </div>
    </div>
  );
};
