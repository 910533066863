import DateTimePicker from 'react-datetime-picker';

import { Button, ButtonStyle } from '../button/button';
import { GenericPopup } from '../generic-popup/generic-popup';
import styles from './custom-date-time-picker.module.css';

interface Props {
  close: () => void;
  setDateTime: (date: Date) => void;
  value: Date;
  minDate?: Date;
  maxDate?: Date;
}

export const CustomDateTimePickerPopup = ({
  close,
  setDateTime,
  value,
  minDate,
  maxDate,
}: Props): JSX.Element => {
  return (
    <GenericPopup
      title="Pick custom date"
      canBeClosed
      onClose={close}
      zIndex={10}
      id={'custom-date-picker-popup'}
    >
      <DateTimePicker
        value={value}
        onChange={setDateTime}
        minDate={minDate}
        maxDate={maxDate}
        className={styles.dateTimePicker}
      />
      <Button
        type="button"
        onClick={close}
        buttonStyle={ButtonStyle.white}
        id={'custom-date-popup-accept-button'}
      >
        Accept
      </Button>
    </GenericPopup>
  );
};
