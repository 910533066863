import { useEffect, useState } from 'react';

export const useContainerDimensions = (
  ref: React.RefObject<HTMLDivElement>,
): { width: number; height: number } => {
  const getDimensions = (): { width: number; height: number } => ({
    width: ref.current !== null ? ref.current.offsetWidth : 0,
    height: ref.current !== null ? ref.current.offsetHeight : 0,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions(getDimensions());
    };

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return dimensions;
};
