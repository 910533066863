import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { COLORS, OWNERSHIP_LICENSES } from 'constant';
import { env } from 'env/env';

import BscLogo from 'assets/bsc_round.png';
import EthLogo from 'assets/eth_round.png';
import { BidIcon, FixedPriceIcon } from 'assets/icons';
import PolyLogo from 'assets/poly_round.png';
import { Typography } from 'components/common';
import { Button, ButtonStyle } from 'components/common/button';
import { CategoryPicker } from 'components/common/category-picker';
import { CurrencyDropdown } from 'components/common/currency-dropdown';
import { DatePicker, RelativeDate } from 'components/common/date-picker';
import { FileInput } from 'components/common/file-input';
import { GenericPopup } from 'components/common/generic-popup';
import { ToggleInputControl } from 'components/common/toggle-input';
import {
  UnderlinedInputControl,
  UnderlinedTextAreaControl,
} from 'components/common/underlined-input';
import WithChainChange from 'components/hoc/with-chain-change/with-chain-change';
import { AuctionsContext } from 'context/auctions-context';
import { AuthContext } from 'context/auth-context';
import { getLastConnectedProvider, ProviderType } from 'context/browser-auth-context';
import { ItemsContext } from 'context/items-context';
import { LicenseContext } from 'context/license-context';
import { SalesContext } from 'context/sales-context';
import { Category } from 'services/categories-service';
import { Item } from 'services/item-service';
import { TransactionType } from 'types/transaction-type';
import { ACCEPTED_AUDIO_MIME_TYPES } from 'utils/accepted-audio-file-types';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  JPEG_MIME_TYPE,
  PNG_MIME_TYPE,
} from 'utils/accepted-image-types';
import {
  AllowedCurrencies,
  chainIdToNameMap,
  getCurrentChain,
  getCurrentCurrency,
} from 'utils/blockchain-helpers';
import { blockchainNumberRegex } from 'utils/blockchain-number-regex';
import { getDaysBetweenTwoDates, getTomorrowDate } from 'utils/date-helpers';

import styles from './create-forge-page.module.css';
import { PreviewColumn } from './preview-column';

const endsAtAllowedValues = [
  RelativeDate.OneHour,
  RelativeDate.TwelveHours,
  RelativeDate.OneDay,
  RelativeDate.TwoDays,
  RelativeDate.Custom,
];

const startsAtAllowedValues = [
  RelativeDate.Now,
  RelativeDate.OneHour,
  RelativeDate.TwelveHours,
  RelativeDate.OneDay,
  RelativeDate.TwoDays,
  RelativeDate.Custom,
];

enum OwnershipType {
  FullOwnership = 'full_ownership',
  UsageWithinOreSystem = 'usage_within_ore',
}

interface FormInputs {
  unlockableContent: boolean;
  unlockableContentText?: string;
  isPrivate: boolean;
  putOnSale?: boolean;
  price?: string;
  minimumBid?: string;
  nsfw: boolean;
  name: string;
  description: string;
  royalties?: string;
  quantity: string;
  ownershipType: OwnershipType;
  liabilityRelease: boolean;
  purchaseLimit?: string;
  saleQuantity: string;
  itemUsage: string;
  commercialUsageType: string;
  oreChamberStreamingApproved?: boolean;
}

export const CreateForgePage = () => {
  const [transactionType, setTransactionType] = useState(TransactionType.FixedPrice);
  const [previewImageFile, setPreviewImageFile] = useState<File>();
  const [pixelCount, setPixelCount] = useState(0);
  const [file, setFile] = useState<File>();
  const [pickedCategory, setPickedCategory] = useState<Category>();
  const [unlockableContentFile, setUnlockableContentFile] = useState<File>();
  const [bidStartsAt, setBidStartsAt] = useState<Date>(new Date());
  const [bidEndsAt, setBidEndsAt] = useState<Date>(getTomorrowDate());
  const [maxEndsAtDate, setMaxEndsAtDate] = useState<Date>();
  const currentCurrency = getCurrentCurrency();
  const [transactionCurrency, setTransactionCurrency] = useState<AllowedCurrencies>(
    currentCurrency?.toLocaleUpperCase() as AllowedCurrencies,
  );
  const [isInAdvancedMode, setAdvancedMode] = useState(false);
  const { currentChainId } = useContext(AuthContext);

  useEffect(() => {
    const date = new Date(bidStartsAt.getTime());
    date.setDate(date.getDate() + 30);
    setMaxEndsAtDate(date);
  }, [bidStartsAt]);

  const navigate = useNavigate();
  const provider = getLastConnectedProvider();

  const currentChainName = currentChainId ? chainIdToNameMap[currentChainId] : '';

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    getValues,
    setValue,
  } = useForm<FormInputs>({
    defaultValues: {
      quantity: '1',
      ownershipType: OwnershipType.FullOwnership,
      itemUsage: 'private',
      commercialUsageType: 'single',
      oreChamberStreamingApproved: false,
    },
  });

  const {
    createItem,
    updateItem,
    uploadUnlockableContent: uploadUnlockableContentToIpfs,
  } = useContext(ItemsContext);

  const { createSale } = useContext(SalesContext);
  const { createAuction } = useContext(AuctionsContext);
  const { licenseTexts } = useContext(LicenseContext);

  watch((_, { name }) => {
    /* If user sets the private toggle to true,
     * then we should assure that putOnSale is turned off */
    if (name === 'isPrivate') {
      if (getValues('isPrivate')) {
        setValue('putOnSale', false);
      }
    }
  });

  const onPreviewImageFileDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections?: FileRejection[]) => {
      if (acceptedFiles.length === 0) {
        if (fileRejections && fileRejections.length > 0) {
          throw new Error(fileRejections[0].errors[0].message);
        } else {
          throw new Error('Something went wrong with uploading the file. Please try again');
        }
      }
      const file = acceptedFiles[0];

      if (file.size > 4 * 1024 * 1024) {
        toast.error('Your preview image file is too big!');
        toast.info('Maximum accepted preview image size: 4 MiB');
        return;
      }

      if (file.type !== JPEG_MIME_TYPE && file.type !== PNG_MIME_TYPE) {
        toast.warn('You must use png or jpg for preview file.');
        return;
      }

      setPreviewImageFile(file);
      toast.success('Preview image attached!');
    },
    [],
  );

  const onFileDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections?: FileRejection[]): File => {
      if (acceptedFiles.length === 0) {
        if (fileRejections && fileRejections.length > 0) {
          throw new Error(fileRejections[0].errors[0].message);
        } else {
          throw new Error('Something went wrong with uploading the file. Please try again');
        }
      }

      const file = acceptedFiles[0];

      if (file.size > env.maximumUploadFileSize) {
        throw new Error('User has exceeded maximum file size of 1GB');
      }

      return file;
    },
    [],
  );

  const onMainFileDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      try {
        const file = onFileDrop(acceptedFiles, fileRejections);
        if (file && [JPEG_MIME_TYPE, PNG_MIME_TYPE].includes(file.type)) {
          const reader = new FileReader();
          reader.onload = (event): void => {
            if (event.target && event.target.result) {
              const image = new Image();
              image.src = event.target.result as string;
              image.onload = () => {
                setPixelCount(image.width * image.height);
              };
            }
          };
          reader.readAsDataURL(file);
        }
        setFile(file);
        toast.success('Main file attached!');
      } catch (e: any) {
        toast.warn(e.message);
        console.error(e);
      }
    },
    [isInAdvancedMode],
  );

  const onUnlockableFileDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      try {
        const file = onFileDrop(acceptedFiles, fileRejections);
        setUnlockableContentFile(file);
        toast.success('Locked file attached successfully!');
      } catch (e) {
        toast.warn('Something went wrong with uploading the file. Please try again');
        console.error(e);
      }
    },
    [],
  );

  const onMusicFileDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      try {
        const file = onFileDrop(acceptedFiles, fileRejections);
        setUnlockableContentFile(file);
        toast.success('Media file attached successfully!');
      } catch (e) {
        toast.warn('Something went wrong with uploading the file. Please try again');
        console.error(e);
      }
    },
    [],
  );

  const isPreviewFileRequired = useMemo(() => {
    if (
      pickedCategory?.id === 'music' ||
      pickedCategory?.id === 'podcasts' ||
      pixelCount < 8000000
    ) {
      return false;
    }
    return true;
  }, [pickedCategory, pixelCount]);

  const validateFileInputs = (): void => {
    if (!file) {
      toast.warn('File is required!');
      throw 'File required, but not provided.';
    }
    if (isPreviewFileRequired && previewImageFile === undefined) {
      toast.warn('Preview image is required');
      throw 'Preview file is required, but not provided.';
    }
  };

  const validateSaleInputs = (): void => {
    /* Check conditions only if we put it on sale */
    if (getValues('putOnSale') && !getValues('isPrivate')) {
      if (transactionType === TransactionType.FixedPrice) {
        if (!getValues('price')) {
          toast.warn('You have to enter a price to put item on sale!');
          throw 'Sale price is not defined.';
        }
      }
      if (transactionType === TransactionType.Bid) {
        if (!getValues('minimumBid')) {
          toast.warn('You have to enter a minimum bid to put item on auction!');
          throw 'Minimum bid is not defined.';
        }
        if (bidStartsAt >= bidEndsAt) {
          toast.warn('Starting date must be before expiration date for auction!');
          throw 'Auction starting date is greater than ending date.';
        }
        if (getDaysBetweenTwoDates(bidStartsAt, bidEndsAt) > 30) {
          toast.warn('The auction must not last longer than 30 days.');
          throw 'The auction must not last longer than 30 days.';
        }
      }
    }
  };

  const putItemOnSaleAndRedirect = async (item: Item): Promise<void> => {
    const purchaseLimitInput = getValues('purchaseLimit');
    const purchaseLimit = purchaseLimitInput ? parseInt(purchaseLimitInput) : undefined;

    const saleQuantityInput = getValues('saleQuantity');
    const saleQuantity = parseInt(saleQuantityInput);

    if (transactionType === TransactionType.FixedPrice) {
      const price = getValues('price');
      if (price) {
        const sale = await createSale(price, transactionCurrency, item.id, purchaseLimit);
        navigate(`/sale/${sale.id}`);
      }
    } else if (transactionType === TransactionType.Bid) {
      const bid = getValues('minimumBid');
      if (bid) {
        const auction = await createAuction(
          bid,
          item.id,
          saleQuantity,
          transactionCurrency,
          bidStartsAt.toString(),
          bidEndsAt.toString(),
          purchaseLimit,
        );
        navigate(`/auction/${auction.id}`);
      }
    }
  };

  const uploadUnlockableContent = async (item: Item): Promise<void> => {
    const text = getValues('unlockableContentText');

    if (!text && !unlockableContentFile) {
      toast.warn('Locked media file is required!');
      throw new Error('Unlockable content file or description is required!');
    }

    if (unlockableContentFile) {
      await uploadUnlockableContentToIpfs(item.id, unlockableContentFile);
    }

    if (text) {
      await updateItem({ lockedText: text }, item.id);
    }
  };

  const mapItemUsage = (ownershipType?: string, usage?: string, type?: string) => {
    if (ownershipType === OwnershipType.FullOwnership) {
      return undefined;
    }
    if (usage === 'private') {
      return 'private';
    } else if (type === 'single') {
      return 'commercial_single';
    } else {
      return 'commercial_multi';
    }
  };

  const submit = async (): Promise<void> => {
    if (file) {
      const item = await createItem({
        price: parseFloat(getValues('price') || '') || env.notForSaleTokenPrice,
        royalties: parseInt(getValues('royalties') || '') || 0,
        quantity: parseInt(getValues('quantity')),
        name: getValues('name'),
        file,
        previewImage: previewImageFile,
        metaData: {},
        legal: getValues('ownershipType'),
        chain: getCurrentChain(),
        usage: mapItemUsage(
          getValues('ownershipType'),
          getValues('itemUsage'),
          getValues('commercialUsageType'),
        ),
        oreChamberStreamingApproved:
          getValues('ownershipType') === OwnershipType.UsageWithinOreSystem
            ? getValues('oreChamberStreamingApproved')
            : undefined,
      });

      await updateItem(
        {
          displayName: getValues('name'),
          visibility: getValues('isPrivate') ? 'private' : 'public',
          description: getValues('description'),
          royalties: getValues('royalties'),
          categoryId: pickedCategory?.id,
          nsfw: getValues('nsfw'),
        },
        item.id,
      );

      if (
        getValues('unlockableContent') ||
        pickedCategory?.id === 'music' ||
        pickedCategory?.id === 'podcasts'
      ) {
        await uploadUnlockableContent(item);
      }

      if (getValues('putOnSale') && !getValues('isPrivate')) {
        await putItemOnSaleAndRedirect(item);
      } else {
        navigate(`/item/${item.id}`);
      }
    }
  };

  const renderSaleSubForm = () => {
    const itemQuantity = parseInt(getValues('quantity'));
    return (
      <div className={styles.priceInputContainer}>
        {transactionType === TransactionType.FixedPrice && (
          <>
            <UnderlinedInputControl
              title="Price"
              placeholder="Enter price for one piece"
              type="number"
              unit={
                <CurrencyDropdown
                  setValue={setTransactionCurrency}
                  value={transactionCurrency}
                  id={'create-item-price-currency-select'}
                />
              }
              {...register('price', {
                required: {
                  value: !!watch('putOnSale') && transactionType === TransactionType.FixedPrice,
                  message: 'Price is required!',
                },
                pattern: {
                  value: blockchainNumberRegex,
                  message: 'Price must be a number with maximum of 15 decimal places',
                },
              })}
              errorMessage={errors.price?.message}
              id={'create-item-price-input'}
            />
            <UnderlinedInputControl
              type="number"
              title="Quantity"
              placeholder="e.g. “1”"
              {...register('saleQuantity', {
                min: {
                  value: 1,
                  message: 'Minimum sale item quantity is 1!',
                },
                max: {
                  value: itemQuantity,
                  message: `You can't put more items than ${itemQuantity}.`,
                },
              })}
              errorMessage={errors.saleQuantity?.message}
              id={'create-item-quantity-input'}
            />
            <UnderlinedInputControl
              title="Purchase limit"
              type="number"
              placeholder="Enter how many items can user buy in one transaction (optional)"
              id={'create-item-purchase-limit-input'}
              {...register('purchaseLimit', {
                min: {
                  value: 1,
                  message: 'Purchase limit must be greater than 0!',
                },
              })}
              errorMessage={errors.purchaseLimit?.message}
            />
          </>
        )}

        {transactionType === TransactionType.Bid && (
          <>
            <UnderlinedInputControl
              title="Minimum bid"
              type="number"
              placeholder="Enter minimum bid in ORE"
              {...register('minimumBid', {
                required: {
                  value: !!watch('putOnSale') && transactionType === TransactionType.Bid,
                  message: 'Minimum bid is required!',
                },
                pattern: {
                  value: blockchainNumberRegex,
                  message: 'Minimum bid must be a number with maximum of 15 decimal places',
                },
              })}
              errorMessage={errors.minimumBid?.message}
              id={'create-item-minimum-bid-input'}
            />
            <UnderlinedInputControl
              type="number"
              title="Quantity"
              placeholder="e.g. “1”"
              {...register('saleQuantity', {
                min: {
                  value: 1,
                  message: 'Minimum auction item quantity is 1!',
                },
                max: {
                  value: itemQuantity,
                  message: `You can't put more items than ${itemQuantity}.`,
                },
              })}
              errorMessage={errors.saleQuantity?.message}
              id={'create-item-quantity-input'}
            />
            <UnderlinedInputControl
              title="Purchase limit"
              type="number"
              placeholder="Enter how many items can user buy in one transaction (optional)"
              id={'create-item-purchase-limit-input'}
              {...register('purchaseLimit', {
                min: {
                  value: 1,
                  message: 'Purchase limit must be greater than 0!',
                },
              })}
              errorMessage={errors.purchaseLimit?.message}
            />
            <div className={styles.bidDateRow}>
              <DatePicker
                dropdownValues={startsAtAllowedValues}
                value={bidStartsAt}
                setValue={setBidStartsAt}
                title={'Starting date'}
                id={'create-item-auction-starting-date-dropdown'}
              />
              <DatePicker
                dropdownValues={endsAtAllowedValues}
                value={bidEndsAt}
                setValue={setBidEndsAt}
                title={'Expiration date'}
                id={'create-item-auction-ending-date-dropdown'}
                maxValue={maxEndsAtDate}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderMainFileTitle = (): string => {
    switch (pickedCategory?.id) {
      case 'music':
      case 'podcasts':
        return 'Album Cover';
      default:
        return 'Upload file';
    }
  };

  const getAcceptedFileTypesTitle = (): string => {
    switch (pickedCategory?.id) {
      case 'music':
      case 'podcasts':
        return 'PNG, JPEG';
      default:
        return 'PNG, JPEG, GIF, WEBP, MP4 or MP3';
    }
  };

  const isEasyMintForGraphics =
    !isInAdvancedMode && (!file || [PNG_MIME_TYPE, JPEG_MIME_TYPE].includes(file?.type));

  const ChainButton = ({
    title,
    logo,
    currency,
    onClick,
  }: {
    title: string;
    logo: string;
    currency: 'eth' | 'bsc' | 'polygon';
    onClick: () => void;
  }) => (
    <Button
      buttonStyle={ButtonStyle.second}
      onClick={onClick}
      isActive={currentChainName === currency}
      id={`chain-button-${currency}`}
    >
      <div className={styles.buttonContent}>
        <img src={logo} />
        {title}
      </div>
    </Button>
  );

  const ButtonWithChainChange = WithChainChange(ChainButton);

  return (
    <div className={styles.container}>
      <div className={styles.titleToggleContainer}>
        <Typography.Heading fontSize={36} fontWeight={700} paddingBottom={10}>
          Create single Forge NFT
        </Typography.Heading>
      </div>

      <div className={styles.contentContainer}>
        <PreviewColumn
          putOnSale={!!watch('putOnSale')}
          currency={transactionCurrency}
          name={watch('name')}
          transactionType={transactionType}
          previewImage={isPreviewFileRequired ? previewImageFile : previewImageFile || file}
          quantity={parseInt(watch('quantity'))}
          price={parseFloat(watch('price') || '') || 0}
          bid={parseFloat(watch('minimumBid') || '') || 0}
          mainFile={file}
          unlockableFile={unlockableContentFile}
          removeMainFile={(): void => setFile(undefined)}
          removeUnlockableFile={(): void => setUnlockableContentFile(undefined)}
          description={watch('description')}
        />
        <div></div>
        {isSubmitting && (
          <GenericPopup title="Please wait" canBeClosed={false} id={'create-item-submit-popup'}>
            <p className={styles.gradientText}>
              NFT is being created. For bigger files it might take some time. Please stay on this
              page.
            </p>
            <div className={styles.barLoader}>
              <BarLoader color={COLORS.ORANGE} height={5} width={200} />
            </div>
          </GenericPopup>
        )}
        <div className={styles.formColumn}>
          <div className={styles.easyMintToggle}>
            <label
              className={classNames(styles.easyMintOption, {
                [styles.active]: !isInAdvancedMode,
              })}
            >
              <input
                id={'easyMintMode'}
                type="radio"
                onChange={() => setAdvancedMode(!isInAdvancedMode)}
                checked={!isInAdvancedMode}
              />
              <div className={styles.easyMintInput}>
                <div></div>
              </div>
              <span>Easy Mint</span>
            </label>

            <label
              className={classNames(styles.easyMintOption, {
                [styles.active]: isInAdvancedMode,
              })}
            >
              <input
                id={'advancedMode'}
                type="radio"
                onChange={() => setAdvancedMode(!isInAdvancedMode)}
                checked={isInAdvancedMode}
              />
              <div className={styles.easyMintInput}>
                <div></div>
              </div>
              <span>Advanced</span>
            </label>
          </div>
          <div className={styles.chainButtonsContainer}>
            <div className={styles.chainButtonsHeader}>
              <p className="gradientText">Pick chain</p>
            </div>
            <div className={styles.chainButtons}>
              <ButtonWithChainChange
                chainId={env.binanceChainId}
                logo={BscLogo}
                title="Binance"
                currency="bsc"
              />
              <ButtonWithChainChange
                chainId={env.ethereumChainId}
                logo={EthLogo}
                title="Ethereum"
                currency="eth"
              />
              <ButtonWithChainChange
                chainId={env.polygonChainId}
                logo={PolyLogo}
                title="Polygon"
                currency="polygon"
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <CategoryPicker pickedCategory={pickedCategory} setPickedCategory={setPickedCategory} />

            <div className={styles.fileInputContainer}>
              <p>{renderMainFileTitle()}</p>
              <FileInput
                tooltipText={getAcceptedFileTypesTitle()}
                onDrop={onMainFileDrop}
                accept={
                  pickedCategory?.id === 'music' || pickedCategory?.id === 'podcasts'
                    ? ACCEPTED_IMAGE_MIME_TYPES
                    : undefined
                }
                id={'create-item-main-file-input'}
              />
            </div>

            {pickedCategory?.id !== 'music' &&
              pickedCategory?.id !== 'podcasts' &&
              (isInAdvancedMode || !isEasyMintForGraphics || pixelCount > 8000000) && (
                <div className={styles.fileInputContainer}>
                  <p>
                    Preview image{!isPreviewFileRequired && ' (optional)'}
                    <span className={styles.previewImageInfo}>
                      It will be displayed in the card. Maximum accepted size is{' '}
                      <strong>4 MiB</strong>. Consider cropping image to fit 244x244px container.
                    </span>
                  </p>

                  <FileInput
                    tooltipText="PNG, JPEG"
                    accept={ACCEPTED_IMAGE_MIME_TYPES}
                    onDrop={onPreviewImageFileDrop}
                    id={'create-item-preview-image-input'}
                  />
                  <p>The image that is shown in the list of all items.</p>
                </div>
              )}

            {pickedCategory?.id === 'music' || pickedCategory?.id === 'podcasts' ? (
              <div className={styles.mediaFileContainer}>
                <p>Media (locked)</p>
                <FileInput
                  tooltipText="MP3"
                  accept={ACCEPTED_AUDIO_MIME_TYPES}
                  onDrop={onMusicFileDrop}
                  id={'create-item-unlockable-music-file-input'}
                />
              </div>
            ) : isInAdvancedMode ? (
              <>
                <div>
                  {/* Unlockable content */}
                  <ToggleInputControl
                    title="Unlockable content"
                    description={'Content will be unlocked after successful transaction.'}
                    {...register('unlockableContent')}
                    id={'create-item-unlockable-content-checkbox'}
                  />
                  {watch('unlockableContent') && (
                    <div className={styles.unlockableContentContainer}>
                      <FileInput
                        tooltipText="PNG, JPEG, GIF, WEBP, MP4 or MP3"
                        onDrop={onUnlockableFileDrop}
                        id={'create-item-unlockable-file-input'}
                      />
                      <UnderlinedInputControl
                        type="text"
                        title=""
                        placeholder="Digital key, code to redeem or link to a file"
                        {...register('unlockableContentText', {
                          required: {
                            value: watch('unlockableContent') && !unlockableContentFile,
                            message: 'Either unlockable content file or text is required!',
                          },
                        })}
                        errorMessage={errors.unlockableContentText?.message}
                        id={'create-item-unlockable-content-text-input'}
                      />
                    </div>
                  )}
                </div>
                <ToggleInputControl
                  title="Private"
                  description="Visible only to you"
                  {...register('isPrivate')}
                  id={'create-item-private-checkbox'}
                />
                {!watch('isPrivate') && (
                  <>
                    <ToggleInputControl
                      title="Put on sale"
                      description={'You\'ll receive bids on this item'}
                      {...register('putOnSale')}
                      id={'create-item-put-on-sale-checkbox'}
                    />

                    {watch('putOnSale') && (
                      <>
                        <div className={styles.saleTypeSwitcher}>
                          <SaleTypeOption
                            text="Fixed price"
                            onClick={(): void => setTransactionType(TransactionType.FixedPrice)}
                            chosen={transactionType === TransactionType.FixedPrice}
                            icon={<FixedPriceIcon />}
                          />
                          {currentCurrency === 'bnb' && (
                            <SaleTypeOption
                              text="Ongoing auction"
                              onClick={(): void => setTransactionType(TransactionType.Bid)}
                              chosen={transactionType === TransactionType.Bid}
                              icon={<BidIcon />}
                            />
                          )}
                        </div>
                        {renderSaleSubForm()}
                      </>
                    )}
                  </>
                )}
                <ToggleInputControl
                  title="NSFW"
                  description="Is token image not safe to display"
                  {...register('nsfw')}
                  id={'create-item-nsfw-checkbox'}
                />
              </>
            ) : null}

            <div className={styles.textInputsContainer}>
              <UnderlinedInputControl
                type="text"
                title="Name"
                placeholder="e.g. “Game item”"
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Name is required!',
                  },
                })}
                errorMessage={errors.name?.message}
                id={'create-item-name-input'}
              />

              <UnderlinedTextAreaControl
                // type='text'
                title="Description"
                placeholder="e.g. “When owning, you can use this item in game”"
                {...register('description', {
                  required: {
                    value: true,
                    message: 'Description is required!',
                  },
                })}
                errorMessage={errors.description?.message}
                id={'create-item-description-input'}
                bottomNote={'You can use Markdown syntax.'}
              />

              <UnderlinedInputControl
                type="number"
                title="Royalties"
                placeholder="e.g. “10%”"
                unit={<span>%</span>}
                {...register('royalties', {
                  min: {
                    value: 0,
                    message: 'You cannot choose a negative royalties value!',
                  },
                })}
                errorMessage={errors.royalties?.message}
                id={'create-item-royalties-input'}
              />
              <UnderlinedInputControl
                type="number"
                title="Item quantity"
                placeholder="e.g. “1”"
                {...register('quantity', {
                  required: {
                    value: true,
                    message: 'Item quantity is required!',
                  },
                  min: {
                    value: 1,
                    message: 'Minimum item quantity is 1!',
                  },
                })}
                errorMessage={errors.quantity?.message}
                id={'create-audio-item-quantity-input'}
              />
            </div>

            <div className={styles.ownershipContainer}>
              <div className={styles.ownershipToggle}>
                <label
                  className={classNames(styles.ownershipOption, {
                    [styles.active]: watch('ownershipType') === OwnershipType.FullOwnership,
                  })}
                  htmlFor={OwnershipType.FullOwnership}
                >
                  <input
                    id={OwnershipType.FullOwnership}
                    type="radio"
                    value={OwnershipType.FullOwnership}
                    {...register('ownershipType')}
                  />
                  <div className={styles.ownershipInput}>
                    <div></div>
                  </div>
                  <span>Full ownership of digital Asset</span>
                </label>

                <label
                  className={classNames(styles.ownershipOption, {
                    [styles.active]: watch('ownershipType') === OwnershipType.UsageWithinOreSystem,
                  })}
                >
                  <input
                    id={OwnershipType.UsageWithinOreSystem}
                    type="radio"
                    value={OwnershipType.UsageWithinOreSystem}
                    {...register('ownershipType')}
                  />
                  <div className={styles.ownershipInput}>
                    <div></div>
                  </div>
                  <span>Usage within the ORE System</span>
                </label>
              </div>
              {watch('ownershipType') === OwnershipType.UsageWithinOreSystem && (
                <div className={styles.ownershipToggle}>
                  <label
                    className={classNames(styles.ownershipOption, {
                      [styles.active]: watch('itemUsage') === 'private',
                    })}
                    htmlFor={'private'}
                  >
                    <input
                      id={'private'}
                      type="radio"
                      value={'private'}
                      {...register('itemUsage')}
                    />
                    <div className={styles.ownershipInput}>
                      <div></div>
                    </div>
                    <span>Private Use</span>
                  </label>

                  <label
                    className={classNames(styles.ownershipOption, {
                      [styles.active]: watch('itemUsage') === 'commercial',
                    })}
                  >
                    <input
                      id={'commercial'}
                      type="radio"
                      value={'commercial'}
                      {...register('itemUsage')}
                    />
                    <div className={styles.ownershipInput}>
                      <div></div>
                    </div>
                    <span>Commercial Use</span>
                  </label>
                </div>
              )}
              {watch('ownershipType') === OwnershipType.UsageWithinOreSystem &&
                watch('itemUsage') === 'commercial' && (
                  <div className={styles.ownershipToggle}>
                    <label
                      className={classNames(styles.ownershipOption, {
                        [styles.active]: watch('commercialUsageType') === 'single',
                      })}
                      htmlFor={'single'}
                    >
                      <input
                        id={'single'}
                        type="radio"
                        value={'single'}
                        {...register('commercialUsageType')}
                      />
                      <div className={styles.ownershipInput}>
                        <div></div>
                      </div>
                      <span>Single Entity</span>
                    </label>

                    <label
                      className={classNames(styles.ownershipOption, {
                        [styles.active]: watch('commercialUsageType') === 'multi',
                      })}
                    >
                      <input
                        id={'multi'}
                        type="radio"
                        value={'multi'}
                        {...register('commercialUsageType')}
                      />
                      <div className={styles.ownershipInput}>
                        <div></div>
                      </div>
                      <span>Multi-Entity</span>
                    </label>
                  </div>
                )}
              {watch('ownershipType') === OwnershipType.UsageWithinOreSystem ? (
                <p>{licenseTexts?.usageWithinOre}</p>
              ) : (
                <p>{licenseTexts?.fullOwnership}</p>
              )}
              {watch('ownershipType') === OwnershipType.UsageWithinOreSystem &&
                (watch('itemUsage') === 'private' ? (
                  <p>{licenseTexts?.privateUse}</p>
                ) : (
                  <p>{licenseTexts?.commercialUse}</p>
                ))}
              {watch('ownershipType') === OwnershipType.UsageWithinOreSystem &&
                watch('itemUsage') === 'commercial' &&
                (watch('commercialUsageType') === 'single' ? (
                  <p>{licenseTexts?.commercialUseSingle}</p>
                ) : (
                  <p>{licenseTexts?.commercialUseMulti}</p>
                ))}
            </div>
            {watch('ownershipType') === OwnershipType.UsageWithinOreSystem && (
              <div>
                <ToggleInputControl
                  title="Approved for ORE Chamber Streaming"
                  description=""
                  {...register('oreChamberStreamingApproved')}
                  id={'approved-for-ore-chamber-streming-checkbox'}
                />
              </div>
            )}
            <div>
              {/* Liability release check */}
              <ToggleInputControl
                title="Acknowledgement"
                description=""
                {...register('liabilityRelease', {
                  required: {
                    value: true,
                    message: 'You have to accept the terms to create the item!',
                  },
                })}
                errorMessage={errors.liabilityRelease?.message}
                id={'create-item-acknowledgement-checkbox'}
              />
              <p className={styles.liabilityReleaseDescription}>
                By minting this item I that I have full indemnify and hold harmless Ore System and
                our past, present and future employees, officers, directors, equity holders,
                affiliates, and agents from and against all actual or alleged third party claims,
                damages, awards, judgments, losses, liabilities, obligations, penalties, interest,
                fees, expenses (including, without limitation, attorneys’ fees and expenses) and
                costs (including, without limitation, court costs, costs of settlement and costs of
                pursuing indemnification and insurance), of every kind and nature whatsoever, that
                are caused by, arise out of or are related to (a) your use or misuse of the services
                provided by Ore System, (b) your violation of these terms, or (c) your violation of
                the intellectual property rights of any third party.
              </p>
            </div>

            <div className={styles.submitContainer}>
              <Button
                type="submit"
                buttonStyle={ButtonStyle.color}
                onClick={handleSubmit(
                  async () => {
                    try {
                      validateFileInputs();
                      validateSaleInputs();
                      await submit();
                    } catch (e) {
                      console.error('Could not successfully submit the create nft form.');
                      console.error(e);
                      toast.warn('Your form contains mistakes. Correct it and try again.');
                    }
                  },
                  () => {
                    toast.warn('Your form contains mistakes. Correct it and try again.');
                  },
                )}
                id={'create-item-submit-button'}
              >
                Create item
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

interface SaleTypeOptionProps {
  chosen?: boolean;
  onClick: () => void;
  text: string;
  icon: JSX.Element;
}

const SaleTypeOption = ({ onClick, text, chosen, icon }: SaleTypeOptionProps) => (
  <div
    className={classNames(styles.saleTypeSwitch, { [styles.chosenSaleType]: chosen })}
    onClick={onClick}
  >
    <div>{icon}</div>
    <p>{text}</p>
  </div>
);
