import { useNavigate, useParams } from 'react-router-dom';

import { LoadingPagePlaceholder } from '../../components/common/loading-page-placeholder/loading-page-placeholder';
import { useCollection } from '../../services/collection-service';
import { CollectionComponent } from './collection-component';

export const CollectionPage = (): JSX.Element => {
  const { id } = useParams();
  const { data: collection, error } = useCollection(id);
  const navigate = useNavigate();

  if (error) {
    navigate('/not-found');
  }

  if (collection === undefined) {
    return <LoadingPagePlaceholder />;
  }

  return <CollectionComponent collection={collection} />;
};
