import { useState } from 'react';

import { SetOfColumnsValues } from 'types/music-page';

import styles from './track-card.module.css';
import { TrackCardContent } from './track-card-elements/tad-tpd-track-card-content';

export interface ITrackCardProps {
  setOfColumns: SetOfColumnsValues;
  queuePosition: string;
  author: string;
  authorURL: string;
  songTitle: string;
  albumURL: string;
  isPlaying: boolean;
  duration: string;
  albumName: string;
  price: string;
  albumImage?: string;
  lastColumn?: JSX.Element;
  songId: string;
  playlistId?: string;
}

export const TrackCard = (props: ITrackCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.rowWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TrackCardContent
        isHovered={isHovered}
        price={props.price}
        albumName={props.albumName}
        setOfColumns={props.setOfColumns}
        duration={props.duration}
        queuePosition={props.queuePosition}
        author={props.author}
        authorURL={props.authorURL}
        albumURL={props.albumURL}
        isPlaying={props.isPlaying}
        songTitle={props.songTitle}
        albumImage={props.albumImage}
        lastColumn={props.lastColumn}
        songId={props.songId}
        playlistId={props.playlistId}
      />
    </div>
  );
};
