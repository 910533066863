import { useState } from 'react';
import { ReactElement } from 'react-markdown';

import {
  addDays,
  addHours,
  getDaysBetweenTwoDates,
  getHoursBetweenTwoDates,
  getMinutesBetweenTwoDates,
} from '../../../utils/date-helpers';
import { CustomDateTimePickerPopup } from '../custom-date-time-picker/custom-date-time-picker-popup';
import { Dropdown } from '../dropdown';
import styles from './date-picker.module.css';

export enum RelativeDate {
  Now = 'now',
  OneHour = 'one-hour',
  TwelveHours = 'twelve-hours',
  OneDay = 'one-day',
  TwoDays = 'two-days',
  Custom = 'custom',
}

interface DatePickerProps {
  dropdownValues: Array<RelativeDate>;
  value: Date;
  setValue: (date: Date) => void;
  title: string;
  id: string;
  maxValue?: Date;
}

export const DatePicker = ({
  dropdownValues,
  value,
  setValue,
  title,
  id,
  maxValue,
}: DatePickerProps): ReactElement => {
  const [isCustomDatePopupOpen, setIsCustomDatePopupOpen] = useState(false);

  const chooseDate = (val: RelativeDate): void => {
    setValue(relativeDateToDate(val));
  };

  const openDatePickerPopup = (): void => {
    setIsCustomDatePopupOpen(true);
  };

  return (
    <>
      <div className={styles.datePickerContainer}>
        <p>{title}</p>
        <Dropdown
          selected={dateToDisplayName(value)}
          isWide
          id={id}
          options={dropdownValues.map((val) => {
            return (
              <div
                key={val}
                onClick={
                  val === RelativeDate.Custom ? openDatePickerPopup : (): void => chooseDate(val)
                }
                id={`${id}-${val.toString()}`}
              >
                {relativeDateToDisplayName(val)}
              </div>
            );
          })}
        />
      </div>

      {isCustomDatePopupOpen && (
        <CustomDateTimePickerPopup
          close={(): void => setIsCustomDatePopupOpen(false)}
          value={value}
          setDateTime={setValue}
          minDate={new Date()}
          maxDate={maxValue}
        />
      )}
    </>
  );
};

/* ====== Helper functions ====== */

const relativeDateToDisplayName = (date: RelativeDate): string => {
  switch (date) {
    case RelativeDate.Now:
      return 'Right after listing';
    case RelativeDate.OneHour:
      return 'One hour from now';
    case RelativeDate.TwelveHours:
      return 'Twelve hours from now';
    case RelativeDate.OneDay:
      return 'One day from now';
    case RelativeDate.TwoDays:
      return 'Two days from now';
    case RelativeDate.Custom:
      return 'Custom date and time';
  }
};

const relativeDateToDate = (date: RelativeDate): Date => {
  const now = new Date();
  switch (date) {
    case RelativeDate.Now:
      return now;
    case RelativeDate.OneHour:
      return addHours(now, 1);
    case RelativeDate.TwelveHours:
      return addHours(now, 12);
    case RelativeDate.OneDay:
      return addDays(now, 1);
    case RelativeDate.TwoDays:
      return addDays(now, 2);
    case RelativeDate.Custom:
      return now;
  }
};

const dateToDisplayName = (date: Date): string => {
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'always', style: 'long' });

  const now = new Date();
  const minutes = getMinutesBetweenTwoDates(now, date);
  const hours = getHoursBetweenTwoDates(now, date);
  const days = getDaysBetweenTwoDates(now, date);

  if (days > 2) {
    return date.toLocaleString();
  } else if (hours >= 24) {
    return rtf.format(days, 'day');
  } else {
    if (hours > 0) {
      return rtf.format(hours, 'hour');
    } else if (minutes > 0) {
      return rtf.format(minutes, 'minute');
    } else {
      return 'Right after listing.';
    }
  }
};
