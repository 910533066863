import { createContext } from 'react';

import { useProperties } from 'services/properties-service';

interface LicenseContextType {
  licenseTexts?: {
    fullOwnership?: string;
    usageWithinOre?: string;
    privateUse?: string;
    commercialUse?: string;
    commercialUseSingle?: string;
    commercialUseMulti?: string;
  };
}

export const LicenseContext = createContext<LicenseContextType>({
  licenseTexts: undefined,
});

export const LicenseContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { data: licenses } = useProperties();
  const licenseTexts = {
    fullOwnership: licenses?.find((license) => license.name === 'license.full_ownership.desc')
      ?.value,
    usageWithinOre: licenses?.find((license) => license.name === 'license.within_ore.desc')?.value,
    privateUse: licenses?.find((license) => license.name === 'license.private_use.desc')?.value,
    commercialUse: licenses?.find((license) => license.name === 'license.commercial_use.desc')
      ?.value,
    commercialUseSingle: licenses?.find(
      (license) => license.name === 'license.commercial_use.single_entity',
    )?.value,
    commercialUseMulti: licenses?.find(
      (license) => license.name === 'license.commercial_use.multi_entity',
    )?.value,
  };

  return (
    <LicenseContext.Provider
      value={{
        licenseTexts: licenseTexts,
      }}
    >
      {children}
    </LicenseContext.Provider>
  );
};
