import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Category, useCategories } from '../../../services/categories-service';
import { useItemsByCategoryIdWithPagination } from '../../../services/item-service';
import { getDropdownOptionDisplayName } from '../../../utils/dropdown-option-display-name';
import styles from './browse-by-category.module.css';

export const BrowseByCategory = (): JSX.Element => {
  const { data: categories } = useCategories();

  return (
    <div className={styles.categoriesContainer}>
      <h2>Browse by category</h2>
      <div className={styles.categoriesList}>
        {(categories || []).map((category) => (
          <CategoryCard category={category} key={`category-link-${category.id}`} />
        ))}
      </div>
    </div>
  );
};

interface CategoryCardProps {
  category: Category;
}

const CategoryCard = ({ category }: CategoryCardProps): JSX.Element => {
  const { items: categoryItems } = useItemsByCategoryIdWithPagination(category.id, 25);

  const filteredItems = useMemo(
    () =>
      categoryItems
        .filter((item) => item.previewImageUrl && item.visibility === 'public')
        .slice(0, 3),
    [categoryItems],
  );

  const renderCategoryImages = (): JSX.Element[] => {
    return filteredItems.map((item) => <img key={item.id} src={item.previewImageUrl} alt="img" />);
  };

  if (filteredItems.length === 0) {
    return <></>;
  } else {
    return (
      <Link
        className={styles.categoryCard}
        to={`/explore?categoryId=${category.id}`}
        key={`category-link-${category.id}`}
      >
        <span>{getDropdownOptionDisplayName(category.id)}</span>

        <div className={styles.categoryCardImgWrapper}>{renderCategoryImages()}</div>
      </Link>
    );
  }
};
