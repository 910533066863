import styles from './carousel-placeholders.module.css';

interface Props {
  text: string;
}

export const NoItemsPlaceholder = ({ text }: Props): JSX.Element => {
  return (
    <div className={styles.noItemsContainer}>
      <p>{text}</p>
    </div>
  );
};
