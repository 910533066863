import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ChangeHandler, FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as FacebookLogo } from '../../assets/icons/facebook-page.svg';
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram-page.svg';
import { ReactComponent as TwitterLogo } from '../../assets/icons/twitter-page.svg';
import { ReactComponent as PageLogo } from '../../assets/icons/web-page.svg';
import { ReactComponent as YoutubeLogo } from '../../assets/icons/youtube-page.svg';
import { Button, ButtonStyle } from '../../components/common/button/button';
import { MarkdownDisplay } from '../../components/common/markdown-display/markdown-display';
import { UnderlinedInputControl } from '../../components/common/underlined-input/underlined-input';
import { UnderlinedTextAreaControl } from '../../components/common/underlined-input/underlined-text-area';
import { UserProfile, UserProfileRequest } from '../../services/user-service';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  JPEG_MIME_TYPE,
  PNG_MIME_TYPE,
} from '../../utils/accepted-image-types';
import styles from './profile-form.module.css';

const httpUrlRegex =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

// eslint-disable-next-line no-useless-escape
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

interface Props {
  userProfile?: UserProfile;
  submit: (user: UserProfileRequest, profileAvatar?: File, profileBanner?: File) => Promise<void>;
  walletAddress: string;
}

const validateAndSetFile = (
  files: Array<File>,
  setFileUrl: (url: string) => void,
  setFile: (file: File) => void,
): void => {
  if (files.length === 0) {
    console.error('no files specified');
  }
  const file = files[0];

  if (file.type !== JPEG_MIME_TYPE && file.type !== PNG_MIME_TYPE) {
    toast.warn('wrong image type, accepted are .jpg and .png');
  }
  setFile(file);
  const reader = new FileReader();

  reader.onload = (event): void => {
    if (event.target && event.target.result) {
      setFileUrl(event.target.result as string);
    }
  };

  reader.readAsDataURL(file);
};

interface FormInputs {
  name: string;
  email: string;
  bio: string;
  websiteAddress: string;
  facebookAccount: string;
  twitterAccount: string;
  instagramAccount: string;
  youtubeChannel: string;
}

export const ProfileForm = ({ userProfile, submit }: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: {
      name: userProfile?.displayName === null ? undefined : userProfile?.displayName,
      bio: userProfile?.bio === null ? undefined : userProfile?.bio,
      email: userProfile?.email === null ? undefined : userProfile?.email,
      websiteAddress: userProfile?.websiteUrl === null ? undefined : userProfile?.websiteUrl,
      facebookAccount: userProfile?.facebook === null ? undefined : userProfile?.facebook,
      twitterAccount: userProfile?.twitter === null ? undefined : userProfile?.twitter,
      instagramAccount: userProfile?.instagram === null ? undefined : userProfile?.instagram,
      youtubeChannel:
        userProfile?.youtubeChannel === null ? undefined : userProfile?.youtubeChannel,
    },
  });

  const name = watch('name');
  const email = watch('email');
  const bio = watch('bio');
  const websiteAddress = watch('websiteAddress');
  const facebookAccount = watch('facebookAccount');
  const twitterAccount = watch('twitterAccount');
  const instagramAccount = watch('instagramAccount');
  const youtubeChannel = watch('youtubeChannel');

  const [profileImageUrl, setProfileImageUrl] = useState<string>(
    userProfile?.profileAvatarUrl || '/av-placeholder.jpg',
  );
  const [bannerImageUrl, setBannerImageUrl] = useState<string>(
    userProfile?.profileBannerUrl || '/bg-placeholder.png',
  );

  const [profileImage, setProfileImage] = useState<File>();
  const [bannerImage, setBannerImage] = useState<File>();

  const onProfileImageDrop = useCallback((acceptedFiles: Array<File>) => {
    validateAndSetFile(acceptedFiles, setProfileImageUrl, setProfileImage);
  }, []);

  const onBannerImageDrop = useCallback((acceptedFiles: Array<File>) => {
    validateAndSetFile(acceptedFiles, setBannerImageUrl, setBannerImage);
  }, []);

  const { getRootProps: getProfileImageRootProps, getInputProps: getProfileImageInputProps } =
    useDropzone({ onDrop: onProfileImageDrop });
  const { getRootProps: getBannerImageRootProps, getInputProps: getBannerImageInputProps } =
    useDropzone({ onDrop: onBannerImageDrop });

  useEffect(() => {
    // reload states on userProfile reload, refresh page case
    setProfileImageUrl((value) => userProfile?.profileAvatarUrl || value);
    setBannerImageUrl((value) => userProfile?.profileBannerUrl || value);
  }, [userProfile]);

  const navigate = useNavigate();

  const validateAndSubmit = async (): Promise<void> => {
    const newUserProfile: UserProfileRequest = {
      ...userProfile,
      displayName: name,
      bio: bio,
      email: email,
      facebook: facebookAccount,
      twitter: twitterAccount,
      instagram: instagramAccount,
      youtubeChannel: youtubeChannel,
      websiteUrl: websiteAddress,
    };

    await submit(newUserProfile, profileImage, bannerImage);
    navigate('/current-user');
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputsColumn}>
        <h1 className={styles.pageTitle}>Edit profile</h1>
        <UnderlinedInputControl
          title="Display name"
          placeholder="name"
          type="text"
          id={'edit-profile-display-name-input'}
          {...register('name')}
        />
        <UnderlinedInputControl
          title="Email"
          placeholder="email@example.com"
          type="text"
          id={'edit-profile-email-input'}
          {...register('email', {
            pattern: {
              value: emailRegex,
              message: 'You must enter a valid email',
            },
          })}
          errorMessage={errors.email?.message}
        />
        <UnderlinedTextAreaControl
          title="Bio"
          placeholder="lorem ipsum"
          id={'edit-profile-bio-input'}
          {...register('bio')}
          bottomNote={'You can use Markdown syntax.'}
        />

        <div className={styles.verification}></div>
        <div className={styles.links}>
          <LinkInput
            placeholder="Your website address"
            icon={<PageLogo />}
            id={'edit-profile-website-address-input'}
            {...register('websiteAddress', {
              pattern: {
                value: httpUrlRegex,
                message: 'You must enter a valid url',
              },
            })}
            errorMessage={errors.websiteAddress?.message}
          />
          <LinkInput
            placeholder="Twitter account address"
            icon={<TwitterLogo />}
            id={'edit-profile-twitter-address-input'}
            {...register('twitterAccount', {
              pattern: {
                value: httpUrlRegex,
                message: 'You must enter a valid url',
              },
            })}
            errorMessage={errors.twitterAccount?.message}
          />
          <LinkInput
            placeholder="Facebook account address"
            icon={<FacebookLogo />}
            id={'edit-profile-facebook-address-input'}
            {...register('facebookAccount', {
              pattern: {
                value: httpUrlRegex,
                message: 'You must enter a valid url',
              },
            })}
            errorMessage={errors.facebookAccount?.message}
          />
          <LinkInput
            placeholder="Instagram account address"
            icon={<InstagramLogo />}
            id={'edit-profile-instagram-address-input'}
            {...register('instagramAccount', {
              pattern: {
                value: httpUrlRegex,
                message: 'You must enter a valid url',
              },
            })}
            errorMessage={errors.instagramAccount?.message}
          />
          <LinkInput
            placeholder="Youtube channel address"
            icon={<YoutubeLogo />}
            id={'edit-profile-youtube-address-input'}
            {...register('youtubeChannel', {
              pattern: {
                value: httpUrlRegex,
                message: 'You must enter a valid url',
              },
            })}
            errorMessage={errors.youtubeChannel?.message}
          />
        </div>

        <Button
          type="submit"
          buttonStyle={ButtonStyle.color}
          onClick={handleSubmit(validateAndSubmit, () => toast.warn('Please verify form'))}
          id={'edit-profile-submit-button'}
        >
          Update profile
        </Button>
      </div>
      <div className={styles.blankColumn}></div>
      <div className={styles.imagesColumn}>
        <p>Profile image</p>
        <div className={styles.profileImage} {...getProfileImageRootProps()}>
          <img src={profileImageUrl} alt="avatar" />
          <EditIcon className={styles.imageEditIcon} />
          <input
            {...getProfileImageInputProps()}
            accept={ACCEPTED_IMAGE_MIME_TYPES}
            id={'edit-profile-profile-image-input'}
          />
        </div>
        <p>Profile banner</p>
        <div className={styles.profileBanner} {...getBannerImageRootProps()}>
          <img src={bannerImageUrl} alt="banner" />
          <EditIcon className={styles.imageEditIcon} />
          <input
            {...getBannerImageInputProps()}
            accept={ACCEPTED_IMAGE_MIME_TYPES}
            id={'edit-profile-profile-banner-input'}
          />
        </div>
        <p>Bio preview:</p>
        <MarkdownDisplay text={watch('bio')} />
      </div>
    </div>
  );
};

interface LinkInputProps {
  icon: JSX.Element;
  placeholder: string;
  id: string;
  name: string; // FormControl registered name
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  errorMessage?: string;
}

// TODO: color icon on input focus
const LinkInput = React.forwardRef<
  HTMLInputElement,
  LinkInputProps & ReturnType<UseFormRegister<FieldValues>>
>(({ icon, placeholder, name, errorMessage, onChange, onBlur, id }: LinkInputProps, ref) => (
  <div className={styles.linkInput}>
    {icon}
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      onWheel={(e): void => e.currentTarget.blur()}
      id={id}
    ></input>
    <span className="inputValidationError">{errorMessage}</span>
  </div>
));
LinkInput.displayName = 'LinkInput';
