export const addHours = (date: Date, hours: number): Date => {
  const newDate = new Date(date.getTime());
  newDate.setHours(date.getHours() + hours);

  return newDate;
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + days);

  return newDate;
};

export const getTomorrowDate = (): Date => {
  return addDays(new Date(), 1);
};

export const getDaysBetweenTwoDates = (date1: Date, date2: Date): number => {
  const differenceInMs = date2.getTime() - date1.getTime();
  return Math.round(differenceInMs / (1000 * 60 * 60 * 24));
};

export const getHoursBetweenTwoDates = (date1: Date, date2: Date): number => {
  const differenceInMs = date2.getTime() - date1.getTime();
  return Math.round(differenceInMs / (1000 * 60 * 60));
};

export const getMinutesBetweenTwoDates = (date1: Date, date2: Date): number => {
  const differenceInMs = date2.getTime() - date1.getTime();
  return Math.round(differenceInMs / (1000 * 60));
};
