import { useContext } from 'react';
import axios from 'axios';
import { env } from 'env/env';
import useSWR, { KeyedMutator, SWRResponse } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { AuthContext } from 'context/auth-context';
import { objectToFormData } from 'utils/object-to-form-data';

import { dataFetcher } from './data-fetcher';
import { baseHeaders, getAuthHeader } from './headers';

export interface Item {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstOwnerWalletId: string;
  collectionId?: string;
  displayName: string;
  description: string;
  categoryId: string;
  contractAddress: string;
  royalties?: string;
  mediaIpfsUrl: string;
  lockedMediaIpfsUrl?: string;
  hasLockedMedia: boolean;
  nsfw: boolean;
  visibility: 'public' | 'private';
  previewImageUrl: string;
  watermarkedImageUrl: string;
  likesCount: number;
  streamPreviewUrl?: string;
  mediaLengthSeconds?: string;
  chain?: 'eth' | 'bsc' | 'polygon';
  legal?: string;
  usage?: string;
  oreChamberStreamingApproved?: boolean;
}

export interface ItemRequestBody {
  displayName?: string;
  description?: string;
  royalties?: string;
  categoryId?: string;
  nsfw?: boolean;
  visibility?: 'public' | 'private';
  previewImage?: File;
  lockedMediaIpfsId?: string;
  lockedText?: string;
  mediaLengthSeconds?: string;
}

export interface ItemsList {
  offset: number;
  totalCount: number;
  items: Array<Item>;
}

export const useItems = (
  kind = 'all',
): {
  data: Item[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<ItemsList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/items?kind=${kind}&limit=25`;
  const result = useSWR(url, (url) => dataFetcher<ItemsList>(url));

  return {
    ...result,
    data: result.data?.items,
  };
};

export const useOwnedItemsByWalletId = (walletId?: string, categoryId?: string) => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletId}/items?category_id=${categoryId || ''}`;
  const result = useSWR(walletId ? url : null, (url) => dataFetcher<ItemsList>(url, jwt));

  return {
    ...result,
    data: result.data?.items,
  };
};

export const useItemsByWalletIdWithPagination = (
  limit: number,
  walletId?: string,
): {
  items: Item[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<ItemsList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<ItemsList[] | undefined>;
  mutate: KeyedMutator<ItemsList[]>;
} => {
  const { jwt } = useContext(AuthContext);

  const {
    data,
    error,
    size: page,
    setSize: setPage,
    mutate,
  } = useSWRInfinite(
    (page) =>
      walletId
        ? `${env.apiUrl}/users/${walletId}/items?offset=${page * limit}&limit=${limit}`
        : null,
    (url) => dataFetcher<ItemsList>(url, jwt),
  );

  const extractedData = (data || []).map((p) => p.items);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const items = data ? new Array<Item>().concat(...extractedData) : [];

  const isEmpty = items.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<ItemsList[] | undefined> => setPage(page + 1);

  return {
    items,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
    mutate,
  };
};

export const useCreatedItemsByWalletIdAndCategory = (
  walletId?: string,
  categoryId?: string,
): {
  data: Item[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<ItemsList>;
  isValidating: boolean;
} => {
  const { jwt } = useContext(AuthContext);

  const url = `${env.apiUrl}/categories/${categoryId}/items?first_owner_wallet_id=${walletId}`;
  const result = useSWR(url, (url) => dataFetcher<ItemsList>(url, jwt));

  return {
    ...result,
    data: result.data?.items,
  };
};

export const useCurrentUserItems = (): {
  data: Item[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<ItemsList>;
  isValidating: boolean;
} => {
  const { walletAddress, jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletAddress}/items`;
  const result = useSWR(url, (url) => dataFetcher<ItemsList>(url, jwt));
  return {
    ...result,
    data: result.data?.items,
  };
};

export const useItemsByCategoryIdWithPagination = (
  categoryId: string,
  limit: number,
): {
  items: Item[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<ItemsList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<ItemsList[] | undefined>;
} => {
  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) => `${env.apiUrl}/items/categories/${categoryId}?offset=${page * limit}&limit=${limit}`,
    (url) => dataFetcher<ItemsList>(url),
  );

  const extractedData = (data || []).map((p) => p.items);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const items = data ? new Array<Item>().concat(...extractedData) : [];

  const isEmpty = items.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<ItemsList[] | undefined> => setPage(page + 1);

  return {
    items,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};

export const useItem = (itemId?: string): SWRResponse<Item> => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/items/${itemId}`;
  const result = useSWR(itemId ? url : null, (url: string) => dataFetcher<Item>(url, jwt));
  return result;
};

export const createNewItem = async (
  jwt: string,
  itemId: string,
  mediaIpfsId: string,
  legal: 'usage_within_ore' | 'full_ownership',
  chain: 'bsc' | 'eth' | 'polygon',
  previewImage?: Blob,
  usage?: string,
  oreChamberStreamingApproved?: boolean,
): Promise<Item> => {
  const url = `${env.apiUrl}/items`;
  const body = objectToFormData({
    id: itemId,
    previewImage,
    mediaIpfsId,
    legal,
    usage,
    oreChamberStreamingApproved: oreChamberStreamingApproved?.toString(),
    chain,
  });

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as Item;
};

export const patchItem = async (
  jwt: string,
  item: ItemRequestBody,
  itemId: string,
): Promise<Item> => {
  const url = `${env.apiUrl}/items/${itemId}`;
  const body = objectToFormData({ ...item, nsfw: item.nsfw?.toString() });
  const response = await axios({
    url,
    method: 'PATCH',
    data: body,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as Item;
};

export const likeItem = async (jwt: string, itemId: string): Promise<void> => {
  const url = `${env.apiUrl}/items/${itemId}/like`;
  await axios({
    url,
    method: 'POST',
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });
};

export const unlikeItem = async (jwt: string, itemId: string): Promise<void> => {
  const url = `${env.apiUrl}/items/${itemId}/unlike`;
  await axios({
    url,
    method: 'POST',
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });
};
