import { useNavigate } from 'react-router-dom';
import { env } from 'env/env';

import { CircularImage } from 'components/common';
import { Auction } from 'services/auctions-service';
import { Item } from 'services/item-service';
import { Sale } from 'services/sales-service';
import { useUser } from 'services/user-service';
import truncate from 'utils/string-truncator';

import styles from './item-page.module.css';

interface Props {
  item: Item;
  sale?: Sale;
  auction?: Auction;
}

export const ItemOwner = ({ item, sale, auction }: Props) => {
  const { data: owner } = useUser(sale?.ownerWalletId || auction?.ownerWalletId);
  const navigate = useNavigate();

  if (sale && sale.ownerWalletId === item.firstOwnerWalletId) return null;

  if (auction && auction.ownerWalletId === item.firstOwnerWalletId) return null;

  if (auction === undefined || sale === undefined) return null;

  const ownerWalletId = sale?.ownerWalletId || auction?.ownerWalletId;

  return (
    <div className={styles.userInfoContainer}>
      <p className={styles.ownerTitle}>owner</p>
      <div className={styles.userInfo} onClick={(): void => navigate(`/user/${ownerWalletId}`)}>
        {
          <CircularImage
            imageUrl={owner?.profileAvatarUrl || '/av-placeholder.jpg'}
            className={styles.avatarCircle}
            width={36}
            height={36}
          />
        }
        <p>
          {owner?.displayName ||
            (ownerWalletId ? truncate(ownerWalletId, env.shortenedWalletIdLength) : '')}
        </p>
      </div>
    </div>
  );
};
