import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingPagePlaceholder } from '../../components/common/loading-page-placeholder/loading-page-placeholder';
import { useItem } from '../../services/item-service';
import { ItemComponent } from './item-component';

export const ItemPage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: item, error } = useItem(id);

  useEffect(() => {
    if (error) {
      navigate('/not-found');
    }
  }, [error, navigate]);

  if (item === undefined) {
    return <LoadingPagePlaceholder />;
  }

  return <ItemComponent item={item} />;
};
