import styles from './card-action-row.module.css';

interface Props {
  button: React.ReactNode;
  listingInfo?: string;
  quantityInfo: string;
}

export const CardActionRow = ({ button, listingInfo, quantityInfo }: Props) => (
  <div className={styles.container}>
    {button}
    <div className={styles.infoContainer}>
      <p className={styles.listingInfo}>{listingInfo}</p>
      <p className={styles.quantity}>{quantityInfo}</p>
    </div>
  </div>
);
