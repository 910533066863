import { Outlet } from 'react-router-dom';

import { Header, HeaderType } from 'components/common/header/header';

export const MainPageLayoutWrapper = () => (
  <>
    <Header type={HeaderType.Forge} />
    <Outlet />
  </>
);
