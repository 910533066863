import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { env } from 'env/env';

import bnbLogo from 'assets/binnance-coin.svg';
import ethLogo from 'assets/eth_round.png';
import {
  CloseIcon,
  CopyIcon,
  DisconnectIcon,
  ListText,
  RefreshIcon,
  SendIcon,
  TakeDownRequestIcon,
} from 'assets/icons';
import oreLogo from 'assets/ore-logo.png';
import maticLogo from 'assets/poly_round.png';
import { Button, ButtonStyle, Typography } from 'components/common';
import { TopUpPopup } from 'components/top-up-popup';
import { CurrentUserProfileContext } from 'context/current-user-profile-context';
import { getCurrentCurrency } from 'utils/blockchain-helpers';
import truncate from 'utils/string-truncator';

import styles from './wallet-info-popup.module.css';

const TOOLTIP_DELAY_MS = 1500;

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const WalletInfoPopup = ({ isOpen, close }: Props): JSX.Element => {
  const { walletAddress, oreBalance, bnbBalance, logOut } = useContext(CurrentUserProfileContext);
  const [isTopupPopupOpen, setIsTopupPopupOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <div className={styles.container} id="wallet-info-popup">
          <div className={styles.header}>
            <div className={styles.walletId}>
              <span title={walletAddress}>
                {truncate(walletAddress, env.shortenedWalletIdLength)}
              </span>
              <CopyToClipboardBtn text={walletAddress} />
            </div>
            <div className={styles.closeBtn} onClick={close} id="wallet-info-popup-close-button">
              <CloseIcon />
            </div>
          </div>
          <div className={styles.balancesWrapper}>
            <BalanceCard balance={bnbBalance} currency={getCurrentCurrency()} />
            <BalanceCard balance={oreBalance} currency="ore" />
            <Button
              id="top-up-button"
              className={styles.topUpButton}
              buttonStyle={ButtonStyle.wideColor}
              onClick={() => setIsTopupPopupOpen(true)}
            >
              Top up your ORE account
            </Button>
          </div>
          <NavLink to="/current-user/transactions-history" className={styles.actionButton}>
            <ListText />
            <Typography.Span fontSize={14}>Transactions history</Typography.Span>
          </NavLink>
          <NavLink to="/send-nft" className={styles.actionButton}>
            <SendIcon />
            <span>Send NFT</span>
          </NavLink>
          <NavLink to="/refresh-nft" className={styles.actionButton}>
            <RefreshIcon />
            <span>Refresh NFT quantity</span>
          </NavLink>
          <NavLink to="/take-down-request-form" className={styles.actionButton}>
            <TakeDownRequestIcon />
            <span>Take down request</span>
          </NavLink>
          <div className={styles.actionButton} onClick={logOut}>
            <DisconnectIcon />
            <span>Disconnect</span>
          </div>
        </div>
      )}

      {isTopupPopupOpen && <TopUpPopup close={() => setIsTopupPopupOpen(false)} />}
    </>
  );
};

interface BalanceProps {
  currency: 'bnb' | 'ore' | 'eth' | 'matic';
  balance: number;
}

const BalanceCard = ({ currency, balance }: BalanceProps): JSX.Element => {
  const currenciesMap: Record<string, string> = {
    bnb: bnbLogo,
    ore: oreLogo,
    eth: ethLogo,
    matic: maticLogo,
  };

  return (
    <div className={styles.balanceCard}>
      <div className={styles.currencyIcon}>
        <img src={currenciesMap[currency]} alt={currency} />
      </div>
      <div className={styles.balance}>
        <div>Balance</div>
        <div id={`wallet-info-popup-${currency}-balance`}>
          {balance >= 0 ? `${balance} ${currency?.toUpperCase()}` : 'N/A'}
        </div>
      </div>
    </div>
  );
};

interface CopyToClipboardProps {
  text: string;
}

const CopyToClipboardBtn = ({ text }: CopyToClipboardProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (): Promise<void> => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, TOOLTIP_DELAY_MS);
  };

  return (
    <div className={styles.copyToClipboard}>
      {isCopied && <div className={styles.tooltip}>Copied!</div>}
      <CopyIcon title="Copy to clipboard" onClick={handleCopy} id="wallet-info-popup-copy-button" />
    </div>
  );
};
