import React from 'react';

const regex = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/;

export const nameSpecialCharactersValidator = (file: File) => {
  if (!regex.test(file.name)) {
    return {
      code: 'fileName-invalid',
      message: 'File name contains unsuported characters. Please try again',
    };
  }

  return null;
};
