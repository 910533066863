import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { COLORS } from 'constant';

import { FireIcon } from 'assets/icons';

import { Typography } from '../typography';
import styles from './bid-timer.module.css';

interface BidTimerProps {
  endOfAuction: string | undefined;
  displayMode: 'card' | 'itemPage';
}

export const BidTimer = ({ endOfAuction, displayMode }: BidTimerProps) => {
  const isInCardMode = displayMode == 'card';

  const calculateTimeLeft = useCallback(() => {
    if (endOfAuction) {
      const difference = +new Date(endOfAuction) - +new Date();

      const allHours = Math.floor(difference / (1000 * 60 * 60));
      const days = Math.floor(allHours / 24);
      const displayHours = allHours - days * 24;

      if (difference > 0) {
        return {
          days,
          hours: displayHours,
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
    }

    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }, [endOfAuction]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  if (!endOfAuction) {
    return <></>;
  } else {
    return isInCardMode ? (
      <div
        className={classNames(styles.bidTimerCard, {
          [styles.card]: isInCardMode,
        })}
      >
        <span>
          {timeLeft.days > 0 && <>{timeLeft.days} days </>}
          {timeLeft.hours}:{timeLeft.minutes >= 10 ? timeLeft.minutes : `0${timeLeft.minutes}`}:
          {timeLeft.seconds >= 10 ? timeLeft.seconds : `0${timeLeft.seconds}`}
        </span>
        <FireIcon />
      </div>
    ) : (
      <div className={styles.bidTimer}>
        <Typography.Heading fontSize={12} fontWeight={500} color={COLORS.GRAY_TEXT_1}>
          <>
            <FireIcon /> Ends In
          </>
        </Typography.Heading>
        <div>
          {timeLeft.days > 0 && <>{timeLeft.days}d </>}
          {timeLeft.hours}h {timeLeft.minutes >= 10 ? timeLeft.minutes : `0${timeLeft.minutes}`}m{' '}
          {timeLeft.seconds >= 10 ? timeLeft.seconds : `0${timeLeft.seconds}`}s
        </div>
      </div>
    );
  }
};
