import styles from './image.module.css';

interface IProps {
  src: string;
  width: string;
  height: string;
  borderRadius?: string;
  alt?: string;
}

export const Image = (props: IProps) => (
  <img
    src={props.src}
    className={styles.image}
    style={{ borderRadius: props.borderRadius ?? 0, width: props.width, height: props.height }}
    alt={props.alt}
  />
);
