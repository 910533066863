import { BeatLoader } from 'react-spinners';

import { ItemCard } from '../../components/common/item-card/item-card';
import { LoadMoreButton } from '../../components/common/load-more-button/load-more-button';
import { useItemsByWalletIdWithPagination } from '../../services/item-service';
import styles from './profile-page.module.css';

const ITEMS_LIMIT = 8;

interface Props {
  walletAddress: string;
  pickedCategory?: string;
}

export const UserItems = ({ walletAddress, pickedCategory }: Props) => {
  const {
    items: userItems,
    isLoadingMore,
    isReachingEnd,
    isLoadingInitialData,
    loadMore,
  } = useItemsByWalletIdWithPagination(ITEMS_LIMIT, walletAddress);

  const filteredItems = pickedCategory
    ? userItems.filter((item) => item.categoryId === pickedCategory)
    : userItems;

  const renderItemsList = (): JSX.Element | JSX.Element[] => {
    if (isLoadingInitialData) {
      return (
        <p className={styles.noItems}>
          <BeatLoader color="#85838E" size={9} />
        </p>
      );
    }

    if (filteredItems.length === 0) {
      return <p className={styles.noItems}>No items to display</p>;
    }

    return filteredItems.map((item) => <ItemCard key={item.id} prefetchedItem={item} />);
  };

  return (
    <div className={styles.activeList}>
      <div className={styles.nftsContainer}>{renderItemsList()}</div>
      {(pickedCategory ? filteredItems.length > ITEMS_LIMIT : !isReachingEnd) && (
        <LoadMoreButton isLoading={!!isLoadingMore} onClick={loadMore} />
      )}
    </div>
  );
};
