import { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import classNames from 'classnames';

import { Item, useCurrentUserItems } from '../../services/item-service';
import { Button, ButtonStyle } from '../common/button/button';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import styles from './items-picker-popup.module.css';

interface Props {
  close: () => void;
  pickedItems: Array<Item>;
  setPickedItems: (items: Item[]) => void;
  buttonText?: string;
  pickMusic?: boolean;
}

export const ItemsPickerPopup = ({
  close,
  pickedItems,
  setPickedItems,
  buttonText,
  pickMusic,
}: Props): JSX.Element => {
  let { data: currentUserItems } = useCurrentUserItems();
  const isLoading = !currentUserItems;
  const [localPickedItems, setLocalPickedItems] = useState<Array<Item>>(pickedItems);
  if (pickMusic) {
    currentUserItems = currentUserItems?.filter((item) => item.categoryId === 'music');
  }

  const toggleItem = (item: Item): void => {
    const currentIndex = localPickedItems.findIndex((i) => i.id === item.id);
    if (currentIndex !== -1) {
      return setLocalPickedItems((current) => [...current.filter((i) => i.id !== item.id)]);
    }

    return setLocalPickedItems((current) => [...current, item]);
  };

  return (
    <GenericPopup
      onClose={close}
      canBeClosed
      title={<p className={styles.popupTitle}>Choose items you want add to this collection</p>}
      id={'items-picker-popup'}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <BeatLoader color="#85838E" size={9} />
        </div>
      ) : (
        <div className={styles.itemsPopup}>
          <>
            <div className={styles.itemsList}>
              {(currentUserItems || []).map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={(): void => toggleItem(item)}
                    className={styles.itemRow}
                  >
                    <div
                      className={classNames(styles.itemInput, {
                        [styles.picked]: localPickedItems.find((i) => i.id === item.id),
                      })}
                    >
                      <div></div>
                    </div>
                    <img src={item.previewImageUrl} alt={'preview'} />
                    <p>{item.displayName}</p>
                  </div>
                );
              })}
            </div>
          </>
          <Button
            type="button"
            buttonStyle={ButtonStyle.second}
            onClick={(): void => {
              setPickedItems(localPickedItems);
              close();
            }}
            id={'collection-item-picker-submit-button'}
          >
            {buttonText ? buttonText : `Add ${localPickedItems.length} items`}
          </Button>
        </div>
      )}
    </GenericPopup>
  );
};
