import { env } from 'env/env';
import useSWR from 'swr';

import { dataFetcher } from './data-fetcher';

export interface IProperty {
  name: string;
  value: string;
}

export const useProperties = () => {
  const url = `${env.apiUrl}/properties`;
  const { data } = useSWR(url, (url) => dataFetcher<IProperty[]>(url), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
  };
};
