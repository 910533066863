import useSWR, { KeyedMutator } from 'swr';

import { env } from '../env/env';
import { dataFetcher } from './data-fetcher';

export interface Category {
  id: string; // from swagger: it's also a name of category
  createdAt: string;
  updatedAt: string;
}

interface CategoriesList {
  totalCount?: number;
  offset?: number;
  categories: Array<Category>;
}

export const useCategories = (): {
  data: Category[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  mutate: KeyedMutator<CategoriesList>;
  isValidating: boolean;
} => {
  const url = `${env.apiUrl}/items/categories`;

  const result = useSWR(url, (url) => dataFetcher<CategoriesList>(url));
  return {
    ...result,
    data: result.data?.categories,
  };
};
