import { Dropdown, DropdownStyle } from 'components/common/dropdown';
import { useCategories } from 'services/categories-service';
import { getDropdownOptionDisplayName } from 'utils/dropdown-option-display-name';

import { ReactComponent as CategoriesIcon } from '../../assets/icons/all_categories.svg';
import styles from './category-picker.module.css';

interface CategoryDropdownProps {
  pickedCategory?: string;
  setPickedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CategoryDropdown = ({
  pickedCategory,
  setPickedCategory,
}: CategoryDropdownProps): JSX.Element => {
  const { data: categories } = useCategories();

  return (
    <Dropdown
      icon={<CategoriesIcon className={styles.categoryIcon} />}
      selected={pickedCategory ? getDropdownOptionDisplayName(pickedCategory) : undefined}
      placeholderTitle="Category"
      id={'items-list-category-dropdown'}
      dropdownStyle={DropdownStyle.FullBorder}
      fixedWidth={160}
      alignRight
      options={[
        <div
          key={'no-category'}
          onClick={(): void => {
            setPickedCategory(undefined);
          }}
          id={'items-list-category-dropdown-all'}
        >
          All
        </div>,
        ...(categories || []).map((category) => (
          <div
            key={category.id}
            onClick={(): void => {
              setPickedCategory(category.id);
            }}
            id={`items-list-category-dropdown-${category.id}`}
          >
            {getDropdownOptionDisplayName(category.id)}
          </div>
        )),
      ]}
    />
  );
};

export default CategoryDropdown;
