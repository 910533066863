import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, ButtonStyle, Typography } from 'components/common';
import { Dropdown, DropdownStyle } from 'components/common/dropdown';
import { GenericPopup } from 'components/common/generic-popup';
import { UnderlinedInputControl } from 'components/common/underlined-input';
import { ItemsPickerPopup } from 'components/items-picker-popup/items-picker-popup';
import { MusicTableHeader } from 'components/music-table-header';
import { PlaylistMusicItem } from 'components/particulars/playlist-music-item';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';
import { AuthContext } from 'context/auth-context';
import { Item } from 'services/item-service';
import { createNewPlaylist, deletePlaylist, editPlaylist, Playlist } from 'services/music-service';
import { getDetailsFromItems } from 'utils/playlist-helpers';

import styles from './playlist-page.module.css';

interface FormInputs {
  name: string;
  description: string;
}

interface IProps {
  playlist?: Playlist;
  refetchPlaylist?: any;
}

export const CreatePlaylistPage = ({ playlist, refetchPlaylist }: IProps) => {
  const [pickedItems, setPickedItems] = useState<Array<Item>>([]);
  const [requestPending, setRequestPending] = useState(false);
  const [tracksPopupOpen, setTracksPopupOpen] = useState(false);
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState('New playlist');
  const [playlistDescription, setPlaylistDescription] = useState<string | undefined>(undefined);

  const { jwt } = useContext(AuthContext);
  const { pause, isPaused, playPlaylist, addPlaylistFromItems } = useContext(AudioPlayerContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormInputs>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!playlist) {
      return;
    }

    if (playlist.items) {
      addPlaylistFromItems(playlist.items, playlist.displayName, playlist.id);
      setPickedItems(playlist.items);
    }

    setPlaylistName(playlist.displayName);
    setPlaylistDescription(playlist?.description);
  }, [playlist]);

  const isNew = !playlist;

  const onPlayPauseClicked = () => {
    if (playlist?.items) {
      if (!isPaused) {
        pause();
      } else {
        playPlaylist(playlist.id);
      }
    }
  };
  const onSave = async () => {
    setRequestPending(true);
    const response = isNew
      ? await createNewPlaylist(
          jwt,
          playlistName,
          pickedItems.map((i) => i.id),
        )
      : await editPlaylist(
          jwt,
          playlist.id,
          playlistName,
          pickedItems.map((i) => i.id),
        );

    if (isNew && response.status === 201) {
      navigate(`/music/playlist/${response.data.id}`);
      toast.info('Playlist created');
    } else if (response.status === 200) {
      await refetchPlaylist();
      toast.info('Playlist edited');
    } else {
      toast.error(`Error ${isNew ? 'creating' : 'updating'} playlist`);
    }
    setRequestPending(false);
  };
  const onDelete = async () => {
    if (!playlist) {
      return;
    }
    setRequestPending(true);
    setDeletePopupOpen(false);
    const response = await deletePlaylist(jwt, playlist.id);
    if (response.status === 204) {
      navigate('/music/library');
      toast.info('Playlist deleted');
    } else {
      toast.error('Error deleting playlist');
    }
    setRequestPending(false);
  };

  const checkIfDirty = () => {
    const areItemsUnchanged = () => {
      if (playlist?.items.length === pickedItems.length) {
        return playlist?.items.every((item) => {
          if (pickedItems.filter((pickedItem) => pickedItem.id === item.id).length) {
            return true;
          }

          return false;
        });
      }

      return false;
    };
    const isNameUnchanged = playlistName === playlist?.displayName;
    const isDescriptionUnchanged =
      (!playlistDescription && !playlist?.description) ||
      playlistDescription === playlist?.description;

    if (isNew) {
      return !!pickedItems.length;
    } else {
      return !(areItemsUnchanged() && isNameUnchanged && isDescriptionUnchanged);
    }
  };

  const isDirty = useMemo(
    () => checkIfDirty(),
    [playlist, pickedItems, playlistDescription, playlistName],
  );

  return (
    <>
      <Typography.Paragraph fontSize={16} fontWeight={400} marginBottom={8}>
        Playlist
      </Typography.Paragraph>
      <div className={styles.header}>
        <Typography.Heading fontSize={36} fontWeight={700} marginBottom={12}>
          {playlistName}
        </Typography.Heading>
        <Button
          onClick={onSave}
          id={'save-playlist'}
          buttonStyle={ButtonStyle.color}
          disabled={!isDirty || requestPending}
        >
          Save
        </Button>
      </div>
      <Typography.Paragraph fontSize={16} fontWeight={400} marginBottom={8}>
        {playlistDescription ?? ''}
      </Typography.Paragraph>
      <div className={styles.tracksCounter}>{getDetailsFromItems(pickedItems)}</div>
      <div className={styles.optionsContainer}>
        {!isNew && (
          <PlayPauseButton
            isPlaying={!isPaused}
            onPlayPauseClicked={onPlayPauseClicked}
            colorType="orange"
            padding={16}
            width={56}
            height={56}
          />
        )}
        <Dropdown
          placeholderTitle="..."
          isWide
          options={[
            <div
              key="add-tracks"
              onClick={() => {
                setTracksPopupOpen(true);
              }}
            >
              Add tracks
            </div>,
            <div
              key="edit-details"
              onClick={() => {
                setDetailsPopupOpen(true);
              }}
            >
              Edit details
            </div>,
            ...(isNew
              ? []
              : [
                  <div
                    key="delete"
                    onClick={() => {
                      setDeletePopupOpen(true);
                    }}
                  >
                    Delete playlist
                  </div>,
                ]),
          ]}
          dropdownStyle={DropdownStyle.FullBorder}
          fixedWidth={55}
        />
      </div>
      <>
        {pickedItems.length ? (
          <div className={styles.pickedItemsList}>
            <MusicTableHeader setOfColumns={'TAD'} />
            {pickedItems.map((item, i) => {
              return (
                <PlaylistMusicItem
                  authorId={item.firstOwnerWalletId}
                  item={item}
                  key={item.id}
                  imageURL={item?.previewImageUrl}
                  queuePosition={(i + 1).toString()}
                  albumName={item?.displayName ?? ''}
                  playlistId={isNew ? 'owned-music' : playlist.id}
                />
              );
            })}
          </div>
        ) : (
          <div className={styles.playlistContainer}>
            <p>There are no tracks at this playlist.</p>
            <Button
              id={'addTracks'}
              onClick={() => {
                setTracksPopupOpen(true);
              }}
              buttonStyle={ButtonStyle.color}
            >
              Add tracks
            </Button>
          </div>
        )}
      </>
      {tracksPopupOpen && (
        <ItemsPickerPopup
          close={(): void => setTracksPopupOpen(false)}
          pickedItems={pickedItems}
          setPickedItems={setPickedItems}
          pickMusic
        />
      )}
      {detailsPopupOpen && (
        <GenericPopup
          canBeClosed
          title="Edit details"
          id={'edit-details-popup'}
          onClose={() => {
            setDetailsPopupOpen(false);
          }}
        >
          <div className={styles.container}>
            <UnderlinedInputControl
              type={'text'}
              title={'Name'}
              placeholder={'e.g. "Favorite tracks"'}
              id={'edit-details-name-input'}
              {...register('name')}
              errorMessage={errors.name?.message}
            />
            <UnderlinedInputControl
              type={'text'}
              title={'Description'}
              placeholder={'e.g. "Playlist with new tracks"'}
              id={'edit-details-description-input'}
              {...register('description')}
              errorMessage={errors.description?.message}
            />
            <Button
              onClick={handleSubmit(() => {
                const name = getValues('name');
                const description = getValues('description');
                setPlaylistName(name);
                setPlaylistDescription(description);
                setDetailsPopupOpen(false);
              })}
              buttonStyle={ButtonStyle.green}
              id={'save-details-button'}
              type="submit"
            >
              Save
            </Button>
          </div>
        </GenericPopup>
      )}
      {deletePopupOpen && (
        <GenericPopup
          canBeClosed
          title="Are you sure?"
          id={'delete-popup'}
          onClose={() => {
            setDeletePopupOpen(false);
          }}
        >
          <div>Do you want to delete this playlist?</div>
          <div className={styles.deletePopupButtons}>
            <Button id="delete-playlist-confirm" buttonStyle={ButtonStyle.color} onClick={onDelete}>
              YES
            </Button>
            <Button
              id="delete-playlist-cancel"
              buttonStyle={ButtonStyle.white}
              onClick={() => {
                setDeletePopupOpen(false);
              }}
            >
              NO
            </Button>
          </div>
        </GenericPopup>
      )}
    </>
  );
};
