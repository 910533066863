import { env } from '../../env/env';
import { useHotCollections } from '../../services/collection-service';
import { CollectionCard } from '../collection-card';
import { GenericCarousel } from '../common/carousel/generic-carousel';
import { LoadingItemsPlaceholder } from '../common/carousel-placeholders/loading-items-placeholder';
import { NoItemsPlaceholder } from '../common/carousel-placeholders/no-items-placeholder';
import { DisplayType } from '../common/display-type-picker/display-type-picker';

export const CollectionsCarousel = (): JSX.Element => {
  const { data: collections } = useHotCollections();

  const renderChildren = (): JSX.Element[] => {
    if (collections === undefined) {
      return [<LoadingItemsPlaceholder key={'collections-carousel-loading-items'} />];
    }

    if (collections.length === 0) {
      return [
        <NoItemsPlaceholder
          text={'There are no featured collections yet'}
          key={'collections-carousel-no-items'}
        />,
      ];
    }

    return collections
      .slice(0, env.maxCarouselItems)
      .map((collection) => <CollectionCard key={collection.id} collection={collection} />);
  };

  return (
    <GenericCarousel
      title={'Featured Collections'}
      link={`/explore?displayType=${DisplayType.Collections}`}
      linkDisplayName={'Go to all collections'}
    >
      {renderChildren()}
    </GenericCarousel>
  );
};
