import { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, ButtonStyle } from '../../components/common/button/button';
import { UnderlinedInputControl } from '../../components/common/underlined-input/underlined-input';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import styles from './refresh-nft-page.module.css';

interface FormInputs {
  itemId: string;
}

export const RefreshNftPage = (): JSX.Element => {
  const { refreshUserItem } = useContext(CurrentUserProfileContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>();

  const itemId = watch('itemId');

  const refresh = useCallback(async () => {
    await refreshUserItem(itemId);

    toast.info('Item refreshed. You can check it on your profile page.');
  }, [itemId, refreshUserItem]);

  return (
    <div className={styles.container}>
      <h1>Refresh NFT quantity</h1>
      <p>You can refresh quantity of nft if You believe You are missing some.</p>
      <div className={styles.form}>
        <UnderlinedInputControl
          title="NFT ID"
          placeholder={'eg. 1234567890'}
          type={'text'}
          id={'refresh-nft-id-input'}
          {...register('itemId', {
            required: {
              value: true,
              message: 'You must specify item id',
            },
          })}
          errorMessage={errors.itemId?.message}
        />
        <Button
          onClick={handleSubmit(refresh, () => toast.warn('Please verify form.'))}
          buttonStyle={ButtonStyle.color}
          id={'refresh-nft-button'}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
};
