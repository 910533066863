import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { ProfileSummary } from 'components/profile-summary';
import { useUser } from 'services/user-service';

import styles from './profile-page.module.css';
import { UserItemsSection } from './user-items-section';
import { UserMusicSection } from './user-music-section';

export const ProfilePage = () => {
  const { walletAddress } = useParams();
  const { data: userProfile, error } = useUser(walletAddress);
  const location = useLocation();
  const navigate = useNavigate();

  if (error) {
    navigate('/not-found');
  }

  if (userProfile === undefined || walletAddress === undefined) {
    return <></>;
  }

  const musicEnabled = location.pathname.includes('music');

  return !error && !userProfile ? (
    <p>
      Loading <BeatLoader />
    </p>
  ) : !error && userProfile && walletAddress ? (
    <div className={styles.container}>
      <ProfileSummary userProfile={userProfile} walletAddress={walletAddress} />
      {musicEnabled ? (
        <UserMusicSection walletAddress={walletAddress} />
      ) : (
        <UserItemsSection walletAddress={walletAddress} title="User items" />
      )}
    </div>
  ) : (
    <></>
  );
};
