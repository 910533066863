import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import {
  MenuIcon,
  MoreIcon,
  RepeatIcon,
  RepeatOneIcon,
  ShuffleIcon,
  SkipToNextIcon,
  SkipToPreviousIcon,
} from 'assets/audio-player-icons';
import { IconButton } from 'components/common/icon-button';
import { RangeInput } from 'components/common/range-input';
import { PlayPauseButton } from 'components/play-pause-button';
import { VolumeController } from 'components/volume-controller';
import { AudioPlayerContext, RepeatMode } from 'context/audio-player-context';
import { useUser } from 'services/user-service';
import { renderTime } from 'utils/seconds-to-duration';
import truncate from 'utils/string-truncator';

import styles from './audio-player-widget.module.css';

const audioPlayerWidgetIconButtonsBaseProps = {
  width: 24,
  height: 24,
  padding: 0,
  colorType: 'grey' as const,
  margin: '0 15px',
};

export const AudioPlayerWidget = () => {
  const {
    play,
    pause,
    isPaused,
    toggleRandom,
    toggleRepeat,
    repeatMode,
    randomEnabled,
    duration,
    currentTime,
    moveToTime,
    playNext,
    playPrevious,
    volume,
    setVolume,
    currentSong,
  } = useContext(AudioPlayerContext);
  const { data: author } = useUser(currentSong?.authorAddress);

  const location = useLocation();

  const setQueueIconState = () => {
    const shouldBeActive = location.pathname === '/music/queue';
    setIsQueueVisible(shouldBeActive);
  };

  const navigate = useNavigate();

  const [isQueueVisible, setIsQueueVisible] = useState(true);

  useEffect(() => {
    setQueueIconState();
  }, [location]);

  return (
    <div className={styles.playerWrapper}>
      <div className={classNames(styles.progressLine, { [styles.disabled]: true })}>
        {currentSong && (
          <RangeInput
            min={0}
            max={duration || 0}
            value={currentTime || 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              moveToTime(parseFloat(e.target.value));
            }}
          />
        )}
      </div>
      <div className={styles.container}>
        <div>
          <IconButton
            icon={<MenuIcon />}
            iconType="stroke"
            {...audioPlayerWidgetIconButtonsBaseProps}
            colorType={isQueueVisible ? 'orange' : 'grey'}
            onClick={() => navigate('/music/queue')}
          />

          {/* TODO: take info from item */}
          {currentSong && (
            <div className={styles.songDetails}>
              <div className={styles.albumCover}>
                <img src={currentSong.imageUrl} alt="album cover" />
              </div>
              <div>
                <p className={styles.songTitle}>{currentSong.title}</p>
                {/* TODO: Replace with song's title  */}
                <p className={styles.songAuthor}>
                  {author?.displayName || truncate(currentSong.authorAddress)}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.controllsContainer}>
          <IconButton
            icon={<ShuffleIcon />}
            iconType="stroke"
            onClick={toggleRandom}
            {...audioPlayerWidgetIconButtonsBaseProps}
            colorType={randomEnabled ? 'orange' : 'grey'}
          />
          <IconButton
            icon={<SkipToPreviousIcon />}
            onClick={playPrevious}
            iconType="both"
            {...audioPlayerWidgetIconButtonsBaseProps}
          />
          <PlayPauseButton
            isPlaying={!isPaused}
            onPlayPauseClicked={isPaused ? play : pause}
            colorType="white"
            padding={8}
            width={32}
            height={32}
          />
          <IconButton
            icon={<SkipToNextIcon />}
            onClick={playNext}
            iconType="both"
            {...audioPlayerWidgetIconButtonsBaseProps}
          />
          <IconButton
            icon={repeatMode === RepeatMode.ONE ? <RepeatOneIcon /> : <RepeatIcon />}
            iconType="stroke"
            onClick={toggleRepeat}
            {...audioPlayerWidgetIconButtonsBaseProps}
            colorType={repeatMode === RepeatMode.OFF ? 'grey' : 'orange'}
          />
        </div>
        <div>
          {renderTime(currentTime)} / {renderTime(duration)}
          <VolumeController volume={volume} setVolume={setVolume} />
          <IconButton
            icon={<MoreIcon />}
            iconType="stroke"
            {...audioPlayerWidgetIconButtonsBaseProps}
          />
        </div>
      </div>
    </div>
  );
};
