import useSWRInfinite from 'swr/infinite';

import { env } from '../env/env';
import { dataFetcher } from './data-fetcher';

export enum ActivityKind {
  ItemCreated = 'item_created',
  SaleCreated = 'sale_created',
  AuctionCreated = 'auction_created',
  ItemSold = 'item_sold',
  ItemLiked = 'item_liked',
  ItemUnliked = 'item_unliked',
}

export enum ActivityKindFilter {
  listings = 'listing',
  sales = 'sale',
  bids = 'bid',
  likes = 'like',
  followings = 'follow',
}

export interface Activity {
  id: string;
  createdAt: string;
  kind: ActivityKind;
  itemId?: string;
  userId1: string;
  userId2?: string;
  auctionId?: string;
  saleId?: string;
}

interface ActivityList {
  activities: Array<Activity>;
}

export const useActivitiesWithPagination = (
  limit: number,
  kind?: ActivityKindFilter,
): {
  activities: Activity[];
  isLoadingMore: boolean | undefined;
  isLoadingInitialData: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  loadMore: () => Promise<ActivityList[] | undefined>;
  setPage: (size: number | ((_size: number) => number)) => Promise<ActivityList[] | undefined>;
} => {
  const {
    data,
    error,
    size: page,
    setSize: setPage,
  } = useSWRInfinite(
    (page) =>
      `${env.apiUrl}/activities?offset=${page * limit}&limit=${limit}${
        kind ? '&group=' + kind : ''
      }`,
    (url) => dataFetcher<ActivityList>(url),
  );

  const extractedData = (data || []).map((p) => p.activities);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (page > 0 && data && typeof data[page - 1] === 'undefined');

  const activities = data ? new Array<Activity>().concat(...extractedData) : [];

  const isEmpty = activities.length === 0;
  const isReachingEnd =
    isEmpty || (extractedData && extractedData[extractedData.length - 1]?.length < limit);

  const loadMore = (): Promise<ActivityList[] | undefined> => setPage(page + 1);

  return {
    activities,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    setPage,
  };
};
