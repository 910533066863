import styles from './card-action-button.module.css';

interface Props {
  text: string;
  onClick?: () => void;
}

export const CardActionButton = ({ text, onClick }: Props) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {text}
    </button>
  );
};
