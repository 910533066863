import { useContext } from 'react';
import { COLORS } from 'constant';

import { Typography } from 'components/common';
import { AudioPlayerContext, RepeatMode } from 'context/audio-player-context';

import { MusicQueuePlaylistItem } from './music-queue-playlist-item';

export const MusicQueue = () => {
  const { currentSong, currentPlaylist, repeatMode } = useContext(AudioPlayerContext);

  const getCurrentSongIndex = () => {
    if (currentSong) {
      return currentPlaylist?.songs.indexOf(currentSong);
    }
  };

  const currentQueue = () => {
    switch (repeatMode) {
      case RepeatMode.OFF: {
        return currentPlaylist?.songs?.slice(getCurrentSongIndex()).slice(1);
      }
      case RepeatMode.ONE: {
        return null;
      }
      case RepeatMode.ON: {
        const currentIndex = getCurrentSongIndex();

        const currentSongs = currentPlaylist?.songs?.slice(getCurrentSongIndex()).slice(1);

        if (currentSongs && currentSong && currentIndex) {
          let newSongs = currentSongs;
          const songsTopush = currentPlaylist?.songs?.filter((u, i) => i < currentIndex);
          if (songsTopush) {
            newSongs = newSongs.concat(songsTopush);
          }
          return newSongs;
        }

        return currentSongs;
      }
    }
  };

  return (
    <>
      <Typography.Heading fontSize={24} fontWeight={700} marginBottom={24} color={COLORS.WHITE}>
        Queue
      </Typography.Heading>
      <Typography.Heading
        fontSize={16}
        fontWeight={700}
        marginBottom={8}
        color={COLORS.GRAY_TEXT_1}
      >
        Now playing
      </Typography.Heading>
      <MusicQueuePlaylistItem
        song={currentSong}
        key={currentSong?.id}
        currentlyPlayedItemId={currentSong?.id}
        queuePosition={'0'}
      />
      <Typography.Heading
        fontSize={16}
        fontWeight={700}
        marginBottom={24}
        marginTop={24}
        color={COLORS.GRAY_TEXT_1}
      >
        Next
      </Typography.Heading>
      {currentQueue()?.map((song, i) => (
        <MusicQueuePlaylistItem
          song={song}
          key={song.id}
          currentlyPlayedItemId={currentSong?.id}
          queuePosition={(i + 2).toString()}
        />
      ))}
    </>
  );
};
