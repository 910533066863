import { useNavigate } from 'react-router-dom';

import { Item } from '../../services/item-service';
import { CardActionButton } from '../common/card/card-action-button';
import { CardActionRow } from '../common/card/card-action-row';
import { ItemCard } from '../common/item-card';

interface SaleProps {
  item?: Item;
  collectionName?: string;
  ownerWalletAddress?: string;
  price?: string;
  currency: string;
  quantity: number;
  freeQuantity?: number;
  saleId: string;
  previewMode?: boolean;
}

export const SaleCard = ({
  item,
  collectionName,
  ownerWalletAddress,
  price,
  currency,
  quantity,
  freeQuantity,
  saleId,
  previewMode,
}: SaleProps): JSX.Element => {
  const saleUrl = `/sale/${saleId}`;
  const navigate = useNavigate();

  return (
    <ItemCard
      prefetchedItem={item}
      collectionName={collectionName}
      ownerWalletAddress={ownerWalletAddress}
      previewMode={previewMode}
      redirectionUrl={saleUrl}
    >
      <CardActionRow
        button={<CardActionButton text={'Buy now'} onClick={() => navigate(saleUrl)} />}
        listingInfo={`price: ${price} ${currency}`}
        quantityInfo={`${freeQuantity || quantity}/${quantity}`}
      />
    </ItemCard>
  );
};
