import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { AuctionsContext } from '../../context/auctions-context';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { env } from '../../env/env';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import { Auction, useBidsForAuction } from '../../services/auctions-service';
import { allowOreAmount, amountFromWei, signBid } from '../../services/blockchain-service';
import { Item } from '../../services/item-service';
import { UserProfile } from '../../services/user-service';
import { TransactionType } from '../../types/transaction-type';
import { blockchainNumberRegex } from '../../utils/blockchain-number-regex';
import truncate from '../../utils/string-truncator';
import { Button, ButtonStyle } from '../common/button/button';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { UnderlinedInputControl } from '../common/underlined-input/underlined-input';
import styles from './transaction-popups.module.css';
import { TransactionSummary } from './transaction-summary';

enum Step {
  first,
  second,
}

interface FormInputs {
  quantity: string;
  bid: string;
}

interface Props {
  close: () => void;
  item: Item;
  itemOwner: UserProfile | undefined;
  serviceFee: number;
  auction: Auction;
}

export const PlaceABidPopup = ({
  close,
  item,
  itemOwner,
  serviceFee,
  auction,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { oreBalance, bnbBalance, walletAddress } = useContext(CurrentUserProfileContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>();

  const [step, setStep] = useState<Step>(Step.first);
  const minimumBidFloat = parseFloat(amountFromWei(auction.minimumBid));
  const { placeBid } = useContext(AuctionsContext);
  const { mutate } = useBidsForAuction(auction.id);

  const validateBidAndGoToNextStep = (): void => {
    if (quantity === undefined) {
      toast.warn('You need to specify quantity');
      return;
    }

    if (bid === undefined) {
      toast.warn('You need to specify bid amount');
      return;
    }

    if (bid * quantity < minimumBidFloat * quantity) {
      toast.warn(
        `Your bid amount is lower than minimum bid, minimum bid is: ${amountFromWei(
          auction.minimumBid,
        )}`,
      );
      return;
    }

    if (auction.settlementToken === 'ORE' && bid * quantity >= oreBalance) {
      // eslint-disable-next-line quotes
      toast.warn("You don't have enough ORE");
      return;
    }

    if (auction.settlementToken === 'BNB' && bid * quantity >= bnbBalance) {
      // eslint-disable-next-line quotes
      toast.warn("You don't have enough BNB");
      return;
    }

    setStep(Step.second);
  };

  const bid = parseFloat(watch('bid'));
  const quantity = parseInt(watch('quantity'));

  const approve = useCallback(async () => {
    await allowOreAmount(walletAddress, bid * quantity);
  }, [bid, quantity, walletAddress]);

  const sign = useCallback(async () => {
    await signBid(item.id, bid * quantity, auction.settlementToken);
    await placeBid(auction.id, quantity, (bid * quantity).toString());
    mutate();
  }, [item.id, bid, quantity, auction.settlementToken, auction.id, placeBid, mutate]);

  const { makeCall: makeAllowanceCall, state: allowanceCallState } = useCallWithState(
    approve,
    true,
  );
  const { makeCall: makeSignCall, state: signCallState } = useCallWithState(sign, true);

  const getAllowanceButtonChild = (): JSX.Element | string => {
    switch (allowanceCallState) {
      case CallState.Ready:
        return 'Approve';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Approved!';
    }
  };

  const getSignButtonChild = (): JSX.Element | string => {
    switch (signCallState) {
      case CallState.Ready:
        return 'Sign';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Signed!';
    }
  };

  switch (step) {
    case Step.first:
      return (
        <GenericPopup
          title="Place a bid"
          canBeClosed
          onClose={close}
          id={'place-a-bid-popup-first-step'}
        >
          <div className={styles.subTitle}>
            You are about to place a bid for <span className={styles.NFT}>{item.displayName}</span>
            <br />
            from{' '}
            <span
              className={styles.userName}
              onClick={(): void => navigate(`/user/${auction.ownerWalletId}`)}
            >
              {itemOwner?.displayName ||
                truncate(auction.ownerWalletId, env.shortenedWalletIdLength)}
            </span>
          </div>
          <form>
            <UnderlinedInputControl
              type="number"
              title="Your bid"
              placeholder="e.g. 100"
              id={'place-a-bid-popup-bid-input'}
              {...register('bid', {
                required: {
                  value: true,
                  message: 'You must specify your bid',
                },
                pattern: {
                  value: blockchainNumberRegex,
                  message: 'Bid must be a number with maximum of 15 decimal places',
                },
                min: {
                  value: amountFromWei(auction.minimumBid),
                  message: `Your bid amount is lower than minimum bid, minimum bid is: ${amountFromWei(
                    auction.minimumBid,
                  )}`,
                },
                max: {
                  value: (auction.settlementToken === 'BNB' ? bnbBalance : oreBalance) / quantity,
                  message: `You can't bid more than ${
                    auction.settlementToken === 'BNB' ? bnbBalance : oreBalance
                  }`,
                },
              })}
              errorMessage={errors.bid?.message}
            />
            <UnderlinedInputControl
              type="number"
              title="Quantity"
              placeholder="e.g. 1"
              {...register('quantity', {
                required: {
                  value: true,
                  message: 'You must specify quantity',
                },
                min: {
                  value: 1,
                  message: 'You must bid at least 1 item',
                },
                max: {
                  value: auction.quantity,
                  message: `You can't bid more items than ${auction.quantity}.`,
                },
              })}
              errorMessage={errors.quantity?.message}
              id={'place-a-bid-popup-quantity-input'}
            />
          </form>

          <TransactionSummary
            transactionType={TransactionType.Bid}
            currency={auction.settlementToken}
            balance={auction.settlementToken === 'ORE' ? oreBalance : bnbBalance}
            nftPrice={bid && quantity ? bid * quantity : 0}
            serviceFeePercent={serviceFee}
          />

          <Button
            className={styles.button}
            buttonStyle={ButtonStyle.wideWhite}
            onClick={handleSubmit(validateBidAndGoToNextStep, () =>
              toast.warn('Please verify form.'),
            )}
            disabled={quantity === undefined && bid === undefined}
            id={'place-a-bid-popup-next-step-button'}
          >
            Place a bid
          </Button>
        </GenericPopup>
      );
    case Step.second:
      return (
        <GenericPopup
          title="Follow steps"
          canBeClosed={signCallState !== CallState.Ready}
          onClose={close}
          id={'place-a-bid-popup-second-step'}
        >
          <h4>Approve a bid</h4>
          <p>Checking balance and approving</p>
          <Button
            className={styles.button}
            buttonStyle={
              allowanceCallState === CallState.Ended ? ButtonStyle.wideGreen : ButtonStyle.wideWhite
            }
            onClick={makeAllowanceCall}
            disabled={allowanceCallState !== CallState.Ready}
            id={'place-a-bid-popup-approve-button'}
          >
            {getAllowanceButtonChild()}
          </Button>

          <h4>Signature</h4>
          <p>Create a signature to place a bid</p>
          <Button
            className={styles.button}
            buttonStyle={ButtonStyle.wideSecond}
            onClick={(): Promise<void> => makeSignCall().then(close)}
            disabled={allowanceCallState !== CallState.Ended || signCallState !== CallState.Ready}
            id={'place-a-bid-popup-sign-button'}
          >
            {getSignButtonChild()}
          </Button>
        </GenericPopup>
      );
  }
};
