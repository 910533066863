import classNames from 'classnames';

import styles from './range-input.module.css';

interface Props {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  step?: number;
  className?: string;
}

export const RangeInput = ({ min, max, step, className, value, onChange }: Props) => (
  <input
    type="range"
    min={min}
    max={max}
    step={step || 0.5}
    className={classNames(styles.customRange, className)}
    value={value}
    onChange={onChange}
    /* Little hack to make background two color and still following the bar's value width */
    style={{ backgroundSize: `${((value - min) * 100) / (max - min)}% 100%` }}
  />
);
