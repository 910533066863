import { useEffect } from 'react';

import { env } from '../../env/env';

export const TakeDownRequestFormPage = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ff-script';
    script.src = env.takeDownRequestFormUrl || '';
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return <div id="ff-compose" style={{ marginTop: '30px', marginBottom: '50px' }}></div>;
};
