import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { MUSIC_TABS } from 'constant';

import { CategoriesIcon, TransactionTypeIcon } from 'assets/icons';
import { LayoutStack, Typography } from 'components/common';
import { Dropdown, DropdownStyle } from 'components/common/dropdown/dropdown';
import { LoadMoreButton } from 'components/common/load-more-button';
import { Tabs } from 'components/common/tabs';
import { TabOptions } from 'components/common/tabs/tabs';
import { MusicCard } from 'components/music-card';
import { MusicCollectionCard } from 'components/music-collection-card';
import { useAuctionsWithPagination } from 'services/auctions-service';
import { useCollectionsWithPagination } from 'services/collection-service';
import { useSalesWithPagination } from 'services/sales-service';
import { getDropdownOptionDisplayName } from 'utils/dropdown-option-display-name';

import styles from './music-page-explore.module.css';

const ITEMS_LIMIT = 12;

export const MusicPageExplore = () => {
  const { selectedTab: paramTab } = useParams();
  const initialTab = TABS.filter((t) => t.value == paramTab)[0] ?? TABS[0];
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [transactionSelected, setTransactionSelected] = useState<string | undefined>();
  // TODO: add subcategories on backend
  const [categoriesSelected, setCategoriesSelected] = useState<string | undefined>();

  return (
    <>
      <Typography.Heading fontSize={36} fontWeight={700} marginBottom={12}>
        Explore Audio
      </Typography.Heading>
      <Typography.Paragraph fontSize={16} fontWeight={400} marginBottom={64}>
        Explore thousands of NFT Music and Podcasts.
      </Typography.Paragraph>
      <div className={styles.buttonsWrapper}>
        <Tabs displayOptions={TABS} currentOption={selectedTab} setValue={setSelectedTab} />
        <div className={styles.dropdownsWrapper}>
          <Dropdown
            isWide
            fixedWidth={120}
            icon={<CategoriesIcon className={styles.icon} />}
            placeholderTitle="Category"
            options={[
              <div
                key={'no-category'}
                onClick={(): void => {
                  setCategoriesSelected(undefined);
                }}
                id={'items-list-category-dropdown-all'}
              >
                All
              </div>,
              ...(CATEGORY_OPTIONS || []).map((category) => (
                <div
                  key={category}
                  onClick={(): void => {
                    setCategoriesSelected(category);
                  }}
                  id={`items-list-category-dropdown-${category}`}
                >
                  {getDropdownOptionDisplayName(category)}
                </div>
              )),
            ]}
            selected={categoriesSelected}
            dropdownStyle={DropdownStyle.FullBorder}
          />
          <Dropdown
            fixedWidth={120}
            placeholderTitle="Sale Type"
            icon={<TransactionTypeIcon className={styles.icon} />}
            options={[
              <div
                key={'no-saleType'}
                onClick={(): void => {
                  setTransactionSelected(undefined);
                }}
                id={'items-list-sale-type-dropdown-all'}
              >
                All
              </div>,
              ...(TRANSACTION_OPTIONS || []).map((transactionOption) => (
                <div
                  key={transactionOption}
                  onClick={(): void => {
                    setTransactionSelected(transactionOption);
                  }}
                  id={`items-list-sale-type-dropdown-${transactionOption}`}
                >
                  {getDropdownOptionDisplayName(transactionOption)}
                </div>
              )),
            ]}
            selected={transactionSelected}
            dropdownStyle={DropdownStyle.FullBorder}
          />
        </div>
      </div>
      {selectedTab === TABS[0] && <MusicTabContent transactionSelected={transactionSelected} />}
      {selectedTab === TABS[1] && <PodcastsTabContent />}
      {selectedTab === TABS[2] && <CollectionsTabContent />}
    </>
  );
};

const TABS: TabOptions = [
  { value: MUSIC_TABS.MUSIC, label: 'Music' },
  { value: MUSIC_TABS.PODCASTS, label: 'Podcasts' },
  // { value: MUSIC_TABS.AUTHORS, label: 'Authors' },
  { value: MUSIC_TABS.COLLECTION, label: 'Collections' },
];

const CATEGORY_OPTIONS = [
  'Concerts',
  'Mood',
  'Party',
  'Chill',
  'Workout',
  'Rock',
  'Focus',
  'Romance',
];

const TRANSACTION_OPTIONS = ['Bid', 'FixedPrice'];

const MusicTabContent = ({ transactionSelected }: { transactionSelected: string | undefined }) => {
  const {
    auctions,
    loadMore: loadMoreAuctions,
    isLoadingInitialData: isLoadingInitialAuctions,
    isLoadingMore: isLoadingMoreAuctions,
    isReachingEnd: areAuctionsReachingEnd,
  } = useAuctionsWithPagination(ITEMS_LIMIT, 'music');

  const {
    sales,
    loadMore: loadMoreSales,
    isLoadingInitialData: isLoadingInitialSales,
    isLoadingMore: isLoadingMoreSales,
    isReachingEnd: areSalessReachingEnd,
  } = useSalesWithPagination(ITEMS_LIMIT, 'music');

  const isLoadingInitalData = isLoadingInitialAuctions || isLoadingInitialSales;
  const canLoadMore = !isLoadingInitalData && !(areAuctionsReachingEnd && areSalessReachingEnd);

  return (
    <>
      {isLoadingInitalData && <BeatLoader color="#85838E" size={9} />}
      {!isLoadingInitalData && (
        <LayoutStack rowElements={6} gapX={16} gapY={16}>
          {[
            ...(auctions && transactionSelected !== 'FixedPrice' ? auctions : []).map((auction) => (
              <MusicCard
                key={auction.id}
                playAsSingle
                price={`${auction.minimumBid} ${auction.settlementToken}`}
                itemId={auction.itemId}
                redirectLink={`/auction/${auction.id}`}
              />
            )),
            ...(sales && transactionSelected !== 'Bid' ? sales : []).map((sale) => (
              <MusicCard
                key={sale.id}
                playAsSingle
                price={`${sale.price} ${sale.settlementToken}`}
                itemId={sale.itemId}
                redirectLink={`/sale/${sale.id}`}
              />
            )),
          ]}
        </LayoutStack>
      )}
      {canLoadMore && (
        <LoadMoreButton
          isLoading={!!(isLoadingMoreAuctions || isLoadingMoreSales)}
          onClick={async () => {
            await loadMoreAuctions();
            await loadMoreSales();
          }}
        />
      )}
    </>
  );
};

const CollectionsTabContent = () => {
  const {
    collections,
    loadMore: loadMoreCollections,
    isLoadingInitialData: isLoadingInitialCollections,
    isLoadingMore: isLoadingMoreCollections,
    isReachingEnd: areCollectionsReachingEnd,
  } = useCollectionsWithPagination(ITEMS_LIMIT, 'music');

  return (
    <>
      {isLoadingInitialCollections && <BeatLoader color="#85838E" size={9} />}
      {!isLoadingInitialCollections && (
        <LayoutStack rowElements={6} gapX={16} gapY={16}>
          {[
            ...(collections ? collections : []).map((collection) => (
              <MusicCollectionCard key={collection.id} collection={collection} />
            )),
          ]}
        </LayoutStack>
      )}
      {!areCollectionsReachingEnd && (
        <LoadMoreButton isLoading={!!isLoadingMoreCollections} onClick={loadMoreCollections} />
      )}
    </>
  );
};

const PodcastsTabContent = () => {
  const {
    collections,
    loadMore: loadMoreCollections,
    isLoadingInitialData: isLoadingInitialCollections,
    isLoadingMore: isLoadingMoreCollections,
    isReachingEnd: areCollectionsReachingEnd,
  } = useCollectionsWithPagination(ITEMS_LIMIT, 'podcasts');

  return (
    <>
      {isLoadingInitialCollections && <BeatLoader color="#85838E" size={9} />}
      {!isLoadingInitialCollections && (
        <LayoutStack rowElements={6} gapX={16} gapY={16}>
          {[
            ...(collections ? collections : []).map((collection) => (
              <MusicCollectionCard key={collection.id} collection={collection} />
            )),
          ]}
        </LayoutStack>
      )}
      {!areCollectionsReachingEnd && (
        <LoadMoreButton isLoading={!!isLoadingMoreCollections} onClick={loadMoreCollections} />
      )}
    </>
  );
};
