export const TRACK_COLUMNS_SETS = {
  // title / artist / date / duration
  TAD: 'TAD',
  // title / price / duration
  TPD: 'TPD',
} as const;

export const MUSIC_TABS = {
  MUSIC: 'music',
  COLLECTION: 'collection',
  PODCASTS: 'podcasts',
  AUTHORS: 'authors',
  PLAYLISTS: 'playlists',
};

export const AUTHOR_MUSIC_TABS = {
  SINGLES: 'singles',
  ALBUMS: 'albums',
  PODCASTS: 'podcasts',
  OWNED: 'owned',
  LIKED: 'liked',
  ACTIVITY: 'activity',
};
