export const secondsToDuration = (duration?: string, shortFormat?: boolean) => {
  const seconds = Number(duration);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  return shortFormat
    ? renderTime(seconds)
    : (h > 0 ? h + ' h ' : '') + //hours
        (m > 0 ? m + ' min ' : '') + //minutes
        (s > 0 ? s + ' sec ' : ''); //seconds
};

export const renderTime = (time?: number) => {
  if (time === undefined) {
    return '0:00';
  }

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  // FIXME: implement return with function
  // prettier-ignore
  return `${hours ? `${hours}:` : ''}${hours && minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
