import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardActionButton, CardActionRow } from 'components/common';
import { BidTimer } from 'components/common/bid-timer';
import { ItemCard } from 'components/common/item-card/item-card';
import { Item } from 'services/item-service';

interface AuctionProps {
  itemId?: string;
  item?: Item;
  collectionName?: string;
  ownerWalletAddress?: string;
  minimalBid?: string;
  currency: string;
  quantity: number;
  freeQuantity?: number;
  auctionId: string;
  endOfAuction?: string;
  previewMode?: boolean;
}

export const AuctionCard = ({
  itemId,
  item: itemObj,
  currency,
  quantity,
  freeQuantity,
  collectionName,
  minimalBid,
  ownerWalletAddress,
  auctionId,
  endOfAuction,
  previewMode,
}: AuctionProps): JSX.Element => {
  const auctionUrl = `/auction/${auctionId}`;
  const navigate = useNavigate();

  const isAuctionActive = useCallback((): boolean => {
    if (endOfAuction) {
      const now = new Date();
      const end = new Date(endOfAuction);
      return end > now;
    }
    return false;
  }, [endOfAuction]);

  return (
    <ItemCard
      itemId={itemId}
      prefetchedItem={itemObj}
      collectionName={collectionName}
      ownerWalletAddress={ownerWalletAddress}
      previewMode={previewMode}
      redirectionUrl={auctionUrl}
    >
      <BidTimer endOfAuction={endOfAuction} displayMode={'card'} />
      {isAuctionActive() && (
        <CardActionRow
          button={<CardActionButton text={'Bid now'} onClick={() => navigate(auctionUrl)} />}
          listingInfo={`minimal bid: ${minimalBid} ${currency}`}
          quantityInfo={`${freeQuantity || quantity}/${quantity}`}
        />
      )}
    </ItemCard>
  );
};
