import { BeatLoader } from 'react-spinners';

import { Button, ButtonStyle } from '../button/button';

interface Props {
  isLoading: boolean;
  onClick: () => void;
}

export const LoadMoreButton = ({ isLoading, onClick }: Props): JSX.Element => {
  return (
    <Button buttonStyle={ButtonStyle.second} onClick={onClick} id={'load-more-items-button'}>
      {isLoading ? (
        <>
          Loading &nbsp; <BeatLoader color="#f5f5f5" size={9} />
        </>
      ) : (
        'Load more'
      )}
    </Button>
  );
};
