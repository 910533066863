import { Card } from 'components/common/card';
import { Collection } from 'services/collection-service';
import { useUser } from 'services/user-service';

import styles from './collection-card.module.css';

interface Props {
  collection?: Collection;
  previewMode?: boolean;
}

export const CollectionCard = ({ collection, previewMode }: Props): JSX.Element => {
  const { data: creator } = useUser(collection?.creatorWalletId);

  return (
    <Card
      imageUrl={collection?.previewImageUrl}
      creatorImgUrl={creator?.profileAvatarUrl}
      creatorWalletAddress={collection?.creatorWalletId}
      type="wide"
      title={collection?.displayName || ''}
      bottomElement={<p className={styles.description}>{collection?.description}</p>}
      redirectLink={`/collection/${collection?.id}`}
      disableLinks={previewMode}
    />
  );
};
