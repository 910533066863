import classNames from 'classnames';

import { ColorsValueType, Weight } from 'types/themes';
import { getFontSizeInRem, typographyStylesBuilder } from 'utils/style-builders';

import styles from './typography.module.css';

type InlineElementChildrenType = string | JSX.Element;

interface IProps {
  children: InlineElementChildrenType;
  fontWeight?: Weight;
  fontSize?: number;
  color?: ColorsValueType;
  classNames?: string;
}

interface IParagraphProps extends IProps {
  paddingBottom?: number;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  marginBottom?: number;
  marginTop?: number;
}

export const Span = (props: IProps) => {
  const { fontWeight, color } = props;
  const fontSize = getFontSizeInRem(props?.fontSize || 16);

  return (
    <span
      className={props.classNames}
      style={typographyStylesBuilder({ fontWeight, fontSize, color })}
    >
      {props.children}
    </span>
  );
};

export const Paragraph = (props: IParagraphProps) => {
  const fontSize = getFontSizeInRem(props.fontSize || 16);
  const paddingBottom = getFontSizeInRem(props?.paddingBottom || 0);
  const marginBottom = getFontSizeInRem(props?.marginBottom || 0);
  const marginTop = getFontSizeInRem(props?.marginTop || 0);
  const { fontWeight, color, textAlign } = props;

  return (
    <p
      className={classNames(styles.marginReset, props.classNames)}
      style={typographyStylesBuilder({
        fontSize,
        paddingBottom,
        color,
        textAlign,
        fontWeight,
        marginBottom,
        marginTop,
      })}
    >
      {props.children}
    </p>
  );
};

export const Heading = (props: IParagraphProps) => {
  const fontSize = getFontSizeInRem(props.fontSize || 16);
  const paddingBottom = getFontSizeInRem(props?.paddingBottom || 0);
  const marginBottom = getFontSizeInRem(props?.marginBottom || 0);
  const marginTop = getFontSizeInRem(props?.marginTop || 0);
  const { fontWeight, textAlign, color } = props;

  return (
    <h6
      className={classNames(styles.marginReset, props.classNames)}
      style={typographyStylesBuilder({
        fontSize,
        paddingBottom,
        color,
        textAlign,
        fontWeight,
        marginBottom,
        marginTop,
      })}
    >
      {props.children}
    </h6>
  );
};

export const Typography = { Heading, Paragraph, Span };
