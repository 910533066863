import { Card } from 'components/common/card';
import { UserProfile, useUser } from 'services/user-service';
import truncate from 'utils/string-truncator';

interface Props {
  walletAddress?: string;
  prefetchedUser?: UserProfile;
  redirectLink: string;
  hoverableElement?: React.ReactNode;
  bottomElement?: React.ReactNode;
  active?: boolean;
}

export const UserCard = ({
  walletAddress,
  prefetchedUser,
  redirectLink,
  hoverableElement,
  bottomElement,
  active = false,
}: Props) => {
  const { data } = useUser(walletAddress);
  const user = prefetchedUser || data;
  return (
    <Card
      title={user?.displayName || truncate(walletAddress || user?.id || '')}
      bottomElement={bottomElement}
      hoverableElement={hoverableElement}
      redirectLink={redirectLink}
      imageUrl={user?.profileAvatarUrl}
      imageStyle="circle"
      centerTitle
      displayUsers={false}
      displayBorder={false}
      type="small"
      hoverable
      active={active}
    />
  );
};
