import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { COLORS, TRACK_COLUMNS_SETS } from 'constant';
import { SEPARATORS } from 'constant/separators';

import { CircularImage, ImagePlaceholder, Typography } from 'components/common';
import { MusicTableHeader } from 'components/music-table-header';
import { PlayPauseButton } from 'components/play-pause-button';
import { AudioPlayerContext } from 'context/audio-player-context';
import { Collection } from 'services/collection-service';
import { UserProfile } from 'services/user-service';
import { getItemsTotalDuration } from 'utils/playlist-helpers';

import styles from './particulars.module.css';
import { PlaylistMusicItem } from './playlist-music-item';

interface IProps {
  collection?: Collection;
  creator?: UserProfile;
}

const getAlbumSummaryDescription = (collection?: Collection) => {
  if (!collection) {
    return '';
  }
  const duration = getItemsTotalDuration(collection?.items);
  return collection
    ? `${SEPARATORS.DOT} ${collection?.itemsIds?.length} tracks ${SEPARATORS.DOT} ${duration}`
    : '';
};

export const ParticularAlbum = ({ collection, creator }: IProps) => {
  const { pause, isPaused, playPlaylist, addPlaylistFromItems } = useContext(AudioPlayerContext);

  useEffect(() => {
    if (collection?.items) {
      addPlaylistFromItems(collection?.items, collection.displayName, collection.id);
    }
  }, [collection]);

  const onPlayPauseClicked = () => {
    if (collection?.items) {
      if (!isPaused) {
        pause();
      } else {
        playPlaylist(collection.id);
      }
    }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.previewAlbumColumn}>
          {collection?.previewImageUrl ? (
            <img
              src={collection?.previewImageUrl}
              alt="preview"
              className={classNames(styles.image)}
            />
          ) : (
            <ImagePlaceholder className={classNames(styles.image)} />
          )}
        </div>
        <div className={styles.informationColumn}>
          <Typography.Heading
            fontSize={14}
            fontWeight={600}
            color={COLORS.WHITE}
            marginTop={32}
            textAlign={'left'}
          >
            Album
          </Typography.Heading>
          <Typography.Heading
            fontSize={36}
            fontWeight={700}
            color={COLORS.WHITE}
            textAlign={'left'}
            marginBottom={24}
          >
            {collection?.displayName ?? ''}
          </Typography.Heading>
          <div className={styles.previewAlbumColumns}>
            <CircularImage
              imageUrl={creator?.profileAvatarUrl || '/av-placeholder.jpg'}
              withBorder
              borderColor={COLORS.GRAY_DARKER}
              width={35}
              height={35}
            />
            <Typography.Span fontSize={16} fontWeight={700} color={COLORS.WHITE}>
              {creator?.displayName ?? ''}
            </Typography.Span>
            <Typography.Span fontSize={14} fontWeight={400} color={COLORS.GRAY_TEXT_2}>
              {getAlbumSummaryDescription(collection)}
            </Typography.Span>
          </div>
        </div>
      </div>
      <div className={styles.playlistContainer}>
        <PlayPauseButton
          isPlaying={!isPaused}
          onPlayPauseClicked={onPlayPauseClicked}
          colorType="orange"
          padding={16}
          width={56}
          height={56}
        />
      </div>
      <div>
        <MusicTableHeader setOfColumns={TRACK_COLUMNS_SETS.TAD} />
        <div className={styles.playlistList}>
          {collection?.items?.map((item, i) => (
            <PlaylistMusicItem
              item={item}
              key={item.id}
              authorName={creator?.displayName}
              imageURL={collection?.previewImageUrl}
              queuePosition={(i + 1).toString()}
              albumName={collection?.displayName ?? ''}
              playlistId={collection.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};
