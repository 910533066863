import { COLORS } from 'constant';

import { Image, Link, Typography } from 'components/common';
import { getFontSizeInRem } from 'utils/style-builders';

import styles from '../track-card.module.css';

interface IProps {
  author: string;
  songTitle: string;
  imageURL: string;
  authorURL: string;
  isPlaying: boolean;
}

export const SongDetails = (songDetails: IProps) => (
  <div className={styles.songDetails}>
    <div className={styles.albumCover}>
      <Image
        src={songDetails.imageURL}
        alt="album cover"
        width={getFontSizeInRem(48)}
        height={getFontSizeInRem(48)}
      />
    </div>
    <div>
      <div>
        <Typography.Heading
          color={songDetails.isPlaying ? COLORS.ORANGE : COLORS.WHITE}
          fontSize={14}
          fontWeight={600}
          marginBottom={4}
        >
          {songDetails.songTitle}
        </Typography.Heading>
      </div>
      <Link label={songDetails.author || 'Unknown'} to={songDetails.authorURL} weight={500} />
    </div>
  </div>
);
