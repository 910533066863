import { useCallback, useContext, useMemo, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import classNames from 'classnames';

import CategoryDropdown from 'components/category-picker';

import { ReactComponent as CategoriesIcon } from '../../assets/icons/all_categories.svg';
import { ReactComponent as TransactionTypeIcon } from '../../assets/icons/purchase.svg';
import { NavbarVisibilityContext } from '../../context/navbar-visibility-context';
import { Auction } from '../../services/auctions-service';
import { amountFromWei } from '../../services/blockchain-service';
import { useCategories } from '../../services/categories-service';
import { Collection } from '../../services/collection-service';
import { useItem } from '../../services/item-service';
import { Sale } from '../../services/sales-service';
import { TransactionType } from '../../types/transaction-type';
import { getDropdownOptionDisplayName } from '../../utils/dropdown-option-display-name';
import { AuctionCard } from '../auction-card';
import { CollectionCard } from '../collection-card/collection-card';
import { DisplayType, DisplayTypePicker } from '../common/display-type-picker/display-type-picker';
import { Dropdown, DropdownStyle } from '../common/dropdown';
import { LoadMoreButton } from '../common/load-more-button/load-more-button';
import { SaleCard } from '../sale-card';
import styles from './sales-auctions-list.module.css';

interface Props {
  sales: Array<Sale>;
  auctions: Array<Auction>;
  collections?: Array<Collection>;
  loadMoreAuctions?: () => Promise<void>;
  loadMoreSales?: () => Promise<void>;
  loadMoreCollections?: () => Promise<void>;
  isLoadingMoreSales?: boolean;
  isLoadingMoreAuctions?: boolean;
  isLoadingMoreCollections?: boolean;
  isLoadingInitialSales?: boolean;
  isLoadingInitialAuctions?: boolean;
  isLoadingInitialCollections?: boolean;
  canLoadMoreSales?: boolean;
  canLoadMoreCollections?: boolean;
  canLoadMoreAuctions?: boolean;
  transactionTypeFilter?: TransactionType;
  pickedCategory?: string;
  setPickedCategory?: React.Dispatch<React.SetStateAction<string | undefined>>;
  displayType?: DisplayType;
  canDisplayCollections?: boolean;
  canFilterByCategory?: boolean;
}

export const GenericSalesAuctionsList = ({
  transactionTypeFilter,
  pickedCategory,
  loadMoreAuctions,
  loadMoreSales,
  auctions,
  sales,
  collections,
  displayType,
  canDisplayCollections,
  loadMoreCollections,
  isLoadingMoreAuctions,
  isLoadingMoreCollections,
  isLoadingMoreSales,
  canLoadMoreAuctions,
  canLoadMoreCollections,
  canLoadMoreSales,
  isLoadingInitialAuctions,
  isLoadingInitialCollections,
  isLoadingInitialSales,
  canFilterByCategory = true,
  setPickedCategory,
}: Props): JSX.Element => {
  const { isVisible: isNavbarVisible } = useContext(NavbarVisibilityContext);
  const [pickedTransactionType, setPickedTransactionType] = useState<TransactionType | undefined>(
    transactionTypeFilter,
  );
  const [pickedDisplayType, setPickedDisplayType] = useState<DisplayType>(
    displayType || DisplayType.Items,
  );

  const loadNextEntries = async (): Promise<void> => {
    if (pickedDisplayType === DisplayType.Collections && loadMoreCollections) {
      await loadMoreCollections();
    }

    if (loadMoreSales && pickedTransactionType !== TransactionType.Bid) {
      await loadMoreSales();
    }

    if (loadMoreAuctions && pickedTransactionType !== TransactionType.FixedPrice) {
      await loadMoreAuctions();
    }
  };

  const isCorrectTransactionType = useCallback(
    (entry: Sale | Auction) => {
      if (pickedTransactionType === undefined) {
        return true;
      }

      if ('minimumBid' in entry && pickedTransactionType === TransactionType.Bid) {
        return true;
      }

      if ('price' in entry && pickedTransactionType === TransactionType.FixedPrice) {
        return true;
      }

      return false;
    },
    [pickedTransactionType],
  );

  const displayItems = useMemo(() => {
    const mixed = [...sales, ...auctions];

    if (pickedTransactionType !== undefined) {
      const filtered = mixed.filter((entry) => isCorrectTransactionType(entry));

      return filtered;
    }

    return mixed;
  }, [sales, auctions, pickedTransactionType, isCorrectTransactionType]);

  const displayCollections = useMemo(() => {
    return (collections || []).filter((collection) => {
      if (pickedCategory) {
        return collection.categoryId === pickedCategory;
      }

      return true;
    });
  }, [collections, pickedCategory]);

  const canLoadMore = useMemo(() => {
    if (pickedDisplayType === DisplayType.Collections) {
      return !!canLoadMoreCollections;
    }

    if (pickedTransactionType === TransactionType.Bid) {
      return !!canLoadMoreAuctions;
    }

    if (pickedTransactionType === TransactionType.FixedPrice) {
      return !!canLoadMoreSales;
    }

    return !!canLoadMoreAuctions || !!canLoadMoreSales;
  }, [
    canLoadMoreAuctions,
    canLoadMoreCollections,
    canLoadMoreSales,
    pickedDisplayType,
    pickedTransactionType,
  ]);

  const isLoading = useMemo(() => {
    if (pickedDisplayType === DisplayType.Collections) {
      return !!isLoadingMoreCollections;
    }

    if (pickedTransactionType === TransactionType.Bid) {
      return !!isLoadingMoreAuctions;
    }

    if (pickedTransactionType === TransactionType.FixedPrice) {
      return !!isLoadingMoreSales;
    }

    return !!isLoadingMoreAuctions || !!isLoadingMoreSales;
  }, [
    isLoadingMoreAuctions,
    isLoadingMoreCollections,
    isLoadingMoreSales,
    pickedDisplayType,
    pickedTransactionType,
  ]);

  const renderCollectionsList = (): JSX.Element | JSX.Element[] => {
    if (isLoadingInitialCollections) {
      return (
        <p className={styles.noItems}>
          <BeatLoader color="#85838E" size={9} />
        </p>
      );
    }

    if (displayCollections.length === 0) {
      return <p className={styles.noItems}>No collections to display</p>;
    }

    return displayCollections.map((collection) => (
      <CollectionCard key={`collection-${collection.id}`} collection={collection} />
    ));
  };

  const renderItemsList = (): JSX.Element | JSX.Element[] => {
    if (isLoadingInitialAuctions || isLoadingInitialSales) {
      return (
        <p className={styles.noItems}>
          <BeatLoader color="#85838E" size={9} />
        </p>
      );
    }

    if (displayItems.length === 0) {
      return <p className={styles.noItems}>No items to display</p>;
    }

    return displayItems.map((entry) => (
      <FilterableItem
        entry={entry}
        currentCategory={pickedCategory}
        key={`explore-card-${entry.id}`}
      />
    ));
  };

  const renderLoadMoreButton = (): JSX.Element => {
    if (
      pickedDisplayType === DisplayType.Items &&
      (isLoadingInitialSales || isLoadingInitialAuctions)
    ) {
      return <></>;
    }

    if (pickedDisplayType === DisplayType.Collections && isLoadingInitialCollections) {
      return <></>;
    }

    if (!canLoadMore) {
      return <></>;
    }

    return <LoadMoreButton isLoading={isLoading} onClick={loadNextEntries} />;
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.stickyButtonsRow, {
          [styles.intersectingWithNavbar]: isNavbarVisible,
        })}
      >
        <div className={styles.filtersContainer}>
          {setPickedCategory && canFilterByCategory && (
            <CategoryDropdown
              setPickedCategory={setPickedCategory}
              pickedCategory={pickedCategory}
            />
          )}
          {pickedDisplayType === DisplayType.Items && (
            <TransactionTypePicker
              setPickedTransactionType={setPickedTransactionType}
              pickedTransactionType={pickedTransactionType}
            />
          )}
        </div>
        {canDisplayCollections && (
          <DisplayTypePicker
            value={pickedDisplayType}
            setValue={setPickedDisplayType}
            onPicked={setPickedCategory ? (): void => setPickedCategory(undefined) : undefined}
          />
        )}
      </div>
      <div className={styles.itemsContainer}>
        {pickedDisplayType === DisplayType.Collections && renderCollectionsList()}
        {pickedDisplayType === DisplayType.Items && renderItemsList()}
      </div>
      {renderLoadMoreButton()}
    </div>
  );
};

const FilterableItem = ({
  entry,
  currentCategory,
}: {
  entry: Auction | Sale;
  currentCategory?: string;
}): JSX.Element => {
  const { data: item } = useItem(entry.itemId);
  if (currentCategory !== undefined && item?.categoryId !== currentCategory) {
    return <></>;
  }

  if ('minimumBid' in entry) {
    const auction = entry as Auction;

    return (
      <AuctionCard
        item={item}
        minimalBid={amountFromWei(auction.minimumBid)}
        currency={auction.settlementToken}
        quantity={auction.quantity}
        auctionId={auction.id}
        ownerWalletAddress={auction.ownerWalletId}
        endOfAuction={auction.endsAt}
      />
    );
  }
  const sale = entry as Sale;

  return (
    <SaleCard
      item={item}
      price={amountFromWei(sale.price)}
      currency={sale.settlementToken}
      quantity={sale.startQuantity}
      freeQuantity={sale.remainingQuantity}
      saleId={sale.id}
      ownerWalletAddress={sale.ownerWalletId}
    />
  );
};

const getTransactionTypeDisplayName = (transactionType: TransactionType): string => {
  switch (transactionType) {
    case TransactionType.Bid:
      return 'Place a bid';
    case TransactionType.FixedPrice:
      return 'Buy now';
  }
};

interface TransactionTypePickerProps {
  pickedTransactionType?: TransactionType;
  setPickedTransactionType: React.Dispatch<React.SetStateAction<TransactionType | undefined>>;
}

const TransactionTypePicker = ({
  pickedTransactionType,
  setPickedTransactionType,
}: TransactionTypePickerProps): JSX.Element => {
  return (
    <Dropdown
      icon={<TransactionTypeIcon className={styles.categoryIcon} />}
      selected={
        pickedTransactionType !== undefined
          ? getTransactionTypeDisplayName(pickedTransactionType)
          : undefined
      }
      placeholderTitle="Sale type"
      id={'items-list-transaction-type-dropdown'}
      dropdownStyle={DropdownStyle.FullBorder}
      alignRight
      fixedWidth={160}
      options={[
        <div
          key={'no-transaction-type'}
          onClick={(): void => {
            setPickedTransactionType(undefined);
          }}
          id={'items-list-transaction-type-dropdown-all'}
        >
          All
        </div>,
        <div
          key={'place-a-bid'}
          onClick={(): void => {
            setPickedTransactionType(TransactionType.Bid);
          }}
          id={'items-list-transaction-type-dropdown-auction'}
        >
          {getTransactionTypeDisplayName(TransactionType.Bid)}
        </div>,
        <div
          key={'buy-now'}
          onClick={(): void => {
            setPickedTransactionType(TransactionType.FixedPrice);
          }}
          id={'items-list-transaction-type-dropdown-sale'}
        >
          {getTransactionTypeDisplayName(TransactionType.FixedPrice)}
        </div>,
      ]}
    />
  );
};
