export const baseHeaders = {
  CamelizeKeys: 'true',
  Accept: 'application/json',
};

export const jsonHeaders = {
  'Content-Type': 'application/json',
  ...baseHeaders,
};

export const getAuthHeader = (token: string): { Authorization: string } => {
  return { Authorization: `Bearer ${token}` };
};
