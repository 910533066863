import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuctionsCarousel } from '../../components/auctions-carousel/auctions-carousel';
import { CollectionsCarousel } from '../../components/collections-carousel/collections-carousel';
import { Button, ButtonStyle } from '../../components/common/button/button';
import { SalesAuctionsList } from '../../components/sales-auctions-list/sales-auctions-list';
import { StealthGamesCarousel } from '../../components/stealth-games-carousel/stealth-games-carousel';
import { TopSellers } from '../../components/top-sellers/top-sellers';
import { Item, useItems } from '../../services/item-service';
import { useUser } from '../../services/user-service';
import truncate from '../../utils/string-truncator';
import { BrowseByCategory } from './browse-by-category/browse-by-category';
import styles from './main-page.module.css';

export const MainPage = (): JSX.Element => {
  const { data: items } = useItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const randomItem = useMemo(() => {
    if (items) {
      const filteredItems = items.filter((i) => i.previewImageUrl);
      return filteredItems[Math.round((Math.random() * 10000) % filteredItems.length)];
    }
  }, [items]);

  return (
    <>
      <HeroComponent item={randomItem} />
      <div className={styles.container}>
        <StealthGamesCarousel />
        <CollectionsCarousel />
        <AuctionsCarousel />
        <TopSellers />
        <BrowseByCategory />
        <h2>Explore</h2>
        <SalesAuctionsList />
      </div>
    </>
  );
};

interface HeroProps {
  item?: Item;
}

const HeroComponent = ({ item }: HeroProps): JSX.Element => {
  const navigate = useNavigate();

  const { data: itemCreator } = useUser(item?.firstOwnerWalletId);

  return (
    <div className={styles.hero}>
      {item?.previewImageUrl && (
        <img src={item?.previewImageUrl} className={styles.backgroundImage} />
      )}
      {item?.previewImageUrl && <div className={styles.imageGradient}></div>}
      <div className={styles.heroDescription}>
        <h1>ORE Forge - Next Gen NFT</h1>
        <p>
          Digital marketplace for Next-Gen crypto collectibles and non-fungible tokens. Buy, sell,
          and discover exclusive in-game digital assets.
        </p>
        <div className={styles.heroButtonsWrapper}>
          <Button
            buttonStyle={ButtonStyle.color}
            onClick={(): void => navigate('/explore')}
            id={'main-page-explore-link'}
          >
            Explore
          </Button>
          <Button
            buttonStyle={ButtonStyle.second}
            onClick={(): void => navigate('/create-forge')}
            id={'main-page-create-link'}
          >
            Create new NFT...
          </Button>
        </div>
      </div>
      <div className={styles.carousel}>
        {item?.previewImageUrl && (
          <h2 className={styles.carouselTitle}>
            <Link to={`/item/${item.id}`}>{item.displayName}</Link>
          </h2>
        )}
        {item?.previewImageUrl && (
          <p className={styles.carouselItemCreator}>
            by{' '}
            <Link to={`/user/${item.firstOwnerWalletId}`}>
              {itemCreator?.displayName || truncate(item.firstOwnerWalletId)}
            </Link>
          </p>
        )}
        {item?.previewImageUrl && (
          <img src={item.previewImageUrl} alt="nft" id="main-page-hero-image" />
        )}
        {item?.previewImageUrl && <div className={styles.imageGradient}></div>}
      </div>
    </div>
  );
};
