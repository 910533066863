import { createContext, useCallback, useContext } from 'react';

import {
  addItemToCollection,
  Collection,
  CollectionRequest,
  createNewCollection,
  deleteCollectionById,
  removeItemFromCollection,
} from '../services/collection-service';
import { AuthContext } from './auth-context';

interface CollectionsContextType {
  removeCollection: (collectionId: string) => Promise<void>;
  createCollection: (collectionRequest: CollectionRequest) => Promise<Collection>;
  addItem: (itemId: string, collectionId: string) => Promise<void>;
  removeItem: (itemId: string, collectionId: string) => Promise<void>;
}

export const CollectionsContext = createContext<CollectionsContextType>({
  removeCollection: () => Promise.reject(),
  createCollection: () => Promise.reject(),
  removeItem: () => Promise.reject(),
  addItem: () => Promise.reject(),
});

export const CollectionsContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { jwt } = useContext(AuthContext);

  const createCollection = useCallback(
    async (collectionRequest: CollectionRequest) => {
      const newCollection = await createNewCollection(collectionRequest, jwt);

      return newCollection;
    },
    [jwt],
  );

  const addItem = useCallback(
    async (itemId: string, collectionId: string) => {
      await addItemToCollection(itemId, collectionId, jwt);
    },
    [jwt],
  );

  const removeItem = useCallback(
    async (itemId: string, collectionId: string) => {
      await removeItemFromCollection(itemId, collectionId, jwt);
    },
    [jwt],
  );

  const removeCollection = useCallback(
    async (collectionId: string) => {
      await deleteCollectionById(collectionId, jwt);
    },
    [jwt],
  );

  return (
    <CollectionsContext.Provider
      value={{
        createCollection,
        addItem,
        removeItem,
        removeCollection,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};
