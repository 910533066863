import React, { ComponentType, useContext, useState } from 'react';

import { Button, ButtonStyle } from 'components/common';
import { GenericPopup } from 'components/common/generic-popup';
import { AuthContext } from 'context/auth-context';
import { getLastConnectedProvider, ProviderType } from 'context/browser-auth-context';
import { getChainIdFromSymbol, getCurrentChain } from 'utils/blockchain-helpers';

import styles from './with-chain-change.module.css';

const WithChainChange = <T extends { onClick: () => void }>(Component: ComponentType<T>) => {
  const WithChainChangeComponent = ({
    chainId,
    ...props
  }: {
    chainId: number;
    [x: string]: any;
  }) => {
    const { browserActions } = useContext(AuthContext);
    const [popupOpen, setPopupOpen] = useState(false);

    const provider = getLastConnectedProvider();
    const currentChainId = getChainIdFromSymbol(getCurrentChain());
    const chainChangeNecessary = currentChainId !== chainId;

    const makeWalletCall = async () => {
      provider === ProviderType.Metamask
        ? await browserActions?.connectMetamask(chainId)
        : await browserActions?.connectWalletConnect?.();
      window.location.reload();
    };

    return (
      <div>
        {popupOpen && (
          <GenericPopup
            canBeClosed
            title="Do you want to switch chain?"
            id={'delete-popup'}
            onClose={(e) => {
              e.stopPropagation();
              setPopupOpen(false);
            }}
          >
            <div>
              This action will cause the site to refresh and any details on this page will be lost.
            </div>
            <div className={styles.popupButtons}>
              <Button
                id="delete-playlist-confirm"
                buttonStyle={ButtonStyle.color}
                onClick={async (e) => {
                  e.stopPropagation();
                  await makeWalletCall();
                }}
              >
                YES
              </Button>
              <Button
                id="delete-playlist-cancel"
                buttonStyle={ButtonStyle.white}
                onClick={(e) => {
                  e.stopPropagation();
                  setPopupOpen(false);
                }}
              >
                NO
              </Button>
            </div>
          </GenericPopup>
        )}
        <Component
          {...(props as T)}
          onClick={
            chainChangeNecessary
              ? () => {
                  setPopupOpen(true);
                }
              : undefined
          }
        />
      </div>
    );
  };

  return WithChainChangeComponent;
};

export default WithChainChange;
