import { BrowserAuthContext, BrowserAuthContextProvider } from './browser-auth-context';
import { LauncherAuthContext, LauncherAuthContextProvider } from './launcher-auth-context';

export interface AuthContextType {
  isAuthorized: boolean;
  isBanned: boolean;
  isInitialized: boolean;
  jwt: string;
  walletAddress?: string;
  browserActions?: {
    connectMetamask: (currentChainId: number) => Promise<void>;
    connectWalletConnect?: () => Promise<void>;
    disconnect?: () => void;
  };
  providersSet: boolean;
  currentChainId?: number;
}

export const isLauncher = (window as any).oreStealth !== undefined;

export const AuthContext = isLauncher ? LauncherAuthContext : BrowserAuthContext;
export const AuthContextProvider = isLauncher
  ? LauncherAuthContextProvider
  : BrowserAuthContextProvider;
