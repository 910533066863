import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { ReactComponent as YoutubeLogo } from '../../../assets/icons/youtube-page.svg';
import OreRedYellowLogo from '../../../assets/ore-red-yellow-logo.png';
import { ReactComponent as DiscordLogo } from '../../../assets/social-icons/discord.svg';
import { ReactComponent as FacebookLogo } from '../../../assets/social-icons/fb.svg';
import { ReactComponent as LinkedinLogo } from '../../../assets/social-icons/linkedin.svg';
import { ReactComponent as TelegramLogo } from '../../../assets/social-icons/telegram.svg';
import { ReactComponent as TwitterLogo } from '../../../assets/social-icons/twitter.svg';
import styles from './footer.module.css';

export const Footer = (): JSX.Element => {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.navContainer}>
          <ul>
            <ListTitle title="Marketplace" />
            <li>
              <NavLink path="/music/home" name="ORE Music" />
            </li>
            <li>
              <NavLink path="/explore" name="Discover" />
            </li>
            <li>
              <NavLink path="/create" name="Create" />
            </li>
            <li>
              <NavLink path="/activity" name="Activity" />
            </li>
            <li>
              <NavLink path="/privacy-policy" name="Privacy Policy" />
            </li>
          </ul>
          <ul>
            <ListTitle title="Profile" />
            <li>
              <NavHashLink path="/current-user#items" name="Items" />
            </li>
            <li>
              <NavHashLink path="/current-user#collections" name="Collections" />
            </li>
            <li>
              <NavLink path="/activity" name="Activity" />
            </li>
          </ul>
          <ul>
            <ListTitle title="ORE Help" />
            <li>
              <NavLink path="/faq" name="FAQ" />
            </li>
            <li>
              <a
                className={styles.navLink}
                href="https://ore-system.atlassian.net/servicedesk/customer/portal/2"
                target="_blank"
                rel="noreferrer"
              >
                Support
              </a>
            </li>
          </ul>
          <ul>
            <ListTitle title="Join our community!" />
            <ul className={styles.icons}>
              <li>
                <IconLink path="https://www.facebook.com/oresystem/">
                  <FacebookLogo />
                </IconLink>
              </li>
              <li>
                <IconLink path="https://twitter.com/oresystemnft">
                  <TwitterLogo />
                </IconLink>
              </li>
              <li>
                <IconLink path="https://www.youtube.com/c/oresystemnft">
                  <YoutubeLogo className={styles.svgIcon} />
                </IconLink>
              </li>
              <li>
                <IconLink path="https://www.linkedin.com/company/ore-system/">
                  <LinkedinLogo />
                </IconLink>
              </li>
              <li>
                <IconLink path="https://discord.gg/6d8pk6jubb">
                  <DiscordLogo />
                </IconLink>
              </li>
              <li>
                <IconLink path="https://t.me/theoresystem">
                  <TelegramLogo />
                </IconLink>
              </li>
            </ul>
          </ul>
        </div>
        <div className={styles.companyInfo}>
          <img src={OreRedYellowLogo} alt="Ore Forge" />
          <p>
            Digital marketplace for Next-Gen crypto collectibles and non-fungible tokens. Buy, sell,
            and discover exclusive in-game digital assets.
          </p>
          <p>© ORE FORGE, 2022. All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

interface ListTitleProps {
  title: string;
}

const ListTitle = ({ title }: ListTitleProps): JSX.Element => {
  return <li className={styles.listTitle}>{title}</li>;
};

interface NavLinkProps {
  path: string;
  name: string;
}

const NavLink = ({ path, name }: NavLinkProps): JSX.Element => {
  return (
    <Link className={styles.navLink} to={path}>
      {name}
    </Link>
  );
};

const NavHashLink = ({ path, name }: NavLinkProps): JSX.Element => {
  return (
    <HashLink className={styles.navLink} to={path}>
      {name}
    </HashLink>
  );
};

interface IconLinkProps {
  path: string;
  children: React.ReactNode;
}

const IconLink = ({ path, children }: IconLinkProps): JSX.Element => {
  return (
    <a className={styles.iconLink} href={path} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
