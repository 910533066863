import { COLORS } from 'constant';

import { LayoutStack, Typography } from 'components/common';

type Props = {
  heading: string;
  children?: JSX.Element[] | JSX.Element;
};

export const MusicMainPageRow = (props: Props) => (
  <>
    <Typography.Heading fontSize={24} fontWeight={700} marginBottom={24} color={COLORS.WHITE}>
      {props.heading}
    </Typography.Heading>
    <LayoutStack rowElements={6} marginBottom={40} gapX={16}>
      {props.children}
    </LayoutStack>
  </>
);
