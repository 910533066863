import { createContext, useContext } from 'react';

import { AllowedCurrencies } from 'utils/blockchain-helpers';

import {
  Auction,
  Bid,
  createNewAuction,
  markBidAccepted,
  placeNewBid,
} from '../services/auctions-service';
import { acceptBid as acceptBidChain } from '../services/blockchain-service';
import { AuthContext } from './auth-context';

interface AuctionsContextType {
  createAuction: (
    minimumBid: string,
    itemId: string,
    quantity: number,
    settlementToken: AllowedCurrencies,
    startsAt?: string,
    endsAt?: string,
    purchaseLimit?: number,
  ) => Promise<Auction>;
  placeBid: (auctionId: string, quantity: number, amount: string) => Promise<void>;
  acceptBid: (auction: Auction, bid: Bid) => Promise<void>;
}

export const AuctionsContext = createContext<AuctionsContextType>({
  createAuction: () => Promise.reject(),
  placeBid: () => Promise.reject(),
  acceptBid: () => Promise.reject(),
});

export const AuctionsContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { jwt } = useContext(AuthContext);

  const createAuction = async (
    minimumBid: string,
    itemId: string,
    quantity: number,
    settlementToken: AllowedCurrencies,
    startsAt?: string,
    endsAt?: string,
    purchaseLimit?: number,
  ): Promise<Auction> => {
    const newAuction = await createNewAuction(
      jwt,
      minimumBid,
      itemId,
      quantity,
      settlementToken,
      startsAt,
      endsAt,
      purchaseLimit,
    );

    return newAuction;
  };

  const placeBid = async (auctionId: string, quantity: number, amount: string): Promise<void> => {
    await placeNewBid(auctionId, jwt, quantity, amount);
  };

  const acceptBid = async (auction: Auction, bid: Bid): Promise<void> => {
    await acceptBidChain(
      bid.ownerWalletId,
      auction.settlementToken,
      bid.amount,
      auction.itemId,
      bid.quantity,
    );

    await markBidAccepted(auction.id, bid.id, jwt);
  };

  return (
    <AuctionsContext.Provider
      value={{
        createAuction,
        placeBid,
        acceptBid,
      }}
    >
      {children}
    </AuctionsContext.Provider>
  );
};
