import { useContext, useEffect, useMemo } from 'react';

import { PlayPauseButton } from 'components/play-pause-button';
import { UserCard } from 'components/user-card';
import { AudioPlayerContext } from 'context/audio-player-context';
import { useCreatedItemsByWalletIdAndCategory } from 'services/item-service';
import { UserProfile } from 'services/user-service';
import { generateAuthorSinglesPlaylistId } from 'utils/music-playlist-names-generators';

import styles from './author-card.module.css';

interface Props {
  walletAddress?: string;
  prefetchedUser?: UserProfile;
}

export const AuthorCard = ({ walletAddress, prefetchedUser }: Props) => {
  const ensuredWalletAddress = walletAddress || prefetchedUser?.id;
  const { pause, play, isPaused, playPlaylist, addPlaylistFromItems, currentPlaylist } =
    useContext(AudioPlayerContext);
  const { data: currentUserItems } = useCreatedItemsByWalletIdAndCategory(
    ensuredWalletAddress,
    'music',
  );
  const playlistId = useMemo(
    () =>
      ensuredWalletAddress ? generateAuthorSinglesPlaylistId(ensuredWalletAddress) : undefined,
    [ensuredWalletAddress],
  );

  const playableItems = useMemo(
    () => (currentUserItems || []).filter((i) => i.lockedMediaIpfsUrl || i.streamPreviewUrl),
    [currentUserItems],
  );

  const isACurrentPlaylist = currentPlaylist !== undefined && currentPlaylist?.id === playlistId;

  const isPlaying = !isPaused && isACurrentPlaylist;

  const canBePlayed = playableItems.length > 0;

  useEffect(() => {
    if (playlistId && playableItems && ensuredWalletAddress) {
      addPlaylistFromItems(playableItems, `${ensuredWalletAddress} singles`, playlistId);
    }
  }, [playableItems, playlistId, ensuredWalletAddress, addPlaylistFromItems]);

  const onClicked = () => {
    if (isACurrentPlaylist) {
      if (isPlaying) {
        pause();
        return;
      }
      play();
      return;
    }

    if (playlistId) {
      playPlaylist(playlistId);
      return;
    }
  };

  const renderButton = () => (
    <div className={styles.playPauseButton}>
      <PlayPauseButton
        isPlaying={isPlaying}
        onPlayPauseClicked={onClicked}
        colorType="orange"
        width={56}
        height={56}
        padding={18}
      />
    </div>
  );

  return (
    <UserCard
      walletAddress={ensuredWalletAddress}
      redirectLink={`/music/user/${ensuredWalletAddress}`}
      hoverableElement={canBePlayed && !isPlaying && renderButton()}
      bottomElement={
        <div className={styles.container}>
          {canBePlayed && isACurrentPlaylist && renderButton()}
          <p className={styles.subtitle}>Artist</p>
        </div>
      }
      active={isPlaying}
    />
  );
};
