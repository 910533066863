import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { env } from 'env/env';

import { Button, ButtonStyle, LayoutStack } from 'components/common';
import { GenericPopup } from 'components/common/generic-popup/generic-popup';
import { PaypalCheckoutButtons } from 'components/common/paypal-checkout-button';
import { UnderlinedInputControl } from 'components/common/underlined-input/underlined-input';
import { CurrencyContext } from 'context/currency-context';
import { CurrentUserProfileContext } from 'context/current-user-profile-context';
import { SalesContext } from 'context/sales-context';
import { CallState, useCallWithState } from 'hooks/call-with-state';
import { allowOreAmount, amountFromWei } from 'services/blockchain-service';
import { Item } from 'services/item-service';
import { useOrePriceInUSD } from 'services/paypal-transactions-service';
import { Sale } from 'services/sales-service';
import { UserProfile } from 'services/user-service';
import { TransactionType } from 'types/transaction-type';
import truncate from 'utils/string-truncator';

import styles from './transaction-popups.module.css';
import { TransactionSummary } from './transaction-summary';

enum Step {
  first,
  second,
}

interface FormInputs {
  quantity: number;
}

interface Props {
  close: () => void;
  item: Item;
  itemOwner: UserProfile | undefined;
  sale: Sale;
  serviceFee: number;
}

export const CheckoutPopup = ({ close, item, itemOwner, serviceFee, sale }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { oreBalance, bnbBalance, walletAddress } = useContext(CurrentUserProfileContext);
  const { buyToken } = useContext(SalesContext);
  const [step, setStep] = useState<Step>(Step.first);
  const priceFloat = parseFloat(amountFromWei(sale.price));
  const { orePriceInUSD } = useContext(CurrencyContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormInputs>();

  const quantity = watch('quantity');
  const canBuy =
    quantity !== undefined &&
    priceFloat * quantity < (sale.settlementToken === 'ORE' ? oreBalance : bnbBalance);

  const canBeBoughtWithPayPal =
    orePriceInUSD !== undefined && quantity !== undefined && quantity > 0 && sale.settlementToken === 'ORE'
      ? priceFloat * quantity * orePriceInUSD > 5
      : false;

  const allowPrice = useCallback(async () => {
    try {
      await allowOreAmount(walletAddress, priceFloat * quantity);
    } catch {
      toast.error('Please try again, we could not accept allowance');
    }
  }, [priceFloat, quantity, walletAddress]);

  const buy = useCallback(async () => {
    await buyToken(
      priceFloat,
      quantity,
      sale.ownerWalletId,
      item.id,
      sale.settlementToken,
      sale.id,
    );
  }, [buyToken, item.id, priceFloat, quantity, sale.id, sale.ownerWalletId, sale.settlementToken]);

  const { makeCall: makeAllowanceCall, state: allowanceCallState } = useCallWithState(
    allowPrice,
    true,
  );
  const { makeCall: makeBuyCall, state: buyCallState } = useCallWithState(buy, true);

  const submit = async (): Promise<void> => {
    if (sale.settlementToken === 'BNB') {
      await makeBuyCall();
      close();
      return;
    } else if (sale.settlementToken === 'ORE') {
      setStep(Step.second);
    }
  };

  const getAllowanceButtonChild = (): JSX.Element | string => {
    switch (allowanceCallState) {
      case CallState.Ready:
        return 'Approve';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Approved!';
    }
  };

  const getBuyButtonChild = (): JSX.Element | string => {
    if (sale.settlementToken === 'ORE' && step === Step.first) {
      return 'Next';
    }

    switch (buyCallState) {
      case CallState.Ready:
        return 'Purchase';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Purchased!';
    }
  };

  const processPurchase = useCallback(() => {
    quantity !== undefined ? makeAllowanceCall : (): Promise<void> => Promise.resolve();
  }, [makeAllowanceCall, quantity]);

  switch (step) {
    case Step.first:
      return (
        <GenericPopup title="Checkout" canBeClosed onClose={close} id={'checkout-popup-first-step'}>
          <div className={styles.subTitle}>
            You are about to purchase a <span className={styles.NFT}>{item.displayName}</span>
            <br />
            from{' '}
            <span
              className={styles.userName}
              onClick={(): void => navigate(`/user/${sale.ownerWalletId}`)}
            >
              {itemOwner?.displayName || truncate(sale.ownerWalletId, env.shortenedWalletIdLength)}
            </span>
          </div>
          <form>
            <UnderlinedInputControl
              type="number"
              title="Quantity"
              placeholder="e.g. 1"
              id={'checkout-popup-quantity-input'}
              {...register('quantity', {
                required: {
                  value: true,
                  message: 'You must specify quantity',
                },
                min: {
                  value: 1,
                  message: 'You must buy at least 1 item',
                },
                max: {
                  value: sale.remainingQuantity,
                  message: `You can't buy more items than ${sale.remainingQuantity}.`,
                },
              })}
              errorMessage={errors.quantity?.message}
            />
          </form>

          <TransactionSummary
            transactionType={TransactionType.FixedPrice}
            currency={sale.settlementToken}
            balance={sale.settlementToken === 'ORE' ? oreBalance : bnbBalance}
            nftPrice={quantity ? priceFloat * quantity : 0}
            serviceFeePercent={serviceFee}
            orePriceInUSD={orePriceInUSD}
          />
          <LayoutStack rowElements={1} gapY={8}>
            <Button
              buttonStyle={ButtonStyle.wideWhite}
              onClick={handleSubmit(submit, () => toast.warn('Please verify form.'))}
              disabled={
                !canBuy &&
                (allowanceCallState !== CallState.Ended || buyCallState !== CallState.Ready)
              }
              id={'checkout-popup-purchase-button'}
            >
              {getBuyButtonChild()}
            </Button>
            {canBeBoughtWithPayPal ? (
              <>
                <h4>Purchase with PayPal</h4>
                <PaypalCheckoutButtons
                  tokenQuantity={priceFloat}
                  onClick={handleSubmit(() => ({}))}
                  onApprove={close}
                  directItemPurchase={{
                    sale_id: sale.id,
                    item_id: item.id,
                    item_quantity: quantity,
                  }}
                  isValid={watch('quantity') !== undefined && watch('quantity') > 0}
                />
              </>
            ) : (
              <></>
            )}
            <Button
              buttonStyle={ButtonStyle.wideSecond}
              onClick={close}
              id={'checkout-popup-cancel-button'}
            >
              Cancel
            </Button>
          </LayoutStack>
        </GenericPopup>
      );
    case Step.second:
      return (
        <GenericPopup
          title="Follow steps"
          canBeClosed={buyCallState === CallState.Ready}
          onClose={close}
          id={'checkout-popup-second-step'}
        >
          <h4>Approve the transaction</h4>
          <Button
            className={styles.button}
            buttonStyle={
              allowanceCallState === CallState.Ended ? ButtonStyle.wideGreen : ButtonStyle.wideWhite
            }
            onClick={processPurchase}
            disabled={allowanceCallState !== CallState.Ready}
            id={'checkout-popup-approve-button'}
          >
            {getAllowanceButtonChild()}
          </Button>
          <h4>Send transaction with your wallet</h4>
          <Button
            className={styles.button}
            buttonStyle={
              buyCallState === CallState.Ended ? ButtonStyle.wideGreen : ButtonStyle.wideWhite
            }
            onClick={(): Promise<void> => makeBuyCall().then(close)}
            disabled={allowanceCallState !== CallState.Ended || buyCallState !== CallState.Ready}
            id={'checkout-popup-second-step-purchase-button'}
          >
            {getBuyButtonChild()}
          </Button>
        </GenericPopup>
      );
  }
};
