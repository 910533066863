import { useContext } from 'react';
import axios from 'axios';
import { env } from 'env/env';
import useSWR from 'swr';

import { AuthContext } from 'context/auth-context';

import { Collection } from './collection-service';
import { dataFetcher } from './data-fetcher';
import { baseHeaders, getAuthHeader, jsonHeaders } from './headers';
import { Item, ItemsList } from './item-service';
import { UserProfile } from './user-service';

export interface PlaylistsList {
  offset: number;
  totalCount: number;
  playlists: Array<Playlist>;
}

export interface Playlist {
  createdAt: string;
  displayName: string;
  id: string;
  items: Item[];
  itemsIds: string[];
  ownerWalletId: string;
  totalMediaLengthSeconds: number;
  updatedAt: string;
  description?: string;
}

export const useCurrentUserMusic = () => {
  const { jwt, walletAddress } = useContext(AuthContext);

  const url = `${env.apiUrl}/users/${walletAddress}/music`;
  const result = useSWR(walletAddress && jwt ? url : null, (url) =>
    dataFetcher<ItemsList>(url, jwt),
  );
  return result;
};

export const useCurrentUserPlaylists = () => {
  const { jwt, walletAddress } = useContext(AuthContext);

  const url = `${env.apiUrl}/users/${walletAddress}/playlists`;
  const result = useSWR(walletAddress && jwt ? url : null, (url) =>
    dataFetcher<PlaylistsList>(url, jwt),
  );
  return result;
};

export const usePlaylist = (id?: string) => {
  const { jwt, walletAddress } = useContext(AuthContext);

  const url = `${env.apiUrl}/playlists/${id}`;
  const result = useSWR(walletAddress && jwt ? url : null, (url) =>
    dataFetcher<Playlist>(url, jwt),
  );
  return result;
};

export const useCurrentUserTopAuthors = (limit?: number) => {
  const { jwt, walletAddress } = useContext(AuthContext);

  const url = `${env.apiUrl}/users/${walletAddress}/music/authors/top?limit=${limit || ''}`;
  const result = useSWR(walletAddress && jwt ? url : null, (url) =>
    dataFetcher<{
      users: UserProfile[];
    }>(url, jwt),
  );
  return result;
};

export const usePopularMusic = () => {
  const { jwt } = useContext(AuthContext);

  const url = `${env.apiUrl}/music/popular_top`;
  const result = useSWR(jwt ? url : null, (url) => dataFetcher<ItemsList>(url, jwt));
  return result;
};

export const notifyPlayed = (itemId: string, jwt: string) => {
  const url = `${env.apiUrl}/items/${itemId}/notify_played`;
  const body = JSON.stringify({ itemId });
  const headers = { ...baseHeaders, ...getAuthHeader(jwt) };
  return fetch(url, { method: 'POST', body, headers });
};

export const usePopularNewAlbums = (limit?: number) => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/music/albums/popular_new?limit=${limit || ''}`;
  const result = useSWR(jwt ? url : null, (url) =>
    dataFetcher<{
      collections: Collection[];
    }>(url, jwt),
  );

  return result;
};

export const createNewPlaylist = async (
  jwt: string,
  displayName: string,
  itemsIds: string[],
): Promise<any> => {
  const url = `${env.apiUrl}/playlists`;
  const body = JSON.stringify({
    display_name: displayName,
    items_ids: itemsIds,
  });

  return await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'POST',
    data: body,
  });
};

export const notifyPlaylitPlayed = (playlistId: string, jwt: string) => {
  const url = `${env.apiUrl}/playlists/${playlistId}/notify_played`;
  const body = JSON.stringify({ itemId: playlistId });
  const headers = { ...baseHeaders, ...getAuthHeader(jwt) };
  return fetch(url, { method: 'POST', body, headers });
};

export const editPlaylist = async (
  jwt: string,
  playlistId: string,
  displayName: string,
  itemsIds: string[],
): Promise<any> => {
  const url = `${env.apiUrl}/playlists/${playlistId}`;
  const body = JSON.stringify({
    display_name: displayName,
    items_ids: itemsIds,
  });

  return await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'PATCH',
    data: body,
  });
};

export const deletePlaylist = async (jwt: string, playlistId: string): Promise<any> => {
  const url = `${env.apiUrl}/playlists/${playlistId}`;

  return await axios({
    url,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(jwt),
    },
    method: 'DELETE',
  });
};
