import classNames from 'classnames';

import styles from './positioning-wrapper.module.css';

type AlignType = 'start' | 'center' | 'end';

interface IProps {
  children?: JSX.Element | JSX.Element[];
  verticalAlign?: AlignType;
  horizontalAlign?: AlignType;
  height?: string;
}

export const PositioningWrapper = ({
  children,
  verticalAlign = 'start',
  horizontalAlign = 'start',
  height = '100%',
}: IProps) => (
  <div
    style={{ height }}
    className={classNames(styles.positioningWrapper, {
      [styles.horizontalStart]: horizontalAlign === 'start',
      [styles.horizontalCenter]: horizontalAlign === 'center',
      [styles.horizontalEnd]: horizontalAlign === 'end',
      [styles.verticalStart]: verticalAlign === 'start',
      [styles.verticalCenter]: verticalAlign === 'center',
      [styles.verticalEnd]: verticalAlign === 'end',
    })}
  >
    {children}
  </div>
);
