import { createContext } from 'react';

import { useOrePriceInUSD } from 'services/paypal-transactions-service';

interface CurrencyContextType {
  orePriceInUSD?: number;
}

export const CurrencyContext = createContext<CurrencyContextType>({
  orePriceInUSD: undefined,
});

export const CurrencyContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const { price } = useOrePriceInUSD();
  return (
    <CurrencyContext.Provider
      value={{
        orePriceInUSD: price,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
