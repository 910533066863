import { useEventListener } from './event-listener';

// https://usehooks-ts.com/react-hook/use-on-click-outside

type Handler = (event: MouseEvent) => void;

export const useOnClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  handler: Handler,
): void => {
  useEventListener('mousedown', (event) => {
    const el = ref?.current;
    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target as Node)) {
      return;
    }
    // Explicit type for "mousedown" event.
    handler(event as unknown as MouseEvent);
  });
};
