import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { GenericPopup } from 'components/common/generic-popup/generic-popup';
import { PaypalCheckoutButtons } from 'components/common/paypal-checkout-button';
import { UnderlinedInputControl } from 'components/common/underlined-input/underlined-input';
import { CurrencyContext } from 'context/currency-context';
import { blockchainNumberRegex } from 'utils/blockchain-number-regex';

interface Props {
  close: () => void;
}

type FormInputs = {
  quantity: number;
};

export const TopUpPopup = ({ close }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const quantity = watch('quantity');
  const { orePriceInUSD } = useContext(CurrencyContext);
  const minQuantity = orePriceInUSD ? 5 / orePriceInUSD : 0.000000000000001;

  return (
    <GenericPopup canBeClosed title="Top up your account" id="top-up-popup" onClose={close}>
      <UnderlinedInputControl
        title="Quantity"
        placeholder="Enter the quantity of ORE you want to top up"
        type="number"
        unit={<>ORE</>}
        {...register('quantity', {
          required: {
            value: true,
            message: 'You have to type in how much ORE you want to buy!',
          },
          min: {
            value: minQuantity,
            message: `The minimum purchase quantity is ${minQuantity} ORE (5 USD)`,
          },
          pattern: {
            value: blockchainNumberRegex,
            message: 'It must be a number with maximum of 15 decimal places',
          },
        })}
        id={'choose-quantity-input'}
        errorMessage={errors.quantity?.message}
      />

      <PaypalCheckoutButtons
        tokenQuantity={quantity}
        isValid={
          watch('quantity') !== undefined &&
          minQuantity !== undefined &&
          watch('quantity') > minQuantity
        }
        onClick={handleSubmit(() => ({}))}
        onApprove={close}
      />
    </GenericPopup>
  );
};
