import { createContext, useState } from 'react';

interface NavbarVisibility {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
}

export const NavbarVisibilityContext = createContext<NavbarVisibility>({
  isVisible: true,
  setIsVisible: () => ({}),
});

export const NavbarVisibilityContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return (
    <NavbarVisibilityContext.Provider
      value={{
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </NavbarVisibilityContext.Provider>
  );
};
