import { useContext } from 'react';
import axios from 'axios';
import { snakeKeys } from 'js-convert-case';
import useSWR, { SWRResponse } from 'swr';

import { AuthContext } from '../context/auth-context';
import { env } from '../env/env';
import { objectToFormData } from '../utils/object-to-form-data';
import { dataFetcher } from './data-fetcher';
import { baseHeaders, getAuthHeader, jsonHeaders } from './headers';
import { Item } from './item-service';
import { Playlist } from './music-service';

export interface UserProfile {
  id: string;
  displayName?: string;
  bio?: string;
  verified?: boolean;
  verificationRequestSent?: boolean;
  customUrlSlug?: string;
  websiteUrl?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  youtubeChannel?: string;
  profileAvatarUrl?: string;
  profileBannerUrl?: string;
  createdAt: string;
  updatedAt: string;
  email?: string;
  likedItemsIds: string[] | null;
  ownedItems: Array<{
    itemId: string;
    quantity: number;
  }>;
  privacyPolicyAcceptedAt?: string;
  recentlyPlayedItems: Item[];
  recentlyPlayedPlaylists: Playlist[];
}

export interface Subscription {
  name: string;
  active: boolean;
  activeUntil: string;
}

export interface UserProfileRequest {
  displayName?: string;
  email?: string;
  bio?: string;
  websiteUrl?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  youtubeChannel?: string;
  acceptedPrivacyPolicy?: 'true';
}

export const useUser = (walletAddress?: string): SWRResponse<UserProfile> => {
  const { jwt } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletAddress?.toLowerCase()}`;

  const result = useSWR(walletAddress && jwt ? url : null, (url) =>
    dataFetcher<UserProfile>(url, jwt),
  );
  return result;
};

export const useCurrentUser = (): SWRResponse<UserProfile> => {
  const { jwt, walletAddress } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletAddress?.toLowerCase()}`;

  const result = useSWR(jwt && walletAddress ? url : null, (url) =>
    dataFetcher<UserProfile>(url, jwt),
  );
  return result;
};

export const createUserProfile = async (
  userProfile: UserProfileRequest,
  token: string,
): Promise<UserProfile> => {
  const url = `${env.apiUrl}/users`;
  const body = JSON.stringify(snakeKeys(userProfile));

  const response = await axios({
    url,
    method: 'POST',
    data: body,
    headers: {
      ...jsonHeaders,
      ...getAuthHeader(token),
    },
  });

  return response.data as UserProfile;
};

export const editUserProfile = async (
  walletAddress: string,
  userProfile: UserProfileRequest,
  token: string,
  profileAvatar?: File,
  profileBanner?: File,
): Promise<UserProfile> => {
  const url = `${env.apiUrl}/users/${walletAddress}`;
  const form = objectToFormData({ ...userProfile, profileAvatar, profileBanner });

  const response = await axios({
    url,
    method: 'PATCH',
    data: form,
    headers: {
      ...baseHeaders,
      ...getAuthHeader(token),
    },
  });

  return response.data as UserProfile;
};

export const refreshUserItemQuantity = async (
  walletAddress: string,
  itemId: string,
): Promise<UserProfile> => {
  const url = `${env.apiUrl}/users/${walletAddress.toLowerCase()}/items/${itemId}/refresh`;

  const response = await axios({
    url,
    method: 'POST',
    headers: {
      ...baseHeaders,
    },
  });

  return response.data as UserProfile;
};

export const acceptPrivacyPolicy = async (
  walletAddress: string,
  jwt: string,
): Promise<UserProfile> => {
  const url = `${env.apiUrl}/users/${walletAddress}/accept_privacy_policy`;

  const response = await axios({
    url,
    method: 'POST',
    headers: {
      ...baseHeaders,
      ...getAuthHeader(jwt),
    },
  });

  return response.data as UserProfile;
};

export const useCurrentUserSubscriptions = () => {
  const { jwt, walletAddress } = useContext(AuthContext);
  const url = `${env.apiUrl}/users/${walletAddress}/subscriptions`;

  const result = useSWR(jwt && walletAddress ? url : null, (url) =>
    dataFetcher<{
      subscriptions: Subscription[];
    }>(url, jwt),
  );

  return result;
};
