import { useLikeItem } from '../../../hooks/like-item';
import { Item, useItem } from '../../../services/item-service';
import { useUser } from '../../../services/user-service';
import { Card } from '../card';

interface Props {
  prefetchedItem?: Item;
  itemId?: string;
  collectionName?: string;
  ownerWalletAddress?: string;
  previewMode?: boolean;
  redirectionUrl?: string;
  hoverableElement?: React.ReactNode;
}

export const ItemCard = ({
  prefetchedItem,
  itemId,
  collectionName,
  ownerWalletAddress,
  children,
  previewMode,
  hoverableElement,
  redirectionUrl,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const { data: fetchedItem } = useItem(itemId);
  const item = fetchedItem || prefetchedItem;
  const { handleLikeItem, isLiked } = useLikeItem(item?.id);

  const { data: creator } = useUser(item?.firstOwnerWalletId);
  const { data: owner } = useUser(ownerWalletAddress);

  const creatorImgUrl = creator?.profileAvatarUrl;
  const ownerImgUrl = owner?.profileAvatarUrl;

  const createRedirectionUrl = (): string => {
    if (!previewMode) {
      if (redirectionUrl) {
        return redirectionUrl;
      } else if (item?.id) {
        return `/item/${item.id}`;
      }
    }
    return '#';
  };

  return (
    <Card
      imageUrl={item?.previewImageUrl}
      creatorImgUrl={creatorImgUrl}
      creatorWalletAddress={item?.firstOwnerWalletId}
      ownerImgUrl={ownerImgUrl}
      ownerWalletAddress={ownerWalletAddress}
      likeable
      liked={isLiked}
      likesCount={item?.likesCount}
      onLikeClicked={handleLikeItem}
      disableLinks={previewMode}
      redirectLink={createRedirectionUrl()}
      title={item?.displayName || ''}
      bottomElement={children}
      additionalLinkText={collectionName}
      additionalLink={`/collection/${collectionName}`}
      hoverableElement={hoverableElement}
      chain={item?.chain}
    />
  );
};
