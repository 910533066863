import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Item } from 'services/item-service';
import {
  notifyPlayed,
  notifyPlaylitPlayed,
  useCurrentUserMusic,
  usePopularMusic,
} from 'services/music-service';

import { AuthContext } from './auth-context';

export const OWNED_MUSIC_ID = 'owned-music';
export const POPULAR_MUSIC_ID = 'popular-music';

export enum RepeatMode {
  OFF = 'off',
  ON = 'on',
  ONE = 'one',
}

interface AudioPlayer {
  play: () => void;
  pause: () => void;
  playNext: () => void;
  playPrevious: () => void;
  playPlaylist: (playlistId: string, songId?: string) => void;
  volume: number;
  setVolume: (volume: number) => void;
  isPlaying: boolean;
  randomEnabled: boolean;
  toggleRandom: () => void;
  repeatMode: RepeatMode;
  toggleRepeat: () => void;
  isPaused: boolean;
  currentSongId?: string;
  title?: string;
  author?: string;
  duration?: number;
  currentTime?: number;
  setCurrentTime?: (t: number) => void;
  moveToTime: (t: number) => void;
  currentSongsList?: Song[];
  currentSong?: Song;
  playlists?: Playlist[];
  currentPlaylist?: Playlist;
  addPlaylistFromItems: (items: Item[], displayName: string, id: string) => void;
  playSingleItem: (item: Item) => void;
}

export interface Playlist {
  id: string;
  songs: Song[];
  displayName: string;
}

export interface Song {
  id: string;
  url: string;
  authorAddress: string;
  title: string;
  imageUrl: string;
}

// lockedMediaIpfsUrl or streamPreviewUrl must be checked before using
const itemToSong = (item: Item): Song => {
  return {
    id: item.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: (item.lockedMediaIpfsUrl || item.streamPreviewUrl)!,
    authorAddress: item.firstOwnerWalletId,
    title: item.displayName,
    imageUrl: item.previewImageUrl,
  };
};

const itemsListToPlaylist = (items: Item[], playlistId: string, displayName: string): Playlist => {
  return {
    id: playlistId,
    displayName,
    songs: [
      ...(items || []).flatMap((item) =>
        item.lockedMediaIpfsUrl || item.streamPreviewUrl ? itemToSong(item) : [],
      ),
    ],
  };
};

export const AudioPlayerContext = createContext<AudioPlayer>({
  play: () => ({}),
  pause: () => ({}),
  playNext: () => ({}),
  playPrevious: () => ({}),
  playPlaylist: () => ({}),
  volume: 50,
  setVolume: () => ({}),
  isPlaying: false,
  randomEnabled: false,
  toggleRandom: () => ({}),
  repeatMode: RepeatMode.OFF,
  toggleRepeat: () => ({}),
  isPaused: false,
  currentSongsList: [],
  moveToTime: () => ({}),
  addPlaylistFromItems: () => ({}),
  playSingleItem: () => ({}),
});

export const AudioPlayerContextProvider = ({ children }: React.HTMLProps<HTMLDivElement>) => {
  const { data: ownedMusic } = useCurrentUserMusic();
  const { data: popularTopMusic } = usePopularMusic();

  const [currentAudio, setCurrentAudio] = useState(new Audio());
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist>();
  const [volume, setVolume] = useState(50);
  const [randomEnabled, setRandomEnabled] = useState(false);
  const [repeatMode, setRepeatMode] = useState(RepeatMode.OFF);
  const [isPaused, setIsPaused] = useState(true);
  const [currentSongIndex, setCurrentSongIndex] = useState<number>();
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currentSongsList, setCurrentSongsList] = useState<Song[]>([]);
  const { jwt } = useContext(AuthContext);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);

  useEffect(() => {
    currentAudio.onpause = () => setIsPaused(true);
    currentAudio.onplay = () => setIsPaused(false);
    currentAudio.onloadedmetadata = () => setDuration(currentAudio.duration);

    currentAudio.onended = () => {
      if (repeatMode === RepeatMode.ONE) {
        currentAudio.currentTime = 0;
        currentAudio.play();
      } else if (repeatMode === RepeatMode.OFF) {
        setCurrentSongIndex(undefined);
        setCurrentTime(0);
        setDuration(0);
        setCurrentPlaylist(undefined);
      } else {
        playNext();
      }
    };

    const currentTimeInterval = setInterval(() => {
      if (currentAudio.currentTime) {
        setCurrentTime(currentAudio.currentTime);
      }
    }, 500);

    return () => clearInterval(currentTimeInterval);
  }, [currentAudio, repeatMode]);

  useEffect(() => {
    currentAudio.volume = volume / 100;
  }, [currentAudio, volume]);

  useEffect(() => {
    if (ownedMusic && popularTopMusic) {
      setPlaylists((current) => {
        const filtered = current.filter(
          (playlist) => playlist.id !== OWNED_MUSIC_ID && playlist.id !== POPULAR_MUSIC_ID,
        );
        return [
          ...filtered,
          itemsListToPlaylist(ownedMusic.items, OWNED_MUSIC_ID, 'Owned Music'),
          itemsListToPlaylist(popularTopMusic.items, POPULAR_MUSIC_ID, 'Popular Music'),
        ];
      });
    }
  }, [ownedMusic, popularTopMusic]);

  const playNext = () => {
    if (currentSongIndex === undefined) {
      return;
    }

    const nextSongIndex = currentSongIndex + 1;

    if (nextSongIndex >= currentSongsList.length && repeatMode === RepeatMode.OFF) {
      return;
    }

    const correctedNextSongIndex = nextSongIndex % currentSongsList.length;

    currentAudio.pause();
    const nextSong = currentSongsList[correctedNextSongIndex];
    const audio = new Audio(nextSong.url);
    setCurrentSongIndex(correctedNextSongIndex);
    setCurrentAudio(audio);
    audio.play();
  };

  const playPrevious = () => {
    if (currentSongIndex === undefined) {
      return;
    }

    if (currentTime > 2) {
      // TODO: magic number
      currentAudio.currentTime = 0;
      currentAudio.play();
      return;
    }

    const previousSongIndex = currentSongIndex - 1;

    if (previousSongIndex < 0 && repeatMode === RepeatMode.OFF) {
      return;
    }

    const correctedPreviousSongIndex =
      previousSongIndex < 0 ? currentSongsList.length - 1 : previousSongIndex;

    currentAudio.pause();
    const previousSong = currentSongsList[correctedPreviousSongIndex];
    const audio = new Audio(previousSong.url);
    setCurrentSongIndex(correctedPreviousSongIndex);
    setCurrentAudio(audio);
    audio.play();
  };

  const toggleRandom = () => {
    setRandomEnabled((val) => !val);
  };

  // kinda complicated, review it later
  useEffect(() => {
    if (randomEnabled) {
      setCurrentSongsList((list) => {
        return shuffleSongsList(list, currentSongIndex);
      });
    } else {
      setCurrentSongsList((list) => {
        if (currentSongIndex !== undefined && currentPlaylist !== undefined) {
          const currentSong = list[currentSongIndex];
          const newSongIndex = currentPlaylist.songs.findIndex(
            (song) => song.id === currentSong.id,
          );
          setCurrentSongIndex(newSongIndex);
        }

        return currentPlaylist?.songs || [];
      });
    }
    // only when switching random
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomEnabled]);

  const toggleRepeat = () => {
    switch (repeatMode) {
      case RepeatMode.OFF:
        setRepeatMode(RepeatMode.ON);
        return;
      case RepeatMode.ON:
        setRepeatMode(RepeatMode.ONE);
        return;
      case RepeatMode.ONE:
        setRepeatMode(RepeatMode.OFF);
        return;
    }
  };

  const play = () => {
    if (currentAudio.src) {
      currentAudio.play();
    } else {
      // TODO: change to owned music
      playPlaylist(OWNED_MUSIC_ID);
    }
  };

  const pause = () => {
    currentAudio.pause();
  };

  useEffect(() => {
    return pause;
  }, []);

  const shuffleSongsList = (songs: Song[], firstSongIndex?: number) => {
    const firstSong = firstSongIndex !== undefined ? songs[firstSongIndex] : undefined;
    const rest = songs.filter((_, index) => index !== firstSongIndex);
    const shuffled = rest.sort(() => Math.random() - 0.5);

    return firstSong ? [firstSong, ...shuffled] : shuffled;
  };

  const playPlaylist = (playlistId: string, songId?: string) => {
    pause();
    const playlist = playlists.find((p) => p.id === playlistId);
    if (playlist === undefined) {
      return;
    }

    setCurrentPlaylist(playlist);

    const song = songId ? playlist.songs.find((s) => s.id === songId) : playlist.songs[0];

    if (song === undefined) {
      toast.warn('No songs in playlist!');
      return;
    }
    const songIndex = playlist.songs.indexOf(song);

    const songsList = randomEnabled ? shuffleSongsList(playlist.songs, songIndex) : playlist.songs;

    const audio = new Audio(song.url);
    setCurrentSongsList(songsList);
    setCurrentAudio(audio);
    setCurrentSongIndex(randomEnabled ? 0 : songIndex);
    audio.play();
  };

  const currentSong = useMemo(
    () => (currentSongIndex !== undefined ? currentSongsList[currentSongIndex] : undefined),
    [currentSongIndex, currentSongsList],
  );

  useEffect(() => {
    if (currentSong !== undefined) {
      notifyPlayed(currentSong.id, jwt);
    }
  }, [currentSong, jwt]);

  useEffect(() => {
    if (currentPlaylist !== undefined) {
      notifyPlaylitPlayed(currentPlaylist.id, jwt);
    }
  }, [currentPlaylist, jwt]);

  const moveToTime = (time: number) => {
    if (currentAudio) {
      currentAudio.currentTime = time;
    }
  };

  const playSingleItem = (item: Item) => {
    if (!(item.streamPreviewUrl || item.lockedMediaIpfsUrl)) {
      return;
    }

    const song = itemToSong(item);
    setCurrentSongsList([song]);
    setCurrentSongIndex(0);
    const audio = new Audio(song.url);
    audio.play();
    setCurrentAudio(audio);
  };

  const addPlaylistFromItems = useCallback((items: Item[], displayName: string, id: string) => {
    const playlist = itemsListToPlaylist(items, id, displayName);
    setPlaylists((current) => {
      const filtered = current.filter((playlist) => playlist.id !== id);
      return [...filtered, playlist];
    });
  }, []);

  return (
    <AudioPlayerContext.Provider
      value={{
        play,
        pause,
        playNext,
        playPrevious,
        playPlaylist,
        volume,
        setVolume: (volume: number) => setVolume(volume),
        isPlaying: !!currentAudio.src && !currentAudio.paused,
        isPaused,
        randomEnabled,
        toggleRandom,
        repeatMode,
        toggleRepeat,
        currentSongId: currentSongIndex?.toString(),
        title: currentAudio.title,
        author: 'author',
        duration,
        currentTime,
        setCurrentTime,
        currentSongsList,
        currentSong,
        moveToTime,
        playlists,
        currentPlaylist,
        playSingleItem,
        addPlaylistFromItems,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};
