export const getGreeting = () => {
  const hour = new Date().getHours();
  const welcomeTypes = ['Good morning', 'Good afternoon', 'Good evening'];
  let welcomeText = '';

  if (hour < 12) welcomeText = welcomeTypes[0];
  else if (hour < 18) welcomeText = welcomeTypes[1];
  else welcomeText = welcomeTypes[2];

  return welcomeText;
};
