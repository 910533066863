import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { Button, ButtonStyle } from '../../components/common/button/button';
import { LoadMoreButton } from '../../components/common/load-more-button/load-more-button';
import { SendCard } from '../../components/send-card';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { useItemsByWalletIdWithPagination } from '../../services/item-service';
import styles from './send-nft-page.module.css';

const ITEMS_LIMIT = 8;

export const SendNftPage = (): JSX.Element => {
  const { walletAddress, userProfile, refreshUserProfile } = useContext(CurrentUserProfileContext);
  const ownedEntries = userProfile?.ownedItems;

  const {
    items: userItems,
    isLoadingMore,
    isLoadingInitialData,
    isEmpty,
    isReachingEnd,
    loadMore,
    mutate,
  } = useItemsByWalletIdWithPagination(ITEMS_LIMIT, walletAddress);

  const handleItemSend = useCallback(async () => {
    mutate();

    await refreshUserProfile();
  }, [mutate, refreshUserProfile]);

  return (
    <div className={styles.container}>
      <h1>Send NFT</h1>
      {isLoadingInitialData ? (
        <BeatLoader color="#85838E" size={9} />
      ) : isEmpty ? (
        <NoItemsView />
      ) : (
        <div className={styles.nftsContainer}>
          {userItems.map((item) => {
            const ownedQuantity =
              ownedEntries?.find((entry) => entry.itemId === item.id)?.quantity || 0;
            return (
              <SendCard
                key={item.id}
                item={item}
                quantity={ownedQuantity}
                onItemSend={handleItemSend}
              />
            );
          })}
        </div>
      )}
      {!isReachingEnd && <LoadMoreButton isLoading={!!isLoadingMore} onClick={loadMore} />}
    </div>
  );
};

const NoItemsView = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className={styles.noItems}>
      <p>You don’t own any items.</p>
      <div className={styles.buttonsRow}>
        <Button
          buttonStyle={ButtonStyle.color}
          onClick={(): void => navigate('/explore')}
          id={'send-nft-browse-link'}
        >
          Browse marketplace
        </Button>
        <p>or</p>
        <Button
          buttonStyle={ButtonStyle.second}
          onClick={(): void => navigate('/create')}
          id={'send-nft-create-link'}
        >
          Create
        </Button>
      </div>
    </div>
  );
};
