import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { CircularImage } from 'components/common';
import { useTopSellers } from 'services/sellers-service';
import truncate from 'utils/string-truncator';

import styles from './top-sellers.module.css';

export const TopSellers = (): JSX.Element => {
  const { data: sellers } = useTopSellers(0, 12);

  if (sellers === undefined) {
    return (
      <div className={styles.container}>
        <h2>Top sellers</h2>
        <div className={styles.loadContainer}>
          <p>
            Loading <BeatLoader size={9} />
          </p>
        </div>
      </div>
    );
  }

  if (sellers.length === 0) {
    <div className={styles.container}>
      <h2>Top sellers</h2>
      <div className={styles.loadContainer}>
        <p>No sellers yet, be the first one!</p>
      </div>
    </div>;
  }

  return (
    <div className={styles.container}>
      <h2>Top sellers</h2>
      <div className={styles.sellersList}>
        {(sellers || []).map((seller, index) => {
          return (
            <div key={seller.id} className={styles.sellerTile}>
              <p className={styles.sellerPlace}>{index + 1}</p>
              <Link className={styles.sellerImageLink} to={`/user/${seller.id}`}>
                <CircularImage
                  imageUrl={seller.profileAvatarUrl || '/av-placeholder.jpg'}
                  width={48}
                  height={48}
                  className={styles.sellerImage}
                />
              </Link>
              <div className={styles.sellerInfo}>
                <Link className={styles.sellerName} to={`/user/${seller.id}`}>
                  {seller.displayName || truncate(seller.id)}
                </Link>
                <p className={styles.sellCount}>{seller.itemsSellCount} NFTs</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
