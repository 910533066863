import React from 'react';
import { ChangeHandler, FieldValues, UseFormRegister } from 'react-hook-form';

import styles from './underlined-input.module.css';

interface Props {
  title: string;
  placeholder: string;
  type: 'text' | 'number';
  unit?: JSX.Element;
  errorMessage?: string;

  name: string; // FormControl registered name
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  id: string;
}

export const UnderlinedInputControl = React.forwardRef<
  HTMLInputElement,
  Props & ReturnType<UseFormRegister<FieldValues>>
>(({ title, placeholder, type, unit, name, errorMessage, onChange, onBlur, id }: Props, ref) => (
  <div className={styles.inputContainer}>
    <p>{title}</p>
    <div className={styles.inputRow}>
      <input
        type={type}
        name={name}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={(e): void => e.currentTarget.blur()}
        id={id}
      ></input>
      {unit !== undefined && unit}
    </div>
    <span className="inputValidationError">{errorMessage}</span>
  </div>
));
UnderlinedInputControl.displayName = 'UnderlinedInputControl';
