import ReactMarkdown from 'react-markdown';

import styles from './markdown-display.module.css';

interface Props {
  text?: string;
}

export const MarkdownDisplay = ({ text }: Props): JSX.Element => {
  if (!text) {
    return <></>;
  }

  return (
    <ReactMarkdown
      linkTarget={'_blank'}
      className={styles.container}
      components={{
        a: (props): JSX.Element => {
          return <a {...props} className={styles.markdownLink}></a>;
        },
      }}
    >
      {text}
    </ReactMarkdown>
  );
};
