import { env } from 'env/env';

export type AllowedCurrencies = 'ORE' | 'BNB' | 'ETH' | 'MATIC';

export const chainIdToNameMap = {
  [env.ethereumChainId]: 'eth',
  [env.binanceChainId]: 'bsc',
  [env.polygonChainId]: 'polygon',
};

export const getCurrentTokenContract = () => {
  const chainId: keyof typeof chainIdToAddressMap = (window.ethereum as any)?.networkVersion;

  const chainIdToAddressMap = {
    [env.ethereumChainId]: env.ethereumContractAddress,
    [env.binanceChainId]: env.oreTokenContractAddress,
    [env.polygonChainId]: env.polygonContractAddress,
  };

  return chainIdToAddressMap[chainId] ?? '';
};

export const getChainIdFromSymbol = (chainSymbol?: 'eth' | 'polygon' | 'bsc') => {
  const chainIdSymbolMap = {
    eth: env.ethereumChainId,
    polygon: env.polygonChainId,
    bsc: env.binanceChainId,
  };

  return chainSymbol && chainIdSymbolMap[chainSymbol];
};

export const getCurrentChain = () => {
  const chainId: keyof typeof chainIdToNameMap = (window.ethereum as any)?.networkVersion;

  return chainIdToNameMap[chainId] as 'bsc' | 'eth' | 'polygon';
};

export const getCurrentCurrency = () => {
  const chainId: keyof typeof chainIdToCurrencyMap = (window.ethereum as any)?.networkVersion;

  const chainIdToCurrencyMap = {
    [env.ethereumChainId]: 'eth',
    [env.binanceChainId]: 'bnb',
    [env.polygonChainId]: 'matic',
  };

  return chainIdToCurrencyMap[chainId] as 'bnb' | 'eth' | 'matic';
};
