import { FileRejection, useDropzone } from 'react-dropzone';

import { nameSpecialCharactersValidator } from 'utils/drop-zone-validators';

import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import styles from './file-input.module.css';

interface Props {
  tooltipText: string;
  accept?: string;
  id: string;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}

export const FileInput = ({ tooltipText, accept, onDrop, id }: Props): JSX.Element => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    validator: nameSpecialCharactersValidator,
  });

  return (
    <div {...getRootProps()} className={styles.fileInput}>
      <input {...getInputProps()} accept={accept} id={id} />
      <FileIcon />
      <div className={styles.fileInputDescription}>
        <p>{tooltipText}</p>
        <p>Drag and drop files here, or click to browse.</p>
      </div>
    </div>
  );
};
