import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { COLORS } from 'constant';

import BscLogo from 'assets/bsc_round.png';
import EthLogo from 'assets/eth_round.png';
import { HeartIcon } from 'assets/icons';
import PolyLogo from 'assets/poly_round.png';
import { CircularImage } from 'components/common/circular-image';
import { ImagePlaceholder } from 'components/common/image-placeholder';

import styles from './card.module.css';

interface Props {
  redirectLink: string;
  title: string;
  imageUrl?: string;
  creatorImgUrl?: string;
  creatorWalletAddress?: string;
  ownerImgUrl?: string;
  ownerWalletAddress?: string;
  likeable?: boolean;
  liked?: boolean;
  likesCount?: number;
  onLikeClicked?: () => void;
  type?: 'wide' | 'small' | 'default';
  bottomElement?: React.ReactNode;
  hoverableElement?: React.ReactNode;
  additionalLink?: string;
  additionalLinkText?: string;
  disableLinks?: boolean;
  hoverable?: boolean;
  active?: boolean;
  displayBorder?: boolean;
  displayUsers?: boolean;
  imageStyle?: 'rectangle' | 'circle';
  centerTitle?: boolean;
  userLinkPrefix?: string;
  chain?: string;
}

export const Card = ({
  imageUrl,
  creatorImgUrl,
  creatorWalletAddress,
  ownerImgUrl,
  ownerWalletAddress,
  likeable,
  liked,
  likesCount,
  onLikeClicked,
  type = 'default',
  title,
  bottomElement,
  additionalLink,
  additionalLinkText,
  redirectLink,
  disableLinks,
  hoverableElement,
  hoverable = false,
  active = false,
  displayBorder = true,
  displayUsers = true,
  imageStyle = 'rectangle',
  centerTitle = false,
  userLinkPrefix = '',
  chain,
}: Props) => {
  const renderSmallUserImage = (imgUrl?: string) => (
    <CircularImage
      imageUrl={imgUrl || '/av-placeholder.jpg'}
      withBorder
      borderColor={COLORS.GRAY_DARKER}
      width={35}
      height={35}
    />
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.wide]: type === 'wide',
        [styles.small]: type === 'small',
        [styles.hoverable]: hoverable,
        [styles.active]: active,
        [styles.withBorder]: displayBorder,
      })}
    >
      <Link className={styles.imageLink} to={disableLinks ? '#' : redirectLink}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="preview"
            className={classNames(styles.image, {
              [styles.circle]: imageStyle === 'circle',
            })}
          />
        ) : (
          <ImagePlaceholder
            className={classNames(styles.image, {
              [styles.circle]: imageStyle === 'circle',
            })}
          />
        )}
      </Link>
      {likeable && (
        <button
          className={classNames(styles.likesBtn, { [styles.liked]: liked })}
          onClick={onLikeClicked}
          id={'item-card-like-button'}
        >
          {<span>{likesCount || 0}</span>}
          <HeartIcon />
        </button>
      )}
      {displayUsers && (
        <div>
          <Link
            to={!disableLinks ? `${userLinkPrefix}/user/${creatorWalletAddress}` : '#'}
            className={styles.creator}
            title="Creator"
          >
            {renderSmallUserImage(creatorImgUrl)}
          </Link>
          {!!chain && (
            <div className={styles.chain} title="Chain">
              {renderSmallUserImage(
                chain === 'bsc' ? BscLogo : chain === 'eth' ? EthLogo : PolyLogo,
              )}
            </div>
          )}
          {ownerWalletAddress && ownerWalletAddress !== creatorWalletAddress && (
            <Link
              to={disableLinks ? '#' : `${userLinkPrefix}/user/${ownerWalletAddress}`}
              className={styles.owner}
              title="Owner"
            >
              {renderSmallUserImage(ownerImgUrl)}
            </Link>
          )}
        </div>
      )}
      <div className={classNames(styles.linkAndTitleWrapper, { [styles.center]: centerTitle })}>
        {additionalLink && additionalLinkText && (
          <Link to={additionalLink} className={styles.additionalLink}>
            {additionalLinkText}
          </Link>
        )}
        <Link
          className={classNames(styles.title, {
            [styles.center]: centerTitle,
          })}
          to={disableLinks ? '#' : redirectLink}
        >
          {title}
        </Link>
      </div>
      {hoverableElement && <div className={styles.hoverableElement}>{hoverableElement}</div>}
      {bottomElement}
    </div>
  );
};
