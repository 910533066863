import { BeatLoader } from 'react-spinners';

import { CollectionCard } from '../../components/collection-card/collection-card';
import { LoadMoreButton } from '../../components/common/load-more-button/load-more-button';
import { useCollectionsByWalletIdWithPagination } from '../../services/collection-service';
import styles from './profile-page.module.css';

const COLLECTIONS_LIMIT = 6;

interface Props {
  walletAddress: string;
}

export const UserCollections = ({ walletAddress }: Props) => {
  const {
    collections: userCollections,
    isLoadingMore,
    isReachingEnd,
    isLoadingInitialData,
    loadMore,
  } = useCollectionsByWalletIdWithPagination(COLLECTIONS_LIMIT, walletAddress);

  const renderCollectionsList = (): JSX.Element | JSX.Element[] => {
    if (isLoadingInitialData) {
      return (
        <p className={styles.noItems}>
          <BeatLoader color="#85838E" size={9} />
        </p>
      );
    }

    if (userCollections.length === 0) {
      return <p className={styles.noItems}>No collections to display</p>;
    }

    return userCollections.map((collection) => (
      <CollectionCard key={collection.id} collection={collection} />
    ));
  };

  return (
    <div className={styles.activeList}>
      <div className={styles.nftsContainer}>{renderCollectionsList()}</div>
      {!isReachingEnd && <LoadMoreButton isLoading={!!isLoadingMore} onClick={loadMore} />}
    </div>
  );
};
