import { createContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Web3Provider } from '@ethersproject/providers';
import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';

import { env } from '../env/env';
import { exchangeSignatureForToken, getNonce } from '../services/auth-service';
import { changeWeb3 } from '../services/blockchain-service';
import { AuthContextType } from './auth-context';

const defaultChainIdInt = parseInt(env.chainId);

const [metamask, metamaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask(actions),
  env.allowedChainIds,
);

const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect(actions, {
      rpc: {
        [env.binanceChainId]: env.binanceUrl,
        [env.ethereumChainId]: env.binanceUrl,
        [env.polygonChainId]: env.binanceUrl,
      },
    }),
  env.allowedChainIds,
);

export enum ProviderType {
  WalletConnect = 'wallet_connect',
  Metamask = 'metamask',
}

const createWasConnectedKey = (walletAddress: string): string => `${walletAddress}-was-connected`;

const getWasConnected = (walletAddress: string): boolean => {
  return localStorage.getItem(createWasConnectedKey(walletAddress)) === 'true';
};

const markConnected = (walletAddress: string): void => {
  localStorage.setItem(createWasConnectedKey(walletAddress), 'true');
};

const markDisconnected = (walletAddress: string): void => {
  localStorage.removeItem(createWasConnectedKey(walletAddress));
};

const saveLastConnectedProvider = (provider: ProviderType): void =>
  localStorage.setItem(env.lastConnectedProviderKey, provider);
const removeLastConnectedProvider = (): void =>
  localStorage.removeItem(env.lastConnectedProviderKey);

export const getLastConnectedProvider = (): ProviderType => {
  const maybeProvider = localStorage.getItem(env.lastConnectedProviderKey) as ProviderType;
  return maybeProvider || undefined;
};

interface TokenStorage {
  token: string;
  expiresAt: string;
}

export const BrowserAuthContext = createContext<AuthContextType>({
  isAuthorized: false,
  isBanned: false,
  isInitialized: false,
  jwt: '',
  browserActions: {
    connectMetamask: () => Promise.reject(),
    connectWalletConnect: () => Promise.reject(),
    disconnect: () => ({}),
  },
  providersSet: false,
});

export const BrowserAuthContextProvider = ({
  children,
}: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [tokenProcessPassed, setTokenProcessPassed] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [jwt, setJwt] = useState<string>('');
  const [providerType, setProviderType] = useState<ProviderType>(getLastConnectedProvider());
  const [walletActivated, setWalletActivated] = useState(false);
  const [providersSet, setProvidersSet] = useState(false);
  const currentChainIdFromWindow = (window.ethereum as any)?.networkVersion;
  const metamaskProvider = metamaskHooks.useProvider();
  const metamaskAccount = metamaskHooks.useAccount()?.toLowerCase();
  const metamaskChainId = metamaskHooks.useChainId();
  const isMetamaskActive = metamaskHooks.useIsActive();
  const isMetamaskAvailable = !!metamask.provider?.isMetaMask;
  const walletConnectProvider = walletConnectHooks.useProvider();
  const walletConnectAccount = walletConnectHooks.useAccount()?.toLowerCase();
  const walletConnectChainId = walletConnectHooks.useChainId();
  const isWalletConnectActive = walletConnectHooks.useIsActive();
  const currentChainId =
    providerType === ProviderType.WalletConnect ? walletConnectChainId : metamaskChainId;
  const provider = useMemo(() => {
    if (providerType === ProviderType.Metamask) {
      return metamaskProvider;
    }

    if (providerType === ProviderType.WalletConnect) {
      return walletConnectProvider;
    }
  }, [metamaskProvider, providerType, walletConnectProvider]);

  const signer = useMemo(() => {
    return provider?.getSigner();
  }, [provider]);

  const account = useMemo(() => {
    if (providerType === ProviderType.Metamask) {
      return metamaskAccount;
    }

    if (providerType === ProviderType.WalletConnect) {
      return walletConnectAccount;
    }
  }, [metamaskAccount, providerType, walletConnectAccount]);

  const chainId = useMemo(() => {
    if (providerType === ProviderType.Metamask) {
      return metamaskChainId;
    }

    if (providerType === ProviderType.WalletConnect) {
      return walletConnectChainId;
    }
  }, [metamaskChainId, providerType, walletConnectChainId]);

  const isConnected = useMemo(() => {
    if (providerType === ProviderType.Metamask) {
      return isMetamaskActive;
    }

    if (providerType === ProviderType.WalletConnect) {
      return isWalletConnectActive;
    }

    return false;
  }, [isMetamaskActive, isWalletConnectActive, providerType]);

  const getToken = async (walletAddress: string): Promise<string> => {
    if (signer) {
      const nonce = await getNonce(walletAddress);
      const signedNonce = await signer.signMessage(nonce);
      const jwt = await exchangeSignatureForToken(walletAddress, signedNonce);

      return jwt;
    }

    return '';
  };

  const handleAccountsChanged = () => {
    window.location.reload();
  };

  useEffect(() => {
    (window?.ethereum as any)?.on('accountsChanged', handleAccountsChanged);
    return () => {
      (window?.ethereum as any)?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  const retrieveToken = async (walletAddress: string): Promise<void> => {
    const storageKey = `${env.tokenStorageKey}${walletAddress}`;
    const storedTokenInfo = localStorage.getItem(storageKey);

    if (storedTokenInfo) {
      const tokenStorage: TokenStorage = JSON.parse(storedTokenInfo);
      const expiresAtDate = new Date(tokenStorage.expiresAt);

      if (expiresAtDate > new Date() && tokenStorage.token) {
        setJwt(tokenStorage.token);
        setTokenProcessPassed(true);
        return;
      }
    }
    try {
      if (chainId && !env.allowedChainIds.includes(chainId)) {
        toast.warn(
          'You must use binance chain to authorize. If nothing happens after changing chain, please refresh the page.',
        );
      }

      toast.info('Please check your wallet for signing authorization code');

      const newToken = await getToken(walletAddress);

      const expiresAt = new Date();
      expiresAt.setHours(expiresAt.getHours() + env.jwtExpirationInHours);
      const tokenStorage = { token: newToken, expiresAt };
      localStorage.setItem(storageKey, JSON.stringify(tokenStorage));

      setJwt(newToken);
      setTokenProcessPassed(true);
      markConnected(walletAddress);

      // weird hack. When metamask switches chain on connect, it gets for some reason.
      providerType === ProviderType.Metamask
        ? metamask.activate(currentChainIdFromWindow)
        : walletConnect.activate(currentChainIdFromWindow);
    } catch (e) {
      toast.warn('sorry, we could not authenticate you');
      throw e;
    }
  };

  const connectMetamask = async (currentChainId: number): Promise<void> => {
    if (!isMetamaskAvailable) {
      toast.warn('Please install Metamask');
      return;
    }

    setProviderType(ProviderType.Metamask);
    saveLastConnectedProvider(ProviderType.Metamask);

    await metamask.activate(currentChainId ?? defaultChainIdInt);
    setWalletActivated(true);
  };

  const connectWalletConnect = async (): Promise<void> => {
    setProviderType(ProviderType.WalletConnect);
    saveLastConnectedProvider(ProviderType.WalletConnect);

    await walletConnect.activate(currentChainIdFromWindow);

    setWalletActivated(true);
  };

  // after succesful connection to login by user
  useEffect(() => {
    if (account && walletActivated && !getWasConnected(account)) {
      retrieveToken(account).catch((e) => {
        if (e?.response?.status === 403) {
          setIsBanned(true);
        }
        disconnect();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, walletActivated]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (account) {
      interval = setInterval(() => {
        getNonce(account).catch((e) => {
          if (e.response?.status === 403) {
            setIsBanned(true);
            disconnect();
          }
        });
      }, 1800000);
    }
    return () => clearInterval(interval);
  }, []);

  const disconnect = (): void => {
    if (isAuthorized && account) {
      providerType === ProviderType.Metamask ? metamask.deactivate() : walletConnect.deactivate();
      setJwt('');
      const storageKey = `${env.tokenStorageKey}${account}`;
      localStorage.removeItem(storageKey);
      markDisconnected(account);
      setWalletActivated(false);
      removeLastConnectedProvider();
    }
  };

  const initialize = async (): Promise<void> => {
    await metamask.connectEagerly();
    await walletConnect.connectEagerly();
    setIsInitialized(true);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // after refresh, expired token
  useEffect(() => {
    if (isConnected && account && jwt === '' && getWasConnected(account)) {
      retrieveToken(account).catch(disconnect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isConnected, jwt]);

  // chain check
  useEffect(() => {
    if (chainId && !env.allowedChainIds.includes(chainId)) {
      toast.warn('You must use binance chain');
    }
  }, [chainId]);

  const isAuthorized = useMemo(() => {
    return isConnected && tokenProcessPassed && jwt !== '';
  }, [isConnected, tokenProcessPassed, jwt]);

  // wallet connect needs it's own web3 provider to work
  useEffect(() => {
    if (isAuthorized && providerType === ProviderType.WalletConnect && walletConnect.provider) {
      changeWeb3(new Web3Provider(walletConnect.provider));
      setProvidersSet(true);
    }

    if (isAuthorized && providerType === ProviderType.Metamask && metamask.provider) {
      changeWeb3(new Web3Provider(metamask.provider));
      setProvidersSet(true);
    }
  }, [isAuthorized, providerType]);

  return (
    <BrowserAuthContext.Provider
      value={{
        isAuthorized,
        isBanned,
        isInitialized,
        jwt,
        browserActions: {
          connectMetamask,
          connectWalletConnect,
          disconnect,
        },
        walletAddress: account,
        providersSet,
        currentChainId,
      }}
    >
      {children}
    </BrowserAuthContext.Provider>
  );
};
