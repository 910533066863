import { useContext } from 'react';

import { PlaylistCard } from 'components/playlist-card';
import { AudioPlayerContext, OWNED_MUSIC_ID, POPULAR_MUSIC_ID } from 'context/audio-player-context';

import styles from './music-link-cards.module.css';

export const MusicLinkCards = () => {
  const { playlists } = useContext(AudioPlayerContext);

  const ownedMusic = playlists?.find((playlist) => playlist.id === OWNED_MUSIC_ID);
  const popularMusic = playlists?.find((playlist) => playlist.id === POPULAR_MUSIC_ID);

  const ownedMusicProps = {
    id: OWNED_MUSIC_ID,
    title: ownedMusic?.displayName || '',
    redirectTo: '/current-user/music#items',
    imageURLs: ownedMusic?.songs.slice(0, 3).map((song) => song.imageUrl) || [],
  };

  const popularMusicProps = {
    id: POPULAR_MUSIC_ID,
    title: popularMusic?.displayName || '',
    redirectTo: '/explore/music',
    imageURLs: popularMusic?.songs.slice(0, 3).map((song) => song.imageUrl) || [],
  };

  return (
    <div className={styles.linkCardsGrid}>
      {[ownedMusicProps, popularMusicProps].map((linkCard) => (
        <div key={linkCard.id}>
          <PlaylistCard
            redirectTo={linkCard.redirectTo}
            title={linkCard.title}
            imagesUrls={linkCard.imageURLs}
            playlistId={linkCard.id}
          />
        </div>
      ))}
    </div>
  );
};
