import classNames from 'classnames';

import { Badge } from '../badge';
import styles from './tabs.module.css';

export type TabOption = { value: string; label: string; badge?: string };

export type TabOptions = TabOption[];

interface Props {
  displayOptions: TabOptions;
  currentOption: TabOption;
  setValue: (option: TabOption) => void;
}

export const Tabs = ({ currentOption, displayOptions, setValue }: Props) => {
  return (
    <div className={styles.tabsWrapper}>
      {displayOptions.map((typeOption) => (
        <button
          key={typeOption.value}
          onClick={() => setValue(typeOption)}
          disabled={currentOption.value === typeOption.value}
          className={classNames({ [styles.active]: currentOption.value === typeOption.value })}
        >
          {typeOption.label}
          {typeOption.badge && (
            <Badge content={typeOption.badge} selected={currentOption.value === typeOption.value} />
          )}
        </button>
      ))}
    </div>
  );
};
