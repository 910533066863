import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber, utils } from 'ethers';
import useSWR from 'swr';

import { getCurrentTokenContract } from 'utils/blockchain-helpers';

import ORE_CONTRACT_ABI from '../ABI/ORE_CONTRACT_ABI.json';
import ORE_TOKEN_CONTRACT_ABI from '../ABI/ORE_TOKEN_CONTRACT_ABI.json';
import { env } from '../env/env';
import { uploadFileToIpfs } from './ipfs-service';

export interface OreTokenInfo {
  price: number;
  royalties: number;
  quantity: number;
  name: string;
  file: Blob;
  chain: 'bsc' | 'eth' | 'polygon';
  previewImage?: Blob;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaData: any;
  legal: 'usage_within_ore' | 'full_ownership';
  usage?: string;
  oreChamberStreamingApproved?: boolean;
}

// CONTENT OF THIS FILE IS NOT FULLY UNDERSTANDABLE, IT'S REWRITE OF OLD FRONTEND

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let web3Provider: Web3Provider | undefined =
  (window as any).ethereum && new Web3Provider((window as any).ethereum);
let web3Signer = web3Provider?.getSigner();

let oreContract = new Contract(env.oreContractAddress, ORE_CONTRACT_ABI, web3Signer);

//let oreTokenContract = new Contract(getTokenContract(), ORE_TOKEN_CONTRACT_ABI, web3Signer);
const getOreTokenContract = () =>
  new Contract(getCurrentTokenContract(), ORE_TOKEN_CONTRACT_ABI, web3Signer);

export const changeWeb3 = (newWeb3: Web3Provider): void => {
  web3Provider = newWeb3;
  web3Signer = newWeb3.getSigner();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oreContract = new Contract(env.oreContractAddress, ORE_CONTRACT_ABI, newWeb3.getSigner());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // oreTokenContract = new Contract(
  //   '0x436c5fe40D17f91738119385c130eF218eA28Adb', //'0x0000000000000000000000000000000000000000'
  //   ORE_TOKEN_CONTRACT_ABI,
  //   newWeb3.getSigner(),
  // );
};

export const calculatePrice = async (price: number, quantity: number): Promise<number> => {
  const serviceFee = (await getServiceFee()) / 100;

  const subtotalPrice = price * quantity;
  const totalPrice = subtotalPrice + subtotalPrice * serviceFee;

  return totalPrice;
};

export const allowOreAmount = async (walletAddress: string, oreAmount: number): Promise<void> => {
  const allowance = await oreContract.allowance(walletAddress, env.oreTokenContractAddress);
  const allowanceFromWei = utils.formatEther(allowance);
  const price = await calculatePrice(oreAmount, 1);

  const totalAmount = parseFloat(allowanceFromWei) + price;
  const tx = await oreContract.approve(
    env.oreTokenContractAddress,
    utils.parseEther(totalAmount.toFixed(15)).toString(),
  );
  // .send({ from: walletAddress });
  await tx.wait();
};

export const signBid = async (
  itemId: string,
  price: number,
  currency: string,
): Promise<string | undefined> => {
  return await web3Signer?.signMessage(`Biddinng ${itemId} for ${price} ${currency}`);
};

export const createOreToken = async (
  oreTokenInfo: OreTokenInfo,
  authToken: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ oreTokenId: number; ipfsImageId: any }> => {
  const oreTokenContract = getOreTokenContract();
  const tx = await oreTokenContract.setApproval(env.oreTokenContractAddress, true);
  const oreTokenContractApproveResult = await tx.wait();
  const oreTokenIdAsString = oreTokenContractApproveResult?.events?.find(
    (obj: { event: string }) => obj.event === 'Approve',
  )?.args?.['token_id'] as BigNumber;

  if (!oreTokenIdAsString) {
    throw new Error('Cannot approve creating ORE token.');
  }

  const oreTokenId = oreTokenIdAsString.toNumber();
  const oreTokenPriceInWei = utils.parseEther(`${oreTokenInfo.price}`).toString();
  const oreTokenImageIpfsId = await uploadFileToIpfs(authToken, oreTokenInfo.file);
  const oreTokenMetaDataIpfsId = await uploadFileToIpfs(
    authToken,
    new Blob([JSON.stringify(oreTokenInfo.metaData)]),
  );
  const txMint = await oreTokenContract.mint(
    oreTokenId,
    oreTokenPriceInWei,
    oreTokenInfo.royalties,
    oreTokenInfo.quantity,
    oreTokenInfo.name,
    oreTokenImageIpfsId,
    oreTokenMetaDataIpfsId,
  );
  await txMint.wait();

  return { oreTokenId, ipfsImageId: oreTokenImageIpfsId };
};

export const buyTokenWithBNB = async (
  price: number,
  quantity: number,
  ownerWalletAddress: string,
  tokenId: string,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const subtotal = price * quantity;
  const subtotalWei = utils.parseEther(subtotal.toFixed(15)).toString();
  const total = subtotal + subtotal * ((await getServiceFee()) / 100);
  const totalWei = utils.parseEther(total.toFixed(15)).toString();

  const tx = await oreTokenContract.saleToken(ownerWalletAddress, tokenId, subtotalWei, quantity, {
    value: totalWei,
  });
  await tx.wait();
};

export const buyTokenWithORE = async (
  price: number,
  quantity: number,
  ownerWalletAddress: string,
  tokenId: string,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const total = utils.parseEther((price * quantity).toFixed(15)).toString();
  const tx = await oreTokenContract.saleWithToken(
    ownerWalletAddress,
    tokenId,
    total, // weird to 100 on the old frontend, investigate later
    quantity,
    'ORE', // not sure here also
  );

  await tx.wait();
};

export const acceptBid = async (
  bidderWalletAddres: string,
  currency: 'BNB' | 'ORE',
  amount: string,
  itemId: string,
  quantity: number,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const tx = await oreTokenContract.acceptBId(
    currency,
    bidderWalletAddres,
    amount,
    itemId,
    quantity,
  );

  await tx.wait();
};

export const getServiceFee = async (): Promise<number> => {
  const oreTokenContract = getOreTokenContract();
  const serviceFee = await oreTokenContract.getServiceFee();
  return parseFloat(utils.formatEther(serviceFee));
};

export const getBalance = async (
  walletAddress: string,
): Promise<{ oreBalance: number; bnbBalance: number }> => {
  const weiBnbBalance = await web3Provider?.getBalance(walletAddress);
  const bnbBalance = parseFloat(utils.formatEther(weiBnbBalance || '0'));

  if (![56, 97].includes(parseInt((window.ethereum as any)?.networkVersion))) {
    return { oreBalance: -1, bnbBalance };
  }

  const weiOreBalance = await oreContract.balanceOf(walletAddress);
  const oreBalance = parseFloat(utils.formatEther(weiOreBalance));

  return { oreBalance, bnbBalance };
};

export const useBalance = (walletAddress?: string): { oreBalance: number; bnbBalance: number } => {
  const { data } = useSWR(walletAddress ? walletAddress : null, getBalance, {
    refreshInterval: 5000,
  });

  return {
    oreBalance: data?.oreBalance || 0,
    bnbBalance: data?.bnbBalance || 0,
  };
};

export const putItemOnSale = async (
  walletAddress: string,
  itemId: string,
  price: string,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const tx = await oreTokenContract.orderPlace(itemId, price);
  await tx.wait();
};

export const amountFromWei = (amount: string): string => {
  return utils.formatEther(amount);
};

export const amountToWei = (amount: string): string => {
  return utils.parseEther(amount).toString();
};

export const sendNft = async (
  fromWalletAddress: string,
  toWalletAddress: string,
  itemId: string,
  quantity: number,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const tx = await oreTokenContract.safeTransferFrom(
    fromWalletAddress,
    toWalletAddress,
    itemId,
    quantity,
    [],
  );

  await tx.wait();
};

export const burnNft = async (
  walletAddress: string,
  itemId: string,
  quantity: number,
): Promise<void> => {
  const oreTokenContract = getOreTokenContract();
  const tx = await oreTokenContract.burn(walletAddress, itemId, quantity);
  await tx.wait();
};
