import { BeatLoader } from 'react-spinners';

import { AuctionCard } from '../../components/auction-card';
import { Auction, useUserAuctions } from '../../services/auctions-service';
import styles from './user-auctions-list.module.css';

interface Props {
  walletAddress: string;
  showActive?: boolean;
  showFinished?: boolean;
}

export const UserAuctionsList = ({ walletAddress, showActive, showFinished }: Props) => {
  const { data: auctionsResultObj, error } = useUserAuctions(
    walletAddress,
    showFinished,
    showActive,
  );

  const renderAuctionsGrid = () => {
    const auctions: Auction[] | undefined = auctionsResultObj?.auctions;
    if (!error && !auctions) {
      return (
        <div className={styles.loadingAuctions}>
          <BeatLoader color="#f5f5f5" />
        </div>
      );
    } else if (auctions?.length === 0) {
      return <p className={styles.noItems}>No auctions to display</p>;
    } else {
      return auctions?.map((a) => (
        <AuctionCard
          key={a.id}
          itemId={a.itemId}
          ownerWalletAddress={walletAddress}
          currency={a.settlementToken}
          quantity={a.quantity}
          auctionId={a.id}
          endOfAuction={a.endsAt}
          minimalBid={a.minimumBid}
        />
      ));
    }
  };

  return <div className={styles.auctionsContainer}>{renderAuctionsGrid()}</div>;
};
