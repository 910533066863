import { Eip1193ProviderProxyBrowserWindowClient } from 'eip1193_provider_proxy';

import { isLauncher } from './launcher';

export const overrideWindowEthereum = () => {
  if (isLauncher()) {
    const eip1193ProviderProxyClient = new Eip1193ProviderProxyBrowserWindowClient();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ethereum = eip1193ProviderProxyClient.provider;
  }
};
