import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingPagePlaceholder } from 'components/common/loading-page-placeholder/loading-page-placeholder';
import { usePlaylist } from 'services/music-service';

import { CreatePlaylistPage } from './playlist-page';

export const ParticularPlaylistPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: playlist, error, mutate } = usePlaylist(id);

  useEffect(() => {
    if (error) {
      navigate('/not-found');
    }
  }, [error, navigate]);

  if (playlist === undefined) {
    return <LoadingPagePlaceholder />;
  }

  return <CreatePlaylistPage playlist={playlist} refetchPlaylist={mutate} />;
};
