import { PauseIcon, PlayIcon } from 'assets/audio-player-icons';

import { IconButton } from '../common/icon-button';

interface Props {
  isPlaying: boolean;
  onPlayPauseClicked: () => void;
  disabled?: boolean;
  colorType: 'orange' | 'white';
  width?: number;
  height?: number;
  padding?: number;
  withBackground?: boolean;
}

export const PlayPauseButton = ({
  isPlaying,
  onPlayPauseClicked,
  disabled,
  colorType,
  width,
  height,
  padding,
  withBackground = true,
}: Props) => {
  return (
    <IconButton
      onClick={onPlayPauseClicked}
      disabled={disabled}
      colorType={disabled ? 'grey' : colorType}
      withBackground={withBackground}
      icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
      width={width}
      height={height}
      padding={padding}
    />
  );
};
