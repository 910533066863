import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TRACK_COLUMNS_SETS } from 'constant';

import { Dropdown, DropdownStyle } from 'components/common/dropdown';
import { TrackCard } from 'components/track-card';
import { AudioPlayerContext, Song } from 'context/audio-player-context';
import { useCollection } from 'services/collection-service';
import { useItem } from 'services/item-service';
import { useUser } from 'services/user-service';
import { secondsToDuration } from 'utils/seconds-to-duration';

import styles from './music-queue-playlist-item.module.css';

interface Props {
  song?: Song;
  currentlyPlayedItemId?: string;
  queuePosition: string;
}

export const MusicQueuePlaylistItem = ({ song, currentlyPlayedItemId, queuePosition }: Props) => {
  const { data: item } = useItem(song?.id);
  const { data: author } = useUser(song?.authorAddress);
  const { currentPlaylist } = useContext(AudioPlayerContext);
  return item ? (
    <TrackCard
      key={item.id}
      songId={item.id}
      setOfColumns={TRACK_COLUMNS_SETS.TAD}
      queuePosition={queuePosition}
      author={author?.displayName ?? ''}
      authorURL={author?.websiteUrl ?? ''}
      songTitle={song?.title ?? ''}
      albumURL={''}
      isPlaying={currentlyPlayedItemId === song?.id}
      duration={secondsToDuration(item.mediaLengthSeconds, true)}
      albumName={currentPlaylist?.displayName ?? ''}
      albumImage={song?.imageUrl ?? ''}
      price={''}
      playlistId={currentPlaylist?.id}
      lastColumn={
        <Dropdown
          placeholderTitle="..."
          isWide
          options={[
            <Link to={`/item/${song?.id}`} key={song?.id} className={styles.navLink}>
              Go to track
            </Link>,
            <Link to={`/user/${author?.id}`} key={author?.id} className={styles.navLink}>
              Go to artist
            </Link>,
          ]}
          dropdownStyle={DropdownStyle.Basic}
        />
      }
    />
  ) : null;
};
