import { useContext, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { COLORS, MUSIC_TABS, TRACK_COLUMNS_SETS } from 'constant';

import { LayoutStack, TabOptions, Tabs, Typography } from 'components/common';
import { LoadMoreButton } from 'components/common/load-more-button';
import { MusicCard } from 'components/music-card';
import { MusicCollectionCard } from 'components/music-collection-card';
import { AuthContext } from 'context/auth-context';
import { useCollectionsByWalletIdWithPagination } from 'services/collection-service';
import { useCurrentUserMusic, useCurrentUserPlaylists } from 'services/music-service';

import PlaylistsTable from './components';

export const MusicLibrary = () => {
  const [currentOption, setCurrentOption] = useState(TABS[0]);

  return (
    <>
      <Typography.Heading fontSize={36} fontWeight={700} color={COLORS.WHITE} marginBottom={12}>
        My library
      </Typography.Heading>
      <Typography.Paragraph
        color={COLORS.GRAY_TEXT_2}
        fontSize={16}
        fontWeight={400}
        marginBottom={64}
      >
        Here you can find your owned music and podcasts.
      </Typography.Paragraph>
      <Tabs displayOptions={TABS} currentOption={currentOption} setValue={setCurrentOption} />
      {currentOption.value === MUSIC_TABS.MUSIC && <MusicTabsContent />}
      {currentOption.value === MUSIC_TABS.COLLECTION && <CollectionsTabContent />}
      {currentOption.value === MUSIC_TABS.PLAYLISTS && <PlaylistTabsContent />}
    </>
  );
};

const TABS: TabOptions = [
  { value: MUSIC_TABS.MUSIC, label: 'Music' },
  { value: MUSIC_TABS.COLLECTION, label: 'Collections' },
  { value: MUSIC_TABS.PLAYLISTS, label: 'Playlists' },
];

const MusicTabsContent = () => {
  const { data } = useCurrentUserMusic();

  // SOME WEIRD OLD LEGACY STUFF
  const music = (data?.items || []).filter((item) => item.lockedMediaIpfsUrl);

  return (
    <LayoutStack rowElements={6} gapX={16} gapY={16} marginTop={32}>
      {music.map((item) => (
        <MusicCard key={item.id} prefetchedItem={item} />
      ))}
    </LayoutStack>
  );
};

const PlaylistTabsContent = () => {
  const {
    data: playlistsData,
    error: playlistsError,
    mutate: playlistsMutate,
  } = useCurrentUserPlaylists();
  const isPlaylistLoading = !playlistsData && !playlistsError;

  return (
    <PlaylistsTable
      playlists={playlistsData?.playlists}
      isLoading={isPlaylistLoading}
      mutate={playlistsMutate}
      displayDropdown
    />
  );
};

const CollectionsTabContent = () => {
  const { walletAddress } = useContext(AuthContext);
  const {
    collections,
    loadMore: loadMoreCollections,
    isLoadingInitialData: isLoadingInitialCollections,
    isLoadingMore: isLoadingMoreCollections,
    isReachingEnd: areCollectionsReachingEnd,
  } = useCollectionsByWalletIdWithPagination(8, walletAddress, 'music');

  return (
    <>
      {isLoadingInitialCollections && <BeatLoader color="#85838E" size={9} />}
      {!isLoadingInitialCollections && (
        <LayoutStack rowElements={6} gapX={16} gapY={16} marginTop={32}>
          {[
            ...collections.map((collection) => (
              <MusicCollectionCard key={collection.id} collection={collection} />
            )),
          ]}
        </LayoutStack>
      )}
      {!areCollectionsReachingEnd && (
        <LoadMoreButton isLoading={!!isLoadingMoreCollections} onClick={loadMoreCollections} />
      )}
    </>
  );
};
