import classNames from 'classnames';

import styles from './display-type-picker.module.css';

export enum DisplayType {
  Items,
  Collections,
}

interface Props {
  value: DisplayType;
  setValue: (displayType: DisplayType) => void;
  onPicked?: () => void;
}

// NOTE: As for now we have Tabs common component which is to be used
// instead of this one. So if you'd like to refactor this, then you
// probably would be satisfied with Tabs component as it's more generic.
//
// To be deleted and replaced with Tabs.
export const DisplayTypePicker = ({ value, setValue, onPicked }: Props): JSX.Element => {
  return (
    <div className={styles.displayTypeFilter}>
      <button
        onClick={(): void => {
          setValue(DisplayType.Items);
          if (onPicked !== undefined) {
            onPicked();
          }
        }}
        disabled={value === DisplayType.Items}
        className={classNames({ [styles.active]: value === DisplayType.Items })}
        id={'display-type-picker-items-button'}
      >
        Items
      </button>
      <button
        onClick={(): void => {
          setValue(DisplayType.Collections);
          if (onPicked !== undefined) {
            onPicked();
          }
        }}
        disabled={value === DisplayType.Collections}
        className={classNames({ [styles.active]: value === DisplayType.Collections })}
        id={'display-type-picker-collections-button'}
      >
        Collections
      </button>
    </div>
  );
};
