import { Link } from 'react-router-dom';
import { COLORS } from 'constant';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { CircularImage } from 'components/common';
import { MarkdownDisplay } from 'components/common/markdown-display/markdown-display';
import { UserSocialLinks } from 'components/user-social-links';
import { UserProfile } from 'services/user-service';
import truncate from 'utils/string-truncator';

import styles from './profile-summary.module.css';

interface Props {
  walletAddress: string;
  userProfile?: UserProfile;
  editable?: boolean;
}

export const ProfileSummary = ({ walletAddress, userProfile, editable }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.banner}>
          <img src={userProfile?.profileBannerUrl || '/bg-placeholder.png'} alt="background" />
        </div>
        <div className={styles.headerLowerSection}>
          <div className={styles.followActivity}>
            {/* TODO: Follow button, followers count, following count */}
          </div>
          <div className={styles.avatar}>
            <CircularImage
              imageUrl={userProfile?.profileAvatarUrl || '/av-placeholder.jpg'}
              width={144}
              height={144}
              withBorder
              borderColor={COLORS.GRAY_DARKER}
              borderWidth={8}
            />
          </div>
          <div className={styles.socialWrapper}>
            {userProfile && <UserSocialLinks userProfile={userProfile} />}
            {editable && (
              <Link className={styles.editButton} to="edit">
                <EditIcon />
                Edit
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className={styles.walletAddress}>{truncate(walletAddress)}</div>
      <div className={styles.username}>{userProfile?.displayName}</div>
      <div className={styles.bio}>
        <MarkdownDisplay text={userProfile?.bio} />
      </div>
    </div>
  );
};
