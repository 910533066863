import { ClipLoader } from 'react-spinners';

import styles from './carousel-placeholders.module.css';

export const LoadingItemsPlaceholder = (): JSX.Element => {
  return (
    <div className={styles.noItemsContainer}>
      <ClipLoader color={'#404048'} />
      <p>Loading</p>
    </div>
  );
};
