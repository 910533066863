import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { COLORS } from 'constant';
import { KeyedMutator } from 'swr';

import { Button, ButtonStyle, TableRow, TableRowElement, Typography } from 'components/common';
import { Dropdown, DropdownStyle } from 'components/common/dropdown';
import { GenericPopup } from 'components/common/generic-popup';
import { AuthContext } from 'context/auth-context';
import { Item } from 'services/item-service';
import {
  deletePlaylist,
  Playlist,
  PlaylistsList,
  useCurrentUserPlaylists,
} from 'services/music-service';
import { getDetailsFromItems, getItemsTotalDuration } from 'utils/playlist-helpers';

import styles from './playlist-table.module.css';

export const PlaylistsTable = ({
  playlists,
  isLoading,
  mutate,
  displayDropdown,
}: {
  playlists?: Playlist[];
  isLoading?: boolean;
  mutate?: KeyedMutator<PlaylistsList>;
  displayDropdown?: boolean;
}) => {
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [playlistIdToDelete, setPlaylistIdToDelete] = useState('');
  const navigate = useNavigate();
  const { jwt } = useContext(AuthContext);

  const onDelete = async () => {
    setDeletePopupOpen(false);
    const response = await deletePlaylist(jwt, playlistIdToDelete);
    if (response.status === 204) {
      mutate && mutate();
      setPlaylistIdToDelete('');
      toast.info('Playlist deleted');
    } else {
      toast.error('Error deleting playlist');
    }
  };

  const getColumnContent = (text: string) => (
    <Typography.Span color={COLORS.TEXT_INFO_2} fontSize={14} fontWeight={500}>
      {text}
    </Typography.Span>
  );
  const MUSIC_TABLE_HEADERS = () => [
    {
      columnContent: '',
      flexBasis: '3%',
    },
    {
      columnContent: getColumnContent('Title'),
      flexBasis: '80%',
    },
    {
      columnContent: getColumnContent('Duration'),
      flexBasis: '20%',
    },
  ];
  const PlaylistTableRow = ({
    id,
    name,
    description,
    items,
  }: {
    id: string;
    name: string;
    description: string;
    items: Item[];
  }) => {
    const TABLE_ROW_COLUMNS: TableRowElement[] = [
      {
        columnContent: (
          <div className={styles.titleCell} onClick={() => navigate(`/music/playlist/${id}`)}>
            <span className={styles.name}>{name}</span>
            <span>{'description'}</span>
          </div>
        ),
        flexBasis: '90%',
      },
      {
        columnContent: (
          <div className={styles.durationCell}>
            <div>{getDetailsFromItems(items)}</div>
            <div className={styles.dropdownContainer}>
              {displayDropdown && (
                <Dropdown
                  placeholderTitle="..."
                  isWide={true}
                  options={[
                    <div
                      key={'delete'}
                      onClick={() => {
                        setPlaylistIdToDelete(id);
                        setDeletePopupOpen(true);
                      }}
                    >
                      Delete
                    </div>,
                  ]}
                  dropdownStyle={DropdownStyle.Basic}
                  alignRight
                />
              )}
            </div>
          </div>
        ),
        flexBasis: '30%',
      },
    ];
    return (
      <div className={styles.rowWrapper}>
        <TableRow tableRowColumns={TABLE_ROW_COLUMNS} />
      </div>
    );
  };

  const PlaylistsTable = () => {
    return playlists?.length ? (
      <div>
        <div className={styles.tableHeader}>
          <TableRow tableRowColumns={MUSIC_TABLE_HEADERS()} />
        </div>
        {playlists?.map((p: any) => (
          <PlaylistTableRow
            key={p.id}
            id={p.id}
            name={p.displayName}
            description=""
            items={p.items}
          />
        ))}
      </div>
    ) : (
      <div className={styles.noPlaylistsText}>No playlists</div>
    );
  };

  return isLoading ? (
    <BeatLoader color="#85838E" size={9} />
  ) : (
    <div>
      <PlaylistsTable />
      {deletePopupOpen && (
        <GenericPopup
          canBeClosed
          title="Are you sure?"
          id={'delete-popup'}
          onClose={() => {
            setDeletePopupOpen(false);
          }}
        >
          <div>Do you want to delete this playlist?</div>
          <div className={styles.deletePopupButtons}>
            <Button id="delete-playlist-confirm" buttonStyle={ButtonStyle.color} onClick={onDelete}>
              YES
            </Button>
            <Button
              id="delete-playlist-cancel"
              buttonStyle={ButtonStyle.white}
              onClick={() => {
                setDeletePopupOpen(false);
              }}
            >
              NO
            </Button>
          </div>
        </GenericPopup>
      )}
    </div>
  );
};
