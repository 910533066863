import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import styles from './carousel.module.css';

import 'react-multi-carousel/lib/styles.css';

const getCarouselConfig = (numberOfElements: number): ResponsiveType => ({
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: numberOfElements < 4 ? numberOfElements : 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 790 },
    items: numberOfElements < 2 ? numberOfElements : 2,
  },
  mobile: {
    breakpoint: { max: 790, min: 0 },
    items: 1,
  },
});

interface Props {
  children: Array<JSX.Element>;
  title: JSX.Element | string;
  link?: string;
  linkDisplayName?: string;
}

export const GenericCarousel = ({ children, title, link, linkDisplayName }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      {link && linkDisplayName && (
        <div className={styles.link}>
          <Link to={link}>{linkDisplayName}</Link>
        </div>
      )}
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.sliderContainer}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Carousel
          responsive={getCarouselConfig(children.length)}
          containerClass={styles.carousel}
          infinite
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside
          autoPlay={false}
          shouldResetAutoplay={false}
          itemClass={styles.item}
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};

const LeftArrow = ({ onClick }: { onClick?: () => void }): JSX.Element => {
  return (
    <div className={classNames(styles.arrowContainer, styles.leftArrowContainer)}>
      <button className={styles.arrow} onClick={onClick} id={'carousel-left-arrow-button'}>
        <ArrowIcon />
      </button>
    </div>
  );
};

const RightArrow = ({ onClick }: { onClick?: () => void }): JSX.Element => {
  return (
    <div className={classNames(styles.arrowContainer, styles.rightArrowContainer)}>
      <button
        className={classNames(styles.arrow, styles.arrowRight)}
        onClick={onClick}
        id={'carousel-right-arrow-button'}
      >
        <ArrowIcon />
      </button>
    </div>
  );
};

const ButtonGroup = ({
  next,
  previous,
}: {
  next?: () => void;
  previous?: () => void;
}): JSX.Element => {
  return (
    <>
      <LeftArrow onClick={previous} />
      <RightArrow onClick={next} />
    </>
  );
};
