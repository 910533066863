import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { CallState, useCallWithState } from '../../hooks/call-with-state';
import { burnNft } from '../../services/blockchain-service';
import { Button, ButtonStyle } from '../common/button/button';
import { GenericPopup } from '../common/generic-popup/generic-popup';
import { UnderlinedInputControl } from '../common/underlined-input/underlined-input';
import styles from './burn-nft-component.module.css';

interface Props {
  itemId: string;
  ownedQuantity: number;
}

export const BurnNftComponent = ({ itemId, ownedQuantity }: Props): JSX.Element => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div>
      <Button
        buttonStyle={ButtonStyle.red}
        onClick={(): void => setIsPopupOpen(true)}
        id={'burn-nft-popup-open-button'}
      >
        Burn NFT
      </Button>
      {isPopupOpen && (
        <Popup
          itemId={itemId}
          ownedQuantity={ownedQuantity}
          closePopup={(): void => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
};

interface FormInputs {
  quantity: string;
}

const Popup = ({
  itemId,
  ownedQuantity,
  closePopup,
}: {
  itemId: string;
  ownedQuantity: number;
  closePopup: () => void;
}): JSX.Element => {
  const { walletAddress, refreshUserItem } = useContext(CurrentUserProfileContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormInputs>();

  const burn = useCallback(async () => {
    const quantity = parseInt(getValues('quantity'));

    await burnNft(walletAddress, itemId, quantity);
    const profile = await refreshUserItem(itemId);
    const isStillOwned = profile.ownedItems.find((entry) => entry.itemId === itemId) !== undefined;

    return isStillOwned;
  }, [getValues, itemId, refreshUserItem, walletAddress]);

  const { state, makeCall } = useCallWithState(burn, true);

  const submit = useCallback(async () => {
    try {
      const isStillOwned = await makeCall();

      if (isStillOwned) {
        closePopup();
      } else {
        navigate('/current-user');
      }
    } catch {
      toast.warn('Please retry.');
    }
  }, [closePopup, makeCall, navigate]);

  const renderButtonText = useCallback(() => {
    switch (state) {
      case CallState.Ready:
        return 'Burn';
      case CallState.Started:
        return <BeatLoader />;
      case CallState.Ended:
        return 'Burnt';
    }
  }, [state]);

  return (
    <GenericPopup
      canBeClosed={state === CallState.Ready}
      onClose={closePopup}
      title="Burn NFT"
      id={'burn-nft-popup'}
    >
      <div className={styles.container}>
        <UnderlinedInputControl
          type={'number'}
          title={'Quantity'}
          placeholder={'How many NFTs would you like to burn?'}
          id={'burn-nft-quantity-input'}
          {...register('quantity', {
            required: {
              value: true,
              message: 'You must specify quantity',
            },
            min: {
              value: 1,
              message: 'You must burn at least 1 item',
            },
            max: {
              value: ownedQuantity,
              message: `You can't burn more items than you have. You have: ${ownedQuantity} items.`,
            },
          })}
          errorMessage={errors.quantity?.message}
        />
        <Button
          onClick={handleSubmit(submit, () => {
            toast.warn('please verify form');
          })}
          buttonStyle={state === CallState.Ended ? ButtonStyle.green : ButtonStyle.red}
          disabled={state !== CallState.Ready}
          id={'burn-nft-popup-burn-button'}
          type="submit"
        >
          {renderButtonText()}
        </Button>
        <Button
          onClick={closePopup}
          buttonStyle={ButtonStyle.second}
          disabled={state !== CallState.Ready}
          id={'burn-nft-popup-cancel-button'}
        >
          Cancel
        </Button>
      </div>
    </GenericPopup>
  );
};
