import { useContext, useEffect, useState } from 'react';

import { AllowedCurrencies } from 'utils/blockchain-helpers';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file.svg';
import { AuctionCard } from '../../components/auction-card';
import { ItemCard } from '../../components/common/item-card/item-card';
import { MarkdownDisplay } from '../../components/common/markdown-display/markdown-display';
import { SaleCard } from '../../components/sale-card';
import { CurrentUserProfileContext } from '../../context/current-user-profile-context';
import { Item } from '../../services/item-service';
import { TransactionType } from '../../types/transaction-type';
import { JPEG_MIME_TYPE, PNG_MIME_TYPE } from '../../utils/accepted-image-types';
import truncate from '../../utils/string-truncator';
import styles from './create-forge-page.module.css';

interface Props {
  name: string;
  previewImage?: File;
  putOnSale: boolean;
  transactionType: TransactionType;
  currency: AllowedCurrencies;
  quantity?: number;
  price?: number;
  bid?: number;
  mainFile?: File;
  unlockableFile?: File;
  removeUnlockableFile: () => void;
  removeMainFile: () => void;
  description?: string;
}

export const PreviewColumn = ({
  name,
  previewImage,
  putOnSale,
  transactionType,
  currency,
  quantity,
  price,
  bid,
  mainFile,
  unlockableFile,
  removeUnlockableFile,
  removeMainFile,
  description,
}: Props): JSX.Element => {
  const { walletAddress } = useContext(CurrentUserProfileContext);
  const [mainFileUrl, setMainFileUrl] = useState<string>();
  const [previewImageFileUrl, setPreviewImageFileUrl] = useState<string>();

  useEffect(() => {
    if (previewImage !== undefined) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event.target && event.target.result) {
          setPreviewImageFileUrl(event.target.result as string);
        }
      };

      reader.readAsDataURL(previewImage);
    } else {
      setPreviewImageFileUrl(undefined);
    }
  }, [previewImage]);

  useEffect(() => {
    if (
      mainFile !== undefined &&
      (mainFile.type === JPEG_MIME_TYPE || mainFile.type === PNG_MIME_TYPE)
    ) {
      const reader = new FileReader();

      reader.onload = (event): void => {
        if (event.target && event.target.result) {
          setMainFileUrl(event.target.result as string);
        }
      };

      reader.readAsDataURL(mainFile);
    } else {
      setMainFileUrl(undefined);
    }
  }, [mainFile]);

  const itemObj: Item = {
    id: '',
    createdAt: '',
    updatedAt: '',
    firstOwnerWalletId: walletAddress,
    displayName: name,
    description: '',
    categoryId: '',
    contractAddress: '',
    mediaIpfsUrl: '',
    hasLockedMedia: false,
    nsfw: false,
    visibility: 'public',
    previewImageUrl: previewImageFileUrl || '',
    watermarkedImageUrl: '',
    likesCount: 0,
  };

  const renderCard = (): JSX.Element => {
    if (!putOnSale) {
      return <ItemCard prefetchedItem={itemObj} previewMode />;
    }

    if (transactionType === TransactionType.FixedPrice) {
      return (
        <SaleCard
          item={itemObj}
          price={price?.toString()}
          currency={currency}
          quantity={quantity || 0}
          ownerWalletAddress={walletAddress}
          saleId=""
          previewMode
        />
      );
    }

    return (
      <AuctionCard
        item={itemObj}
        minimalBid={bid?.toString()}
        currency={currency}
        quantity={quantity || 0}
        ownerWalletAddress={walletAddress}
        auctionId=""
        previewMode
      />
    );
  };

  return (
    <div className={styles.previewColumn}>
      <p>NFT Preview</p>
      {renderCard()}
      <p>Media</p>
      {mainFileUrl ? (
        <div className={styles.previewMainFileContainer}>
          <img className={styles.previewMainFile} src={mainFileUrl} alt="preview" />
          <CloseIcon
            className={styles.closeIcon}
            onClick={removeMainFile}
            id={'create-item-remove-main-file'}
          />
        </div>
      ) : mainFile ? (
        <NonImageFile
          filename={mainFile.name}
          onClick={removeMainFile}
          id={'create-item-remove-main-file'}
        />
      ) : (
        <p className={styles.noFilePicked}>No file picked.</p>
      )}
      <p>Unlockable file</p>
      {unlockableFile ? (
        <NonImageFile
          filename={unlockableFile.name}
          onClick={removeUnlockableFile}
          id={'create-item-remove-unlockable-file'}
        />
      ) : (
        <p className={styles.noFilePicked}>No file picked.</p>
      )}
      <p>Description preview:</p>
      <MarkdownDisplay text={description} />
    </div>
  );
};

const NonImageFile = ({
  filename,
  onClick,
  id,
}: {
  filename: string;
  onClick: () => void;
  id: string;
}): JSX.Element => {
  return (
    <div className={styles.nonImageFileContainer}>
      <FileIcon /> {truncate(filename, 10)}{' '}
      <CloseIcon className={styles.closeIcon} onClick={onClick} id={id} />
    </div>
  );
};
